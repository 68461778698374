import React from "react";
import { showNotification } from "@mantine/notifications";
import { NOTIFICATION_AUTO_CLOSE } from "../utils/constants";
import { IconCheck } from "@tabler/icons-react";

export function showSuccessNotification(message, title = "Success") {
  return showNotification({
    autoClose: NOTIFICATION_AUTO_CLOSE,
    title: title,
    message: message,
    color: "teal",
    icon: <IconCheck />,
  });
}

export function showErrorNotification(
  message = "Something went wrong. Please refresh the page or try again after sometime.",
  title = "Sorry"
) {
  return showNotification({
    autoClose: NOTIFICATION_AUTO_CLOSE,
    title: title,
    message: message,
    color: "red",
  });
}
