import React from 'react';
import { Loader, Container, Box } from '@mantine/core';

function FullPageSpinner() {
  return (
    <Container size="lg">
      <Box sx={{ position: 'relative', height: '100vh' }}>
        <Loader sx={{ position: 'absolute', top: '40%', right: '50%' }} size="lg" />
      </Box>
    </Container>
  );
}

export { FullPageSpinner };
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="a498d810-a6ca-5681-9039-59c38b7d92df")}catch(e){}}();
//# debugId=a498d810-a6ca-5681-9039-59c38b7d92df
