import React, { useEffect, useState, memo } from "react";
import { Text, Card, Space, Group, Badge, Divider } from "@mantine/core";

import style from "../../style.scss";
import { TextualReport, RatingReport, MCQReport, SurveyStats, EmailReport } from "./components/reports";
import { parseLogicFlow } from "../../utils/helpers";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "../../components/ErrorFallback";
import * as Sentry from "@sentry/react";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
//import ResponseFilters from './ResponseFilters';
//import { IconX } from '@tabler/icons-react';

export default memo(function SurveyResponseReport(props) {
  const { surveyId, questions, dateRange, platform, searchParams, setSearchParams } = props;
  const intl = useIntl();

  console.log("rangeDate", dateRange);

  //const [report, setReport] = useState();
  const initialGraph = new Map();
  const [graphType, setGraphType] = useState(initialGraph);
  //const [value, setValue] = useState('this_month');
  let navigate = useNavigate();

  const graphInitialState = questions => {
    questions &&
      questions.map(q => {
        switch (parseInt(q.question_type)) {
          case 2:
          case 3:
            initialGraph.set(q.id, "pie");
            break;
          case 4:
            initialGraph.set(q.id, "bar");
            break;
          case 5:
            initialGraph.set(q.id, "bar");
            break;
          case 1:
            initialGraph.set(q.id, "summary");
            break;
          default:
            break;
        }
      });
    return initialGraph;
  };

  //console.log('responses in responsereport', responseData);

  useEffect(() => {
    setGraphType(graphInitialState(questions));
  }, []);

  const internalProps = {
    graphType: graphType,
    setGraphType: setGraphType,
    dateRange: dateRange,
    searchParams: searchParams,
    setSearchParams: setSearchParams,
  };

  const questionTypeBadgeText = questionType => {
    switch (questionType) {
      case 1:
        return "Open Text";
      case 2:
        return "MCQ: Single Select";
      case 3:
        return "MCQ: Multi Select";
      case 4:
        return "NPS";
      case 5:
        return "Rating";
      case 12:
        return "Email";
      default:
        break;
    }
  };

  function logicStatement(question) {
    //console.log('####### question in reports #######', question);
    const questionflow =
      question.options.next_conditions && question.options.next_conditions.length > 0
        ? parseLogicFlow(question.order, question.id, question.options.next_conditions)
        : [];
    //console.log('####### questionflow #######', questionflow);
    return questionflow;
  }

  const logicstatements = questions && questions.map(q => logicStatement(q)).flat();
  //console.log('####### logicstatements #######', logicstatements);

  function logicMessage(question) {
    const filteredLogicStatements = logicstatements.filter(ls => ls.nextQ == question.order);

    //console.log('@####### filteredLogicStatements #######', filteredLogicStatements);
    if (filteredLogicStatements.length == 1) {
      return filteredLogicStatements[0].message + ".";
    } else if (filteredLogicStatements.length > 1) {
      const firstStatement = filteredLogicStatements[0];
      const statementsafterfirst = filteredLogicStatements.slice(1);

      const removeAfterfromStatementsAfterFirst = statementsafterfirst.map(s => s.message.replace("Asked", ""));
      // console.log('@####### statementsafterfirst', statementsafterfirst);
      // console.log('@####### removeAfterfromStatementsAfterFirst', statementsafterfirst);
      const finalStatements = firstStatement.message + " and " + removeAfterfromStatementsAfterFirst.join(" and ");
      return finalStatements + ".";
    } else {
      return "";
    }

    // return filteredLogicStatements.length > 0 ? filteredLogicStatements[0].message : '';
  }

  console.log("#19 questions", questions);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
      onError={error => {
        // log the error to your error reporting service
        Sentry.captureException(error);
      }}
    >
      <Group className={style.responseReport}>
        <div className={style.reportSection}>
          <Space h="md" />
          <SurveyStats surveyId={surveyId} dateRange={dateRange} platform={platform} searchParams={searchParams} />
          <Space h="50px" />
          {questions &&
            questions.map((q, idx) => {
              return (
                <div key={idx} style={{ clear: "both" }}>
                  <Card shadow="sm" p="lg" radius="md" withBorder sx={{ minHeight: "250px" }}>
                    <Group>
                      <Text weight={400}>
                        {idx + 1}. {q.question_text}
                      </Text>

                      <Badge color="gray" variant="outline">
                        {questionTypeBadgeText(q.question_type)}
                      </Badge>
                    </Group>
                    <Space h="xs" />
                    <Text size="xs" color="dimmed">
                      {logicMessage(q)}
                    </Text>

                    <Divider />
                    <Space h="sm" />

                    {q.question_type === 1 ? (
                      <TextualReport
                        questionType={q.question_type}
                        questionId={q.id}
                        surveyId={surveyId}
                        {...internalProps}
                      />
                    ) : null}
                    {q.question_type === 2 || q.question_type === 3 ? (
                      <MCQReport
                        questionType={q.question_type}
                        questionId={q.id}
                        surveyId={surveyId}
                        {...internalProps}
                      />
                    ) : null}

                    {q.question_type === 4 || q.question_type === 5 ? (
                      <RatingReport
                        questionType={q.question_type}
                        questionId={q.id}
                        surveyId={surveyId}
                        {...internalProps}
                        ratingScale={
                          q.options.rating_range
                            ? q.options.rating_scale
                            : q.options.rating_type
                            ? q.options.rating_type
                            : "numerical"
                        }
                        ratingRange={q.options.rating_range ? q.options.rating_range : q.options.rating_scale}
                      />
                    ) : null}
                    {q.question_type === 12 ? (
                      <EmailReport
                        questionType={q.question_type}
                        questionId={q.id}
                        surveyId={surveyId}
                        {...internalProps}
                      />
                    ) : null}
                  </Card>
                  <Space h="md" />
                </div>
              );
            })}
        </div>
      </Group>
    </ErrorBoundary>
  );
});
