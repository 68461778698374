import React from "react";

import { StyledTextInput } from "../../../../components/MantineOverride";

export function EmailQuestion(props) {
  const { idx, question, handleChange } = props;

  return (
    <div className="shqQuestion">
      <StyledTextInput
        label={`Question ${idx + 1}`}
        id={`question${idx}`}
        name="question"
        maxLength={256}
        value={question.questionText}
        onChange={e => handleChange(idx, e)}
      />
    </div>
  );
}
