import React from "react";

import AuthenticatedRoute from "./layouts/authenticated/AuthenticatedRoute";
import UnauthenticatedRoute from "./layouts/unauthenticated/UnauthenticatedRoute";
import Page404 from "./pages/Page404";

import SurveyOutlet from "./pages/SurveyOutlet";
import Survey from "./pages/survey/Survey";
import SurveyList from "./pages/surveylist/SurveyList";

import NewSurvey from "./pages/survey/NewSurvey";
import EditSurvey from "./pages/survey/EditSurvey";
import SurveyResponse from "./pages/responses/SurveyResponse";

import { Login, Signup } from "./pages/auth";
import { Organization, Settings, Team } from "./pages/account";
import { createBrowserRouter } from "react-router-dom";
import Connect from "./pages/connect/Connect";
import ForgotPassword from "./pages/auth/passwordreset/ForgotPassword";
import ResetPassword from "./pages/auth/passwordreset/ResetPassword";
import ResetDone from "./pages/auth/passwordreset/ResetDone";
import AccountActivated from "./pages/account/team/AccountActivated";
import AcceptTeamInvite from "./pages/account/team/AcceptTeamInvite";
import Data from "./pages/data/Data";
import { UserAttributes } from "./pages/data/UserAttributes";
import Slack from "./pages/integrations/Slack";
import Webhook from "./pages/integrations/Webhook";
import Zapier from "./pages/integrations/Zapier";
import Integrations from "./pages/integrations";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "./components/ErrorFallback";

const wrapWithErrorBoundary = element => (
  <ErrorBoundary
    FallbackComponent={ErrorFallback}
    onReset={() => {
      // reset the state of your app so the error doesn't happen again
    }}
    onError={error => {
      // log the error to your error reporting service
      Sentry.captureException(error);
    }}
  >
    {element}
  </ErrorBoundary>
);

import * as Sentry from "@sentry/react";
const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
export const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: <AuthenticatedRoute />,

    children: [
      // {
      //   index: true,
      //   element: <Dashboard />,
      // },
      {
        path: "connect",
        element: wrapWithErrorBoundary(<Connect />),
      },
      {
        path: "respondents",
        element: wrapWithErrorBoundary(<Data />),
      },
      {
        path: "attributes",
        element: wrapWithErrorBoundary(<UserAttributes />),
      },
      {
        path: "integrations/",
        element: wrapWithErrorBoundary(<Integrations />),
      },
      { path: "integrations/slack", element: wrapWithErrorBoundary(<Slack />) },
      { path: "integrations/webhook", element: wrapWithErrorBoundary(<Webhook />) },
      { path: "integrations/zapier", element: wrapWithErrorBoundary(<Zapier />) },
      {
        path: "surveys/",
        element: <SurveyOutlet />,

        children: [
          { index: true, element: wrapWithErrorBoundary(<SurveyList />) },
          { path: "new", element: wrapWithErrorBoundary(<Survey />), handle: { restrict: ["Viewer"] } },
          { path: "new/:template", element: wrapWithErrorBoundary(<NewSurvey />), handle: { restrict: ["Viewer"] } },
          {
            path: ":surveyId/edit/:section",
            element: wrapWithErrorBoundary(<EditSurvey />),
            handle: { restrict: ["Viewer"] },
          },
          { path: ":surveyId/responses/:tab", element: wrapWithErrorBoundary(<SurveyResponse />) },
        ],
      },
      { path: "account/sites", element: wrapWithErrorBoundary(<Organization />) },
      { path: "account/settings", element: wrapWithErrorBoundary(<Settings />) },
      { path: "account/team", element: wrapWithErrorBoundary(<Team />) },
    ],
  },
  {
    element: <UnauthenticatedRoute />,
    children: [
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/signup",
        element: <Signup />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "/reset-password/:token",
        element: <ResetPassword />,
      },
      {
        path: "/reset-password/:token/success",
        element: <ResetDone />,
      },
      { path: "invite/:token", element: <AcceptTeamInvite /> },
      { path: "invite/:token/success", element: <AccountActivated /> },
    ],
  },
  { path: "*", element: <Page404 /> },
]);
