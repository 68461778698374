import React, { useEffect, useState } from "react";
import { Box, Flex, TextInput, Select, MultiSelect, Group, Menu, Text, Chip, UnstyledButton } from "@mantine/core";

import style from "../../style.scss";
import { useIntl } from "react-intl";
import {
  comparatorByQuestionType,
  filteredLogicOptions,
  ratingOptionsByQtype,
  choiceOptions,
} from "../../utils/QuestionLogicHelper";

export function FilterQuestion(props) {
  const { surveyId, index, filter, questions, dispatch, token, csrftoken, searchParams } = props;
  const intl = useIntl();
  const [opened, setOpened] = useState(searchParams.get("filterby_response") ? false : true);
  const [comparatorOpened, setComparatorOpened] = useState(false);
  console.log("#filter", questions);
  //const { classes } = useStyles();

  const questionList = questions
    .filter(q => q.question_type !== 1)
    .map(question => {
      return {
        order: `Q${question.order + 1}`,
        value: question.id,
        qtype: question.question_type,
        label: question.question_text,
      };
    });

  console.log("#filter 2", questionList);

  function handleFilterChange(value, index, section) {
    console.log("#filter change", value, index, section);
    dispatch({ filterType: "responseFilter", type: "updateFilter", value: value, index: index, section: section });
    if (section == "qid") {
      dispatch({
        type: "updateFilter",
        filterType: "responseFilter",
        index: index,
        section: "qtype",
        value: questionList.find(q => q.value == value)?.qtype,
      });
    }
  }

  function selectedComparator(filterObj, idx) {
    console.log("filterObj", filterObj);
    if (filterObj.comparator !== "") {
      const selectedOperator = filteredLogicOptions(
        comparatorByQuestionType,
        selectedQuestion && selectedQuestion.question_type
      ).find(o => o.value == filterObj.comparator)?.operator;
      return selectedOperator;
    } else {
      dispatch({
        type: "updateFilter",
        filterType: "responseFilter",
        index: idx,
        section: "comparator",
        value: "eq",
      });
      return "=";
    }
  }

  const selectedQuestion = questions.find(q => q.id == filter.qid);
  console.log("@question filter", filter);

  function renderQuestionOptions(qtype) {
    console.log("#filter 3.3", filter.resp);
    if ((qtype === 2 || qtype === 3) && selectedQuestion.choices && selectedQuestion.choices.length > 0) {
      return (
        <Chip.Group
          multiple={true}
          value={filter.resp}
          sx={{ padding: "0.5rem 1.6rem" }}
          onChange={val => handleFilterChange(val, index, "resp")}
        >
          {selectedQuestion.choices.map((c, idx) => {
            return (
              <Chip key={idx} value={String(c.id)} radius="sm">
                {c.choice}
              </Chip>
            );
          })}
        </Chip.Group>
      );
    } else if (qtype === 4 || qtype === 5) {
      return (
        <Chip.Group
          multiple={true}
          sx={{ padding: "0.5rem 1.6rem" }}
          value={filter.resp}
          onChange={val => handleFilterChange(val, index, "resp")}
        >
          {ratingOptionsByQtype(parseInt(qtype)).map((rating, idx) => {
            return (
              <Chip key={idx} value={String(rating.value)} radius="sm">
                {rating.label}
              </Chip>
            );
          })}
        </Chip.Group>
      );
    }
  }

  return (
    <Box>
      <Group sx={{ flexGrow: 1 }} position="apart" spacing={0}>
        <Text px="1.6rem" size={14} color="#868E96">
          {intl.formatMessage({ id: "filters.response_to" })}
        </Text>
        <Menu sx={{ flexGrow: 1 }} position="bottom-start" shadow="md" width={300} opened={opened} onChange={setOpened}>
          <Menu.Target>
            <UnstyledButton>
              <Text className={`${style.filterHighlighter} ${opened && style.inputHighlight}`} size={16}>
                {filter && filter.qid
                  ? selectedQuestion.question_text
                  : intl.formatMessage({ id: "filters.select_question" })}
              </Text>
            </UnstyledButton>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Label>{intl.formatMessage({ id: "common.questions" })}</Menu.Label>
            {console.log("#19 questionlist", questionList)}
            {questionList
              .filter(q => q.qtype !== 12 && q.qtype !== 30)
              .map((q, idx) => {
                return (
                  <Menu.Item key={idx} onClick={() => handleFilterChange(q.value, index, "qid")}>
                    <Text size={14}>{q.label}</Text>
                  </Menu.Item>
                );
              })}
          </Menu.Dropdown>
        </Menu>
      </Group>

      <Flex
        mih={50}
        //bg="rgba(0, 0, 0, .3)"
        gap={4}
        justify="flex-start"
        align="flex-end"
        direction="row"
        wrap="wrap"
        grow={0}
      >
        <Text px="1.6rem" size={14} color="#868E96">
          {intl.formatMessage({ id: "filters.equals" })}
        </Text>
        {console.log("#filter 3.2", filter)}
        {filter.qid ? renderQuestionOptions(selectedQuestion.question_type) : null}
      </Flex>
    </Box>
  );
}
