import React from "react";

import { Switch } from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons-react";

export function IsRequired(props) {
  const { idx, question, dispatch, intl } = props;

  function handleChange(event) {
    event.stopPropagation();
    //setChecked(event.currentTarget.checked);
    if (event.currentTarget.checked === question.isRequired) return;
    if (question.isRequired === true && event.currentTarget.checked === false) {
      if (question.options.nextConditions && question.options.nextConditions.length > 0) {
        dispatch({
          type: "unSetNextConditions",
          index: idx,
        });
        dispatch({
          type: "addSkipLogic",
          index: idx,
        });
      }
    } else if (question.isRequired === false && event.currentTarget.checked === true) {
      if (question.options.nextConditions && question.options.nextConditions.length > 0) {
        dispatch({
          type: "unSetNextConditions",
          index: idx,
        });
      }
    }

    dispatch({
      type: "isRequired",
      index: idx,
      value: event.currentTarget.checked,
    });
  }

  return (
    <Switch
      label={intl.formatMessage({ id: "survey.q_required", defaultMessage: "Required" })}
      aria-label="Required"
      styles={() => ({
        label: {
          fontSize: "14px",
          color: "dimgray",
          paddingRight: "6px",
        },
        track: {
          //marginRight: '10px',
          pointerEvents: "all",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
      })}
      offset="xs"
      labelPosition="left"
      checked={question.isRequired}
      color="indigo"
      onClick={event => event.stopPropagation()}
      onChange={event => handleChange(event)}
      value={question.isRequired}
      thumbIcon={question.isRequired ? <IconCheck size={12} stroke={3} /> : <IconX size={12} stroke={3} />}
    />
  );
}
