import React from "react";
import { Paper, Text, Group, ActionIcon } from "@mantine/core";
import { IconCheck, IconPencil, IconTrash } from "@tabler/icons-react";

export default function EditNotification({ index, notificationState, setEditing, handleDelete }) {
  return (
    <Paper shadow="xs" p="md" mt={20} mb={20} withBorder>
      <Group position="apart">
        <Group spacing={4} grow={0}>
          <Text color="dimmed" size={14}>
            {index + 1}:
          </Text>
          <IconCheck style={{ marginRight: "10px" }} size={"2rem"} stroke={4} color="green" />
          <Text fz={16}>
            {notificationState.notify_for == "specific"
              ? "Notify for specific responses in "
              : "Notify for all responses in "}
          </Text>
          <Text span fz={16} fw={700}>
            {notificationState["channel"]["name"]}
          </Text>
        </Group>
        <Group>
          <ActionIcon>
            <IconPencil size={"2rem"} stroke={1} onClick={() => setEditing(true)} />
          </ActionIcon>
          <ActionIcon>
            <IconTrash size={"2rem"} stroke={1} onClick={e => handleDelete(e, notificationState.id, index)} />
          </ActionIcon>
        </Group>
      </Group>
    </Paper>
  );
}
