import styled from '@emotion/styled';
import { Text, TextInput, Select, Button, Alert, SegmentedControl, ColorInput, NumberInput } from '@mantine/core';

export const TextualTag = styled(Text)`
  color: ${({ theme }) => theme.colors.dark[3]};
  font-size: 10px;
  border: 1px solid ${({ theme }) => theme.colors.dark[3]};
  padding: 2px 5px;
  border-radius: 6px;
`;

export const SubHeadingText = styled(Text)`
  font-size: 14px;
  font-weight: 700;
`;

export const StyledTextInput = styled(TextInput)`
  & .mantine-TextInput-label {
    color: ${({ theme }) => theme.colors.dark[3]};
    font-size: 14px;
    font-weight: 400;
  }
  .mantine-TextInput-input {
    font-weight: 400;

    cursor: pointer;
    padding-right: 0px;
  }
  .mantine-TextInput-rightSection {
    pointer-events: all;
    z-index: 10;
    right: 0px;
    visibility: hidden;
    cursor: pointer;
    &:hover {
      visibility: visible;
    }
  }
`;

export const StyledSelect = styled(Select)`
  .mantine-Select-wrapper {
    width: ${props => props.width};
  }
  & .mantine-Select-label {
    color: ${({ theme }) => theme.colors.dark[3]};
    font-size: 14px;
    font-weight: 400;
  }
  .mantine-Select-input {
    font-weight: 400;
  }

  .mantine-Select-rightSection {
    pointer-events: none;
  }
  .mantine-Select-item {
    font-size: 14px;
    font-weight: 400;
    width: 100%;
  }
`;

export const GhostButton = styled(Button)`
  &.mantine-Button-root {
    color: ${({ theme }) => theme.colors.dark[2]};
    font-weight: 400;
    font-style: ${props => console.log('props.withBorder', props.withBorder)};
    border: ${props => (props.withBorder ? `1px solid` : '0px')};
    border-color: ${({ theme }) => theme.colors.dark[1]};
    background-color: transparent;
  }
`;

export const ErrorBox = styled(Alert)`
  .mantine-Alert-title {
    line-height: 1;
    font-weight: 500;
    margin: 0;
  }
  .mantine-Alert-icon {
    display: none;
  }
`;

export const SegmentedControlSHQ = styled(SegmentedControl)`
  input[type='radio']:checked + label.mantine-SegmentedControl-labelActive {
    background-color: white;
    color: #000000;
    font-weight: 700;
  }
  &.mantine-SegmentedControl-root {
    margin-bottom: 10px;
  }
`;

export const TextInputSHQ = styled(TextInput)`
  .mantine-Input-wrapper {
    width: 240px;
  }
`;

export const ColorInputSHQ = styled(ColorInput)`
  .mantine-Input-wrapper {
    width: 240px;
  }
`;

export const NumberInputSHQ = styled(NumberInput)`
  .mantine-Input-wrapper {
    width: 240px;
  }
`;
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="8b173197-e3e2-546e-9fd8-3087cd960286")}catch(e){}}();
//# debugId=8b173197-e3e2-546e-9fd8-3087cd960286
