import React, { useState, useEffect } from "react";
import { Text, Menu, Checkbox, Input, Tooltip, Button, Card, Group } from "@mantine/core";
import { useListState, randomId } from "@mantine/hooks";
import { arraySummariseWith } from "../../utils/helpers";
import { IconChevronDown } from "@tabler/icons-react";
import style from "../../style.scss";

export default function StringValueSelector(props) {
  const { filterObject, attributeValues, dispatch, idx, source } = props;
  const [stringValue, setStringValue] = useState(filterObject.predicate);
  const [stringOpened, setStringOpened] = useState(false);

  const attributeValueData = () => {
    const initialArray = [];
    attributeValues &&
      attributeValues
        .map(a => {
          if (a === null) {
            return null;
            // initialArray.push({ value: "Null", label: "Null", checked: stringValue.includes("Null"), key: randomId() });
          } else {
            initialArray.push({ value: a, label: a.toString(), checked: stringValue.includes(a), key: randomId() });
          }
        })
        .filter(item => item !== null);
    return initialArray;
  };

  const [values, handlers] = useListState(attributeValueData);
  const allChecked = values.every(value => value.checked);
  const indeterminate = values.some(value => value.checked) && !allChecked;

  useEffect(() => {
    console.log("attributeValues", attributeValues);
    attributeValueData();
  }, [attributeValues]);

  function handleApplyfilter() {
    const collection = [];
    values.map(v => {
      console.log(v);
      if (v.checked) {
        collection.push(v.value);
      }
    });
    setStringValue(collection);
    if (source === "filter") {
      dispatch({
        type: "updateFilter",
        filterType: "attributeFilter",
        index: idx,
        section: "predicate",
        value: collection,
      });
    } else {
      dispatch({
        type: "updateFilter",
        idx: idx,
        section: "predicate",
        value: collection,
      });
    }
    setStringOpened(false);
  }

  const items = values.map((value, index) => (
    <Checkbox
      mt="xs"
      size="xs"
      label={value.label}
      key={value.key}
      value={value.value}
      checked={value.checked}
      onChange={event => {
        handlers.setItemProp(index, "checked", event.currentTarget.checked);
        if (event.target.checked) {
          setStringValue([...stringValue, event.target.value]);
        } else {
          setStringValue([...stringValue.filter(s => s !== event.target.value)]);
        }
      }}
    />
  ));

  return (
    <Menu
      classNames={style.filterDropdown}
      withinPortal
      position="bottom-end"
      shadow="sm"
      opened={stringOpened}
      onChange={setStringOpened}
    >
      <Menu.Target>
        {source === "filter" ? (
          <Tooltip
            disabled={stringValue.length == 0}
            multiline
            sx={{ fontSize: "1.2rem" }}
            width="23rem"
            withArrow
            label={arraySummariseWith(stringValue, " or ")}
          >
            <Text
              className={`${style.filterHighlighter} ${style.filterValue} ${stringOpened && style.inputHighlight}`}
              size={14}
            >
              {filterObject.predicate.length > 0 ? arraySummariseWith(filterObject.predicate, " or ") : "Select value"}
            </Text>
          </Tooltip>
        ) : (
          <Card withBorder radius="xs" px={10} py={6}>
            <Group>
              <Text className={`${style.filterHighlighter} ${style.filterValue}`} size={14}>
                {filterObject.predicate.length > 0 ? (
                  arraySummariseWith(filterObject.predicate, " or ")
                ) : (
                  <Text color="dimmed">Select value</Text>
                )}
              </Text>
              <IconChevronDown size={16} color="rgb(134, 142, 150)" />
            </Group>
          </Card>
        )}
      </Menu.Target>

      <Menu.Dropdown className={style.filterDropdown}>
        <Input mb={20} sx={{ "input::placeholder": { fontSize: "14px" } }} placeholder="Search ..." />
        <Checkbox
          checked={allChecked}
          size="xs"
          indeterminate={!allChecked}
          label="Select All"
          transitionDuration={0}
          onChange={() => {
            handlers.setState(current => current.map(value => ({ ...value, checked: !allChecked })));
            //handleCheckAll();
          }}
        />

        {items}
        <Button mt={20} fullWidth disabled={!allChecked && !indeterminate} onClick={handleApplyfilter}>
          Add
        </Button>
      </Menu.Dropdown>
    </Menu>
  );
}
