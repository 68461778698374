import React, { useState, useEffect } from "react";
import { Table, Loader, Box, Center, Text, Group, Stack, Space, SegmentedControl } from "@mantine/core";

import { fetchSurveyQuestionResponseData } from "../../../../../api/Survey";
import TimeSegment from "../TimeSegment";
import { TimeseriesGraph } from "../../charts";
import ChartSegmentControl from "../../ChartSegmentControl";
import VerbatimModal from "./VerbatimModal";
import { SummaryReport, VerbatimReport } from "./";
import { IconChevronRight } from "@tabler/icons-react";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "../../../../../components/ErrorFallback";
import NotEnoughResponses from "./NotEnoughResponses";
import NoData from "../NoData";
import { API_URLS } from "../../../../../utils/constants";
import { useIntl } from "react-intl";
import * as Sentry from "@sentry/react";

export function TextualReport(props) {
  const { questionId, surveyId, graphType, setGraphType, dateRange, questionType, searchParams, setSearchParams } =
    props;
  const [responseData, setResponseData] = useState();
  const [interval, setInterval] = useState("month");
  const [currentTag, setCurrentTag] = useState();
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState();
  const [clickCount, setClickCount] = useState(0);

  const intl = useIntl();

  const [opened, setOpened] = useState(false);

  //const theme = useMantineTheme();

  useEffect(() => {
    fetchSurveyQuestionResponseData(surveyId, questionId, dateRange, interval, questionType, searchParams)
      .then(data => {
        console.log("data in fetchSurveyQuestionResponseData", data);
        setResponseData(data);
      })
      .catch(error => {
        console.log("error in fetchSurveyQuestionResponseData", error);
        setError(error);
      })
      .finally(setLoader(false));
  }, [dateRange, interval, searchParams]);

  const chartData = responseData && responseData.timeseries;
  const textData = responseData && responseData.summary;
  const dataSummary = responseData && responseData.summary;
  const sentimentData = dataSummary && dataSummary.sentiments;
  const tagsData = dataSummary && dataSummary.tags;
  const themesData = dataSummary && dataSummary.themes;

  console.log("~TEXT Data", responseData);

  console.log("~TEXT timelineChartData", chartData);
  console.log("~TEXT textData", textData);

  useEffect(() => {
    const taggedCountavailable = tagsData && tagsData.length > 1;
    if (!taggedCountavailable) {
      setGraphType(prevGraphType => new Map(prevGraphType).set(questionId, "verbatim"));
    }
  }, [tagsData]);

  const elements =
    chartData &&
    chartData.map(res => {
      return { tag: res["tag"], count: res["total_count"] };
    });

  console.log("~elements", elements);

  const rows =
    elements &&
    elements.map(element => (
      <tr
        key={element.tag}
        onClick={() => {
          setCurrentTag(element.tag);
          setClickCount(clickCount + 1);
          setOpened(true);
        }}
        style={{ cursor: "pointer" }}
      >
        <td>{element.tag}</td>
        <td>{element.count}</td>
        <td style={{ textAlign: "right" }}>
          <IconChevronRight color="gray" size={14} />
        </td>
      </tr>
    ));
  console.log("~rows", rows);

  function renderChart(type) {
    if (type === "verbatim") {
      return (
        <VerbatimReport
          duration={interval}
          searchParam={searchParams}
          surveyId={surveyId}
          dateRange={dateRange}
          questionId={questionId}
        />
      );
    } else if (type === "text") {
      return chartData ? (
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => {
            // reset the state of your app so the error doesn't happen again
          }}
          onError={error => {
            // log the error to your error reporting service
            Sentry.captureException(error);
          }}
        >
          <Table striped highlightOnHover withBorder>
            <thead>
              <tr>
                <th>{intl.formatMessage({ id: "reports.itdentified_themes", defaultMessage: "Identified Themes" })}</th>
                <th>{intl.formatMessage({ id: "reports.count", defaultMessage: "Count" })}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
        </ErrorBoundary>
      ) : (
        <NotEnoughResponses
          graphType={graphType}
          setGraphType={setGraphType}
          questionId={questionId}
          responseData={textData}
          taggedData={chartData}
        />
      );
    } else if (type === "summary") {
      return sentimentData.length > 1 || tagsData.length > 1 || themesData.length > 1 ? (
        <SummaryReport
          sentimentData={sentimentData}
          tagsData={tagsData}
          themesData={themesData}
          opened={opened}
          setOpened={setOpened}
          questionId={questionId}
          surveyId={surveyId}
          interval={interval}
          dateRange={dateRange}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
      ) : (
        <NotEnoughResponses
          graphType={graphType}
          responseData={textData}
          setGraphType={setGraphType}
          questionId={questionId}
        />
      );
    } else {
      return chartData ? (
        <div className="chartContainer" style={{ width: "100%" }}>
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
              // reset the state of your app so the error doesn't happen again
            }}
            onError={error => {
              // log the error to your error reporting service
              Sentry.captureException(error);
            }}
          >
            <TimeseriesGraph
              chartData={chartData}
              dataKey="tag"
              chartType={type}
              qtype={questionType}
              interval={interval}
              setInterval={setInterval}
            />
          </ErrorBoundary>
        </div>
      ) : (
        <NotEnoughResponses
          graphType={graphType}
          responseData={textData}
          setGraphType={setGraphType}
          questionId={questionId}
        />
      );
    }
  }

  // if (error) {
  //   return <Text>{error || 'Something went wrong.'}</Text>;
  // }

  if (!chartData && !textData) {
    return <NoData />;
  }

  return loader ? (
    <Center>
      <Loader />
    </Center>
  ) : chartData || textData ? (
    <Box>
      <Group position={"right"}>
        {(graphType.get(questionId) == "line" || graphType.get(questionId) == "bar") && (
          <TimeSegment interval={interval} setInterval={setInterval} />
        )}
        <ChartSegmentControl
          graphType={graphType}
          setGraphType={setGraphType}
          questionId={questionId}
          show={["summary", "bar", "line", "verbatim"]}
        />
      </Group>
      <Space h="md" />

      {renderChart(graphType.get(questionId))}

      {currentTag && (
        <VerbatimModal
          clickCount={clickCount}
          opened={opened}
          setOpened={setOpened}
          page1_url={API_URLS.GET_TAGGED_RESPONSES(surveyId, dateRange, questionId)}
          duration={interval}
          tag={currentTag}
        />
      )}
    </Box>
  ) : (
    <Center>
      <Loader />
    </Center>
  );
}
