import React from 'react';
import { Global } from '@mantine/core';

import { RobotoFont } from '../fonts/Roboto';
import { OpenSansFont } from '../fonts/openSans';
import { OrbitronFont } from '../fonts/Orbitron';

import { InterFont } from '../fonts/Inter';

export function CustomFonts() {
  return <Global styles={[RobotoFont, OrbitronFont, InterFont]} />;
}
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="a89e912f-a85f-50ff-968a-777c9a16fe21")}catch(e){}}();
//# debugId=a89e912f-a85f-50ff-968a-777c9a16fe21
