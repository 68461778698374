import React from "react";
import NPSTooltip from "./NPSTooltip";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine } from "recharts";

//import { graphColors } from '../../../../utils/colorScheme';

export function LineGraph2(props) {
  const { chartData, graphOptions = { xAxisProps: { dataKey: "answer" } }, questionType } = props;

  console.log("LINE chartData", chartData);

  console.log("LINEgraphOptions", graphOptions);

  // const chartData = responseSummary
  //   .sort((a, b) => a.answer - b.answer)
  //   .map(resp => {
  //     return {
  //       name: resp['answer'],
  //       value: resp['response_count'],
  //     };
  //   });

  const renderCustomizedLabel = ({ x, y, stroke, value }) => {
    // console.log('x in renderCustomizedLabel', x);
    // console.log('y in renderCustomizedLabel', y);
    // console.log('value in renderCustomizedLabel', value);
    if (y == undefined) {
      return false;
    }
    return (
      <text x={x} y={y} dy={-8} fill={stroke} fontSize={14} textAnchor="middle">
        {value}
      </text>
    );
  };

  console.log("chartdata: ", chartData);

  return (
    <ResponsiveContainer width="95%" height={400}>
      <LineChart width={700} data={chartData} margin={graphOptions.margin}>
        <CartesianGrid horizontal={false} strokeDasharray="1 1" />
        <XAxis {...graphOptions.xAxisProps} />
        <YAxis {...graphOptions.yAxisProps} />

        {questionType == 4 || questionType == 5 ? (
          <>
            <Tooltip content={<NPSTooltip questionType={questionType} />} />
            <Line
              type="linear"
              dataKey="score"
              stroke="#4c6ef5"
              activeDot={{ r: 10 }}
              label={renderCustomizedLabel}
              strokeWidth={3}
              animationDuration={100}
              isAnimationActive={false}
            />
            <ReferenceLine y={0} stroke="grey" strokeDasharray="3 3" />
          </>
        ) : questionType == 1 ? (
          chartData.map(entry => (
            <Line dataKey={"response_pct"} data={entry.data} name={entry.grouped_date} key={entry.grouped_date} />
          ))
        ) : (
          <Line
            type="monotone"
            dataKey="value"
            stroke="#4c6ef5"
            activeDot={{ r: 8 }}
            label={renderCustomizedLabel}
            animationDuration={100}
            isAnimationActive={false}
          />
        )}
      </LineChart>
    </ResponsiveContainer>
  );
}
