import React, { useEffect, useState } from "react";
import { Drawer, Text, Space, Table, Box, Stack, Avatar, Group, Card, ScrollArea, Divider } from "@mantine/core";
import { CopyButton, ActionIcon, Tooltip } from "@mantine/core";
import { IconCheck, IconCopy, IconTrash } from "@tabler/icons-react";
import { useAuthState } from "../context/AuthContext";
import API from "../api/API";
import { API_URLS } from "../utils/constants";
import { groupBy } from "../utils/helpers";
import dayjs from "dayjs";
import { showSuccessNotification, showErrorNotification } from "./notifications";
import { parseRatingResponse } from "../utils/helpers";

export default function RespondentDetailDrawer(props) {
  const { surveyId, opened, setOpened, userResponses, country, setSearchParams, searchParams, questions, owner } =
    props;
  const { user, csrftoken } = useAuthState();

  const canDeleteSurveyResponse = (user && user.email === owner) || (user && user.is_account_owner);

  // const respondentId = () => {
  //   if (respondent.user_id) {
  //     return respondent.user_id;
  //   } else if (respondent.id_uuid) {
  //     return respondent.id_uuid;
  //   } else if (respondent.anon_id) {
  //     return respondent.anon_id;
  //   } else {
  //     return respondent.client_id;
  //   }
  // };

  async function handleResponseDelete(sa_id, idx) {
    console.log("delete response", idx);
    const response = await API.delete(API_URLS.DELETE_SURVEY_RESPONSE(surveyId, sa_id), csrftoken);
    if (response.success) {
      userResponses.responses.splice(idx, 1);
      if (userResponses.responses.length === 0) {
        handleDrawerClose();
      }

      showSuccessNotification("Response deleted successfully");
      setTimeout(() => {
        window.location.reload();
      }, 1000);

      return response.success;
    } else {
      showErrorNotification(response.error.error);
      console.log(response.error);
    }
  }

  function handleDrawerClose() {
    setOpened(false);
    searchParams.delete("respondent");
    setSearchParams(searchParams);
  }

  const respondent = userResponses && userResponses.respondent;
  const metaData = userResponses && userResponses.meta_data;

  return (
    <Drawer position="right" opened={opened} onClose={() => handleDrawerClose()} padding="xl" size="xl" zIndex={999999}>
      <ScrollArea style={{ height: "900px" }} type="scroll" scrollbarSize={4}>
        <Stack justify="center" align="center">
          <Avatar radius="xl" color="indigo" />
          <Group>
            <Text size="16px">User ID: {respondent.user_id}</Text>
            <CopyButton value={respondent.user_id} timeout={2000}>
              {({ copied, copy }) => (
                <Tooltip label={copied ? "Copied" : "Copy"} withArrow position="right">
                  <ActionIcon color={copied ? "teal" : "gray"} onClick={copy}>
                    {copied ? <IconCheck size={16} /> : <IconCopy size={16} />}
                  </ActionIcon>
                </Tooltip>
              )}
            </CopyButton>
          </Group>
        </Stack>
        <Space h="lg" />
        {respondent && respondent.attributes && AttributeList(respondent.attributes)}
        {(country || metaData) && RespondentMetaList(country, metaData)}
        <Space h="lg" />
        <Text size="xs" weight="bold">
          User Responses
        </Text>
        <Divider />
        <Space h="lg" />
        {userResponses &&
          userResponses.responses.map((resp, idx) => {
            console.log("#20.0 resp", resp);
            return (
              <div key={idx} style={{ marginBottom: "50px" }}>
                <Group position="apart" mr={15}>
                  <Text size={14}>{dayjs(resp.started_at).format("MMM D, YYYY h:mm A")}</Text>{" "}
                  {canDeleteSurveyResponse && (
                    <ActionIcon onClick={idx => handleResponseDelete(resp.answers[0]?.survey_answer_id, idx)}>
                      <IconTrash size={16} />
                    </ActionIcon>
                  )}
                </Group>

                <AnswersInEachAttempt questions={questions} answers={resp.answers} />
              </div>
            );
          })}
      </ScrollArea>
    </Drawer>
  );
}

function AttributeList(attributes) {
  console.log("#20 attributes are", attributes);
  //if (!attributes) return;
  const rows = Object.keys(attributes).map(attribute => (
    <tr key={attribute}>
      <td>{attribute}</td>
      <td>{`${attributes[attribute]}`}</td>
    </tr>
  ));

  return (
    <>
      <Space h="sm" />
      <Text size="xs" weight="bold">
        User Attributes
      </Text>
      {Object.keys(attributes).length > 0 ? (
        <Table withBorder withColumnBorders fontSize="xs">
          <thead>
            <tr></tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      ) : (
        <Box sx={{ border: "1px solid #ddd", borderRadius: "4px", textAlign: "left", padding: "10px" }}>
          <Text size={14} color="dimmed">
            No attributes were set for this user
          </Text>
        </Box>
      )}
    </>
  );
}

function RespondentMetaList(country, meta_data) {
  return (
    <>
      <Space h="sm" />
      <Text size="xs" weight="bold">
        Other
      </Text>
      <Table withBorder withColumnBorders fontSize="xs">
        <thead>
          <tr></tr>
        </thead>
        <tbody>
          {meta_data && (
            <>
              <tr key="os">
                <td>OS</td>
                <td>
                  {meta_data["os"]} {meta_data["os_version"]}
                </td>
              </tr>
              <tr key="browser">
                <td>Browser</td>
                <td>
                  {meta_data["browser"]} {meta_data["browser_version"]}
                </td>
              </tr>
            </>
          )}
          {country && (
            <tr key="country">
              <td>Country</td>
              <td>{country}</td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
}

function AnswersInEachAttempt({ questions, answers }) {
  const groupByQuestionID = groupBy("question_id");

  return (
    <Card shadow="sm" p="md" radius="md" withBorder mr={10}>
      {Object.entries(groupByQuestionID(answers)).map(([q_id, value], idx) => {
        console.log("#19.4 q_id", q_id);
        return (
          <div key={q_id}>
            <Text size="16px" weight={400} color="dimmed">
              {idx + 1}: {questions.find(q => q.id === parseInt(q_id))?.question_text}
            </Text>
            <Text size="16px" weight={400} ml={18} mb={10}>
              {value
                .map(v => {
                  return parseRatingResponse(
                    questions.find(q => q.id === parseInt(q_id)),
                    v.answer
                  );
                })
                .join(",")}
              {value.map((v, idx) => {
                return v.comments ? ` (${v.comments})` : null;
              })}
            </Text>
          </div>
        );
      })}
    </Card>
  );
}
