import React, { useState, useEffect } from "react";
import { Box, Button, Space, Card, Group, Modal, Text, Table, Tooltip, Menu, ActionIcon } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { IconPlus, IconDots, IconWebhook } from "@tabler/icons-react";
import { useIntl } from "react-intl";
import { StyledSelect, StyledTextInput } from "../../../components/MantineOverride";
import { DeleteIcon, TextLink } from "../../../components/UI";
import { useDisclosure } from "@mantine/hooks";
import API from "../../../api/API";
import { eventHumanString } from "../../../utils/helpers";
import { showSuccessNotification, showErrorNotification } from "../../../components/notifications";
import { useForm } from "@mantine/form";
import { isValidHttpsUrl } from "../../../utils/helpers";
import { useAuthState } from "../../../context/AuthContext";

export default function Webhook(props) {
  const { csrfToken, surveyId } = props;
  const { user } = useAuthState();
  const [opened, { close, open }] = useDisclosure(false);
  const navigate = useNavigate();
  const userHasPermission = user.isAccountOwner || user.accessLevel == 1;

  const [webhookList, setWebhookList] = useState();
  const [configurationFlag, setConfigurationFlag] = useState(true);

  const [editFlag, setEditFlag] = useState(false);

  const form = useForm({
    initialValues: {
      endpoint: "",
      name: "",
      event: "SURVEY_COMPLETED",
      sid: surveyId,
      id: "",
    },

    validate: {
      name: value => (value.length < 2 ? "Name must have at least 2 letters" : null),
      endpoint: value => (isValidHttpsUrl(value) ? null : "Invalid URL."),
    },
  });

  async function getWebhooks() {
    let response = await API.get(`integrations/webhooks/?sid=${surveyId}`);
    if (response.success) {
      setConfigurationFlag(false);
      setWebhookList(response.success.data);
    }
  }

  function handleAddWebhook() {
    open();
    setEditFlag(false);
    form.reset();
  }

  async function testWebhook(event, id) {
    event.preventDefault();
    console.log("testing webhook");
    let response = await API.post(`integrations/webhooks/${id}/test/`, {}, csrfToken);
    if (response.success) {
      showSuccessNotification("Test webhook triggered successfully.");
      console.log("webhook test success", response.success);
    } else {
      showErrorNotification("Test webhook failed. Please try again.");
      console.log("webhook test failed", response.error);
    }
  }

  async function deleteWebhook(event, id) {
    event.preventDefault();
    let response = await API.delete(`integrations/webhooks/${id}/`, csrfToken);
    if (response.success) {
      setWebhookList(old => old.filter(webhook => webhook.id !== id));
      //   getWebhooks();
    }
  }

  async function editWebhook(event, id) {
    event.preventDefault();
    open();
    setEditFlag(true);
    const webhook = webhookList.filter(webhook => webhook.id === id)[0];
    console.log("editing webhook", webhook);
    form.setValues({
      name: webhook.name,
      endpoint: webhook.settings.webhook_endpoint,
      event: webhook.event,
      sid: surveyId,
      id: webhook.id,
    });
  }

  useEffect(() => {
    getWebhooks();
  }, []);

  const webhookTableData =
    webhookList &&
    webhookList.map(webhook => {
      return {
        id: webhook.id,
        name: webhook.name,
        endpoint: webhook.settings.webhook_endpoint,
        event: webhook.event,
      };
    });

  const rows =
    webhookTableData &&
    webhookTableData.map(row => (
      <tr key={row.id}>
        <td>
          <Tooltip label={row.name} position="top" withArrow>
            <Text sx={{ width: "120px" }} truncate>
              {row.name}
            </Text>
          </Tooltip>
        </td>
        <td>
          <Tooltip label={row.endpoint} position="top" withArrow>
            <Text sx={{ width: "200px" }} truncate>
              {row.endpoint}
            </Text>
          </Tooltip>
        </td>
        <td>
          <Text truncate="end">{eventHumanString(row.event)}</Text>
        </td>

        <td>
          <Menu shadow="md" width={100}>
            <Menu.Target>
              <ActionIcon>
                <IconDots />
              </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item onClick={e => testWebhook(e, row.id)}>
                <TextLink text="Test" />
              </Menu.Item>
              <Menu.Item onClick={e => editWebhook(e, row.id)}>
                <TextLink text="Edit" />
              </Menu.Item>
              <Menu.Item onClick={e => deleteWebhook(e, row.id)}>
                <DeleteIcon iconColor="red" />
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </td>
      </tr>
    ));

  const intl = useIntl();

  return (
    <Box>
      {userHasPermission && (
        <Group position="right">
          {configurationFlag ? (
            <Button
              sx={{ fontWeight: 400, fontSize: "14px" }}
              variant="light"
              leftIcon={<IconWebhook size={16} />}
              onClick={() => navigate(`/integrations/webhook`)}
            >
              {intl.formatMessage({ id: "integrations.configure_webhook_btn", defaultMessage: "Configure Webhook" })}
            </Button>
          ) : (
            <Button
              sx={{ fontWeight: 400, fontSize: "14px" }}
              variant="subtle"
              leftIcon={<IconPlus size={16} />}
              onClick={handleAddWebhook}
            >
              {intl.formatMessage({ id: "integrations.add_webhook_btn", defaultMessage: "Add Webhook" })}
            </Button>
          )}
        </Group>
      )}

      <Space h="md" />

      {rows && rows.length > 0 && (
        <Table fontSize={14}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Endpoint</th>
              <th>Trigger</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      )}

      <AddWebhookModal
        opened={opened}
        close={close}
        intl={intl}
        csrfToken={csrfToken}
        setWebhookList={setWebhookList}
        surveyId={surveyId}
        setEditFlag={setEditFlag}
        editFlag={editFlag}
        form={form}
      />
    </Box>
  );
}

//-------------------- AddWebhookModal.js --------------------

function AddWebhookModal(props) {
  const { opened, close, intl, csrfToken, setWebhookList, editFlag, setEditFlag, form } = props;

  async function handleWebhookSave(data) {
    console.log("handleWebhookSave", data);
    const requestMethod = editFlag ? "put" : "post";
    const postURL = editFlag ? `integrations/webhooks/${data.id}/` : "integrations/webhooks/";
    let response = await API[requestMethod](postURL, data, csrfToken);

    if (response.success) {
      if (editFlag) {
        const updatedWebhook = response.success.data;
        console.log("updatedWebhook", updatedWebhook);
        setWebhookList(old => old.filter(webhook => webhook.id !== data.id));
        setWebhookList(old => [...old, updatedWebhook[0]]);
        close();
      } else {
        setWebhookList(response.success.data);
        close();
      }
    } else {
      console.log("error", response.error);
    }
  }

  function handleClose() {
    setEditFlag(false);

    close();
  }

  return (
    <Modal
      opened={opened}
      width={600}
      onClose={handleClose}
      title={editFlag ? "Edit Webhook" : "Create Webhook"}
      styles={() => ({
        title: {
          fontWeight: 700,
        },
      })}
    >
      <Card p="lg">
        <form onSubmit={form.onSubmit(values => handleWebhookSave(values))}>
          <StyledTextInput
            label={intl.formatMessage({
              id: "common.name",
              defaultMessage: "Name",
            })}
            id="webhookname"
            name="webhookName"
            placeholder={intl.formatMessage({
              id: "integrations.webhook_name_placeholder",
              defaultMessage: "Webhook for [destination]",
            })}
            maxLength={256}
            {...form.getInputProps("name")}
          />
          <Space h="md" />
          <StyledTextInput
            label={intl.formatMessage({
              id: "integrations.webhook_url_label",
              defaultMessage: "URL",
            })}
            type="url"
            id={`webhookurl`}
            name="endpoint"
            placeholder="https://example.com/webhook"
            maxLength={256}
            {...form.getInputProps("endpoint")}
          />
          <Space h="md" />
          <StyledSelect
            label={intl.formatMessage({
              id: "integrations.webhook_trigger_label",
              defaultMessage: "Trigger",
            })}
            placeholder={intl.formatMessage({
              id: "common.pick_one",
              defaultMessage: "Pick one",
            })}
            data={[
              {
                value: "SURVEY_COMPLETED",
                label: intl.formatMessage({
                  id: "integrations.webhook_trigger_completed",
                  defaultMessage: "On survey completion",
                }),
              },
              {
                value: "SURVEY_ANSWERED",
                label: intl.formatMessage({
                  id: "integrations.webhook_trigger_response",
                  defaultMessage: "On every response",
                }),
              },
            ]}
            {...form.getInputProps("event")}
          />
          <Group mt={20} p={20} position="center">
            <Button type="submit">
              {intl.formatMessage({
                id: "common.save",
                defaultMessage: "Save",
              })}
            </Button>
          </Group>
        </form>
      </Card>
    </Modal>
  );
}
