import React from 'react';
import { Outlet } from 'react-router-dom';

export default function SurveyOutlet() {
  return (
    <div>
      <Outlet />
    </div>
  );
}
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="96d3a026-bc03-54c2-ba10-192927131597")}catch(e){}}();
//# debugId=96d3a026-bc03-54c2-ba10-192927131597
