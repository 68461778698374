import React, { forwardRef } from "react";
import { Group, Space, Text, Select } from "@mantine/core";
import { IconStar, Icon123, IconMoodSmile, IconChevronDown } from "@tabler/icons-react";
import { StyledTextInput, StyledSelect } from "../../../../components/MantineOverride";

//import OrderLogic from '../../OrderLogic';

export function Rating(props) {
  const { idx, question, handleChange, dispatch } = props;
  const ratingScaleOptions = [
    { label: "Numerical", value: "numerical", icon: <Icon123 size={14} /> },
    { label: "Star", value: "star", icon: <IconStar size={14} /> },
    { label: "Smiley", value: "emoji", icon: <IconMoodSmile size={14} /> },
  ];

  const ratingRangeOptions = [
    { label: "5 points", value: "5" },
    { label: "3 points", value: "3" },
  ];

  function handleSelectChange(val, type, section) {
    dispatch({
      type: type,
      index: idx,
      value: val,
      section: section,
    });
  }

  const SelectItem = forwardRef(function SelectItem({ label, icon, ...others }, ref) {
    return (
      <div ref={ref} {...others} key={idx}>
        <Group noWrap>
          {icon}

          <Text size="sm">{label}</Text>
        </Group>
      </div>
    );
  });

  return (
    <div className="shqQuestion">
      <StyledTextInput
        label={`Question ${idx + 1}`}
        id={`question${idx}`}
        name="question"
        value={question.questionText}
        maxLength={256}
        required
        onChange={e => handleChange(idx, e)}
      />
      <Space h="md" />
      {question.questionType === 5 && (
        <>
          <Group grow>
            <StyledSelect
              label="Scale"
              required
              icon={ratingScaleOptions.find(x => x.value == question.options.ratingScale)?.icon}
              rightSection={<IconChevronDown size={14} />}
              value={question.options.ratingScale}
              data={ratingScaleOptions}
              itemComponent={SelectItem}
              onChange={val => handleSelectChange(val, "updateRatingType", "ratingScale")}
            />
            <StyledSelect
              label="Range"
              required
              rightSection={<IconChevronDown size={14} />}
              value={`${question.options.ratingRange}`}
              data={ratingRangeOptions}
              itemComponent={SelectItem}
              onChange={val => handleSelectChange(parseInt(val), "updateRatingType", "ratingRange")}
            />
          </Group>
          <Space h="md" />
        </>
      )}

      <Group grow>
        <StyledTextInput
          label="Lowest range label"
          id="rating_low"
          name="rating_low_text"
          value={question.options.minText}
          onChange={e => handleChange(idx, e)}
        />

        <StyledTextInput
          label="Highest range label"
          id="rating_high"
          name="rating_high_text"
          value={question.options.maxText}
          onChange={e => handleChange(idx, e)}
        />
      </Group>
    </div>
  );
}
