import React from "react";

import { Box, Flex, TextInput, Select, MultiSelect, Group, ActionIcon, Tooltip } from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import {
  comparatorByQuestionType,
  filteredLogicOptions,
  ratingOptionsByQtype,
  choiceOptions,
} from "../../../../utils/QuestionLogicHelper";

export function NotificationFilter(props) {
  const { notificationIndex, index, conditions, questions, dispatch } = props;
  console.log("@slbug 4.0 nIndex", notificationIndex);

  console.log("@slbug slack questions", questions);

  const questionList = questions.map(question => {
    return {
      order: `Q${question.order + 1}`,
      value: question.id,
      qtype: question.questionType,
      label: question.questionText,
    };
  });

  function handleConditionChange(value, index, section) {
    console.log("@bug handleConditionChange", value);
    if (section === "question_id") {
      dispatch({ type: "updateCondition", nIndex: notificationIndex, value: value, index: index, section: section });
      dispatch({
        type: "updateCondition",
        nIndex: notificationIndex,
        value: questionList.find(q => q.value == value)?.qtype,
        index: index,
        section: "question_type",
      });
    } else {
      dispatch({ type: "updateCondition", nIndex: notificationIndex, value: value, index: index, section: section });
    }
  }

  function handleDelete() {
    dispatch({ type: "deleteCondition", index: index, nIndex: notificationIndex });
  }

  const selectedQuestion = questions.find(q => q.id == conditions[index].question_id);
  console.log("@slack conditions", conditions[index]);

  function renderPredicate(qtype) {
    if (qtype === 1) {
      return (
        <TextInput
          placeholder="Enter text"
          name="logicP"
          value={conditions[index].predicate}
          onChange={e => handleConditionChange(e, index, "predicate")}
        />
      );
    } else if (qtype === 4 || qtype === 5) {
      return (
        <Select
          data={ratingOptionsByQtype(parseInt(qtype))}
          onChange={val => handleConditionChange(val, index, "predicate")}
          value={
            (String(conditions[index].predicate).length > 0 &&
              ratingOptionsByQtype(parseInt(selectedQuestion.questionType)).find(
                x => parseInt(x.value) == conditions[index].predicate
              )?.value) ||
            ""
          }
          styles={() => ({
            dropdown: {
              width: "100px",
            },
          })}
        />
      );
    } else if (qtype === 2 && selectedQuestion.choices && selectedQuestion.choices.length > 0) {
      return (
        <Select
          placeholder="Select option"
          value={conditions[index].predicate}
          onChange={val => handleConditionChange(val, index, "predicate")}
          data={choiceOptions(selectedQuestion.choices)}
          styles={() => ({
            input: {
              width: "max-content",
            },
          })}
        />
      );
    } else if (qtype === 3 && selectedQuestion.choices && selectedQuestion.choices.length > 0) {
      return (
        <MultiSelect
          placeholder="Select option"
          value={conditions[index].predicate}
          onChange={val => handleConditionChange(val, index, "predicate")}
          data={choiceOptions(selectedQuestion.choices)}
        />
      );
    }
  }

  return (
    <Box>
      <Group position="apart">
        <Select
          label="the answer to question"
          value={conditions[index].question_id}
          onChange={val => handleConditionChange(val, index, "question_id")}
          placeholder="Select question"
          data={questionList}
          sx={{ flexGrow: "1" }}
        />
        {conditions.length > 1 && (
          <Tooltip label="Delete this filter.">
            <ActionIcon>
              <IconTrash size={"2rem"} stroke={1} onClick={handleDelete} />
            </ActionIcon>
          </Tooltip>
        )}
      </Group>

      <Flex
        mih={50}
        //bg="rgba(0, 0, 0, .3)"
        gap="sm"
        justify="flex-start"
        align="flex-end"
        direction="row"
        wrap="wrap"
        grow={0}
      >
        {conditions[index].question_id ? (
          <Select
            // sx={{ display: "flex" }}
            placeholder="Select operator"
            value={conditions[index].comparator}
            onChange={val => handleConditionChange(val, index, "comparator")}
            data={filteredLogicOptions(comparatorByQuestionType, selectedQuestion && selectedQuestion.questionType)}
            styles={() => ({
              root: {
                width: "240px",
              },
            })}
          />
        ) : null}

        {conditions[index].comparator
          ? renderPredicate(parseInt(selectedQuestion && selectedQuestion.questionType))
          : null}
      </Flex>
    </Box>
  );
}
!(function () {
  try {
    var e =
        "undefined" != typeof window
          ? window
          : "undefined" != typeof global
          ? global
          : "undefined" != typeof self
          ? self
          : {},
      n = new Error().stack;
    n &&
      ((e._sentryDebugIds = e._sentryDebugIds || {}), (e._sentryDebugIds[n] = "b1e9daea-b8b4-5665-9eef-4b29783cd81d"));
  } catch (e) {}
})();
//# debugId=b1e9daea-b8b4-5665-9eef-4b29783cd81d
