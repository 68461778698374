import React, { useState } from 'react';
import { useAuthState } from '../../context/AuthContext';
import { Respondents } from './Respondents';
import { UserAttributes } from './UserAttributes';

import { Tabs } from '@mantine/core';

function Data() {
  const auth = useAuthState();
  const [activeTab, setActiveTab] = useState('respondents');

  //const [attribs, setAttribs] = useState({});

  console.log('user in sites is', auth.user);
  console.log('current product in sites is', auth.currentProduct);
  //const userToken = auth.currentProduct !== null ? auth.currentProduct.prodToken : auth.organization.products[0].prodToken;

  return (
    <Tabs value={activeTab} onTabChange={setActiveTab}>
      <Tabs.List>
        <Tabs.Tab value="respondents">Users</Tabs.Tab>
        <Tabs.Tab value="attributes">Attributes</Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="respondents">
        <Respondents />
      </Tabs.Panel>
      <Tabs.Panel value="attributes">
        <UserAttributes />
      </Tabs.Panel>
    </Tabs>
  );
}

export default Data;
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="e0dd3ec6-1f70-5b1a-b9d9-2ef4837d830e")}catch(e){}}();
//# debugId=e0dd3ec6-1f70-5b1a-b9d9-2ef4837d830e
