import React, { useEffect } from "react";
import { useAuthState, useAuthDispatch } from "../../context/AuthContext";
import { getCSRF } from "../../context/AuthContext";
//import { Outlet } from 'react-router-dom';
//import { useRouteMatch } from 'react-router';

import DashboardLayout from "./DashboardLayout";
// import NavSidebar from './NavSidebar';
// import { ErrorBoundary } from 'react-error-boundary';
// import { ErrorFallback } from '../../components/ErrorFallback';
//import style from './../../style.scss';
//import { useAuthState } from '../../../context/AuthContext';

const AuthenticatedLayout = () => {
  const { csrftoken } = useAuthState();
  const dispatch = useAuthDispatch();

  useEffect(() => {
    console.log("csrf in getCSRF", csrftoken);
    if (csrftoken === null) {
      getCSRF(dispatch);
    }
  }, []);
  //return <Layout opened={opened} />;
  return <DashboardLayout />;
};

export default AuthenticatedLayout;
