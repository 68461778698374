import React, { useState, useEffect } from "react";
import { Center, Card, Box, Text, Space, Container, Title, Group, Loader, Paper } from "@mantine/core";
import { useForm } from "@mantine/form";
import { Link, useNavigate, Navigate, useParams } from "react-router-dom";
import { useBrowserStorage } from "../../../hooks";
import { camelizeKeys } from "humps";

import { IconArrowNarrowLeft, IconExclamationCircle } from "@tabler/icons-react";

import { isValidURL } from "../../../utils/helpers";

import { useAuthState, useAuthDispatch, getCSRF } from "./../../../context/AuthContext";
import API from "../../../api/API";
import { API_URLS } from "../../../utils/constants";
import { SignupForm } from "./partials/SignupForm";
import { AccountForm } from "./partials/AccountForm";
import { FullPageSpinner } from "./../../../components/spinners";
import style from "../../../style.scss";
//import { validateBetaInviteToken } from '../../../api/Auth';

export function Signup() {
  //let { token } = useParams();
  const { user, loading, csrftoken } = useAuthState();

  const [step, setStep] = useState(0);
  const [error, setError] = useState(null);

  // const [success, setSuccess] = useState(false);
  // const [tokenLoader, setTokenLoader] = useState(true);
  // const [tokenError, setTokenError] = useState(false);

  const [currentProduct, setCurrentProduct] = useBrowserStorage("Local", "currentProduct", "");
  let navigate = useNavigate();
  const dispatch = useAuthDispatch();

  useEffect(() => {
    console.log("user in getCSRF", user);
    if (csrftoken === null) {
      getCSRF(dispatch);
    }
  }, []);

  // useEffect(() => {
  //   getCSRF(dispatch).then(csrftoken =>
  //     validateBetaInviteToken(token, csrftoken)
  //       .then(data => {
  //         if (data.status_code === 404) {
  //           setTokenError(data);
  //         }
  //         setSuccess(true);

  //         console.log('resetpassword token data', data);
  //       })
  //       .catch(error => {
  //         console.log('****Token Error ****', error);

  //         setTokenError(error);
  //       })
  //       .finally(setTokenLoader(false))
  //   );
  // }, [token]);

  const form = useForm({
    initialValues: {
      fullName: "",
      email: "",
      password: "",
      product_name: "",
      product_website: "",
      website_protocol: "https://",
    },

    validate: values => {
      console.log("STEP in validation is", step);
      if (step === 0) {
        return {
          fullName: values.fullName.trim().length < 2 ? "Name must include at least 2 characters" : null,
          email: /^\S+@\S+$/.test(values.email) ? null : "Invalid email",
          password: values.password.length < 6 ? "Password must include at least 6 characters" : null,
        };
      }

      if (step === 1) {
        console.log("STEP 1 in validation is", values);
        return {
          product_name: values.product_name.trim().length < 2 ? "Name must include at least 2 characters" : null,
          product_website: isValidURL(values.website_protocol + values.product_website) ? null : "Invalid url",
        };
      }

      return {};
    },
    transformValues: values => ({
      fullName: values.fullName,
      email: values.email,
      password: values.password,
      product: {
        name: values.product_name,
        website: values.website_protocol + values.product_website,
      },
    }),
    //validateInputOnChange: true,
  });

  const nextStep = () => {
    console.log("current step is ", form.errors);
    setStep(current => {
      if (form.validate().hasErrors) {
        return current;
      }
      return current < 2 ? current + 1 : current;
    });
  };

  const prevStep = () => setStep(current => (current > 0 ? current - 1 : current));

  function renderAccountForm() {
    const props = {
      form: form,
      loading: loading,
      error: error,
      handleSubmit: handleSubmit,
      next: nextStep,
      prev: prevStep,
    };
    switch (step) {
      case 0:
        return <SignupForm {...props} />;
      case 1:
        return <AccountForm {...props} />;
      default:
        return null;
    }
  }

  function LoginLink() {
    if (step === 0) {
      return (
        <Text size="sm">
          Existing account?&nbsp;
          <Link to="/login/" style={{ fontSize: "1.6rem", color: "#228be6" }}>
            Login
          </Link>
        </Text>
      );
    }
  }
  async function handleSubmit(values) {
    //console.log('event in singup handle submit is', e);
    console.log("values in singup handle submit are", values);
    //e.preventDefault();
    dispatch({ type: "AUTH_REQUEST" });
    let response = await API.post(API_URLS.SIGNUP, { ...values }, csrftoken);
    console.log("Response 1 in handle Submit", response);
    if (response.error) {
      dispatch({ type: "AUTH_ERROR", error: response.error });
      //#TODO add notification here
      setError(response.error);
      if (response.error.email) {
        prevStep();
        //navigate('/login');
      }
      return;
    } else {
      dispatch({ type: "AUTH_SUCCESS", payload: camelizeKeys(response.success.data.user) });
      setCurrentProduct(camelizeKeys(response.success.data.user.organization.products[0]));
      navigate("/surveys/new/");
      //window.location.replace('/surveys/');
      return;
    }
  }
  return !loading ? (
    user === null || user === undefined ? (
      <Container size={520} my={40}>
        <Title align="center" sx={{ fontWeight: 900 }}>
          Welcome to <span style={{ color: "#475569" }}>Feedback</span>Spark
        </Title>
        <Text color="dimmed" size="sm" align="center" mt={5}>
          Create your free account.
        </Text>
        <Card shadow="md" p={30} mt={30} radius="md" withBorder>
          <Box mx="auto">
            {step === 1 && (
              <Link className={style.backArrow} onClick={prevStep} to="#">
                <IconArrowNarrowLeft size={16} />
              </Link>
            )}
            <Group position="center">
              <Text size="xl">{step === 0 ? "Sign up" : "Account"}</Text>
            </Group>
            <form onSubmit={form.onSubmit(handleSubmit)}>{renderAccountForm()}</form>
            <Space h="md" />
            <Center>{LoginLink()}</Center>
          </Box>
        </Card>
      </Container>
    ) : (
      <Navigate to="/surveys/new/" />
    )
  ) : (
    <FullPageSpinner />
  );
}
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="a1b47a0f-30cf-5f6d-b753-ee1edbf50601")}catch(e){}}();
//# debugId=a1b47a0f-30cf-5f6d-b753-ee1edbf50601
