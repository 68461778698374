import React, { useState } from 'react';
import { Group, Text, useMantineTheme, Image, Button, Box } from '@mantine/core';
import { IconUpload, IconPhoto, IconX, IconTrash } from '@tabler/icons-react';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { showErrorNotification, showSuccessNotification } from './notifications';
import { uploadProductLogo } from '../api/Account';
import { useAuthState, useAuthDispatch } from '../context/AuthContext';
import { camelizeKeys } from 'humps';

export default function UploadImage(props) {
  const { token, setUploadModalOpened } = props;
  const dispatch = useAuthDispatch();
  const { csrftoken } = useAuthState();
  const theme = useMantineTheme();
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  function handleImageRemove(imgURL, idx) {
    URL.revokeObjectURL(imgURL);
    //e.preventDefault();
    files.splice(idx, 1);
    setFiles([...files]);

    console.log('final files', files);
  }

  function handleImageChange(files) {
    console.log('image added', files);
    setFiles(files);
  }

  const previews = files.map((file, index) => {
    const imageUrl = URL.createObjectURL(file);
    console.log('image url', imageUrl);
    return (
      <Box key={index}>
        <Group position="apart">
          <Image
            fit="contain"
            width="auto"
            height={100}
            src={imageUrl}
            imageProps={{ onLoad: () => URL.revokeObjectURL(imageUrl) }}
          />

          <IconTrash
            stroke={1}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              handleImageRemove(imageUrl, index);
            }}
          />
        </Group>
      </Box>
    );
  });

  const uploadToS3 = () => {
    console.log('files are', files);
    const formData = new FormData();
    console.log('1: formData is', formData);

    formData.append('logo_img', files[0]);
    console.log(files);
    console.log('2: formData is', formData);
    setLoading(true);
    uploadProductLogo(formData, csrftoken, token).then(data => {
      if (data.status === 200) {
        console.log(data);
        dispatch({ type: 'UPDATE_PRODUCT_LOGO', payload: camelizeKeys(data.data) });
        setLoading(false);

        setFiles([]);

        //setUploadModalOpened(false);
        showSuccessNotification('Image uploaded');
      } else {
        showErrorNotification();
        setLoading(false);
      }
    });
  };

  return (
    <>
      <Dropzone
        loading={loading}
        sx={() => ({
          minHeight: 100,
          maxHeight: '300px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: '2px dashed rgb(206, 212, 218)',
        })}
        styles={{ inner: { pointerEvents: 'all' } }}
        onDrop={e => handleImageChange(e)}
        onReject={files => console.log('rejected files', files)}
        maxSize={3 * 1024 ** 2}
        maxFiles={1}
        multiple={false}
        accept={IMAGE_MIME_TYPE}
        {...props}
      >
        <Group position="center" spacing="xl" style={{ pointerEvents: 'none' }}>
          <Dropzone.Accept>
            <IconUpload
              size={50}
              stroke={1}
              color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
            />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <IconX size={50} stroke={1} color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]} />
          </Dropzone.Reject>
          <Dropzone.Idle>
            <IconPhoto size={50} stroke={1} color="#676262" />
          </Dropzone.Idle>
          <Text size="xl" data-autofocus inline color="#676262">
            Drag image here or click to select file.
          </Text>
        </Group>
      </Dropzone>
      <div
        style={{
          marginTop: '20px',
          marginBottom: '20px',
        }}
      >
        {previews}
      </div>
      {files.length > 0 && (
        <Group position="center">
          <Button onClick={() => uploadToS3()}>Upload</Button>
        </Group>
      )}
    </>
  );
}
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="8fd2434c-aa5f-5dbb-a7a7-d1caf2fac723")}catch(e){}}();
//# debugId=8fd2434c-aa5f-5dbb-a7a7-d1caf2fac723
