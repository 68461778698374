import React, { useState } from "react";
import { Menu, Text, Button, Box, Group } from "@mantine/core";
import { RangeCalendar } from "@mantine/dates";
import { IconCalendar } from "@tabler/icons-react";
import style from "../style.scss";
import dayjs from "dayjs";
import { useIntl } from "react-intl";
//require("dayjs/locale/es");

export default function CustomDateRangePicker(props) {
  const { minDate, maxDate, handleSubmit, customDate, setCustomDate, timeframe } = props;
  const intl = useIntl();

  const user_locale = intl.locale;

  // const [highlight, setHighlight] = useState(false);

  const [menuOpen, setMenuOpen] = useState(false);
  function handleApply() {
    handleSubmit();
    // setHighlight(true);
    setMenuOpen(false);
  }
  function handleCancel() {
    setMenuOpen(false);
    if (timeframe !== null) {
      setCustomDate([null, null]);
    }
  }

  const initialMonthProps = {
    initialMonth: dayjs(customDate[0]).toDate(),
  };

  return (
    <Menu
      withinPortal
      position="bottom-end"
      shadow="sm"
      closeOnClickOutside={true}
      opened={menuOpen}
      onChange={setMenuOpen}
    >
      <Menu.Target>
        <Button
          leftIcon={<IconCalendar stroke={1} size={20} />}
          classNames={{ inner: customDate.every(i => i !== null) && timeframe == null ? style.highlightCustom : null }}
          variant="light"
          styles={() => ({
            root: {
              backgroundColor: "#f1f3f5",
              color: "#495057",
              border: 0,
              padding: "4px",
              fontWeight: 300,
              fontSize: "14px",
              height: "40px",
              borderTopRightRadius: "0px",
              borderBottomRightRadius: "0px",
              marginRight: "-5px",

              "&:hover": {
                backgroundColor: "#f1f3f5",
              },
            },
            inner: {
              padding: "5px 10px",
              borderRadius: "4px",
              borderRight: customDate.every(i => i !== null) && timeframe == null ? "" : "2px solid #dee2e6",
              transition: "color 200ms ease",
            },
            label: {},
          })}
        >
          {customDate.every(i => i !== null) && timeframe === null ? (
            <Text size={14}>
              {customDate[0]
                ? dayjs(customDate[0]).locale(user_locale).format("MMMM D, YYYY")
                : intl.formatMessage({ id: "reports.filter_start_date", defaultMessage: "Start Date" })}{" "}
              -{" "}
              {customDate[1]
                ? dayjs(customDate[1]).locale(user_locale).format("MMMM D, YYYY")
                : intl.formatMessage({ id: "reports.filter_end_date", defaultMessage: "End Date" })}
            </Text>
          ) : (
            intl.formatMessage({ id: "reports.custom_date", defaultMessage: "Custom" })
          )}
        </Button>
      </Menu.Target>

      <Menu.Dropdown>
        <Box sx={{ padding: "10px" }}>
          <RangeCalendar
            value={customDate}
            allowLevelChange={false}
            allowSingleDateInRange={false}
            {...(customDate[0] !== null && initialMonthProps)}
            onChange={setCustomDate}
            maxDate={maxDate}
            //minDate={minDate}
            inputformat="MMM DD, YYYY"
            icon={<IconCalendar size={16} />}
            locale="en"
          />
        </Box>
        <Box sx={{ padding: "10px", borderTop: "1px solid #dee2e6" }}>
          <Group position="right">
            <Button
              sx={{ fontSize: "16px", fontWeight: "500" }}
              variant="subtle"
              color="gray"
              onClick={() => handleCancel()}
            >
              {intl.formatMessage({ id: "common.cancel", defaultMessage: "Cancel" })}
            </Button>
            <Button sx={{ fontSize: "16px", fontWeight: "500" }} variant="subtle" onClick={() => handleApply()}>
              {intl.formatMessage({ id: "common.apply", defaultMessage: "Apply" })}
            </Button>
          </Group>
        </Box>
      </Menu.Dropdown>
    </Menu>
  );
}
