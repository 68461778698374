import update, { extend } from "immutability-helper";
extend("$auto", function (value, object) {
  return object ? update(object, value) : update({}, value);
});
extend("$autoArray", function (value, object) {
  return object ? update(object, value) : update([], value);
});

export const FilterReducer = (state, action) => {
  switch (action.type) {
    case "addFilter":
      return update(state, {
        [action.filterType]: {
          ...(action.filterType === "attributeFilter" && {
            $autoArray: { $push: [{ property: "", operator: "", predicate: "", datatype: "" }] },
          }),
          ...(action.filterType === "responseFilter" && {
            $autoArray: { $push: [{ qid: "", resp: [], qtype: "" }] },
          }),
          ...(action.filterType === "countryFilter" && {
            $autoArray: { $push: [{ code: [] }] },
          }),
        },
      });
    case "deleteFilter":
      return update(state, {
        [action.filterType]: {
          $splice: [[action.index, 1]],
        },
      });
    case "clearAllFilters":
      return update(state, {
        responseFilter: {
          $set: [],
        },
        attributeFilter: {
          $set: [],
        },
        countryFilter: {
          $set: [],
        },
      });
    case "clearEmptyFilters":
      return update(state, {
        responseFilter: {
          $apply: arr => arr.filter(f => f.resp.length > 0),
        },
        countryFilter: {
          $apply: arr => arr.filter(f => f.code.length > 0),
        },
      });
    case "updateFilter":
      return update(state, {
        [action.filterType]: {
          [action.index]: {
            [action.section]: {
              $set: action.value,
            },
            ...(action.section === "property" &&
              action.datatype && {
                datatype: {
                  $set: action.datatype,
                },
                operator: {
                  $set: "",
                },

                predicate: {
                  $set: "",
                },
              }),
            ...(action.section === "operator" &&
              action.datatype === "date" &&
              action.value === "last" && {
                value: {
                  $set: "7-day",
                },
              }),
          },
        },
      });
    case "updateCountryFilter":
      return update(state, {
        countryFilter: {
          [action.index]: {
            code: {
              $set: action.value,
            },
          },
        },
      });

    // case "updateAttributeFilter":
    //   console.log("updateAttributeFilter", action);
    //   return update(state, {
    //     [action.idx]: {
    //       [action.section]: {
    //         $set: action.value,
    //       },
    //       ...(action.section === "property" &&
    //         action.datatype && {
    //           datatype: {
    //             $set: action.datatype,
    //           },
    //           operator: {
    //             $set: "",
    //           },
    //         }),
    //       ...(action.section === "oprator" &&
    //         action.datatype === "date" &&
    //         action.value === "last" && {
    //           value: {
    //             $set: "7-day",
    //           },
    //         }),
    //     },
    //   });

    default:
      break;
  }
};
