import React from "react";
import { Center } from "@mantine/core";
import { IconChartBar, IconChartPie2, IconChartLine, IconMessage2, IconFileText, IconWand } from "@tabler/icons-react";

import { SegmentedControlSHQ } from "../../../components/MantineOverride";

export default function ChartSegmentControl(props) {
  const { graphType, setGraphType, questionId, show } = props;

  function handleSegmentControlChange(val, questionId) {
    setGraphType(new Map([...graphType, ...new Map([[questionId, val]])]));
  }

  return (
    <SegmentedControlSHQ
      value={graphType.get(questionId)}
      radius={20}
      onChange={val => handleSegmentControlChange(val, questionId)}
      data={[
        {
          value: "summary",
          label: (
            <Center>
              <IconWand size={16} />
            </Center>
          ),
        },
        {
          value: "bar",
          label: (
            <Center>
              <IconChartBar size={16} />
            </Center>
          ),
        },
        {
          value: "pie",
          label: (
            <Center>
              <IconChartPie2 size={16} />
            </Center>
          ),
        },
        {
          value: "line",
          label: (
            <Center>
              <IconChartLine size={16} />
            </Center>
          ),
        },
        {
          value: "verbatim",
          label: (
            <Center>
              <IconMessage2 size={16} />
            </Center>
          ),
        },
      ].filter(d => show.includes(d.value))}
    />
  );
}
