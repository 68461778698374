// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.widgetTheme-main-X4Wh2 {
    color: var(--shq-main-font-color);
    background-color: var(--shq-main-bg-color);
    border: 1px solid var(--shq-main-border-color);
}

.widgetTheme-thankyou-L5WmF svg {
    color: var(--shq-icon-color);

}

.widgetTheme-brandWrapper-dfCIb a {
    color: var(--shq-brand-link);
}


.widgetTheme-ratingCellemoji-n98de > .widgetTheme-emojiIcon-sMOiA:hover {
    color: var(--shq-main-bg-color);
    fill: var(--shq-emoji-bg-color);
    stroke: var(--shq-main-bg-color);
    transform: scale(1.2);
    /* fill: var(--shq-rating-accent-color);
    color: var(--shq-rating-font-color); */
}

.widgetTheme-ratingCellemoji-n98de{
    border: none;
}
input.widgetTheme-ratingInput-lnaGA[type='radio']:checked + label.widgetTheme-ratingCellemoji-n98de {
    background:none;
    color: var(--shq-rating-font-color);
}

input.widgetTheme-ratingInput-lnaGA[type='radio']:checked + label.widgetTheme-ratingCellemoji-n98de > .widgetTheme-emojiIcon-sMOiA {
    fill: var(--shq-emoji-bg-color);
    transform: scale(1.2);
    /* fill: var(--shq-rating-accent-color); */
    
}

.widgetTheme-ratingCellstar-XeSne{
    border: none;
  }


.widgetTheme-starIcon-oHlhy{
    color: var(--shq-main-bg-color) !important;
    stroke: var(--shq-main-font-color)
}

.widgetTheme-starIcon-oHlhy.widgetTheme-active-pr9Kd {
    color: var(--shq-emoji-bg-color) !important; 
    fill: var(--shq-emoji-bg-color) !important;
    /* fill: #fbbf24; */
    stroke: var(--shq-emoji-bg-color) !important;
    /* fill: var(--shq-rating-accent-color) !important;
    color: var(--shq-rating-font-color) !important; */
   
  }



.widgetTheme-ratingCellnumerical-Cl1jS{
    border: 1px solid var(--shq-rating-border-color);
}
 
.widgetTheme-ratingCellnumerical-Cl1jS:hover{
    background-color: var(--shq-rating-bg-color);
    color: var(--shq-rating-font-color);
}

input.widgetTheme-ratingInput-lnaGA[type='radio']:checked + label {
    background-color: var(--shq-rating-bg-color);
    color: var(--shq-rating-font-color);
}
input.widgetTheme-ratingInput-lnaGA[type='radio']:checked + label.widgetTheme-ratingCellstar-XeSne {
    background:none;
}



.widgetTheme-progressBarWrapper-ZPyDz {
    background-color: var(--shq-progressbar-background-color);

}
.widgetTheme-progressBar-PsgqK{
    background-color: var(--shq-progressbar-foreground-color);

}

.widgetTheme-checkboxWrapper-PBOu4 ,.widgetTheme-inputWrapper-By5Fs {
    border: 1px solid var(--shq-input-border-color);
    background-color: var(--shq-input-bg-color);
    color: var(--shq-input-font-color);

}

input.widgetTheme-checkboxInput-MmrRq[type='radio']:checked{
    accent-color: var(--shq-accent-color);
}
input.widgetTheme-checkboxInput-MmrRq[type='checkbox']:checked{
    accent-color: var(--shq-accent-color);
}
/* input.checkboxInput[type='radio']:checked + label{
    background-color: #000000;
    color: #ffffff;
} */
input.widgetTheme-ratingInput-lnaGA[type='radio']:checked + label {
  background-color: var(--shq-rating-bg-color);
  color: var(--shq-rating-font-color);
}
/* input.checkboxInput[type='checkbox']:checked + label{
    background-color: #000000;
    color: #ffffff;
} */
button.widgetTheme-submitButton-YjxDJ {
    border: 1px solid transparent;
    background-color: var(--shq-button-active-bg-color);
    color: var(--shq-button-active-font-color);

}

button.widgetTheme-submitButton-YjxDJ[disabled] {
    border: 1px solid #ece9e9;
    background-color:  #ece9e9;
    color: #cccccc;
    pointer-events: none;
}


.widgetTheme-inputWrapper-By5Fs > input:focus,textarea:focus,select:focus {
    border: 1px solid #33c3f0;

}
.widgetTheme-textareaInput-CDoQj {
    border: 1px solid var(--shq-input-border-color);
    background-color: var(--shq-input-bg-color);
    color: var(--shq-input-font-color);

}



textarea.widgetTheme-textareaInput-CDoQj::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: var(--shq-placeholder-color);
    opacity: 0.75;
  }
  textarea.widgetTheme-textareaInput-CDoQj::-moz-placeholder { /* Firefox 19+ */
    color: var(--shq-placeholder-color);
    opacity: 0.75;
  }
  textarea.widgetTheme-textareaInput-CDoQj::placeholder {
    color: var(--shq-placeholder-color);
    opacity: 0.75;
  }
  textarea.widgetTheme-textareaInput-CDoQj::-ms-input-placeholder {
    color: var(--shq-placeholder-color);
    opacity: 0.75;
  }
  textare.widgetTheme-textareaInputa-O7ERZ:-ms-input-placeholder{
    color: var(--shq-placeholder-color);
    opacity: 0.75;
  }`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `widgetTheme-main-X4Wh2`,
	"thankyou": `widgetTheme-thankyou-L5WmF`,
	"brandWrapper": `widgetTheme-brandWrapper-dfCIb`,
	"ratingCellemoji": `widgetTheme-ratingCellemoji-n98de`,
	"emojiIcon": `widgetTheme-emojiIcon-sMOiA`,
	"ratingInput": `widgetTheme-ratingInput-lnaGA`,
	"ratingCellstar": `widgetTheme-ratingCellstar-XeSne`,
	"starIcon": `widgetTheme-starIcon-oHlhy`,
	"active": `widgetTheme-active-pr9Kd`,
	"ratingCellnumerical": `widgetTheme-ratingCellnumerical-Cl1jS`,
	"progressBarWrapper": `widgetTheme-progressBarWrapper-ZPyDz`,
	"progressBar": `widgetTheme-progressBar-PsgqK`,
	"checkboxWrapper": `widgetTheme-checkboxWrapper-PBOu4`,
	"inputWrapper": `widgetTheme-inputWrapper-By5Fs`,
	"checkboxInput": `widgetTheme-checkboxInput-MmrRq`,
	"submitButton": `widgetTheme-submitButton-YjxDJ`,
	"textareaInput": `widgetTheme-textareaInput-CDoQj`,
	"textareaInputa": `widgetTheme-textareaInputa-O7ERZ`
};
export default ___CSS_LOADER_EXPORT___;
