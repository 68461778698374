import React, { useEffect } from "react";

import { Text, Group, Card, Button, Select } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useAuthState } from "../../../../context/AuthContext";
import API from "../../../../api/API";
import { API_URLS } from "../../../../utils/constants";
import { TextInputSHQ } from "./../../../../components/MantineOverride";
import { showSuccessNotification, showErrorNotification } from "../../../../components/notifications";
import { useTimezoneSelect, allTimezones } from "react-timezone-select";

export function Product() {
  const { user, csrftoken, organization } = useAuthState();
  const userHasPermission = user.isAccountOwner || user.accessLevel == 1;

  const labelStyle = "original";
  const timezones = {
    ...allTimezones,
  };

  const { options, parseTimezone } = useTimezoneSelect({ labelStyle, timezones });

  const timezoneData = options.map(option => {
    return {
      label: option.label,
      value: option.value,
    };
  });

  const form = useForm({
    initialValues: {
      name: organization.products[0].name,
      website: organization.products[0].website,
      timezone: organization.products[0].timezone,
    },
    validateInputOnChange: true,
    validate: {
      name: value => (value.length < 2 ? "Name must have at least 2 letters" : null),
      website: value => (/^(http|https):\/\/[^ "]+$/.test(value) ? null : "Invalid url"),
    },
  });

  async function handleSubmit(values, event) {
    let response = await API.patch(
      API_URLS.UPDATE_PRODUCT(organization.products[0].prodToken),
      { ...values },
      csrftoken
    );
    console.log(response);

    if (response.success) {
      form.resetDirty();
      showSuccessNotification("Product details updated.");
      console.log("Product update success", response.success);
    } else {
      showErrorNotification();
      console.log("Product update error", response.error);
    }
    console.log(values);
  }

  //console.log('checkUserCan - can_edit_product', checkUserCan(user, 'can_edit_product'));

  return (
    <form onSubmit={form.onSubmit((values, event) => handleSubmit(values, event))}>
      <Text size="md" fontWeight={400}>
        Product
      </Text>
      <Text size="xs" color="dimmed" fontWeight={400} mb="xs">
        Name is used to automatically fill in survey templates.
      </Text>

      <Card
        withBorder
        radius="md"
        padding="xl"
        sx={theme => ({
          backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
          overflow: "visible",
        })}
      >
        <Group align="flex-start">
          <TextInputSHQ label="Name" {...form.getInputProps("name")} disabled={!userHasPermission} />
          <TextInputSHQ label="Website" {...form.getInputProps("website")} disabled={!userHasPermission} />
          <Select
            label="Timezone"
            sx={{ width: "250px" }}
            placeholder="Select timezone"
            searchable
            nothingFound="No options"
            maxDropdownHeight={280}
            data={timezoneData}
            {...form.getInputProps("timezone")}
          />

          <Button disabled={form.isDirty() && form.isValid() ? false : true} type="submit" sx={{ marginTop: "28px" }}>
            Save
          </Button>
          {form.isDirty() && (
            <Button variant="outline" onClick={() => form.reset()} sx={{ marginTop: "28px" }}>
              Cancel
            </Button>
          )}
        </Group>
      </Card>
    </form>
  );
}
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="b0ea6b84-6dae-5df6-aee6-129534ca915e")}catch(e){}}();
//# debugId=b0ea6b84-6dae-5df6-aee6-129534ca915e
