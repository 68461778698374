import { useEffect, useState } from 'react';

//source 1: https://zakka.dev/useapi-custom-hook/
// source 2: https://github.com/dgamboa/useapi-hook

export function useAPICall(apiFunction, args) {
  const [response, setResponse] = useState({
    data: null,
    error: null,
    loading: true,
  });

  const fetchMethod = () => {
    setResponse({
      ...response,
      loading: true,
    });
    console.log('useAPICall first setResponse', response);

    apiFunction(args)
      .then(res => {
        console.log('useAPICall resp inside APICall', res);

        setResponse({ ...response, loading: false, data: res });
        // setResponse({
        //   error: null,
        //   data: res,
        //   loading: false,
        // });
        console.log(' useAPICall final resp inside APICall', response);
      })
      .catch(error => {
        // Error
        console.log('useAPICall error inside APICall', error);
        setResponse(prevState => ({ ...prevState, loading: false, error: error.error, data: null }));
        console.log('useAPICall error inside APICall', response);
      });
  };

  return [response, fetchMethod];
}
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="f6546dac-9667-5dc1-9cce-a074cb0c8874")}catch(e){}}();
//# debugId=f6546dac-9667-5dc1-9cce-a074cb0c8874
