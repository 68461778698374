import React from "react";
import { Card, Text } from "@mantine/core";
import { pluralize } from "../../../../utils/helpers";
import { FormattedMessage, useIntl } from "react-intl";
// import { NPSColors } from '../../../../utils/colorScheme';

export default function NPSRatingTooltip({ active, payload }) {
  const intl = useIntl();
  if (active && payload && payload.length) {
    const datapoints = payload[0].payload;

    //console.log('payload in tooltip is', datapoints);
    return (
      <div style={{ width: "250px", height: "200px" }}>
        <Card shadow="sm" p="lg" radius="md" withBorder>
          <Text size={14} color="dark.3">
            {intl.formatMessage({ id: "reports.rating", defaultMessage: "Rating" })}: {datapoints.answer}
          </Text>
          <Text weight={700}>
            <FormattedMessage
              id="reports.response_count_plural"
              values={{ count: datapoints.count }}
              defaultMessage={pluralize(datapoints.count, "Response", "s")}
            />
          </Text>
        </Card>
      </div>
    );
  } else {
    return null;
  }
}
