import React, { useRef, useEffect, useCallback, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { Box } from '@mantine/core';
import TextTooltip from '../reports/Textual/TextTooltip';
//import { formatTimeTick } from '../../../../utils/helpers';

ChartJS.register(
  CategoryScale,
  TimeScale,
  LinearScale,
  BarElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

export function BarGraph({
  data,
  timeframe,
  xTitle,
  yTitle,
  xAxisKey,
  yAxisKey,
  labelFormatter,
  xTickCallback,
  yTickCallback,
}) {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipData, setTooltipData] = useState(null);
  const [tooltipPos, setTooltipPos] = useState(null);

  const chartRef = useRef(null);

  console.log('data in chartjs bargraph is ', data);

  useEffect(() => {
    const chart = chartRef.current;

    if (chart) {
      console.log('ChartJS', chart);
    }
  }, []);

  const customTooltip = useCallback(context => {
    console.log('tooltip context', context);
    const tooltipModel = context.tooltip;
    if (tooltipModel.opacity == 0) {
      // hide tooltip visibilty
      setTooltipVisible(false);
      return;
    }

    const chart = chartRef.current;
    const canvas = chart.canvas;
    if (canvas) {
      // enable tooltip visibilty
      setTooltipVisible(true);
      const { offsetLeft: positionX, offsetTop: positionY } = canvas;
      //const position = context.chart.canvas.getBoundingClientRect();

      // set position of tooltip
      const left = positionX + tooltipModel.caretX + 'px';
      const top = positionY + tooltipModel.caretY + 'px';

      // handle tooltip multiple rerender
      if (tooltipPos?.top != top) {
        setTooltipPos({ top: top, left: left });
        setTooltipData(tooltipModel);
      }
    }
  });

  const options = {
    responsive: true,
    // layout: {
    //   padding: {
    //     top: 5,
    //     left: 15,
    //     right: 15,
    //     bottom: 15,
    //   },
    // },
    plugins: {
      title: {
        display: true,
        text: xTitle,
      },
      tooltip: {
        enabled: false,
        position: 'nearest',
        external: customTooltip,
      },
      datalabels: {
        color: '#fff',
        //display: false,
        formatter: labelFormatter,
      },
      legend: {
        position: 'top',
        labels: {
          usePointStyle: true,
          useBorderRadius: true,
          borderRadius: 4,
        },
      },
    },

    barThickness: 50,
    parsing: {
      xAxisKey: xAxisKey,
      yAxisKey: yAxisKey,
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        title: {
          display: true,
          text: yTitle,
        },
        grid: {
          display: true,
        },
        border: {
          display: true,
        },
      },
    },
  };

  return (
    <Box>
      <Bar ref={chartRef} options={options} data={data} />
      {tooltipPos && (
        <TextTooltip data={tooltipData} position={tooltipPos} visibility={tooltipVisible} timeframe={timeframe} />
      )}
    </Box>
  );
}
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="58b06a1a-dd8a-51bc-bb30-71d57ee033d5")}catch(e){}}();
//# debugId=58b06a1a-dd8a-51bc-bb30-71d57ee033d5
