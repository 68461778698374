import React, { useEffect } from "react";
import { Box } from "@mantine/core";
import { Heading } from "../../components";
import { IconBrandSlack } from "@tabler/icons-react";
import { useSearchParams } from "react-router-dom";
import API from "../../api/API";
import { API_URLS } from "../../utils/constants";

export default function Slack() {
  let [searchParams, setSearchParams] = useSearchParams();
  let code = searchParams.get("code");
  let state = searchParams.get("state");
  console.log("code is ", code);

  useEffect(() => {
    if (code && state) {
      API.get(API_URLS.GET_SLACK_TOKEN(code, state)).then(data => {
        console.log("slack data is ", data);
      });
    }
  }, [code, state]);

  return (
    <div>
      {/* <Heading
        title="Slack"
        icon={<IconBrandSlack size={40} />}
        showDivider={true}
        subheading="Send survey responses to a Slack channel."
      /> */}
      <Box>
        <a
          href="https://slack.com/oauth/v2/authorize?scope=channels%3Aread%2Cchat%3Awrite%2Cchat%3Awrite.public&amp;user_scope=&amp;redirect_uri=https%3A%2F%2Flocalhost%3A3000%2Fintegrations%2Fslack%2F&amp;client_id=5627527018160.6377277317957&amp;state=gradian"
          style={{
            alignItems: "center",
            color: "#000",
            backgroundColor: "#fff",
            border: "1px solid #ddd",
            borderRadius: "4px",
            display: "inline-flex",
            fontFamily: "Lato, sans-serif",
            fontSize: "18px",
            fontWeight: "600",
            height: "56px",
            justifyContent: "center",
            textDecoration: "none",
            width: "276px",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            style={{ height: "24px", width: "24px", marginRight: "12px" }}
            viewBox="0 0 122.8 122.8"
          >
            <path
              d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z"
              fill="#e01e5a"
            ></path>
            <path
              d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z"
              fill="#36c5f0"
            ></path>
            <path
              d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z"
              fill="#2eb67d"
            ></path>
            <path
              d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z"
              fill="#ecb22e"
            ></path>
          </svg>
          Connect with Slack
        </a>
      </Box>
    </div>
  );
}
!(function () {
  try {
    var e =
        "undefined" != typeof window
          ? window
          : "undefined" != typeof global
          ? global
          : "undefined" != typeof self
          ? self
          : {},
      n = new Error().stack;
    n &&
      ((e._sentryDebugIds = e._sentryDebugIds || {}), (e._sentryDebugIds[n] = "d263866c-9b7d-527a-b77a-57558a62cb35"));
  } catch (e) {}
})();
//# debugId=d263866c-9b7d-527a-b77a-57558a62cb35
