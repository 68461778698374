import React, { useEffect, useState } from "react";
import { SurveyForm } from "./components/form";
import { useParams } from "react-router-dom";

import { Box } from "@mantine/core";

import { getSurveyById } from "./../../api/Survey";

import { FullPageSpinner } from "../../components/spinners";

export default function EditSurvey() {
  let { surveyId } = useParams();
  const [surveyData, setSurveyData] = useState();
  console.log("surveyid in edit", surveyId);

  useEffect(() => {
    getSurveyById(surveyId).then(data => {
      console.log("survey data in edit form is ", data);
      setSurveyData(data);
    });
  }, [surveyId]);

  const output = () => {
    if (surveyData) {
      return <Box mt={30}>{surveyData && <SurveyForm survey={surveyData} editSurveyId={surveyId} />}</Box>;
    } else {
      return <FullPageSpinner />;
    }
  };

  return output();
}
