import React from "react";
import dayjs from "dayjs";
import { pluralize } from "./helpers";

import { IconLetterCase, IconNumbers, IconAB, IconCalendar } from "@tabler/icons-react";

export const filterComparatorOptions = {
  string: {
    default: { value: "eq", label: "Is" },
    operators: [
      { value: "eq", label: "Is", help: "Includes only results that exactly match the filter value(s)." },
      { value: "neq", label: "Is not", help: "Excludes results that exactly match the filter value(s)." },
      { value: "con", label: "Contains", help: "Includes only results that partically match the filter value(s)." },
      {
        value: "dcon",
        label: "Does not contain",
        help: "Excludes results that partically match the filter value(s).",
      },
      { value: "set", label: "Is set", help: "Includes only results with any value for the filter property." },
      { value: "notset", label: "Is not set", help: "Excludes results with any value for the filter property" },
    ],
  },
  boolean: {
    default: { value: "eq", label: "Is" },
    operators: [
      { value: "eq", label: "Is", help: "" },
      { value: "neq", label: "Is not", help: "" },
    ],
  },
  number: {
    default: { value: "eq", label: "Equals" },
    operators: [
      { value: "eq", label: "Equals", help: "Includes only results that exactly match the filter value(s)." },
      { value: "neq", label: "Not equal", help: "Excludes results that exactly match the filter value(s)." },
      {
        value: "gt",
        label: "Greater than",
        help: "Includes only results that partically match the filter value(s).",
      },
      {
        value: "gte",
        label: "Greater than or equal to",
        help: "Excludes results that partically match the filter value(s).",
      },
      { value: "lt", label: "Less than", help: "Includes only results that partically match the filter value(s)." },
      {
        value: "lte",
        label: "Less than or equal to",
        help: "Excludes results that partically match the filter value(s).",
      },
      { value: "between", label: "Between", help: "Includes only results with any value for the filter property." },
      { value: "notbetween", label: "Not between", help: "Excludes results with any value for the filter property" },
    ],
  },
  date: {
    default: { value: "last", label: "in last" },
    operators: [
      { value: "last", label: "Last", help: "Includes only results in last filter interval." },
      { value: "notinlast", label: "Not in the last", help: "Excludes results within the last filter interval." },
      { value: "between", label: "Between", help: "Includes only results within the filter range." },
      { value: "on", label: "On", help: "Includes only results from the filter date." },
      { value: "noton", label: "Not on", help: "Excludes results from the filter date." },
      { value: "before", label: "Before", help: "Includes only results from before the filter date." },
      { value: "after", label: "After", help: "Includes only results after the filter date." },
    ],
  },
};

export const attributeIcon = type => {
  switch (type) {
    case "string":
      return <IconLetterCase style={{ flexGrow: 0 }} size={16} stroke={1.5} />;
    case "boolean":
      return <IconAB style={{ flexGrow: 0 }} size={16} stroke={1.5} />;
    case "number":
      return <IconNumbers style={{ flexGrow: 0 }} size={16} stroke={1.5} />;
    case "date":
      return <IconCalendar style={{ flexGrow: 0 }} size={16} stroke={1.5} />;
    default:
      break;
  }
};

export const getTimeDiffinNdayFormat = (range, timeUnit = "day") => {
  //expects unix timestamps in the range

  console.log("#399 range", range);

  const startTime = dayjs.unix(range[0]);
  console.log("#399 startTime", startTime);
  const endTime = dayjs.unix(range[1]);
  console.log("#399 endTime", endTime);
  const diff = endTime.diff(startTime, timeUnit);
  console.log("#399 diff", diff);
  if (startTime && isNaN(endTime)) {
    return pluralize(0, timeUnit, "s");
  } else {
    return pluralize(diff, timeUnit, "s");
  }
};
