import React from "react";
import { Navigate, useLocation, useMatches } from "react-router-dom";
import AuthenticatedLayout from "./AuthenticatedLayout";
import { useAuthState } from "./../../context/AuthContext";
import { FullPageSpinner } from "./../../components/spinners";
import { userAccessLevel } from "../../utils/permissions";

export default function AuthenticatedRoute({ children }) {
  const auth = useAuthState();

  const matches = useMatches();
  const loc = useLocation();

  const isRestricted = () => {
    console.log("matches", matches);
    const restrictedTo = matches.find(match => match.pathname == loc.pathname)?.handle;
    if (restrictedTo) {
      if (restrictedTo.restrict.includes(userAccessLevel(auth.user))) {
        return true;
      }
    } else {
      return false;
    }
  };

  let location = useLocation();
  // console.log('CHILDREN', matches);
  // console.log('LOCATION', loc);
  //console.log(location);
  const user = auth.user;
  const isLoading = auth.loading;
  console.log("user inside auth route is", user);
  // console.log('loading state is', isLoading);

  if (isLoading) {
    return <FullPageSpinner />;
  } else if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace={true} />;
  } else if (isRestricted()) {
    return <Navigate to="/" />;
  } else {
    return <AuthenticatedLayout>{children}</AuthenticatedLayout>;
  }
}
