import React, { createContext, useContext, useEffect, useReducer } from 'react';

import { getCSRF, getCurrentUser } from '../api/Auth';
import { initialState, AuthReducer } from './../reducers/AuthReducer';
import { useBrowserStorage } from '../hooks/useBrowserStorage';
import { objectIsEqual } from './../utils/helpers';

const AuthStateContext = createContext();
const AuthDispatchContext = createContext();

function useAuthState() {
  const context = useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error('useAuthState must be used within a AuthProvider');
  }

  return context;
}

function useAuthDispatch() {
  const context = useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within a AuthProvider');
  }

  return context;
}
const AuthProvider = ({ children }) => {
  const [user, dispatch] = useReducer(AuthReducer, initialState);
  const [currentProduct, setCurrentProduct] = useBrowserStorage('Local', 'currentProduct', null);

  useEffect(() => {
    if (user.user === null) {
      getCurrentUser(dispatch)
        .then(response => {
          console.log('>>>>response in AuthContext>>>', response);
          if (response === undefined) {
            return;
          }

          if (currentProduct == null || !objectIsEqual(currentProduct, response.organization.products[0])) {
            console.log('>>>>setting CURRENT PRODUCTt>>>', response.organization.products[0]);
            setCurrentProduct(response.organization.products[0]);
            dispatch({ type: 'SET_CURRENT_PRODUCT', payload: response.organization.products[0] });
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [user.user]);

  return (
    <AuthStateContext.Provider value={user}>
      <AuthDispatchContext.Provider value={dispatch}>{children}</AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

export { getCSRF, AuthProvider, useAuthDispatch, useAuthState };
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="4ec1157e-ec63-59b7-8b04-18639273c539")}catch(e){}}();
//# debugId=4ec1157e-ec63-59b7-8b04-18639273c539
