import React, { useState } from "react";
import {
  Flex,
  Select,
  MultiSelect,
  TextInput,
  ActionIcon,
  Divider,
  Menu,
  Box,
  Button,
  Group,
  Stack,
  Text,
  Tooltip,
  Space,
} from "@mantine/core";
import {
  IconMoodHappy,
  Icon123,
  IconClipboardCheck,
  IconEdit,
  IconClipboardList,
  IconExternalLink,
  IconChevronDown,
  IconTrash,
  IconChevronUp,
  IconCheck,
  IconAt,
} from "@tabler/icons-react";
import { StyledSelect } from "./../../../../components/MantineOverride";
//import Select from 'react-select';

const logicOptions = [
  { value: "gt", label: ">", desc: "is greater than", qtype: [4, 5] },
  { value: "gte", label: "≥", desc: "is greater than or equal to", qtype: [4, 5] },
  { value: "lt", label: "<", desc: "is less than", qtype: [4, 5] },
  { value: "lte", label: "≤", desc: "is less than or equal to", qtype: [4, 5] },
  { value: "eq", label: "=", desc: "is equal to", qtype: [2, 3, 4, 5] },
  { value: "neq", label: "≠", desc: "is not equal to", qtype: [2, 4, 5] },
  { value: "con", label: "contains", desc: "", qtype: [1] },
  { value: "dcon", label: "does not contain", desc: "", qtype: [1] },
  { value: "sub", label: "is submitted", desc: "", qtype: [1, 2, 3, 4, 5] },
  { value: "skip", label: "is skipped", desc: "", qtype: [1, 2, 3, 4, 5, 12] },
  // { value: "incal", label: "includes", desc: "includes", qtype: [3] },
  // { value: "dinc", label: "does not include", desc: "does not include", qtype: [3] },
];

const menuOptions = [
  { value: 1, code: "open_text", label: "Open Text", icon: <IconEdit size={14} /> },
  { value: 4, code: "nps", label: "NPS", icon: <IconMoodHappy size={14} /> },
  { value: 2, code: "mcq_single", label: "MCQ - Single Select", icon: <IconClipboardCheck size={14} /> },
  { value: 3, code: "mcq_multi", label: "MCQ - Multi Select", icon: <IconClipboardList size={14} /> },
  { value: 5, code: "rating", label: "Rating", icon: <Icon123 size={14} /> },
  { value: 12, code: "email", label: "Email", icon: <IconAt size={14} /> },
  { value: 30, code: "redirect", label: "Redirect", icon: <IconExternalLink size={14} /> },
  // { value: 6, code: 'binary', label: 'Binary', icon: <IconArrowsLeftRight size={14} /> },
];

function ratingOptions(n) {
  let ratings = [];
  for (let i = 1; i <= n; i++) {
    ratings.push({ value: i, label: i });
  }
  return ratings;
}

export function OrderLogic(props) {
  const { qidx, logic, logicIndex, question, dispatch, questions } = props;

  const questionOptions = () => {
    let qList = [];
    let totalCount = questions.length;
    for (let i = qidx + 1; i < totalCount; i++) {
      let q = questions.find(q => q.order == i);
      qList.push({
        value: `${i}`,
        label: q.questionType == 30 ? q.options.primaryButtonText : `${q.order + 1}: ${q.questionText}`,
        icon: menuOptions.find(m => m.value === q.questionType)?.icon,
      });
    }
    qList.push({ value: "-1", label: "Thank you screen", icon: <IconCheck size={14} /> });
    return qList;
  };

  function ratingOptionsByQtype(qtype, ratingRange) {
    if (qtype === 4) {
      return ratingOptions(10);
    } else if (qtype === 5) {
      if (ratingRange) {
        return ratingOptions(parseInt(ratingRange));
      } else {
        return ratingOptions(5);
      }
    }
  }

  function filteredPredicateOptions(predicate) {
    console.log("4455 Predicate", predicate);
    console.log("4455 chiceOoptons", choiceOptions(question.choices));

    if (Array.isArray(predicate)) {
      return choiceOptions(question.choices).find(x => predicate.find(l => l === x.value));
    } else {
      return choiceOptions(question.choices).find(x => predicate.value === x.value);
    }
  }

  function choiceOptions(choices) {
    let choiceList = [];
    for (let i = 0; i < choices.length; i++) {
      choiceList.push({ value: choices[i].choice, label: choices[i].choice });
    }
    return choiceList;
  }

  function handleSelectChange(val, type, section) {
    // console.log('question in handle change in logic', question);
    console.log("Val in handleSelectChange is", val);
    dispatch({
      type: type,
      index: qidx,
      logicIndex: logicIndex,
      logicSection: section,
      value: val,
    });
  }

  function handleRemove(qidx, logicIdx) {
    console.log(qidx, logicIdx);
    dispatch({
      type: "removeLogic",
      index: qidx,
      logicIndex: logicIndex,
    });
  }

  function handleChange(e, section) {
    //console.log('value in textual logic is', e.target.value);
    console.log("#17 section in textual logic is", e.target.value);
    dispatch({
      type: "updateLogic",
      index: qidx,
      logicIndex: logicIndex,
      logicSection: section,
      value: e.target.value,
    });
  }
  function handleMenuChange(e, val, section) {
    console.log("menu change", e, val, section);
    dispatch({
      type: "updateLogic",
      index: qidx,
      logicIndex: logicIndex,
      logicSection: section,
      value: val,
    });
  }

  function renderOptions(qtype) {
    if (qtype === 1) {
      return (
        <TextInput
          id={`logicP${logicIndex}`}
          name="logicP"
          value={logic.predicate}
          withAsterisk
          onChange={e => handleChange(e, "predicate")}
        />
      );
    } else if (qtype === 4 || qtype === 5) {
      return (
        <Select
          data={ratingOptionsByQtype(parseInt(question.questionType), question.options.ratingRange)}
          placeholder="Select a rating."
          onChange={val => handleSelectChange(val, "updateLogic", "predicate")}
          rightSection={<IconChevronDown size={14} />}
          value={
            (String(logic.predicate).length > 0 &&
              ratingOptionsByQtype(parseInt(question.questionType), question.options.ratingRange).find(
                x => parseInt(x.value) == logic.predicate
              )?.value) ||
            ""
          }
          styles={() => ({
            dropdown: {
              width: "auto",
            },
          })}
        />
      );
    } else if (qtype === 2 && question.choices && question.choices.length > 0) {
      return (
        <Select
          value={logic.predicate}
          placeholder="Select an option."
          onChange={val => handleSelectChange(val, "updateSingleSelectPredicate", "predicate")}
          data={choiceOptions(question.choices)}
          rightSection={<IconChevronDown size={14} />}
          styles={() => ({
            input: {
              width: "max-content",
            },
          })}
        />
      );
    } else if (qtype === 3 && question.choices && question.choices.length > 0) {
      return (
        <MultiSelect
          value={logic.predicate}
          placeholder="Select option(s)"
          rightSection={<IconChevronDown size={14} />}
          onChange={val => handleSelectChange(val, "updateMultiSelectPredicate", "predicate")}
          data={choiceOptions(question.choices)}
        />
      );
    }
  }

  return (
    <>
      <li>
        <Group position="apart" align="end" mt={12}>
          <Group position="left" align="end">
            <OperatorListMenu
              logicOptions={logicOptions}
              question={question}
              handleChange={handleMenuChange}
              logic={logic}
            />
            {logic.logic && logic.logic !== "sub" && logic.logic !== "skip"
              ? renderOptions(parseInt(question.questionType))
              : null}
          </Group>

          <ActionIcon>
            <IconTrash size={24} stroke={1} onClick={() => handleRemove(qidx, logicIndex)} />
          </ActionIcon>
        </Group>
        <Space h="sm" />
        <Group>
          {logic.logic && (String(logic.predicate).length > 0 || logic.logic == "sub" || logic.logic == "skip") ? (
            <QuestionListMenu questionOptions={questionOptions} handleChange={handleMenuChange} logic={logic} />
          ) : null}
        </Group>
        <Space h="sm" />

        <Divider
          label="and"
          sx={{
            marginBottom: "0px",
            marginTop: "10px",
            ">::after": { borderTop: "0px" },
            //fontWeight: 700,
            fontStyle: "italic",
          }}
        />
      </li>
    </>
  );
}

function OperatorListMenu({ logicOptions, question, handleChange, logic }) {
  const [opened, setOpened] = useState(false);

  function filteredLogicOptions(options, questionType) {
    if (question.isRequired) {
      return options.filter(o => o.value !== "skip").filter(x => x.qtype.find(y => y === questionType));
    } else {
      return [{ value: "skip", label: "is skipped", desc: "", qtype: [1, 2, 3, 4, 5, 12] }];
    }
  }
  const selectedOption = () => {
    let q = logic.logic && logicOptions.find(l => l.value == logic.logic);
    //console.log("#17 q in selectedOption is", q);
    return q;
  };
  return (
    <Menu
      shadow="md"
      opened={opened}
      width="auto"
      position="bottom-start"
      transitionDuration={0}
      onChange={setOpened}
      styles={() => ({
        itemLabel: {
          fontSize: "1.6rem",
        },
      })}
    >
      <Menu.Target>
        <Stack spacing={0}>
          <Text size={"14px"} color="dimmed">
            If response
          </Text>
          <Button
            variant="default"
            fullWidth
            rightIcon={opened ? <IconChevronUp size={14} /> : <IconChevronDown size={14} />}
            sx={{
              fontWeight: 400,
            }}
          >
            {selectedOption() ? (
              selectedOption().desc.length > 0 ? (
                <Tooltip withArrow label={selectedOption().desc} color="gray" position="left-start">
                  <Text truncate>{selectedOption().label}</Text>
                </Tooltip>
              ) : (
                <Text truncate>{selectedOption().label}</Text>
              )
            ) : null}
          </Button>
        </Stack>
      </Menu.Target>
      <Menu.Dropdown>
        {filteredLogicOptions(logicOptions, question.questionType).map((f, idx) => (
          <Menu.Item key={idx} onClick={e => handleChange(e, f.value, "logic")}>
            {f.desc.length > 0 ? (
              <Tooltip withArrow label={f.desc} color="gray" position="right-start">
                <Text size={16}>{f.label}</Text>
              </Tooltip>
            ) : (
              <Text size={16}>{f.label}</Text>
            )}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
}

function QuestionListMenu({ questionOptions, handleChange, logic }) {
  const [opened, setOpened] = useState(false);
  const selectedOption = () => {
    let q = logic.nextQ && questionOptions().find(q => q.value == logic.nextQ);
    console.log("#17 q in selectedOption is", q);
    if (!q) {
      return { label: "Thank you screen", icon: <IconCheck size={14} /> };
    } else {
      return q;
    }
  };
  return (
    <Menu
      shadow="md"
      opened={opened}
      width="auto"
      maxwidth="600px"
      position="bottom-start"
      transitionDuration={0}
      onChange={setOpened}
      styles={() => ({
        itemLabel: {
          fontSize: "1.6rem",
        },
      })}
    >
      <Menu.Target>
        <Stack spacing={0}>
          <Text size={"14px"} color="dimmed">
            Move to
          </Text>
          <Button
            variant="default"
            fullWidth
            leftIcon={selectedOption().icon}
            rightIcon={opened ? <IconChevronUp size={14} /> : <IconChevronDown size={14} />}
            sx={{
              fontWeight: 400,
              maxWidth: "500px",
            }}
          >
            {selectedOption() ? <Text truncate>{selectedOption().label}</Text> : ""}
          </Button>
        </Stack>
      </Menu.Target>
      <Menu.Dropdown>
        {questionOptions().map((q, idx) => (
          <Menu.Item key={idx} icon={q.icon} onClick={e => handleChange(e, q.value, "nextQ")}>
            {q.label}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
}
