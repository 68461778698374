import React from "react";
import { Text, Box, Group } from "@mantine/core";
import { useIntl } from "react-intl";

export default function NoData() {
  const intl = useIntl();
  return (
    <Box>
      <Group position="center" mt="lg">
        {/* <Loader color="gray" variant="dots" /> */}
        <Text color="dimmed">
          {" "}
          {intl.formatMessage({ id: "reports.no_data_msg", defaultMessage: "No data available for this period." })}
        </Text>
      </Group>
    </Box>
  );
}
