import bold from './Orbitron/Orbitron-Bold.ttf';
import extraBold from './Orbitron/Orbitron-ExtraBold.ttf';
import semiBold from './Orbitron/Orbitron-SemiBold.ttf';

export const OrbitronFont = [
  {
    '@font-face': {
      fontFamily: 'Orbitron',
      src: `url('${semiBold}') format('truetype')`,
      fontWeight: 600,
      fontStyle: 'normal',
    },
  },
  {
    '@font-face': {
      fontFamily: 'Orbitron',
      src: `url('${bold}') format('truetype')`,
      fontWeight: 700,
      fontStyle: 'normal',
    },
  },
  {
    '@font-face': {
      fontFamily: 'Orbitron',
      src: `url('${extraBold}') format('truetype')`,
      fontWeight: 800,
      fontStyle: 'normal',
    },
  },
];
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="7d5b4483-10cc-5a21-a165-9b89425448aa")}catch(e){}}();
//# debugId=7d5b4483-10cc-5a21-a165-9b89425448aa
