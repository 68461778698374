import React from "react";
import { Card, Space, List, Text, ThemeIcon, Group, Divider } from "@mantine/core";
import { getDurationString, pluralize } from "../../../../utils/helpers";
import { NPSColors } from "../../../../utils/colorScheme";
import { useIntl, FormattedMessage } from "react-intl";

export default function NPSTooltip({ active, payload, label, questionType }) {
  const intl = useIntl();
  const dataDuration = datapoints => {
    console.log("@dataObj", datapoints);

    return getDurationString(datapoints.grouped_date, datapoints.interval, intl.locale);
  };
  if (active && payload && payload.length) {
    const datapoints = payload[0].payload;

    console.log("payload in tooltip is", datapoints);
    if (datapoints.total_responses == 0) {
      return null;
    }
    return (
      <div style={{ width: "250px", height: "200px" }}>
        <Card shadow="sm" p="lg" radius="md" withBorder>
          <Group position="apart">
            <Text weight={700}>{datapoints.score}</Text>
            <Text color="dark.3" size={14}>
              <FormattedMessage
                id="reports.response_count_plural"
                values={{ count: datapoints.total_responses }}
                defaultMessage={pluralize(datapoints.total_responses, "Response", "s")}
              />
            </Text>
          </Group>
          <Divider />
          <Group position="right">
            <Text color="dark.3" size="xs">
              ({dataDuration(datapoints)})
            </Text>
          </Group>

          <Space h="md" />
          <List spacing="xs" size="sm" center>
            <List.Item icon={<ThemeIcon color={NPSColors["high"]} size={10} radius="xl"></ThemeIcon>}>
              <Text color="dark.5" size={14}>
                {Math.round(datapoints.high_pct)}%{" "}
                {questionType == 4
                  ? intl.formatMessage({ id: "reports.nps_promoters", defaultMessage: "Promoters" })
                  : intl.formatMessage({ id: "reports.rating_positive", defaultMessage: "Positive" })}{" "}
                ({datapoints.high} {intl.formatMessage({ id: "reports.total", defaultMessage: "total" })})
              </Text>
            </List.Item>
            <List.Item icon={<ThemeIcon color={NPSColors["neutral"]} size={10} radius="xl"></ThemeIcon>}>
              <Text color="dark.5" size={14}>
                {Math.round(datapoints.neutral_pct)}%{" "}
                {questionType == 4
                  ? intl.formatMessage({ id: "reports.nps_passives", defaultMessage: "Passives" })
                  : intl.formatMessage({ id: "reports.rating_neutral", defaultMessage: "Neutral" })}{" "}
                ({datapoints.neutral} {intl.formatMessage({ id: "reports.total", defaultMessage: "total" })})
              </Text>
            </List.Item>
            <List.Item icon={<ThemeIcon color={NPSColors["low"]} size={10} radius="xl"></ThemeIcon>}>
              <Text color="dark.5" size={14}>
                {Math.round(datapoints.low_pct)}%{" "}
                {questionType == 4
                  ? intl.formatMessage({ id: "reports.nps_detractors", defaultMessage: "Detractors" })
                  : intl.formatMessage({ id: "reports.rating_negative", defaultMessage: "Negative" })}{" "}
                ({datapoints.low} {intl.formatMessage({ id: "reports.total", defaultMessage: "total" })})
              </Text>
            </List.Item>
          </List>
          {/* <List spacing="xs" size="sm" center>
            <List.Item icon={<ThemeIcon color={NPSColors['high']} size={10} radius="xl"></ThemeIcon>}>
              <Text color="dark.5" size={14}>
                {datapoints.high_pct}% Promoters ({datapoints.high} total)
              </Text>
            </List.Item>
            <List.Item icon={<ThemeIcon color={NPSColors['neutral']} size={10} radius="xl"></ThemeIcon>}>
              <Text color="dark.5" size={14}>
                {datapoints.neutral_pct}% Passives ({datapoints.neutral} total)
              </Text>
            </List.Item>
            <List.Item icon={<ThemeIcon color={NPSColors['low']} size={10} radius="xl"></ThemeIcon>}>
              <Text color="dark.5" size={14}>
                {datapoints.low_pct}% Detractors ({datapoints.low} total)
              </Text>
            </List.Item>
          </List> */}
        </Card>
      </div>
    );
  } else {
    return null;
  }
}
