import React from 'react';
import style from '../style.scss';

export default function FormFieldError(props) {
  const { errors } = props;

  if (errors && errors.length == 1) {
    return <div className={style.formError}>{errors[0]}</div>;
  } else {
    return (
      <ul className={style.formErrorList}>
        {console.log('errors in signup form', errors)}
        {errors &&
          errors.map((error, idx) => {
            return (
              <li key={idx} style={{ color: 'red' }}>
                {error}
              </li>
            );
          })}
      </ul>
    );
  }
}
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="7aa71c57-ef5e-55a5-8865-43170cb85b37")}catch(e){}}();
//# debugId=7aa71c57-ef5e-55a5-8865-43170cb85b37
