import React, { useState } from "react";
import { useAuthState } from "../../../context/AuthContext";
import { decamelizeKeys } from "humps";
import { patchSurvey } from "../../../api/Survey";

import { createStyles, UnstyledButton, Menu, Group, ThemeIcon } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import { useIntl } from "react-intl";
//import { getCurrentTimestampInSecs } from '../../../utils/helpers';

const useStyles = createStyles((theme, { opened }) => ({
  control: {
    width: "160px",
    height: "30px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 14px",

    borderRadius: theme.radius.sm,
    border: `1px solid ${theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[4]}`,
    transition: "background-color 150ms ease",
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[opened ? 5 : 6] : opened ? theme.colors.gray[0] : theme.white,

    "&:hover": {
      backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[0],
    },
  },

  label: {
    fontWeight: 700,
    fontSize: theme.fontSizes.xs,
  },
  item: {},

  icon: {
    transition: "transform 150ms ease",
    transform: opened ? "rotate(180deg)" : "rotate(0deg)",
  },
}));

export function SurveyStateControl({ currentState, surveyId }) {
  const csrfToken = useAuthState().csrftoken;
  const intl = useIntl();
  const [opened, setOpened] = useState(false);
  const { classes } = useStyles({ opened });

  const surveyStateOptions = [
    {
      label: intl.formatMessage({ id: "survey.draft" }),
      value: 0,
      name: intl.formatMessage({ id: "survey.in_draft" }),
      color: "yellow",
      nextStates: [1],
    },
    {
      label: intl.formatMessage({ id: "survey.launch" }),
      value: 1,
      name: intl.formatMessage({ id: "survey.live" }),
      color: "green",
      nextStates: [2, 3],
    },
    {
      label: intl.formatMessage({ id: "survey.pause" }),
      value: 2,
      name: intl.formatMessage({ id: "survey.paused" }),
      color: "red",
      nextStates: [1, 3],
    },
    {
      label: intl.formatMessage({ id: "survey.mark_complete" }),
      value: 3,
      name: intl.formatMessage({ id: "survey.completed" }),
      color: "gray",
      nextStates: [4],
    },
    {
      label: intl.formatMessage({ id: "survey.archive" }),
      value: 4,
      name: intl.formatMessage({ id: "survey.archived" }),
      color: "gray",
      nextStates: [-1],
    },
  ];
  const [selected, setSelected] = useState(surveyStateOptions.find(x => x.value == currentState));

  function handleClick(item) {
    console.log(item);
    setSelected(item);
    const requestBody = {
      state: item.value,
      // ...(item.value == 1 &&
      //   currentState != 2 && {
      //     startedAt: getCurrentTimestampInSecs(),
      //   }),
      // ...(item.value == 1 &&
      //   currentState == 2 && {
      //     endedAt: null,
      //   }),
      // ...(item.value == 3 && {
      //   endedAt: getCurrentTimestampInSecs(),
      // }),
    };

    patchSurvey(decamelizeKeys(requestBody), csrfToken, surveyId).then(data => {
      console.log("SurveyStatechanged new data", data);
      currentState = data.state;
    });
  }

  const availableItems =
    selected &&
    selected.nextStates.map(state => {
      if (state == -1) {
        return null;
      }
      //console.log('currentState in loop is', state);
      let item = surveyStateOptions.find(s => s.value == state);
      //console.log('item in loop is', item);
      return (
        <Menu.Item
          icon={<ThemeIcon color={item.color} radius="xl" size="xs" />}
          onClick={() => handleClick(item)}
          key={item.label}
        >
          {item.label}
        </Menu.Item>
      );
    });

  const items = surveyStateOptions.map(item => (
    <Menu.Item
      //icon={<ThemeIcon color={item.color} radius="xl" size="xs" />}
      onClick={() => handleClick(item)}
      key={item.label}
    >
      {item.label}
    </Menu.Item>
  ));

  return (
    <Menu onOpen={() => setOpened(true)} onClose={() => setOpened(false)} radius="md" withinPortal>
      <Menu.Target>
        <UnstyledButton
          className={classes.control}
          disabled={selected && selected.value == 4}
          sx={selected && selected.value == 4 && { pointerEvents: "none" }}
        >
          <Group spacing="xs">
            <ThemeIcon color={selected && selected.color} radius="xl" size="xs" />

            <span className={classes.label}>{selected && selected.name}</span>
          </Group>
          {selected && selected.value != 4 && <IconChevronDown size="1.6rem" className={classes.icon} stroke={1.5} />}
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>{availableItems}</Menu.Dropdown>
    </Menu>
  );
}
