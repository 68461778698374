import React, { useEffect } from "react";
import style from "../../../style.scss";

export function PreviewSurveyLink(props) {
  const { survey, currentQuestion, currentProduct } = props;

  console.log("#34 currentQuestion", currentQuestion);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      handleIframeRefresh();
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [survey]);

  useEffect(() => {
    handleIframeRefresh();
  }, [currentQuestion]);

  useEffect(() => {
    if (survey.platform === "web") {
      window.addEventListener("message", function (ev) {
        const { message, params, channel } = ev.data;
        if (channel !== "_gradian") return;
        if (message == "reloadSurvey") {
          handleIframeRefresh();
        }
      });
    }
  }, []);

  async function toggleIframe() {
    const frameEl = document.getElementById("previewSurveyFrame");
    console.log("#345.2 frameEl", survey.survey_options);
    if (
      (survey.survey_options.show_introduction || survey.survey_options.introduction || survey.questions.length > 0) &&
      frameEl.style.display === "block"
    ) {
      console.log("#345.3 will set null");
      return null;
    } else if (
      (survey.survey_options.show_introduction || survey.survey_options.introduction || survey.questions.length > 0) &&
      frameEl.style.display === "none"
    ) {
      console.log("#345.4 will show block");
      frameEl.style.display = "block";
    } else {
      console.log("#345.5 will hide block");
      frameEl.style.display = "none";
    }
  }
  function handleIframeRefresh() {
    const frameEl = document.getElementById("previewSurveyFrame");
    if (frameEl) frameEl.src += "";
  }

  async function handleIframeLoad() {
    console.log("***iframeloaded");

    toggleIframe();
    var obj = JSON.parse(
      JSON.stringify({
        message: "surveyData",
        params: { data: survey, cq: currentQuestion, cp: currentProduct },
        channel: "_gradian",
      })
    );
    await window.frames["previewSurveyFrame"].contentWindow.postMessage(obj, "*");
  }

  console.log("survey in PreviewSurvey is", survey);
  //window.frames['inlineFrameExample'].contentWindow.postMessage(survey, '*');

  return (
    <div style={{ marginTop: "50px" }}>
      <div className={style.browserMockup} style={{ height: "650px", width: "100%", position: "relative" }}>
        <div className={style.browserBar}>
          <div className={style.browserButtons}>
            <div className={style.button + " " + style.red}></div>
            <div className={style.button + " " + style.yellow}></div>
            <div className={style.button + " " + style.green}></div>
          </div>
          <div className={style.addressBar}>
            <span className={style.addressText}>https://survey.feedbackspark.com/aasbbnhjukinnmlqopui</span>
          </div>
        </div>
        <div className={style.content}>
          <div
            className={`${style._gradian_container} ${style._surveylink}`}
            style={{
              position: "absolute",
              // transition: "all 0.3s ease-in-out",
              //transition: "width 0.3s ease-in-out, height 0.3s ease-in-out",
            }}
          >
            <div className={style._gradian_inner_container}>
              <div className={`${style.iframeParent} ${style._gradian_main}`}>
                <iframe
                  id="previewSurveyFrame"
                  title="Inline Frame Example"
                  // width="400"
                  //height="400"

                  className={style.previewSurveyClass}
                  onLoad={handleIframeLoad}
                  src={process.env.REACT_APP_SERVER_URL + "surveyhq-preview.html"}
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
