import React from "react";
import { List, Text, Card, Group, Space, Anchor } from "@mantine/core";
import { IconDots, IconDotsVertical } from "@tabler/icons-react";
export default function WebhookInfo(props) {
  const { webhookId } = props;
  console.log("webhookId", webhookId);
  return (
    <div>
      <Text size={16}>Send survey response to your preferred destination using webhooks.</Text>
      <Group position="apart" mt={20}>
        <Card withBorder p={20}>
          <Text size={16} fw={700} mb={10}>
            How to setup
          </Text>
          <List type="ordered" size={16}>
            {webhookId ? null : (
              <List.Item>
                Click <b>Connect</b> above. This will generate your signing secret, which is used to generate webhook
                signatures. The signature is included in request headers and allows you to verify that the webhooks were
                sent by FeedbackSpark, not by a third party.
              </List.Item>
            )}

            <List.Item>
              <Group spacing={5}>
                <Text>
                  Go to <b>Survey List</b> page and click edit from the extended menu
                </Text>
                <Group spacing={0}>
                  (<IconDotsVertical size={16} />
                  ).
                </Group>
              </Group>
            </List.Item>
            <List.Item>
              Visit <b>Notifications</b> settings and click the <b>Webhook</b> tab.
            </List.Item>
            <List.Item>
              Click <b>+ Add webhook</b> and create your webhook by adding a desired name, endpoint, and event type.
              Currently, two events are supported:
              <List withPadding listStyleType="disc" size={16}>
                <List.Item>
                  On survey completion: Triggered when the respondent answers all the questions in the survey. So, if
                  the respondent leaves the survey in between, you will not receive any webhook notification.
                </List.Item>
                <List.Item>
                  On each response: Triggered on each response. So, if you have five questions in a survey and a
                  respondent answers all the questions, you will receive five webhook notifications.
                </List.Item>
              </List>
            </List.Item>
            <List.Item>
              Click <b>Save</b> to save the webhook.
            </List.Item>
            <List.Item>
              <Group spacing={5}>
                Test your webhook by clicking <b>Test</b> button from the extended menu
                <Group spacing={0}>
                  (<IconDots size={16} />
                  ).
                </Group>
              </Group>
            </List.Item>
          </List>
          <Space h="md" />
        </Card>
        <Text size={16}>
          Check{" "}
          <Anchor href="https://feedbackspark.com/docs/webhooks/" target="_blank">
            documentation
          </Anchor>{" "}
          to learn more.
        </Text>
      </Group>
    </div>
  );
}
