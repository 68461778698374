import React, { useEffect, useState } from 'react';
import { fetchSurveyQuestionResponseData } from '../../../../../api/Survey';

import { Flex, RingProgress, Text, Loader, Grid, Space, Card, List, ThemeIcon, Center } from '@mantine/core';
import ChartSegmentControl from '../../ChartSegmentControl';
import { BarGraph2, LineGraph2, PieGraph } from '../../charts';
import { pluralize, formatTimeTick } from '../../../../../utils/helpers';
import NoData from '../NoData';
import { NPSColors } from '../../../../../utils/colorScheme';

export function NPSReport(props) {
  const { questionId, surveyId, graphType, setGraphType, timeframe, customDate } = props;
  const [responseData, setResponseData] = useState();
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState();

  //const [timeframe, setTimeframe] = useState('this_month');

  useEffect(() => {
    fetchSurveyQuestionResponseData(surveyId, questionId, timeframe, customDate, 'week')
      .then(data => {
        setResponseData(data);
      })
      .catch(error => {
        setError(error);
      })
      .finally(setLoader(false));
  }, [timeframe, customDate]);

  //return if(length(data.response_data) > 0);

  const npsChartData = responseData && responseData.timeseries;
  const npsRawData = responseData && responseData.summary;

  console.log('npsChartData', npsChartData);
  console.log('npsRawData', npsRawData);

  const npsRatingChartData =
    responseData &&
    responseData.response_aggregate &&
    responseData.response_aggregate
      .sort((a, b) => a.answer - b.answer)
      .map(resp => {
        return {
          name: parseInt(resp['answer']),
          value: resp['response_count'],
          percentage: resp['response_pct'],
        };
      });

  const npsTick = tickprops => {
    const { x, y, payload } = tickprops;
    console.log('ChartData in npsTick', npsChartData[0]['interval']);
    const { value } = payload;
    const tickvalue = formatTimeTick(value, npsChartData[0]['interval']);

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="middle" fill="#5C5F66" fontSize={12}>
          {tickvalue}
        </text>
      </g>
    );
  };

  const npsGraphOptions = {
    xAxisProps: {
      dataKey: 'grouped_date',
      tick: npsTick,
      //tickFormatter: timeTickFormatter,

      interval: 0,
    },
    yAxisProps: {
      label: { value: 'NPS', fontSize: 14, angle: -90 },
      domain: [-100, 100],
      type: 'number',
      tick: { fill: '#5C5F66', strokeWidth: 0.5, fontSize: 12 },
    },
    margin: {
      top: 30,
      right: 30,
      left: 20,
      bottom: 30,
    },
  };

  const npsRatingGraphOptions = {
    xAxisProps: {
      dataKey: 'name',
      ticks: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      // axisline: false,
      // tickline: false,
      //height: 1,
      textAnchor: 'middle',
      //width: 5,
      tick: { fontSize: 14, fill: '#5C5F66', dy: 5 },
      //hide: true
      type: 'number',
      domain: [1, 10],
      interval: 0,
    },
    yAxisProps: {
      label: { value: 'Response Count', fontSize: 14, angle: -90 },
      type: 'number',
      tick: false,
    },
    margin: {
      top: 30,
      right: 30,
      left: 20,
      bottom: 30,
    },
    cellFill: val => {
      if (parseInt(val) <= 6) {
        return NPSColors['detractor'];
      } else if (parseInt(val) >= 9) {
        return NPSColors['promoter'];
      } else {
        return NPSColors['passive'];
      }
    },
  };

  function renderChart(type, questionType = 4) {
    if (type === 'bar') {
      return (
        <>
          {/* <Text align="center">Rating Distribution</Text> */}
          <BarGraph2
            chartData={npsRatingChartData}
            graphOptions={npsRatingGraphOptions}
            questionType={questionType}
            dataType="npsRating"
          />
        </>
      );
    } else if (type === 'line') {
      return <LineGraph2 chartData={npsChartData} graphOptions={npsGraphOptions} questionType={questionType} />;
    } else if (type === 'pie') {
      return (
        <PieGraph chartData={npsRatingChartData} graphOptions={npsRatingGraphOptions} questionType={questionType} />
      );
    }
  }

  const npsScores = {
    totalResponses: function () {
      return npsChartData && npsChartData.reduce((sum, val) => (sum = sum + val['total_responses']), 0);
    },
    promotersCount: function () {
      return npsChartData && npsChartData.reduce((sum, val) => (sum = sum + val['promoters']), 0);
    },
    passivesCount: function () {
      return npsChartData && npsChartData.reduce((sum, val) => (sum = sum + val['passives']), 0);
    },
    detractorsCount: function () {
      return npsChartData && npsChartData.reduce((sum, val) => (sum = sum + val['detractors']), 0);
    },
    promotersPct: function () {
      if (this.totalResponses() == 0) {
        return 0;
      }
      return Math.round((this.promotersCount() / this.totalResponses()) * 100);
    },
    passivesPct: function () {
      if (this.totalResponses() == 0) {
        return 0;
      }
      return Math.round((this.passivesCount() / this.totalResponses()) * 100);
    },
    detractorsPct: function () {
      if (this.totalResponses() == 0) {
        return 0;
      }
      return Math.round((this.detractorsCount() / this.totalResponses()) * 100);
    },
    nps: function () {
      return this.promotersPct() - this.detractorsPct();
    },
  };

  if (error) {
    return <Text>{error.message || 'Something went wrong.'}</Text>;
  }

  if (!npsChartData || !npsRawData) {
    return <NoData />;
  }

  return loader ? (
    <Center>
      <Loader />
    </Center>
  ) : npsChartData ? (
    <Grid>
      <Grid.Col span={4}>
        <div style={{ width: '250px' }}>
          <Card p="lg" radius="md">
            <Flex justify="center" align="center" direction="column" wrap="nowrap">
              <Card.Section>
                <RingProgress
                  size={170}
                  thickness={16}
                  label={
                    <Flex mih={50} justify="center" align="center" direction="column" wrap="nowrap">
                      <Text size="xs" align="center">
                        NPS
                      </Text>
                      <Text size="40px" weight={400} align="center" px="xs" sx={{ pointerEvents: 'none' }}>
                        {npsScores.nps()}
                      </Text>
                    </Flex>
                  }
                  sections={[
                    {
                      value: npsScores.promotersPct(),
                      color: NPSColors['promoter'],
                      //tooltip: `Promoters: ${npsScores.promotersCount()}`,
                    },
                    {
                      value: npsScores.detractorsPct(),
                      color: NPSColors['detractor'],
                      //tooltip: `Detractors: ${npsScores.detractorsCount()}`,
                    },
                    {
                      value: npsScores.passivesPct(),
                      color: NPSColors['passive'],
                      //tooltip: `Passives: ${npsScores.passivesCount()}`,
                    },
                  ]}
                />
              </Card.Section>
              {/* <Text color="dark.3" size="xs">
                {dataDuration(datapoints)}
              </Text> */}

              <Text color="dark.3" size={14}>
                {pluralize(npsScores.totalResponses(), 'Response', 's')}
              </Text>
              <Space h="md" />
              <List spacing="xs" size="sm" center>
                <List.Item icon={<ThemeIcon color={NPSColors['promoter']} size={10} radius="xl"></ThemeIcon>}>
                  <Text color="dark.5" size={14}>
                    {npsScores.promotersPct()}% Promoters ({npsScores.promotersCount()} total)
                  </Text>
                </List.Item>
                <List.Item icon={<ThemeIcon color={NPSColors['passive']} size={10} radius="xl"></ThemeIcon>}>
                  <Text color="dark.5" size={14}>
                    {npsScores.passivesPct()}% Passives ({npsScores.passivesCount()} total)
                  </Text>
                </List.Item>
                <List.Item icon={<ThemeIcon color={NPSColors['detractor']} size={10} radius="xl"></ThemeIcon>}>
                  <Text color="dark.5" size={14}>
                    {npsScores.detractorsPct()}% Detractors ({npsScores.detractorsCount()} total)
                  </Text>
                </List.Item>
              </List>
            </Flex>
          </Card>
        </div>
      </Grid.Col>
      <Grid.Col span={8}>
        <div className="chartContainer" style={{ width: '100%' }}>
          {/* {renderChart2('bar')} */}
          <ChartSegmentControl
            graphType={graphType}
            setGraphType={setGraphType}
            questionId={questionId}
            show={['bar', 'line']}
          />
          {renderChart(graphType.get(questionId))}
        </div>
      </Grid.Col>
    </Grid>
  ) : (
    <Center>
      <Loader />
    </Center>
  );
}
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="197cfec1-a81c-5858-8b86-c3ccff65943a")}catch(e){}}();
//# debugId=197cfec1-a81c-5858-8b86-c3ccff65943a
