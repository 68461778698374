//import thin from './../fonts/OpenSans/OpenSans-Thin.ttf';

import thin from './Inter/Inter-Thin.ttf';
import light from './Inter/Inter-Light.ttf';
import regular from './Inter/Inter-Regular.ttf';
import medium from './Inter/Inter-Medium.ttf';
import semibold from './Inter/Inter-SemiBold.ttf';
import bold from './Inter/Inter-Bold.ttf';
import black from './Inter/Inter-Black.ttf';
import extrabold from './Inter/Inter-ExtraBold.ttf';
import extralight from './Inter/Inter-ExtraLight.ttf';

export const InterFont = [
  {
    '@font-face': {
      fontFamily: 'Inter',
      src: `url('${thin}') format('truetype')`,
      fontWeight: 100,
      fontStyle: 'normal',
    },
  },
  {
    '@font-face': {
      fontFamily: 'Inter',
      src: `url('${extralight}') format('truetype')`,
      fontWeight: 200,
      fontStyle: 'normal',
    },
  },
  {
    '@font-face': {
      fontFamily: 'Inter',
      src: `url('${light}') format('truetype')`,
      fontWeight: 300,
      fontStyle: 'normal',
    },
  },

  {
    '@font-face': {
      fontFamily: 'Inter',
      src: `url('${regular}') format('truetype')`,
      fontWeight: 400,
      fontStyle: 'normal',
    },
  },
  {
    '@font-face': {
      fontFamily: 'Inter',
      src: `url('${medium}') format('truetype')`,
      fontWeight: 500,
      fontStyle: 'normal',
    },
  },

  {
    '@font-face': {
      fontFamily: 'Inter',
      src: `url('${semibold}') format('truetype')`,
      fontWeight: 600,
      fontStyle: 'normal',
    },
  },
  {
    '@font-face': {
      fontFamily: 'Inter',
      src: `url('${bold}') format('truetype')`,
      fontWeight: 700,
      fontStyle: 'normal',
    },
  },
  {
    '@font-face': {
      fontFamily: 'Inter',
      src: `url('${extrabold}') format('truetype')`,
      fontWeight: 800,
      fontStyle: 'normal',
    },
  },
  {
    '@font-face': {
      fontFamily: 'Inter',
      src: `url('${black}') format('truetype')`,
      fontWeight: 900,
      fontStyle: 'normal',
    },
  },
];
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="ddecf353-8878-5e45-8d69-7b2de4b0b155")}catch(e){}}();
//# debugId=ddecf353-8878-5e45-8d69-7b2de4b0b155
