import React, { useEffect } from "react";
import { useAuthState } from "../../context/AuthContext";
import InstallScript from "../../components/InstallScript";
import { Group, Box, Divider } from "@mantine/core";
import Heading from "../../components/Heading";
import { useIntl } from "react-intl";

function Connect() {
  const intl = useIntl();
  const auth = useAuthState();

  return (
    <>
      <Box>
        <Heading title={intl.formatMessage({ id: "connect_page.heading", defaultMessage: "Connect" })} />

        <InstallScript prodToken={auth.currentProduct.prodToken || auth.organization.products[0].prodToken} />
      </Box>
    </>
  );
}

export default Connect;
