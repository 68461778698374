import React, { forwardRef } from "react";

import { Group, Text, Select } from "@mantine/core";
import { StyledSelect } from "../../../components/MantineOverride";
import style from "./../../../style.scss";
// import { IconSquarePlus } from '@tabler/icons-react';

export default function AttributeSelection(props) {
  const { idx, filter, handleChange, attributeList } = props;

  const SelectItem = forwardRef(function SelectItem({ label, datatype, ...others }, ref) {
    return (
      <div ref={ref} {...others} key={idx}>
        <Group noWrap position="apart">
          <div>
            <Text size="sm">{label}</Text>
          </div>
          <AttributeIcon datatype={datatype} />
        </Group>
      </div>
    );
  });

  return (
    <>
      {/* {idx > 0 ? (
        <Text size="14px" color="dimmed">
          and
        </Text>
      ) : (
        <span></span>
      )} */}
      <StyledSelect
        //label={filterCount > 1 && idx > 0 ? 'and' : null}
        placeholder="Select Attribute"
        itemComponent={SelectItem}
        data={attributeList()}
        //defaultValue={currentProduct.name}
        value={filter.property}
        searchable
        maxDropdownHeight={400}
        onChange={val => handleChange(idx, "property", val, attributeList().find(a => a.value === val)?.datatype)}
        nothingFound="Nobody here"
        filter={(value, item) => item.label.toLowerCase().includes(value.toLowerCase().trim())}
        styles={() => ({
          itemsWrapper: {
            width: "max-content",
          },
          dropdown: {
            width: "auto !important",
          },
        })}
      />
    </>
  );
}

const AttributeIcon = props => {
  const { datatype } = props;
  switch (datatype) {
    case "boolean":
      return (
        <Text
          className={style.AttributeIcon}
          size="10px"
          //color="dimmed"
          sx={{ border: "1px solid", padding: "2px 5px", borderRadius: "6px" }}
        >
          Boolean
        </Text>
      );
    case "string":
      return (
        <Text
          size="10px"
          //color="dimmed"
          sx={{ border: "1px solid", padding: "2px 5px", borderRadius: "6px" }}
          className={style.AttributeIcon}
        >
          String
        </Text>
      );
    case "number":
      return (
        <Text
          size="10px"
          //color="dimmed"
          sx={{ border: "1px solid", padding: "2px 5px", borderRadius: "6px" }}
          className={style.AttributeIcon}
        >
          Number
        </Text>
      );
    case "date":
      return (
        <Text size="10px" sx={{ border: "1px solid", padding: "2px 5px", borderRadius: "6px" }}>
          Date
        </Text>
      );
    default:
      break;
  }
};
