import React, { useEffect, useState, useRef } from "react";
import { useAuthState } from "../../context/AuthContext";
import { Radio, Select, Group, Anchor, NumberInput, Menu, Text, Card, TextInput } from "@mantine/core";
import { Calendar, RangeCalendar } from "@mantine/dates";
import { pluralize, getLastNDiffDates } from "../../utils/helpers";
import style from "./../../style.scss";
import dayjs from "dayjs";
import checkDataType from "../../utils/checkDataType";
import { IconChevronDown } from "@tabler/icons-react";
import StringValueSelector from "./StringValueSelector";
import { getTimeDiffinNdayFormat } from "../../utils/filterHelper";
import { isArray } from "lodash";

export default function ValueSelector(props) {
  const { idx, filterObject, handleChange, dispatch, attributeValues, source } = props;
  const [numRange, setNumRange] = useState(filterObject.predicate);
  const [singleNum, setSingleNum] = useState(0);
  const [timeNumber, setTimeNumber] = useState(7);
  const [timeUnit, setTimeUnit] = useState("day");
  const [rangeValue, setRangeValue] = useState(getLastNDiffDates());

  const numRangeForm = useRef(null);
  const numForm = useRef(null);

  const [lastNRangeCalendarOpen, setLastNRangeCalendarOpen] = useState(false);
  const [rangeCalendarOpen, setRangeCalendarOpen] = useState(false);
  const [dateCalendarOpen, setDateCalendarOpen] = useState(false);
  const [dateValue, setDateValue] = useState();
  const operator = filterObject.operator;
  const datatype = filterObject.datatype;

  const { currentProduct } = useAuthState();
  const userTimezone = currentProduct.timezone || "UTC";

  function handleNumRangeChange(val, position) {
    console.log("handleNumRangeChange", val, position);
    if (position === "start") {
      setNumRange([val, numRange[1]]);
    } else {
      setNumRange([numRange[0], val]);
    }
  }

  const handleBlur = () => {
    const [start, end] = filterObject.predicate;
    if (start !== "" && end !== "" && numRangeForm.current) {
      numRangeForm.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
    }
  };

  const handleNumBlur = () => {
    if (numForm.current) {
      numForm.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
    }
  };

  function handleNumSubmit(e) {
    e.preventDefault();
    if (singleNum) {
      if (source === "filter") {
        dispatch({
          type: "updateFilter",
          filterType: "attributeFilter",
          index: idx,
          section: "predicate",
          value: [singleNum],
        });
      } else {
        dispatch({
          type: "updateFilter",
          idx: idx,
          section: "predicate",
          value: [singleNum],
        });
      }
    }
  }

  function handleNumRangeSubmit(e) {
    e.preventDefault();
    if (numRange.length === 2) {
      if (source === "filter") {
        dispatch({
          type: "updateFilter",
          filterType: "attributeFilter",
          index: idx,
          section: "predicate",
          value: numRange,
        });
      } else {
        dispatch({
          type: "updateFilter",
          idx: idx,
          section: "predicate",
          value: numRange,
        });
      }
    }
  }

  useEffect(() => {
    console.log("#399 operator changed", filterObject);
    if (filterObject.datatype === "date") {
      if (source === "survey") {
        setRangeValue(getLastNDiffDates());
        setTimeNumber(7);
        setTimeUnit("day");
      }
    } else if (filterObject.datatype === "number") {
      setNumRange([]);
      // dispatch({
      //   type: "updateFilter",
      //   filterType: "attributeFilter",
      //   index: idx,
      //   section: "predicate",
      //   value: [],
      // });
    }
  }, [filterObject.operator]);

  function lastNRangeChange() {
    console.log("#399 timeNumber", timeNumber);
    const unixTimestampRange = [
      dayjs(new Date()).startOf("day").subtract(timeNumber, timeUnit).tz(userTimezone).unix(),
      dayjs(new Date()).startOf("day").tz(userTimezone).unix(),
    ];
    if (source === "filter") {
      dispatch({
        type: "updateFilter",
        filterType: "attributeFilter",
        index: idx,
        section: "predicate",
        value: unixTimestampRange,
      });
    } else {
      dispatch({
        type: "updateFilter",
        idx: idx,
        section: "predicate",
        value: `${timeNumber}-${timeUnit}`,
      });
    }
  }

  function handleApply(val, section) {
    if (source === "filter") {
      dispatch({
        type: "updateFilter",
        filterType: "attributeFilter",
        index: idx,
        section: section,
        value: val,
      });
    } else {
      dispatch({
        type: "updateFilter",
        idx: idx,
        section: section,
        value: val,
      });
    }
  }

  if (operator) {
    if (datatype === "boolean") {
      if (source === "filter") {
        return (
          <Radio.Group
            sx={{ marginLeft: source === "filter" ? "10px" : "0px" }}
            spacing="xs"
            offset={5}
            size={source === "filter" ? "xs" : "sm"}
            value={filterObject.predicate[0]}
            onChange={val => handleChange(idx, "predicate", [val])}
            name="Boolean Attribute"
          >
            <Radio value="true" label="True" />
            <Radio value="false" label="False" />
          </Radio.Group>
        );
      } else {
        return (
          <Card withBorder radius="sm" px={10} py={6}>
            <Radio.Group
              sx={{ marginLeft: source === "filter" ? "10px" : "0px" }}
              spacing="xs"
              offset={5}
              size={source === "filter" ? "xs" : "sm"}
              value={filterObject.predicate[0]}
              onChange={val => handleChange(idx, "predicate", [val])}
              name="Boolean Attribute"
            >
              <Radio value="true" label="True" />
              <Radio value="false" label="False" />
            </Radio.Group>
          </Card>
        );
      }
    } else if (datatype === "number") {
      switch (operator) {
        case "between":
        case "notbetween":
          return (
            <form ref={numRangeForm} onSubmit={e => handleNumRangeSubmit(e)}>
              <Group spacing={2}>
                <NumberInput
                  sx={{ width: "80px", fontSize: "14px", "input::placeholder": { fontSize: "14px" } }}
                  hideControls
                  placeholder="Value"
                  value={filterObject.predicate[0]}
                  onBlur={handleBlur}
                  onChange={val => handleNumRangeChange(val, "start")}
                />
                <Text size={12}>and</Text>
                <NumberInput
                  sx={{ width: "80px", fontSize: "14px", "input::placeholder": { fontSize: "14px" } }}
                  hideControls
                  placeholder="Value"
                  value={filterObject.predicate[1]}
                  onBlur={handleBlur}
                  onChange={val => handleNumRangeChange(val, "end")}
                />
              </Group>
            </form>
          );
        default:
          return (
            <form ref={numForm} onSubmit={e => handleNumSubmit(e)}>
              <NumberInput
                sx={{ width: "100px", "input::placeholder": { fontSize: "14px" } }}
                placeholder="Enter value"
                hideControls
                onBlur={handleNumBlur}
                value={filterObject.predicate}
                onChange={val => setSingleNum(val)}
              />
            </form>
          );
      }
    } else if (datatype === "date") {
      switch (operator) {
        case "last":
        case "notinlast":
          return (
            <>
              <Menu
                opened={lastNRangeCalendarOpen}
                onChange={setLastNRangeCalendarOpen}
                position="bottom-start"
                shadow="sm"
              >
                {console.log("source filter object", filterObject)}
                <Menu.Target>
                  {source === "filter" ? (
                    <Text className={style.filterHighlighter} size={14}>
                      {filterObject.predicate && isArray(filterObject.predicate)
                        ? lastNRangeCalendarOpen
                          ? "Select Duration"
                          : getTimeDiffinNdayFormat(filterObject.predicate, timeUnit)
                        : "Select Duration"}
                    </Text>
                  ) : (
                    <Card withBorder radius="xs" px={10} py={6}>
                      <Group>
                        <Text className={style.filterHighlighter} size={14}>
                          {filterObject.predicate && checkDataType(filterObject.predicate) === "string" ? (
                            pluralize(filterObject.predicate.split("-")[0], filterObject.predicate.split("-")[1], "s")
                          ) : (
                            <Text color="dimmed">Select duration</Text>
                          )}
                        </Text>
                        <IconChevronDown size={18} color="rgb(134, 142, 150)" />
                      </Group>
                    </Card>
                  )}
                </Menu.Target>
                {console.log("#399 filterObject.predicate", filterObject.predicate)}

                <Menu.Dropdown className={style.filterDropdown}>
                  <Group>
                    <NumberInput sx={{ width: "120px" }} value={timeNumber} onChange={val => setTimeNumber(val)} />
                    <Select
                      sx={{ width: "120px" }}
                      value={timeUnit}
                      onChange={val => setTimeUnit(val)}
                      data={[
                        { value: "day", label: timeNumber > 1 ? "Days" : "Day" },
                        { value: "week", label: timeNumber > 1 ? "Weeks" : "Week" },
                        { value: "month", label: timeNumber > 1 ? "Months" : "Month" },
                      ]}
                    />
                  </Group>

                  <Group position="apart" mt={20}>
                    <Anchor color="dimmed" onClick={() => setLastNRangeCalendarOpen(false)}>
                      Close
                    </Anchor>
                    <Anchor
                      onClick={() => {
                        lastNRangeChange();

                        setLastNRangeCalendarOpen(false);
                      }}
                    >
                      Apply
                    </Anchor>
                  </Group>
                </Menu.Dropdown>
              </Menu>
            </>
          );
        case "between":
          return (
            <Menu
              opened={rangeCalendarOpen}
              onChange={setRangeCalendarOpen}
              withinPortal
              position="bottom-end"
              shadow="sm"
            >
              {console.log("#398 filterObject.predicate between", filterObject)}
              <Menu.Target>
                {source === "filter" ? (
                  <Text className={style.filterHighlighter} size={14}>
                    {filterObject.predicate[0]
                      ? dayjs.unix(filterObject.predicate[0]).format("MMM D, YY")
                      : "Start Date"}{" "}
                    -{" "}
                    {filterObject.predicate[1]
                      ? dayjs.unix(filterObject.predicate[1]).format("MMM D, YY")
                      : " End Date"}
                  </Text>
                ) : (
                  <Card withBorder radius="xs" px={10} py={6}>
                    <Group>
                      <Text className={style.filterHighlighter} size={14}>
                        {filterObject.predicate[0]
                          ? dayjs.unix(filterObject.predicate[0]).format("MMM D, YY")
                          : "Start Date"}{" "}
                        -{" "}
                        {filterObject.predicate[1]
                          ? dayjs.unix(filterObject.predicate[1]).format("MMM D, YY")
                          : " End Date"}
                      </Text>
                      <IconChevronDown size={16} color="rgb(134, 142, 150)" />
                    </Group>
                  </Card>
                )}
              </Menu.Target>

              <Menu.Dropdown p={20}>
                <RangeCalendar
                  value={rangeValue}
                  allowLevelChange={false}
                  allowSingleDateInRange={true}
                  onChange={setRangeValue}
                  maxDate={new Date()}
                />
                <Group position="apart" mt={20} mb={10}>
                  <Anchor color="dimmed" onClick={() => setRangeCalendarOpen(false)}>
                    Close
                  </Anchor>
                  <Anchor
                    onClick={() => {
                      handleApply(
                        [
                          dayjs(rangeValue[0]).startOf("day").tz(userTimezone).unix(),
                          dayjs(rangeValue[1]).startOf("day").tz(userTimezone).unix(),
                        ],
                        "predicate"
                      );
                      setRangeCalendarOpen(false);
                    }}
                  >
                    Apply
                  </Anchor>
                </Group>

                {/* <DateRangePicker placeholder="Pick dates range" value={dateValue} onChange={setDateValue} /> */}
              </Menu.Dropdown>
            </Menu>
          );
        case "on":
        case "noton":
        case "before":
        case "after":
          return (
            <Menu
              opened={dateCalendarOpen}
              onChange={setDateCalendarOpen}
              withinPortal
              position="bottom-end"
              shadow="sm"
            >
              {console.log("#398 filterObject.predicate Single date", filterObject)}
              <Menu.Target>
                {source === "filter" ? (
                  <Text className={style.filterHighlighter} size={14}>
                    {filterObject.predicate.toString().length > 0 && dayjs.unix(filterObject.predicate[0]).isValid() ? (
                      dayjs.unix(filterObject.predicate[0]).format("MMM D, YY")
                    ) : (
                      <Text color="dimmed">Select Date</Text>
                    )}
                  </Text>
                ) : (
                  <Card withBorder radius="xs" px={10} py={6}>
                    <Group>
                      <Text className={style.filterHighlighter} size={14}>
                        {filterObject.predicate.toString().length > 0 &&
                        dayjs.unix(filterObject.predicate[0]).isValid() ? (
                          dayjs.unix(filterObject.predicate[0]).format("MMM D, YY")
                        ) : (
                          <Text color="dimmed">Select Date</Text>
                        )}
                      </Text>
                      <IconChevronDown size={16} color="rgb(134, 142, 150)" />
                    </Group>
                  </Card>
                )}
              </Menu.Target>

              <Menu.Dropdown p={20}>
                <Calendar value={dateValue} onChange={setDateValue} maxDate={new Date()} />
                <Group position="apart" mt={20} mb={10}>
                  <Anchor color="dimmed" onClick={() => setDateCalendarOpen(false)}>
                    Close
                  </Anchor>
                  <Anchor
                    onClick={() => {
                      handleApply([dayjs(dateValue).startOf("day").tz(userTimezone).unix()], "predicate");
                      setDateCalendarOpen(false);
                    }}
                  >
                    Apply
                  </Anchor>
                </Group>
              </Menu.Dropdown>
            </Menu>
          );

        default:
          break;
      }
    } else {
      if (filterObject.operator === "set" || filterObject.operator === "notset") {
        return null;
      } else if (filterObject.operator === "con" || filterObject.operator === "dcon") {
        return (
          <TextInput
            placeholder="Enter value"
            sx={{ "input::placeholder": { fontSize: "14px" } }}
            value={filterObject.predicate}
            onChange={e => handleChange(idx, "predicate", e.target.value, "string")}
          />
        );
      } else {
        if (attributeValues && attributeValues.length > 1 && attributeValues.length < 10) {
          console.log("@king ppppppppppppppp", props);
          return (
            <StringValueSelector
              attributeValues={attributeValues}
              dispatch={dispatch}
              filterObject={filterObject}
              idx={idx}
              source={source}
            />
          );
        } else
          return (
            <TextInput
              sx={{ "input::placeholder": { fontSize: "14px" } }}
              placeholder="Enter value"
              value={filterObject.predicate}
              onChange={e => handleChange(idx, "predicate", e.target.value, "string")}
            />
          );
      }
    }
  }
}
