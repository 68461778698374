import update, { extend } from "immutability-helper";
import { QUESTION_TYPE_STRING_TO_ID } from "../utils/constants";
import { useAuthState } from "../context/AuthContext";
import { nanoid } from "nanoid";

extend("$auto", function (value, object) {
  return object ? update(object, value) : update({}, value);
});
extend("$autoArray", function (value, object) {
  return object ? update(object, value) : update([], value);
});
extend("$delKey", function (keys, obj) {
  console.log("delkey Keys", keys);
  console.log("delkey obj", obj);

  for (var i = 0; i < keys.length; i++) {
    delete obj[keys[i]];
  }
  return obj;
});

function questionDefaults(questionType, order = -1, productName) {
  console.log("ORDER IS: ", order);
  if (questionType === "nps") {
    return {
      questionType: QUESTION_TYPE_STRING_TO_ID[questionType],
      questionText: `How likely is it that you would recommend ${productName} to a friend or colleague? `,
      options: {
        ratingScale: "numerical",
        ratingRange: 10,
        minText: "Not at all Likely",
        maxText: "Extremely Likely",
        randomizeChoices: false,
        //nextConditions: [{ logic: "sub", predicate: [], nextQ: `${order + 1}` }],
      },
      isRequired: true,
    };
  } else if (questionType === "rating") {
    return {
      questionType: QUESTION_TYPE_STRING_TO_ID[questionType],
      questionText: `How satisfied are you with our service? `,
      options: {
        ratingScale: "numerical",
        ratingRange: 5,
        minText: "Low",
        maxText: "High",
        randomizeChoices: false,
        //nextConditions: [{ logic: "sub", predicate: [], nextQ: `${order + 1}` }],
      },
      isRequired: true,
    };
  } else if (questionType === "mcq_single" || questionType === "mcq_multi") {
    return {
      questionType: QUESTION_TYPE_STRING_TO_ID[questionType],
      questionText: `What do you think about these options? `,
      options: {
        randomizeChoices: false,
        //nextConditions: [{ logic: "sub", predicate: [], nextQ: `${order + 1}` }],
      },
      isRequired: true,
      choices: [{ choice: "Option 1" }, { choice: "Option 2" }],
    };
  } else if (questionType === "binary") {
    return {
      questionType: QUESTION_TYPE_STRING_TO_ID[questionType],
      questionText: `How would you rate the support you received? `,
      options: {
        choiceType: "text", //thumb/smiley
        positiveColor: "green",
        negativeColor: "red",
        randomizeChoices: false,
        //nextConditions: [{ logic: "sub", predicate: [], nextQ: `${order + 1}` }],
      },
      isRequired: true,
      choices: [{ choice: "Good" }, { choice: "Bad" }],
    };
  } else if (questionType === "email") {
    return {
      questionType: QUESTION_TYPE_STRING_TO_ID[questionType],
      questionText: `What is your email address?`,
      options: {
        randomizeChoices: false,
      },
      isRequired: true,
    };
  } else if (questionType === "redirect") {
    return {
      questionType: QUESTION_TYPE_STRING_TO_ID[questionType],
      questionText: "",
      options: {
        content:
          "<h2 style='text-align: center'>Thank you!</h2><p style='text-align: center'>Please choose a time as per your convenience.</p>",
        primaryButtonText: "Choose a slot",
        primaryButtonLink: "https://calendly.com/<username>/30min",
        secondaryButtonText: "I'll do it later",
        hideSecondaryButton: false,
      },
      isRequired: true,
    };
  } else if (questionType === "open_text") {
    return {
      questionType: QUESTION_TYPE_STRING_TO_ID[questionType],
      questionText: "What can we do better?",
      options: {
        randomizeChoices: false,
      },
      isRequired: true,
    };
  } else {
    return {
      questionType: QUESTION_TYPE_STRING_TO_ID[questionType],
      questionText: "",
      options: {
        randomizeChoices: false,
        //nextConditions: [{ logic: "sub", predicate: [], nextQ: `${order + 1}` }],
      },
      isRequired: true,
    };
  }
}

const SurveryReducer = (state, action) => {
  switch (action.type) {
    case "addIntroduction":
      return update(state, {
        surveyOptions: {
          introduction: {
            $set: {
              content:
                "<h2 style='text-align: center'>Can you help us?</h2><p style='text-align: center'>We are conducting a small study and need your inputs. </p><p style='text-align: center'>It won't take more than <mark>a minute</mark> of your time.</p>",
              primaryButtonText: "Let's, do it",
            },
          },
        },
      });
    case "removeIntroduction":
      return update(state, {
        surveyOptions: {
          $delKey: ["introduction"],
        },
      });
    case "addQuestion":
      return update(state, {
        questions: {
          $push: [
            {
              ...questionDefaults(action.qType, state.questions.length, action.productName),
              order: state.questions.length,
              questionClientId: nanoid(),
            },
          ],
        },
      });
    case "insertQuestion":
      return update(state, {
        questions: {
          $splice: [
            [
              action.index,
              0,
              {
                ...questionDefaults(action.qType, state.questions.length, action.productName),
                order: state.questions.length,
                questionClientId: nanoid(),
              },
            ],
          ],
        },
      });
    case "removeQuestion":
      return update(state, {
        questions: { $splice: [[action.index, 1]] },
      });
    case "changeQuestion":
      console.log("Action in ChangeQuestion", action);
      return update(state, {
        questions: {
          [action.index]: {
            $set: {
              ...questionDefaults(action.newQuestionType, action.index, action.productName),
              order: action.index,
              questionClientId: nanoid(),
            },
          },
        },
      });
    case "updateQuestions":
      return update(state, {
        questions: { $set: action.value },
      });
    case "showIntroduction":
      return update(state, {
        surveyOptions: {
          showIntroduction: {
            $set: action.value,
          },
        },
      });
    case "updateLanguage":
      return update(state, {
        languageCode: {
          $set: action.value,
        },
      });
    // case "updateOptions":
    //   return update(state, {
    //     surveyOptions: {
    //       [action.optionType]: {
    //         ...(action.section && {
    //           [action.section]: {
    //             $set: action.value,
    //           },
    //         }),
    //         $set: action.value,
    //       },
    //     },
    //   });
    case "updateIntroduction":
      return update(state, {
        surveyOptions: {
          introduction: {
            [action.section]: {
              $set: action.value,
            },
          },
        },
      });
    case "updateThankyou":
      console.log("Action in updateThankyou", action);
      console.log("State in updateThankyou", state);
      return update(state, {
        surveyOptions: {
          thankyou: {
            [action.section]: {
              $set: action.value,
            },
          },
        },
      });
    case "updateStatement":
      return update(state, {
        questions: {
          [action.index]: {
            options: {
              [action.section]: {
                $set: action.value,
              },
            },
          },
        },
      });
    case "updateRatingType":
      return update(state, {
        questions: {
          [action.index]: {
            options: {
              [action.section]: {
                $set: action.value,
              },
            },
          },
        },
      });
    case "addLogic":
      return update(state, {
        questions: {
          [action.index]: {
            options: {
              $auto: {
                nextConditions: { $autoArray: { $push: [{ logic: "", predicate: [], nextQ: "-1" }] } },
              },
            },
          },
        },
      });
    case "addSkipLogic":
      return update(state, {
        questions: {
          [action.index]: {
            options: {
              $auto: {
                nextConditions: { $autoArray: { $push: [{ logic: "skip", predicate: [], nextQ: "-1" }] } },
              },
            },
          },
        },
      });
    case "addFilter":
      return update(state, {
        surveyOptions: {
          $auto: {
            attributeFilters: { $autoArray: { $push: [{ property: "", operator: "", predicate: "", datatype: "" }] } },
          },
        },
      });
    case "removeFilter":
      return update(state, {
        surveyOptions: {
          attributeFilters: { $splice: [[action.filterIndex, 1]] },
        },
      });
    case "updateFilter":
      console.log("#991 updateFilter state", state);
      console.log("#991 updateFilter action", action);
      return update(state, {
        surveyOptions: {
          attributeFilters: {
            [action.idx]: {
              [action.section]: {
                $set: action.value,
              },
              ...(action.section === "property" && {
                datatype: {
                  $set: action.datatype,
                },
                predicate: {
                  $set: "",
                },
                operator: {
                  $set: "",
                },
              }),
              ...(action.section === "operator" && {
                predicate: {
                  $set: "",
                },
              }),
            },
          },
        },
      });
    case "updateLogic":
      return update(state, {
        questions: {
          [action.index]: {
            options: {
              nextConditions: {
                [action.logicIndex]: {
                  [action.logicSection]: {
                    $set: action.value,
                  },
                },
              },
            },
          },
        },
      });
    case "updateMultiSelectPredicate":
      return update(state, {
        questions: {
          [action.index]: {
            options: {
              nextConditions: {
                [action.logicIndex]: {
                  [action.logicSection]: {
                    $set: action.value,
                  },
                },
              },
            },
          },
        },
      });
    case "updateSingleSelectPredicate":
      return update(state, {
        questions: {
          [action.index]: {
            options: {
              nextConditions: {
                [action.logicIndex]: {
                  [action.logicSection]: {
                    $set: action.value,
                  },
                },
              },
            },
          },
        },
      });
    case "removeLogic":
      return update(state, {
        questions: {
          [action.index]: {
            options: {
              nextConditions: {
                $splice: [[action.logicIndex, 1]],
              },
            },
          },
        },
      });
    case "isRequired":
      return update(state, {
        questions: {
          [action.index]: {
            isRequired: {
              $set: action.value,
            },
          },
        },
      });
    case "unSetNextConditions":
      return update(state, {
        questions: {
          [action.index]: {
            options: {
              nextConditions: {
                $set: [],
              },
            },
          },
        },
      });
    case "addChoice":
      return update(state, {
        questions: {
          [action.index]: {
            $auto: {
              choices: { $autoArray: { $push: [{ choice: `Option ${action.currentCount + 1}` }] } },
            },
          },
        },
      });
    case "removeChoice":
      return update(state, {
        questions: {
          [action.index]: {
            choices: {
              $splice: [[action.choiceIndex, 1]],
            },
          },
        },
      });
    case "canComment":
      return update(state, {
        questions: {
          [action.index]: {
            choices: {
              [action.choiceIndex]: {
                $auto: {
                  canComment: { $set: action.value },
                },
              },
            },
          },
        },
      });
    case "choiceRandomization":
      return update(state, {
        questions: {
          [action.index]: {
            options: {
              randomizeChoices: {
                $set: action.value,
              },
            },
          },
        },
      });
    case "updateSurveyOptions":
      return update(state, {
        surveyOptions: {
          [action.payload.key]: {
            $set: action.payload.value,
          },
        },
      });
    case "removeSurveyOptions":
      return update(state, {
        surveyOptions: {
          $delKey: [action.payload.key],
        },
      });
    case "settingChange":
      console.log("$999 action.value", action);
      if (action.field === "eventType" && [("pageLoad", "halfScroll", "abandon")].includes(action.value)) {
        return update(state, {
          surveyOptions: {
            [action.section]: {
              [action.field]: {
                $set: action.value,
              },
              $delKey: ["cssSelector", "innerText"],
            },
          },
        });
      } else if (action.section === "email") {
        return update(state, {
          surveyOptions: {
            $auto: {
              email: {
                $auto: {
                  [action.field]: {
                    $set: action.value,
                  },
                },
              },
            },
          },
        });
      } else {
        return update(state, {
          surveyOptions: {
            [action.section]: {
              [action.field]: {
                $set: action.value,
              },
              ...(action.field === "eventType" &&
                ["textClick"].includes(action.value) && {
                  $auto: {
                    innerText: {
                      $set: "",
                    },
                    waitFor: {
                      $set: 0,
                    },
                  },
                  $delKey: ["cssSelector"],
                }),

              ...(action.field === "eventType" &&
                action.value == "cssClick" && {
                  $auto: {
                    cssSelector: {
                      $set: "",
                    },
                    waitFor: {
                      $set: 0,
                    },
                  },
                  $delKey: ["innerText"],
                }),
              ...(action.field === "pages" &&
                action.value == "specific" && {
                  $auto: {
                    urlToMatch: {
                      $set: "",
                    },
                    matchCriteria: {
                      $set: "",
                    },
                  },
                }),
              ...(action.field === "pages" &&
                action.value == "all" && {
                  $delKey: ["urlToMatch", "matchCriteria"],
                }),
            },
          },
        });
      }
    case "updatePlatform":
      return update(state, {
        platform: {
          $set: action.value,
        },
      });

    case "inputChange":
      if (action.targetName === "question") {
        return update(state, {
          questions: {
            [action.index]: {
              questionText: { $set: action.value },
            },
          },
        });
      } else if (action.targetName === "choice") {
        return update(state, {
          questions: {
            [action.index]: {
              choices: {
                [action.choiceIndex]: {
                  choice: { $set: action.value },
                },
              },
            },
          },
        });
      } else if (action.targetName === "surveyName") {
        return update(state, {
          title: {
            $set: action.value,
          },
        });
      } else if (action.targetName === "language") {
        return update(state, {
          language: {
            $set: action.value,
          },
        });
      } else if (action.targetName === "rating_low_text") {
        return update(state, {
          questions: {
            [action.index]: {
              options: {
                minText: {
                  $set: action.value,
                },
              },
            },
          },
        });
      } else if (action.targetName === "rating_high_text") {
        return update(state, {
          questions: {
            [action.index]: {
              options: {
                maxText: {
                  $set: action.value,
                },
              },
            },
          },
        });
      }
      return;
    default:
  }
};

export default SurveryReducer;
