import React from 'react';
import { Title, Group, Divider, Text, Stack } from '@mantine/core';
import style from './../style.scss';

export default function Heading({ children, title, showDivider, subheading, icon }) {
  //const { title } = props;

  return (
    <div className={style.heading}>
      <Group position="apart">
        <Stack spacing={0}>
          <Group>
            {icon}
            <Title>{title}</Title>
          </Group>

          <Text color="dimmed">{subheading}</Text>
        </Stack>

        {children}
      </Group>
      {showDivider && <Divider mt={20} mb={10} />}
    </div>
  );
}
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="458b0151-ca5e-5d8a-9fc2-403df4eeedb1")}catch(e){}}();
//# debugId=458b0151-ca5e-5d8a-9fc2-403df4eeedb1
