import thin from './../fonts/Roboto/Roboto-Thin.ttf';
import light from './../fonts/Roboto/Roboto-Light.ttf';
import regular from './../fonts/Roboto/Roboto-Regular.ttf';
import medium from './../fonts/Roboto/Roboto-Medium.ttf';
import bold from './../fonts/Roboto/Roboto-Bold.ttf';
import black from './../fonts/Roboto/Roboto-Black.ttf';

export const RobotoFont = [
  {
    '@font-face': {
      fontFamily: 'Roboto',
      src: `url('${thin}') format('truetype')`,
      fontWeight: 100,
      fontStyle: 'normal',
    },
  },
  {
    '@font-face': {
      fontFamily: 'Roboto',
      src: `url('${light}') format('truetype')`,
      fontWeight: 300,
      fontStyle: 'normal',
    },
  },
  {
    '@font-face': {
      fontFamily: 'Roboto',
      src: `url('${regular}') format('truetype')`,
      fontWeight: 400,
      fontStyle: 'normal',
    },
  },
  {
    '@font-face': {
      fontFamily: 'Roboto',
      src: `url('${medium}') format('truetype')`,
      fontWeight: 500,
      fontStyle: 'normal',
    },
  },
  {
    '@font-face': {
      fontFamily: 'Roboto',
      src: `url('${bold}') format('truetype')`,
      fontWeight: 700,
      fontStyle: 'normal',
    },
  },
  {
    '@font-face': {
      fontFamily: 'Roboto',
      src: `url('${black}') format('truetype')`,
      fontWeight: 900,
      fontStyle: 'normal',
    },
  },
];
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="d574cdd6-b98a-5ca3-9ed4-a7e9fe55820e")}catch(e){}}();
//# debugId=d574cdd6-b98a-5ca3-9ed4-a7e9fe55820e
