import React, { useEffect, useState } from "react";
import {
  Space,
  Card,
  Flex,
  Radio,
  Button,
  Select,
  Paper,
  Text,
  ActionIcon,
  Group,
  Divider,
  Stack,
  Loader,
  Center,
} from "@mantine/core";
import { SubHeadingText } from "../../../../components/MantineOverride";
import { NotificationFilter } from "./NotificationFilter";
import { useSearchParams } from "react-router-dom";
import _ from "lodash";
import API from "../../../../api/API";
import { API_URLS } from "../../../../utils/constants";
import { checkIfValueExists } from "../../../../utils/helpers";
import { IconPlus, IconTrash } from "@tabler/icons-react";
import { decamelizeKeys } from "humps";
import { showErrorNotification } from "../../../../components/notifications";
//import { EditNotification, SlackButton } from "./../../../../components/notifications/index";
import { EditNotification, SlackButton } from "components/teamNotifications/index";
import style from "../../../../style.scss";

export function Slack(props) {
  const {
    notificationState,
    dispatch,

    questions,

    surveyId,

    handleDelete,
    dbNotifications,
    setDbNotifications,
    csrfToken,
  } = props;

  const [channelList, setChannelList] = useState([]);
  const [loading, setLoading] = useState(false);

  let [searchParams, setSearchParams] = useSearchParams();

  let code = searchParams.get("code");
  let state = searchParams.get("state");

  useEffect(() => {
    if (code && state) {
      API.get(
        API_URLS.GET_SLACK_TOKEN(code, state, encodeURIComponent(window.location.origin + window.location.pathname))
      ).then(data => {
        if (data.success) {
          console.log("@slack data post integration is", data);
          const channels = data.success.data.channel_list;
          const channelData = channels.map(channel => {
            return { label: `#${channel.channel_name}`, value: channel.channel_id };
          });
          console.log("3 slack channels are", channelData);
          setChannelList(channelData);
        }
      });
    }
  }, [code, state]);

  useEffect(() => {
    if (channelList.length == 0) {
      setLoading(true);
      API.get(API_URLS.GET_SLACK_CHANNELS())
        .then(data => {
          console.log("@slack channels are", data);
          if (data.success) {
            const channels = data.success.data.channel_list;
            const channelData = channels.map(channel => {
              return { label: `#${channel.channel_name}`, value: channel.channel_id };
            });
            console.log("3 slack channels are", channelData);
            setChannelList(channelData);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  function handleAddNotification() {
    dispatch({
      type: "addNotification",
      value: { notify_for: "", sid: surveyId, integration: "slack", event: "SURVEY_COMPLETED" },
    });
  }

  const notificationList = notificationState.map((notification, index) => {
    return (
      <SlackNotification
        key={index}
        idx={index}
        notificationState={notification}
        dispatch={dispatch}
        questions={questions}
        surveyId={surveyId}
        handleDelete={handleDelete}
        csrfToken={csrfToken}
        channelList={channelList}
        dbNotifications={dbNotifications}
        setDbNotifications={setDbNotifications}
      />
    );
  });

  if (loading) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  if (channelList.length == 0) {
    return <SlackButton />;
  } else if (notificationState.length == 0) {
    return <AddNotificationButton handleAddNotification={handleAddNotification} />;
  } else if (notificationState.length > 0) {
    return (
      <div>
        <AddNotificationButton handleAddNotification={handleAddNotification} />
        {notificationList}
      </div>
    );
  }
}

function AddNotificationButton({ handleAddNotification }) {
  return (
    <Group position="right">
      <Button variant="subtle" onClick={() => handleAddNotification()} leftIcon={<IconPlus size={16} />}>
        <span style={{ fontSize: "14px", fontWeight: "400" }}>Add Notification</span>
      </Button>
    </Group>
  );
}

function SlackNotification({
  notificationState,
  idx,

  surveyId,

  handleDelete,
  dispatch,
  questions,
  channelList,
  dbNotifications,
  setDbNotifications,
  csrfToken,
}) {
  const [editing, setEditing] = useState(false);
  const currentlyEditing = dbNotifications.find(n => n.id === notificationState.id);

  async function handleSubmit(editing) {
    console.log("submitting notification state", notificationState);
    const requestMethod = editing ? "put" : "post";
    const requestURL = editing
      ? API_URLS.UPDATE_SURVEY_NOTIFICATION(notificationState.id)
      : API_URLS.GET_SAVE_SURVEY_NOTIFICATIONS();

    let response = await API[requestMethod](requestURL, decamelizeKeys(notificationState), csrfToken);
    if (response.success) {
      dispatch({
        type: "notificationsFromServer",

        value: response.success.data,
      });
      setEditing(false);
      setDbNotifications(response.success.data);
    } else {
      console.error("error in saving notification", response);
      showErrorNotification("Something went wrong, please try again after some time.");
    }
  }

  function handleReset() {
    if (editing) {
      setEditing(false);
      dispatch({
        type: "resetNotificationState",
        value: currentlyEditing,
        idx: idx,
      });
    } else {
      dispatch({
        type: "resetNotificationState",
        idx: idx,
        value: { sid: surveyId, integration: "slack", event: "SURVEY_COMPLETED" },
      });
    }
  }

  function handleAddCondition() {
    dispatch({
      type: "addCondition",
      idx: idx,
    });
  }

  function handleDeleteNotification(event) {
    event.preventDefault();
    dispatch({
      type: "deleteNotification",
      idx: idx,
    });
  }

  function handleChange(val, section) {
    console.log("###slbug 1.0", val, section);
    if (section === "channel") {
      val = {
        id: val.value,
        name: val.label,
      };
    }
    if (section === "notify_for" && val === "all") {
      dispatch({
        type: "mergeNotificationSettings",
        idx: idx,
        value: {
          notify_for: val,
          conditions: [],
        },
      });
    } else if (section === "notify_for" && val === "specific") {
      dispatch({
        type: "mergeNotificationSettings",
        idx: idx,
        value: {
          notify_for: val,
          conditions: notificationState.conditions || [],
        },
      });
    } else {
      dispatch({
        type: "updateNotificationSettings",
        idx: idx,
        value: val,
        section: section,
      });
    }
  }

  function renderNotificationSaveButton() {
    const conditionIsSet =
      notificationState.conditions &&
      notificationState.conditions.length > 0 &&
      notificationState.conditions.every(condition => checkIfValueExists(condition.predicate));
    console.log("@slack conditionisset", conditionIsSet);

    const saveButton = (
      <Button size="xs" onClick={() => handleSubmit(editing)}>
        Save Notification
      </Button>
    );

    console.log("#14 will show Save Button 0", notificationState);

    if (notificationState.channel && notificationState.notify_for == "specific") {
      if (editing) {
        console.log(
          "@slbug dbstate is equal?",
          notificationCurrentAndDBStateIsEqual(notificationState, currentlyEditing)
        );
        return notificationCurrentAndDBStateIsEqual(notificationState, currentlyEditing)
          ? null
          : conditionIsSet
          ? saveButton
          : null;
      } else {
        return conditionIsSet ? saveButton : null;
      }
    } else if (notificationState.channel && notificationState.notify_for == "all") {
      console.log("#14 will show Save Button 1");
      if (editing) {
        return notificationCurrentAndDBStateIsEqual(notificationState, currentlyEditing) ? null : saveButton;
      } else {
        console.log("#14 will show Save Button");
        return saveButton;
      }
    }
  }

  function notificationCurrentAndDBStateIsEqual(currentState, dbState) {
    console.log("@dbslack current state is", currentState);
    console.log("@dbslack db state is", dbState);
    const notificationStateWithoutSid = _.omit(currentState, "sid");
    console.log("@dbslack current state witout sid", notificationStateWithoutSid);
    return _.isEqual(notificationStateWithoutSid, dbState);
  }

  if (notificationState && notificationState["id"] && !editing) {
    return (
      <EditNotification
        index={idx}
        setEditing={setEditing}
        handleDelete={handleDelete}
        notificationState={notificationState}
      />
    );
  } else {
    return (
      <Paper shadow="xs" p="md" mt={20} mb={20} withBorder bg="#f3f4f6b3" sx={{ position: "relative" }}>
        <Group position="apart">
          <Text shadow="xs" color="dimmed" size={14}>
            {idx + 1}
          </Text>
          <ActionIcon>
            <IconTrash size={"2rem"} stroke={1} onClick={e => handleDeleteNotification(e)} />
          </ActionIcon>
        </Group>
        <SubHeadingText>Notify for</SubHeadingText>
        {console.log("@slbug questions", questions)}

        <Radio.Group
          value={notificationState.notify_for}
          onChange={e => handleChange(e, "notify_for")}
          name="notificationFor"
          orientation="horizontal"
          spacing="xs"
        >
          <Radio value="all" label="All responses" />
          <Radio value="specific" label="Specific responses" />
          <Space h="md" />
        </Radio.Group>

        {notificationState.notify_for === "specific" ? (
          <>
            <Space h="md" />
            {notificationState.conditions.length > 0 && <SubHeadingText>Send notification if</SubHeadingText>}

            {notificationState.conditions.length > 0 &&
              notificationState.conditions.map((condition, index) => {
                console.log("@bug 0 notification conditions", notificationState.conditions);
                console.log("@bug 1 notification map condition", condition);

                return (
                  <div key={index}>
                    <Card p="sm" withBorder sx={{ overflow: "visible" }} bg="#f3f4f6b3">
                      <NotificationFilter
                        notificationIndex={idx}
                        index={index}
                        conditions={notificationState.conditions}
                        dispatch={dispatch}
                        questions={questions}
                      />
                    </Card>
                    {index !== notificationState.conditions.length - 1 ? (
                      <Divider
                        label="or"
                        labelPosition="center"
                        sx={{
                          marginBottom: "0px",
                          marginTop: "10px",
                          ">::after": { borderTop: "0px" },
                          //fontWeight: 700,
                          fontStyle: "italic",
                        }}
                      />
                    ) : null}
                    <Space h="md" />
                  </div>
                );
              })}

            <Button leftIcon={<IconPlus />} variant="subtle" onClick={handleAddCondition}>
              Add Filter
            </Button>
          </>
        ) : null}
        {console.log("@slbug 6.9 notification state is", notificationState)}
        <Space h="md" />
        <Divider />

        <Space h="md" />
        <Flex>
          {console.log("@slack 2 channnel list is", channelList)}
          {channelList && channelList.length > 0 && (
            <Stack spacing={10}>
              <SubHeadingText>Select Channel</SubHeadingText>
              <Select
                value={notificationState.channel ? notificationState.channel["id"] : null}
                onChange={val => handleChange(channelList && channelList.find(c => c.value == val), "channel")}
                searchable
                allowDeselect
                placeholder="Pick one"
                nothingFound="Channel not found."
                data={channelList}
              />
            </Stack>
          )}
        </Flex>
        <Space h="md" />
        <Divider />

        <Space h="md" />
        <Group>
          {editing && (
            <Button variant="default" size="xs" onClick={handleReset}>
              Cancel
            </Button>
          )}

          {renderNotificationSaveButton(idx)}
        </Group>
      </Paper>
    );
  }
}
