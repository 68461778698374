import React, { useState } from 'react';
import { Button, Group, TextInput, NativeSelect, MediaQuery } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import FormFieldError from './../../../../components/FormFieldError';

export function AccountForm(props) {
  const { form, loading, error } = props;
  //const [value, setValue] = useState('https://');

  const data = [
    { value: 'https://', label: 'https://' },
    { value: 'http://', label: 'http://' },
  ];

  const select = (
    <NativeSelect
      //value={value}
      //onChange={event => setValue(event.currentTarget.value)}
      data={data}
      styles={{
        input: {
          fontWeight: 500,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          width: '85px',
          paddingRight: '20px',
        },
        rightSection: {
          width: '20px',
        },
      }}
      {...form.getInputProps('website_protocol')}
    />
  );

  return (
    <>
      <TextInput label="Company Name" placeholder="Example Inc" {...form.getInputProps(`product_name`)} />
      <MediaQuery query="(max-width: 600px)" styles={{ input: { paddingRight: '0px' } }}>
        <TextInput
          mt="md"
          label="Website"
          placeholder="www.example.com"
          rightSection={select}
          rightSectionWidth={92}
          styles={{
            input: {
              paddingLeft: '90px',
            },
            rightSection: {
              left: 0,
              justifyContent: 'left',
            },
          }}
          {...form.getInputProps('product_website')}
        />
      </MediaQuery>

      {error && error.product && error.product.website ? <FormFieldError errors={error.product.website} /> : null}
      <Group position="center" mt="md">
        <Button type="submit" id="submitButton">
          Signup
        </Button>
      </Group>
    </>
  );
}
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="6742ee63-d8be-5955-a191-cc4c405c02a1")}catch(e){}}();
//# debugId=6742ee63-d8be-5955-a191-cc4c405c02a1
