import React from "react";
import { Center, Stack, Button, Text, Group } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";

export function ErrorFallback({ resetErrorBoundary }) {
  const navigate = useNavigate();
  const intl = useIntl();
  return (
    <div role="alert">
      <Center mt={20}>
        <Stack align="center">
          <Text color="red">
            {" "}
            {intl.formatMessage({
              id: "common.loading_error",
              defaultMessage: "Something went wrong. Please try again.",
            })}
          </Text>
          <Group>
            <Button
              variant="outline"
              onClick={() => {
                resetErrorBoundary;
                navigate("/surveys");
                window.location.reload();
              }}
            >
              {intl.formatMessage({ id: "common.go_back", defaultMessage: "Go back" })}
            </Button>
          </Group>
        </Stack>
      </Center>
    </div>
  );
}
