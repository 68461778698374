import React, { useEffect } from "react";
import { Card, Group, Text, Space, Box, Avatar, useMantineTheme, Stack } from "@mantine/core";
import { groupBy, dateDiffFromToday, pluralize } from "../../../utils/helpers";
import { parseRatingResponse } from "../../../utils/helpers";
import { useIntl } from "react-intl";

export function SurveyUserResponse(props) {
  const { userResponses, questions, platform, setSearchParams, searchParams, setOpened } = props;
  //console.log('response in SurveyResponseCard is', userResponses);
  const groupByQuestionID = groupBy("question_id");
  const intl = useIntl();

  const theme = useMantineTheme();

  const respondent = userResponses.respondent;

  const answers = userResponses.answers;

  const respondentId = () => {
    if (respondent.user_id) {
      return respondent.user_id;
    } else if (respondent.id_uuid) {
      return respondent.id_uuid;
    } else if (respondent.anon_id) {
      return respondent.anon_id;
    } else {
      return respondent.client_id;
    }
  };

  //console.log('groupby question_id', Object.entries(groupByQuestionID(answers)));

  console.log("searchparams 2", searchParams.get("respondent"));

  useEffect(() => {
    if (searchParams.get("respondent") === respondentId()) {
      setOpened(true);
    }
  }, [searchParams.get("respondent")]);

  return (
    <>
      <Card shadow="sm" p="lg" radius="md" withBorder key={respondent.id}>
        <Group
          position="apart"
          sx={{ borderBottom: "1px solid #ddd", paddingBottom: "10px", cursor: "pointer" }}
          onClick={() => {
            setSearchParams(searchParams => {
              return new URLSearchParams({
                ...Object.fromEntries(searchParams.entries()),
                ...{
                  respondent: respondentId(),
                },
              });
            });
            setOpened(true);
          }}
        >
          <Group spacing={5}>
            <Avatar color={theme.primaryColor} radius="xl"></Avatar>
            <Stack spacing={0}>
              <Text size="16px" color={theme.primaryColor}>
                {platform == "email"
                  ? respondent["attributes"] && respondent["attributes"]["email"]
                    ? respondent["attributes"]["email"]
                    : respondentId()
                  : respondentId()}
              </Text>
              {userResponses.response_count > 1 && (
                <Text color="dimmed" size={14}>
                  Responded {pluralize(userResponses.response_count, "time", "s")}
                </Text>
              )}
            </Stack>
          </Group>

          <Text size="xs" color="dimmed">
            {userResponses.started_at && dateDiffFromToday(userResponses.started_at, intl.locale)}
          </Text>
        </Group>

        <Space h="lg" />

        {Object.entries(groupByQuestionID(answers)).map(([q_id, value], idx) => {
          return (
            <Box key={q_id}>
              <Text size="16px" weight={400} color="dimmed">
                {idx + 1}: {questions.find(q => q.id === parseInt(q_id))?.question_text}
              </Text>
              <Text size="16px" weight={400} ml={18}>
                {value
                  .map(v => {
                    return parseRatingResponse(
                      questions.find(q => q.id === parseInt(q_id)),
                      v.answer
                    );
                  })
                  .join(",")}
                {value.map((v, idx) => {
                  return v.comments ? ` (${v.comments})` : null;
                })}
              </Text>

              <Space h="lg" />
            </Box>
          );
        })}
      </Card>
    </>
  );
}
