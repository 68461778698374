import React from "react";
import { CopyButton, Tooltip, ActionIcon } from "@mantine/core";
import { IconCheck, IconCopy } from "@tabler/icons-react";
import { useIntl } from "react-intl";

export default function CopyValueIcon(props) {
  const intl = useIntl();
  const { value } = props;
  return (
    <div>
      <CopyButton value={value} timeout={2000}>
        {({ copied, copy }) => (
          <Tooltip
            label={copied ? intl.formatMessage({ id: "common.copied" }) : intl.formatMessage({ id: "common.copy" })}
            withArrow
            position="right"
          >
            <ActionIcon color={copied ? "teal" : "gray"} onClick={copy}>
              {copied ? <IconCheck size={16} /> : <IconCopy size={16} />}
            </ActionIcon>
          </Tooltip>
        )}
      </CopyButton>
    </div>
  );
}
