import React, { useState, useEffect } from "react";
import { fetchPaginatedQuestionResponseData, fetchPaginatedResponses } from "../../../../../api/Survey";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "../../../../../components/ErrorFallback";
import * as Sentry from "@sentry/react";
import { Table, Center, Loader, Box, Space, Text, useMantineTheme } from "@mantine/core";
import NoData from "../NoData";
import { API_URLS } from "../../../../../utils/constants";
import { useIntl } from "react-intl";

export function EmailReport(props) {
  const { questionId, surveyId, dateRange, questionType, searchParams, setSearchParams, duration } = props;

  const [responseData, setResponseData] = useState();
  const [interval, setInterval] = useState("month");
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState();
  const [nextURL, setNextURL] = useState();
  const theme = useMantineTheme();
  const intl = useIntl();

  useEffect(() => {
    fetchPaginatedQuestionResponseData(API_URLS.GET_QUESTION_RESPONSES(surveyId, questionId), {
      qtype: questionType,
      duration: duration,
      start_date: dateRange[0],
      end_date: dateRange[1],
      ...(searchParams.get("filterby_response") ? { filterby_response: searchParams.get("filterby_response") } : {}),
      ...(searchParams.get("filterby_country") ? { filterby_country: searchParams.get("filterby_country") } : {}),
      ...(searchParams.get("filterby_attribute") ? { filterby_attribute: searchParams.get("filterby_attribute") } : {}),

      // ...(responseFilterParams.length > 0 ? { filterby_response: responseFilterParams.join() } : {}),
    })
      .then(data => {
        console.log("#13 data in fetchTaggedResponses", data);
        setResponseData(data.results);
        setNextURL(data.next);
      })
      .catch(error => {
        setError(error);
      })
      .finally(setLoader(false));
  }, [dateRange, searchParams]);

  //   useEffect(() => {
  //     fetchSurveyQuestionResponseData(surveyId, questionId, dateRange, interval, questionType, searchParams)
  //       .then(data => {
  //         console.log("data in fetchSurveyQuestionResponseData", data);
  //         setResponseData(data);
  //       })
  //       .catch(error => {
  //         console.log("error in fetchSurveyQuestionResponseData", error);
  //         setError(error);
  //       })
  //       .finally(setLoader(false));
  //   }, [dateRange, interval, searchParams]);

  const emailList = responseData;
  console.log("#19 emailList", emailList);

  const emailRows =
    responseData &&
    responseData.map((element, index) => (
      <tr key={index}>
        <td
          onClick={() => {
            searchParams.append("respondent", element.respondent_id);
            setSearchParams(searchParams);
          }}
        >
          <Text sx={{ cursor: "pointer" }} color={theme.primaryColor}>
            {element["answer"]}
          </Text>
        </td>
      </tr>
    ));

  return (
    <>
      {loader ? (
        <Center>
          <Loader />
        </Center>
      ) : (
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => {
            // reset the state of your app so the error doesn't happen again
          }}
          onError={error => {
            // log the error to your error reporting service
            Sentry.captureException(error);
          }}
        >
          {emailList && emailList.length > 0 ? (
            <>
              <Table striped highlightOnHover withBorder>
                <thead>
                  <tr>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>{emailRows}</tbody>
              </Table>
              {emailRows && nextURL && (
                <Box sx={{ textAlign: "center" }}>
                  <Space h="md" />
                  <Text
                    onClick={() => {
                      fetchPaginatedResponses(nextURL).then(data => {
                        setResponseData([...responseData, ...data.results]);

                        setNextURL(data.next);
                      });
                    }}
                    sx={{ cursor: "pointer", textDecorationLine: "underline" }}
                    size="xs"
                    color="blue"
                  >
                    {intl.formatMessage({ id: "common.load_more", defaultMessage: "Load more" })}
                  </Text>
                </Box>
              )}
            </>
          ) : (
            <NoData />
          )}
        </ErrorBoundary>
      )}
    </>
  );
}
