import React, { useState, useEffect } from "react";
import {
  Avatar,
  Title,
  Divider,
  Table,
  Group,
  Text,
  ScrollArea,
  Space,
  Button,
  Center,
  Loader,
  Badge,
  Menu,
  Card,
} from "@mantine/core";

//import { useForm } from '@mantine/form';
import API from "../../../api/API";
import { useAuthState, useAuthDispatch } from "../../../context/AuthContext";
import { getTeamMembers, deleteTeamMember } from "../../../api/Account";
import { ACCESS_LEVELS } from "../../../utils/constants";
import { pythonDateFormatter, capitalize } from "../../../utils/helpers";
import { TeamEditModal, TeamAddModal } from "./components";
import { openConfirmModal } from "@mantine/modals";
import { logoutUser } from "../../../api/Auth";
import { alertMessages, successMessages } from "../../../utils/config";
import Heading from "../../../components/Heading";
import { isAccountOwnerOrSelf } from "../../../utils/permissions";
import { showErrorNotification, showSuccessNotification } from "../../../components/notifications";
import { API_URLS } from "../../../utils/constants";
import { IconPencil, IconTrash, IconPlus, IconDotsVertical, IconSend } from "@tabler/icons-react";

const rolesData = [
  { label: "Editor", value: "1" },
  { label: "Viewer", value: "3" },
];

export function Team() {
  const [opened, setOpened] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [responseData, setResponseData] = useState();
  const [loader, setLoader] = useState(true);
  const [clickedMember, setClickedMember] = useState(); // to pass clicked member data to the modal.

  const auth = useAuthState();
  const dispatch = useAuthDispatch();

  const currentUser = auth.user;

  useEffect(() => {
    getTeamMembers()
      .then(data => {
        if (data.error) {
          console.log(data.error.message);
          showErrorNotification();
        } else {
          console.log("TEAM Data", data);
          setResponseData(data);
        }
      })
      .catch(error => {
        console.log(error.message);
        showErrorNotification();
      })
      .finally(setLoader(false));
  }, []);

  async function handleResendInvite(member, event) {
    const data = {
      full_name: member.fullName,
      email: member.email,
      account_role: member.accountRole,
    };
    let response = await API.post(API_URLS.SEND_TEAM_INVITE, { ...data }, auth.csrftoken);
    console.log("RE_INVITE", response);
    if (response.success) {
      showSuccessNotification(successMessages.invitation_sent(member.fullName));
      //console.log('Team REInvite success', response.success);

      //dispatch({ type: 'AUTH_SUCCESS', payload: response.success.data.user });
    } else {
      //dispatch({ type: 'AUTH_ERROR', error: response.error });
      //console.log('Team REInvite error', response.error);
      showErrorNotification();
    }
  }

  useEffect(() => {
    console.log("ClickedMember", clickedMember);
  }, [clickedMember]);

  const openDeleteModal = member =>
    openConfirmModal({
      title: "Delete account?",
      centered: true,
      children: <Text size="sm">Are you sure you want to delete this account? This action is irreversible.</Text>,
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => {
        deleteTeamMember(member.id, auth.csrftoken)
          .then(data => {
            if (data.error) {
              //console.log('DELETE ERROR Data', data);

              showErrorNotification();
            } else {
              if (currentUser.id == member.id) {
                logoutUser(dispatch);
              }
              setResponseData(responseData.filter(i => i.id !== member.id));
              showSuccessNotification(successMessages.user_deleted, "Success");

              //console.log('DELETE Data', data);
            }
          })

          .finally(setLoader(false));
      },
    });

  const rows =
    responseData &&
    responseData.map(member => (
      <tr key={member.id}>
        <td>
          <Group spacing="sm">
            <Avatar size={40} src={member.avatar} radius={40} />
            <div>
              <Text>{member.fullName}</Text>
            </div>
            {currentUser && currentUser.id == member.id && <Badge color="#F4D06F">You</Badge>}
          </Group>
        </td>
        <td>
          <Group spacing="sm">
            <Text>{member.email}</Text>
            {member && member.state == "invited" && <Badge color="teal">{capitalize(member.state)}</Badge>}
          </Group>
        </td>
        <td>{member.isAccountOwner ? "Owner" : ACCESS_LEVELS[member.accountRole]}</td>
        <td>
          {member.dateJoined ? (
            pythonDateFormatter(member.dateJoined, "MMMM D, YYYY")
          ) : (
            <Text color="dimmed" fs="italic">
              Yet to join
            </Text>
          )}
        </td>

        <td>
          <Menu radius="md" withinPortal>
            <Menu.Target>
              <div style={{ cursor: "pointer" }}>
                <IconDotsVertical size={14} />
              </div>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                onClick={() => {
                  setShowEditModal(true);
                  setClickedMember(member);
                }}
                disabled={!isAccountOwnerOrSelf(currentUser, member)}
              >
                <Group spacing="xs">
                  <IconPencil stroke={1.5} size={14} />
                  <Text size={14}>Edit</Text>
                </Group>
              </Menu.Item>

              {member && member.state == "invited" && (
                <Menu.Item
                  onClick={() => handleResendInvite(member)}
                  disabled={!isAccountOwnerOrSelf(currentUser, member)}
                >
                  <Group spacing="xs">
                    <IconSend size={14} />
                    <Text size={14}>Resend invitation</Text>
                  </Group>
                </Menu.Item>
              )}
              {currentUser.isAccountOwner || currentUser.id == member.id ? (
                member.isAccountOwner ? null : (
                  <Menu.Item onClick={() => openDeleteModal(member)} color="red">
                    <Group spacing="xs">
                      <IconTrash size={14} />
                      <Text size={14}>Delete</Text>
                    </Group>
                  </Menu.Item>
                )
              ) : null}
            </Menu.Dropdown>
          </Menu>
        </td>
      </tr>
    ));

  return loader ? (
    <Center>
      <Loader />
    </Center>
  ) : responseData ? (
    <>
      <Heading title="Team" showDivider={true}>
        <Button
          leftIcon={<IconPlus />}
          onClick={() => {
            if (currentUser.isAccountOwner) {
              setOpened(true);
            } else {
              showErrorNotification(alertMessages.cannot_add_team_member);
            }
          }}
        >
          Add Member
        </Button>
      </Heading>

      <ScrollArea>
        <Card
          withBorder
          radius="md"
          padding="xl"
          sx={theme => ({
            backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
          })}
        >
          <Table verticalSpacing="sm">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Access</th>
                <th>Date Joined</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
        </Card>
      </ScrollArea>

      <TeamAddModal
        opened={opened}
        setOpened={setOpened}
        rolesData={rolesData}
        responseData={responseData}
        setResponseData={setResponseData}
      />
      {clickedMember && (
        <TeamEditModal
          opened={showEditModal}
          setOpened={setShowEditModal}
          member={clickedMember}
          rolesData={rolesData}
        />
      )}
    </>
  ) : null;
}
!(function () {
  try {
    var e =
        "undefined" != typeof window
          ? window
          : "undefined" != typeof global
          ? global
          : "undefined" != typeof self
          ? self
          : {},
      n = new Error().stack;
    n &&
      ((e._sentryDebugIds = e._sentryDebugIds || {}), (e._sentryDebugIds[n] = "97bbe631-6c09-5be1-8963-f31b18cc2941"));
  } catch (e) {}
})();
//# debugId=97bbe631-6c09-5be1-8963-f31b18cc2941
