import React, { useEffect, useState } from 'react';
import API from '../../../api/API';
import { getCSRF } from '../../../context/AuthContext';
import { API_URLS } from '../../../utils/constants';

//import Cookies from 'js-cookie';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthState, useAuthDispatch } from '../../../context/AuthContext';
import { camelizeKeys } from 'humps';
import { FullPageSpinner } from '../../../components/spinners';
import LoginForm from './LoginForm';

export function Login() {
  const { user, loading, csrftoken } = useAuthState();
  const [error, setError] = useState();
  const dispatch = useAuthDispatch();

  let location = useLocation();
  let from = (location.state && location.state.from.pathname) || '/surveys/new/';

  console.log('auth state', useAuthState());

  useEffect(() => {
    console.log('user in getCSRF', user);
    if (csrftoken === null) {
      getCSRF(dispatch);
    }
  }, []);

  async function handleSubmit(data, event) {
    console.log('event in login submit', event);
    console.log('data in login submit', data);
    console.log('found csrf token', csrftoken);
    event.preventDefault();
    dispatch({ type: 'AUTH_REQUEST' });

    let response = await API.post(API_URLS.LOGIN_USER, { ...data }, csrftoken);
    console.log(response);

    if (response.success) {
      console.log('login success for', camelizeKeys(response.success.data.user));
      dispatch({ type: 'AUTH_SUCCESS', payload: camelizeKeys(response.success.data.user) });
      // navigate(from, { replace: true });
      //window.location.replace(from);
    } else {
      setError(camelizeKeys(response.error));
      dispatch({ type: 'AUTH_ERROR', error: camelizeKeys(response.error) });
      console.log('Login error', error);
    }
  }

  const props = {
    loading: loading,
    error: error,
    setError: setError,
    handleSubmit: handleSubmit,
    dispatch: dispatch,
  };

  return !loading ? (
    user === null || user === undefined ? (
      <LoginForm {...props} />
    ) : (
      <Navigate to={from} replace={true} />
    )
  ) : (
    <FullPageSpinner />
  );
}
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="54085c0d-0e2a-51e0-9964-c44a62f6057f")}catch(e){}}();
//# debugId=54085c0d-0e2a-51e0-9964-c44a62f6057f
