import React from 'react';

import { Space } from '@mantine/core';
import { Appearance, Product, Recontact } from './components';
import Heading from '../../../components/Heading';

export default function Settings() {
  return (
    <>
      <Heading title="Settings" showDivider={true} />
      <Product />
      <Space h="md" />
      <Appearance />
      <Space h="md" />
      <Recontact />
      <Space h="md" />
    </>
  );
}
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="2b69d450-6bc9-5363-947d-8c644fe1ff6e")}catch(e){}}();
//# debugId=2b69d450-6bc9-5363-947d-8c644fe1ff6e
