import React, { useState, useEffect } from "react";
import {
  Radio,
  Group,
  Text,
  Space,
  TextInput,
  NumberInput,
  Button,
  Divider,
  Accordion,
  Alert,
  Box,
  Stack,
  Select,
} from "@mantine/core";
import { IconCircleCheck, IconAlertCircle, IconPlus } from "@tabler/icons-react";
import { pluralize, humanizedString, getFromLocalStorage } from "../../../../utils/helpers";
import { AttributeFilter } from "./AttributeFilter";
import { TextualTag, StyledSelect, SubHeadingText } from "../../../../components/MantineOverride";
import { FSToolTip } from "../../../../components";

// import { wrap } from '@sentry/react';

export function SurveySettings(props) {
  const { dispatch, formState, token, csrfToken, surveyId } = props;
  const environment = getFromLocalStorage("FSEnv") || "prod";
  let options = formState.surveyOptions;
  const [recurringAfterResponse, setRecurringAfterResponse] = useState(options.recurringAfterResponse ? "1" : "-1");
  const [recurringAfterDecline, setRecurringAfterDecline] = useState(options.recurringAfterDecline ? "1" : "-1");

  const [accordionItem, setAccordionItem] = useState("platform");

  function handleAccordionChange(e) {
    console.log("According Change", e);
    setAccordionItem(e);
  }

  useEffect(() => {
    //this is effect is to synchronise formState update
    //Ref: https://samuelsson.dev/update-state-with-usereducer-synchronously/
  }, [formState]);

  function handleInputChange(value, section, field) {
    console.log("$123 value in radio group input is", value, section, field);
    dispatch({
      type: "settingChange",
      value: value,
      section: section,
      field: field,
    });
  }

  function handleRecurringAfterResponseChange(value) {
    console.log("## recurring value", value);
    setRecurringAfterResponse(value);
    if (value === "-1") {
      dispatch({
        type: "removeSurveyOptions",
        payload: {
          key: "recurringAfterResponse",
        },
      });
    } else {
      dispatch({
        type: "updateSurveyOptions",
        payload: {
          key: "recurringAfterResponse",
          value: {
            showAfterDays: 90,
            stopAfterDays: -1,
          },
        },
      });
    }
  }

  function handleRecurringAfterDeclineChange(value) {
    console.log("## recurring value", value);
    setRecurringAfterDecline(value);
    if (value === "-1") {
      if (options.recurringAfterResponse) {
        dispatch({
          type: "removeSurveyOptions",
          payload: {
            key: "recurringAfterDecline",
          },
        });
      }
    } else {
      dispatch({
        type: "updateSurveyOptions",
        payload: {
          key: "recurringAfterDecline",
          value: {
            showAfterDays: 30,
            stopAfterDeclines: 2,
          },
        },
      });
    }
  }

  const selectedPages = () => {
    return parseInt(options.triggerCondition.pages) === "all" ? "All Pagess" : "Specific URL";
  };

  const selectedTrigger = () => {
    const condition = options.triggerCondition;
    console.log("## eventType", condition.eventType);
    switch (condition.eventType) {
      case "pageLoad":
        return "On Page Load";
      case "abandon":
        return "On leaving page";
      case "halfScroll":
        return "On page scroll";
      case "textClick":
      case "cssClick":
        return "On Click";
      default:
        break;
    }
  };

  const selectedDelay = () => {
    const condition = options.triggerCondition;
    switch (condition.waitFor) {
      case 0:
        return "Immediately";
      default:
        return `${condition.waitFor} seconds delay`;
    }
  };

  function addFilter(e) {
    e.preventDefault();
    dispatch({
      type: "addFilter",
    });
  }

  return (
    <>
      <Space h="md" />
      <Accordion
        variant="separated"
        onChange={e => handleAccordionChange(e)}
        radius="md"
        defaultValue="when-to-show"
        multiple
        styles={{
          item: {
            // styles added to all items
            backgroundColor: "#fff",
            border: "1px solid #ededed",

            // styles added to expanded item
            "&[data-active]": {
              backgroundColor: "#fff",
            },
          },

          chevron: {
            // styles added to chevron when it should rotate
            "&[data-rotate]": {
              transform: "rotate(-90deg)",
            },
          },
        }}
      >
        {formState.platform === "web" && (
          <>
            <Accordion.Item value="when-to-show">
              <Accordion.Control>
                <Group position="apart">
                  <div>
                    <Text size="md" weight={400}>
                      When to show
                    </Text>
                    <Text size="xs" color="dimmed">
                      Select pages and trigger.
                    </Text>
                  </div>
                  <Group>
                    {accordionItem === "when-to-show" ? null : <TextualTag>{selectedPages()}</TextualTag>}
                    {accordionItem === "when-to-show" ? null : (
                      <Group>
                        <TextualTag>{selectedTrigger()}</TextualTag>
                        <TextualTag>{selectedDelay()}</TextualTag>
                        <IconCircleCheck size={30} color="rgb(81, 207, 102)" />
                      </Group>
                    )}
                  </Group>
                </Group>
                {accordionItem === "when-to-show" ? <Divider></Divider> : null}
              </Accordion.Control>
              <Accordion.Panel>
                <Box>
                  <SubHeadingText>Show on</SubHeadingText>

                  <Radio.Group
                    value={options.triggerCondition.pages}
                    onChange={e => handleInputChange(e, "triggerCondition", "pages")}
                    name="selectPages"
                    orientation="vertical"
                    spacing="xs"
                  >
                    <Radio value="all" label="All pages" />
                    <Radio value="specific" label="Specific pages where" />
                    {options.triggerCondition.pages === "specific" ? (
                      <URLDropdown handleInputChange={handleInputChange} options={options} />
                    ) : null}
                  </Radio.Group>
                </Box>
                <Space h={20} />
                <Divider />
                <Space h={20} />
                <Box>
                  <SubHeadingText>Trigger Survey</SubHeadingText>

                  <Radio.Group
                    name="trigger"
                    value={options.triggerCondition.eventType}
                    onChange={val => handleInputChange(val, "triggerCondition", "eventType")}
                    orientation="vertical"
                    spacing="xs"
                  >
                    <Text size={14} color="dimmed">
                      Page events
                    </Text>
                    <Radio label="on page load" value="pageLoad" />
                    <Radio label="after half way scroll" value="halfScroll" />
                    <Radio label="on exiting the page on desktop" value="abandon" />
                    <Text size={14} mt={20} color="dimmed">
                      Click events
                    </Text>
                    <Group spacing="xs">
                      <Radio sx={{ flexGrow: 0 }} label="On clicking element(s) with Inner Text" value="textClick" />
                      {options.triggerCondition.eventType === "textClick" && (
                        <TextInput
                          sx={{ flexGrow: 1, width: "60%" }}
                          styles={() => ({
                            input: {
                              width: "60%",
                            },
                          })}
                          //size="xs"
                          placeholder="Keep it exact (including spaces)"
                          //styles={{ input: { width: 54, textAlign: 'center' } }}
                          value={options.triggerCondition.innerText || ""}
                          onChange={e => handleInputChange(e.currentTarget.value, "triggerCondition", "innerText")}
                        />
                      )}
                    </Group>
                    <Group spacing="xs">
                      <Radio label="On clicking element(s) with CSS selector" value="cssClick" />
                      {options.triggerCondition.eventType === "cssClick" && (
                        <TextInput
                          //size="xs"
                          placeholder="#foo.bar"
                          //styles={{ input: { width: 54, textAlign: 'center' } }}
                          value={options.triggerCondition.cssSelector || ""}
                          onChange={e => handleInputChange(e.currentTarget.value, "triggerCondition", "cssSelector")}
                        />
                      )}
                    </Group>
                  </Radio.Group>
                </Box>
                <Space h={20} />
                <Divider />
                <Space h={20} />
                <Box>
                  <Group>
                    <Text size={16}>Wait for</Text>
                    <NumberInput
                      size="xs"
                      defaultValue={0}
                      min={0}
                      step={1}
                      placeholder="10"
                      styles={{ input: { width: 54, textAlign: "center" } }}
                      value={options.triggerCondition.waitFor}
                      onChange={val => handleInputChange(val, "triggerCondition", "waitFor")}
                    />
                    <Text size="sm">seconds</Text>
                    <Text size="sm">before showing the survey</Text>
                  </Group>
                </Box>

                <Space h={50} />
                <Alert icon={<IconAlertCircle size={16} />} title="Important!" color="yellow" radius="xs">
                  If a user is eligible for multiple surveys on a page, only the most recent one will be displayed.
                </Alert>
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="frequency">
              <Accordion.Control>
                <div>
                  <Text size="md" weight={400}>
                    Frequency
                  </Text>
                  <Text size="xs" color="dimmed">
                    Set how often the user sees your survey.
                  </Text>
                </div>
                {accordionItem === "frequency" ? <Divider></Divider> : null}
              </Accordion.Control>
              <Accordion.Panel>
                <Stack spacing={40}>
                  <Stack spacing="0">
                    <Text size={16} weight={700}>
                      If the user responded to survey
                    </Text>
                    <Group>
                      <Select
                        styles={{ input: { width: 350, textAlign: "left" } }}
                        onChange={val => handleRecurringAfterResponseChange(val)}
                        value={recurringAfterResponse === "-1" ? "-1" : "1"}
                        data={[
                          { value: "-1", label: "Never show the survey again." },
                          { value: "1", label: "Show the survey on recurring basis. " },
                        ]}
                      />
                    </Group>

                    <Space h="sm" />
                    {recurringAfterResponse && recurringAfterResponse !== "-1" && (
                      <Stack>
                        <Stack spacing={0}>
                          <Text size={16}>Show after every</Text>{" "}
                          <Group spacing={4}>
                            <NumberInput
                              min={1}
                              styles={{ input: { width: 200, textAlign: "left" } }}
                              value={options.recurringAfterResponse.showAfterDays || 90}
                              onChange={val => handleInputChange(val, "recurringAfterResponse", "showAfterDays")}
                            />
                            <Group align="start" spacing={4}>
                              <Text size={16}>
                                {environment === "prod"
                                  ? pluralize(options.recurringAfterResponse.showAfterDays, "day", "s", true)
                                  : pluralize(options.recurringAfterResponse.showAfterDays, "minute", "s", true)}
                              </Text>
                              <FSToolTip
                                label={`The survey will reappear [X] ${
                                  environment === "prod" ? "days" : "minutes"
                                } after a user responds to it.`}
                              ></FSToolTip>
                            </Group>
                          </Group>
                        </Stack>
                        <Stack spacing={0}>
                          <Text size={16}>Stop after (optional)</Text>{" "}
                          <Group spacing={4}>
                            <NumberInput
                              min={1}
                              styles={{ input: { width: 200, textAlign: "left" } }}
                              placeholder="E.g. 180"
                              value={options.recurringAfterResponse.stopAfterDays == -1 ? " " : 365}
                              onChange={val => handleInputChange(val, "recurringAfterResponse", "stopAfterDays")}
                            />
                            <Group align="start" spacing={4}>
                              <Text size={16}>
                                {environment === "prod"
                                  ? pluralize(options.recurringAfterResponse.stopAfterDays, "day", "s", true)
                                  : pluralize(options.recurringAfterResponse.stopAfterDays, "minute", "s", true)}
                              </Text>
                              <FSToolTip
                                label={`The survey will permanently stop appearing [X] ${
                                  environment === "prod" ? "days" : "minutes"
                                } after a user first responds to it.`}
                              ></FSToolTip>
                            </Group>
                          </Group>
                        </Stack>
                      </Stack>
                    )}
                  </Stack>
                  <Divider />

                  <Stack spacing="0">
                    <Text size={16} weight={700}>
                      If the user closed the survey without responding
                    </Text>
                    <Group>
                      <Select
                        styles={{ input: { width: 350, textAlign: "left" } }}
                        onChange={val => handleRecurringAfterDeclineChange(val)}
                        value={recurringAfterDecline === "-1" ? "-1" : "1"}
                        data={[
                          { value: "-1", label: "Never show the survey again." },
                          { value: "1", label: "Show the survey on recurring basis. " },
                        ]}
                      />
                    </Group>
                    <Space h="sm" />
                    {recurringAfterDecline && recurringAfterDecline !== "-1" && (
                      <Stack>
                        <Stack spacing={0}>
                          <Text size={16}>Show after every</Text>{" "}
                          <Group spacing={4}>
                            <NumberInput
                              min={1}
                              styles={{ input: { width: 200, textAlign: "left" } }}
                              value={options.recurringAfterDecline.showAfterDays || 30}
                              onChange={val => handleInputChange(val, "recurringAfterDecline", "showAfterDays")}
                            />
                            <Group align="start" spacing={4}>
                              <Text size={16}>
                                {environment === "prod"
                                  ? pluralize(options.recurringAfterDecline.showAfterDays, "day", "s", true)
                                  : pluralize(options.recurringAfterDecline.showAfterDays, "minute", "s", true)}
                              </Text>

                              <FSToolTip
                                label={`The survey will reappear [X] ${
                                  environment === "prod" ? "days" : "minutes"
                                } after a user declines it.`}
                              ></FSToolTip>
                            </Group>
                          </Group>
                        </Stack>
                        <Stack spacing={0}>
                          <Text size={16}>Stop after (optional)</Text>{" "}
                          <Group spacing={4}>
                            <NumberInput
                              min={1}
                              styles={{ input: { width: 200, textAlign: "left" } }}
                              placeholder="E.g. 2"
                              value={options.recurringAfterDecline.stopAfterDeclines == -1 ? " " : 2}
                              onChange={val => handleInputChange(val, "recurringAfterDecline", "stopAfterDeclines")}
                            />
                            <Group align="start" spacing={4}>
                              <Text size={16}>
                                {pluralize(options.recurringAfterDecline.stopAfterDeclines, "time", "s", true)}
                              </Text>
                              <FSToolTip label="The survey will permanently stop appearing after a user declines it [X] consecutive times."></FSToolTip>
                            </Group>
                          </Group>
                        </Stack>
                      </Stack>
                    )}
                  </Stack>
                </Stack>

                <Space h="md" />
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="whom-to-show">
              <Accordion.Control>
                <Group position="apart">
                  <div>
                    <Text size="md" weight={400}>
                      Whom to show
                    </Text>
                    <Text size="xs" color="dimmed">
                      Filter users based on their attributes.
                    </Text>
                  </div>
                  <Group>
                    {accordionItem === "whom-to-show" ? null : options.attributeFilters ? (
                      <Group>
                        <TextualTag>{pluralize(options.attributeFilters.length, "Filter", "s")}</TextualTag>
                        <IconCircleCheck size={30} color="rgb(81, 207, 102)" />
                      </Group>
                    ) : null}
                  </Group>
                </Group>
                {accordionItem === "whom-to-show" ? <Divider></Divider> : null}
              </Accordion.Control>
              <Accordion.Panel>
                <Space h="md" />

                {options.attributeFilters &&
                  options.attributeFilters.map((filter, Idx) => {
                    return (
                      <AttributeFilter
                        filterCount={options.attributeFilters.length}
                        key={Idx}
                        idx={Idx}
                        token={token}
                        dispatch={dispatch}
                        surveyId={surveyId}
                        filter={filter}
                        csrfToken={csrfToken}
                      />
                    );
                  })}

                {/* {options.attributeFilters && options.attributeFilters.length > 1 ? <Text>and</Text> : null} */}
                <Space h="md" />
                <Button
                  type="button"
                  leftIcon={<IconPlus size={20} stroke={2} />}
                  variant="outline"
                  onClick={e => addFilter(e)}
                  className="space10"
                >
                  Add Filter
                </Button>
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="customization">
              <Accordion.Control>
                <Group position="apart">
                  <div>
                    <Text size="md" weight={400}>
                      Customization
                    </Text>
                    <Text size="xs" color="dimmed">
                      {formState.questions.length > 3
                        ? "Select widget type, position and question randomization."
                        : "Select widget type and position"}
                    </Text>
                  </div>
                  <Group>
                    {accordionItem === "customization" ? null : (
                      <TextualTag>{humanizedString(options.position.widgetType)}</TextualTag>
                    )}
                    {accordionItem === "customization" ? null : (
                      <Group>
                        {options.position.widgetType === "popover" ? (
                          <TextualTag>{humanizedString(options.position.widgetPosition)}</TextualTag>
                        ) : null}

                        <IconCircleCheck size={30} color="rgb(81, 207, 102)" />
                      </Group>
                    )}
                  </Group>
                </Group>
                {accordionItem === "customization" ? <Divider></Divider> : null}
              </Accordion.Control>
              <Accordion.Panel>
                <Box>
                  <SubHeadingText>Widget Type</SubHeadingText>

                  <Radio.Group
                    value={options.position.widgetType}
                    required
                    name="formfactor"
                    onChange={e => handleInputChange(e, "position", "widgetType")}
                    orientation="vertical"
                    spacing="xs"
                  >
                    <Radio label="Popover" value="popover" />
                    <Radio label="Modal" value="modal" />
                  </Radio.Group>
                </Box>
                <Space h={50} />

                {options.position.widgetType === "popover" ? (
                  <WidgetPosition handleInputChange={handleInputChange} options={options} />
                ) : null}
                <Space h={10} />
              </Accordion.Panel>
            </Accordion.Item>
          </>
        )}
      </Accordion>

      <Space h="md" />
    </>
  );
}

function URLDropdown(props) {
  const { handleInputChange, options } = props;
  const [placeholder, setPlaceholder] = useState("");

  function handleURLChange(value, section, field) {
    console.log(value);
    const selectedOption = value;

    setPlaceholder(urlMatchOptions.find(x => x.value === value)?.placeholder);

    handleInputChange(selectedOption, section, field);
  }

  const urlMatchOptions = [
    {
      key: "exactMatch",
      value: "exactMatch",
      label: "URL exactly matches",
      placeholder: "e.g. http://www.website.com?product=book",
    },
    {
      key: "startsWith",
      value: "startsWith",
      label: "URL starts with",
      placeholder: "e.g. http://www.website.com/reviews",
    },
    {
      key: "endsWith",
      value: "endsWith",
      label: "URL ends with",
      placeholder: "e.g. /reviews",
    },
    { key: "contains", value: "contains", label: "URL contains", placeholder: "e.g. checkout" },
    { key: "regex", value: "regex", label: "URL regex", placeholder: "e.g. {'\\/product\\/[^\\/]+'}" },
  ];

  const ValueAndPlaceholder = () => {
    let matchValue = urlMatchOptions.find(x => x.value === options.triggerCondition.matchCriteria);
    const obj = {
      value: matchValue ? matchValue.value : "",
      placeholder: matchValue ? matchValue.label : "Select Criteria",
    };
    return obj;
  };

  return (
    <>
      <Group>
        <StyledSelect
          value={ValueAndPlaceholder().value}
          onChange={e => handleURLChange(e, "triggerCondition", "matchCriteria")}
          data={urlMatchOptions}
          placeholder={ValueAndPlaceholder().placeholder}
          width="200px"
        />

        <TextInput
          id="urlToMatch"
          placeholder={placeholder}
          value={options.triggerCondition.urlToMatch}
          onChange={e => handleInputChange(e.target.value, "triggerCondition", "urlToMatch")}
          sx={{ width: "360px" }}
        />
      </Group>
    </>
  );
}

function WidgetPosition(props) {
  const { handleInputChange, options } = props;
  return (
    <Box>
      <SubHeadingText>Widget Position</SubHeadingText>
      <Radio.Group
        value={options.position.widgetPosition}
        name="widgetPosition"
        onChange={e => handleInputChange(e, "position", "widgetPosition")}
        orientation="vertical"
        spacing="xs"
      >
        <Radio label="Botton Right" value="bottom_right" />
        <Radio label="Bottom Left" value="bottom_left" />
      </Radio.Group>
    </Box>
  );
}
