import React, { useEffect, Suspense, lazy, useState, useReducer } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { useAuthState } from "./../../context/AuthContext";
import { getSurveyById } from "./../../api/Survey";
import dayjs from "dayjs";
import {
  Tabs,
  Button,
  Box,
  Group,
  Space,
  Flex,
  Collapse,
  Input,
  Text,
  CopyButton,
  SegmentedControl,
  Center,
  Tooltip,
  Loader,
} from "@mantine/core";
import _ from "lodash";
import {
  IconClipboardData,
  IconCopy,
  IconFileDescription,
  IconLink,
  IconPencil,
  IconMailOpened,
  IconFileTypeCsv,
  IconDownload,
  IconClipboard,
  IconFilter,
  IconFilterCheck,
} from "@tabler/icons-react";
import styled from "@emotion/styled";
import { showErrorNotification } from "../../components/notifications";
import { SurveyStateControl } from "./components/SurveyStateControl";
import Heading from "../../components/Heading";
import { FilterReducer } from "../../reducers/FilterReducer";
import ResponseFilters from "./ResponseFilters";
import { embedEmailContent } from "../../utils/embedEmailContent";
import CopyValueIcon from "../../components/CopyValueIcon";
import { InstallCodeBanner } from "../../components/InstallCodeBanner";
import SurveyResponseReport from "./SurveyResponseReport";

import { exportDataAsCSV } from "./../../api/Survey";
import { userIdentifierTag } from "../../utils/config";
import { surveyLinkURL, getQueryStringFromArray } from "../../utils/helpers";
import { FullPageSpinner } from "./../../components/spinners";
import SurveyResponseVerbatim from "./SurveyResponseVerbatim";
import style from "../../style.scss";
import CustomDateRangePicker from "../../components/CustomDateRangePicker";
import { getUnixDateRangeFromTimeframe, getTodayDateWithTz, getDateStringFromtimeframe } from "../../utils/helpers";
import { useIntl } from "react-intl";

//#####-----##########----##################---#############---##################

export default function SurveyResponse() {
  let { surveyId, tab } = useParams();
  const intl = useIntl();
  const { user, currentProduct, csrftoken } = useAuthState();

  const userTimezone = currentProduct.timezone || "UTC";

  const [downloadLoader, setDownloadLoader] = useState(false);

  const [timeframe, setTimeframe] = useState("last_30"); // local state for time frame picker
  const [dateRange, setDateRange] = useState([]);
  const [customDate, setCustomDate] = useState([null, null]); //local state for custom date range picker
  let [searchParams, setSearchParams] = useSearchParams();
  const [opened, setOpened] = useState(
    searchParams.get("filterby_response") ||
      searchParams.get("filterby_country") ||
      searchParams.get("filterby_attribute")
  );
  const [responseData, setResponseData] = useState();
  const [loader, setLoader] = useState(true);
  const [surveyURL, setSurveyURL] = useState();
  const [userTag, setUserTag] = useState();

  const navigate = useNavigate();

  const userHasPermission = user.isAccountOwner || user.accessLevel == 1 || user.accessLevel == 2;

  function parseQueryParams() {
    const searchFilterObject = {
      responseFilter: [],
      attributeFilter: [],
      countryFilter: [],
    };

    if (searchParams.get("filterby_attribute")) {
      const splitArray = searchParams.get("filterby_attribute").split("|");
      console.log("splitArray 1", splitArray);
      if (splitArray.length > 0) {
        splitArray.map(f => {
          const parameters = f.split(";");
          if (parameters.length === 4) {
            searchFilterObject.attributeFilter.push({
              property: parameters[0],
              operator: parameters[1],
              predicate:
                parameters[3] === "number" ? parameters[2].split(",").map(p => parseInt(p)) : parameters[2].split(","),
              datatype: parameters[3],
            });
          }
        });
      }
    }

    if (searchParams.get("filterby_response")) {
      const splitArray = searchParams.get("filterby_response").split("|");
      console.log("splitArray 1", splitArray);
      if (splitArray.length > 0) {
        splitArray.map(f => {
          const parameters = f.split(";");
          if (parameters.length === 3) {
            searchFilterObject.responseFilter.push({
              qid: parseInt(parameters[0]),
              resp: parameters[1].split(","),
              qtype: parseInt(parameters[2]),
            });
          }
        });
      }
      console.log("splitArray 2", searchFilterObject);
    }
    if (searchParams.get("filterby_country")) {
      const splitArray = searchParams.get("filterby_country").split("|");
      console.log("splitArray 1", splitArray);
      if (splitArray.length > 0) {
        splitArray.map(f => {
          const parameters = f.split(",");
          console.log("splitArray 1", parameters);
          if (parameters.length > 0) {
            searchFilterObject.countryFilter.push({
              code: parameters,
            });
          }
        });
      }
      console.log("splitArray 2", searchFilterObject);
    }
    return searchFilterObject;
  }

  console.log("#399 QueryPArams PArse", parseQueryParams());
  const [filterState, dispatch] = useReducer(FilterReducer, parseQueryParams());
  let filterMatchesQueryParams = _.isEqual(filterState, parseQueryParams());

  console.log("#399 filterMatchesQueryParams", filterState);

  useEffect(() => {
    filterMatchesQueryParams = _.isEqual(filterState, parseQueryParams());
  }, [filterState]);

  console.log("#399 filterMatchesQueryParams", filterMatchesQueryParams);

  function handleFilterSubmit() {
    let questionQueryString, countryQueryString, attributeQueryString;
    let filterParams = [];
    const questionQueryArray =
      filterState.responseFilter &&
      filterState.responseFilter.length > 0 &&
      filterState.responseFilter.map(f => {
        return f.qid + ";" + f.resp.join(",") + ";" + f.qtype;
      });
    questionQueryString = getQueryStringFromArray(questionQueryArray);

    questionQueryString.length > 0 ? filterParams.push({ filterby_response: questionQueryString }) : null;
    console.log("#12 filterParams 1", filterParams);
    const countryQueryArray =
      filterState.countryFilter &&
      filterState.countryFilter.length > 0 &&
      filterState.countryFilter.map(f => {
        return f.code.join(",");
      });
    countryQueryString = getQueryStringFromArray(countryQueryArray);

    countryQueryString.length > 0 ? filterParams.push({ filterby_country: countryQueryString }) : null;
    console.log("#12 filterParams 2", filterParams);

    const attributeQueryArray =
      filterState.attributeFilter &&
      filterState.attributeFilter.length > 0 &&
      filterState.attributeFilter.map(f => {
        return f.property + ";" + f.operator + ";" + f.predicate + ";" + f.datatype;
      });
    attributeQueryString = getQueryStringFromArray(attributeQueryArray);

    attributeQueryString.length > 0 ? filterParams.push({ filterby_attribute: attributeQueryString }) : null;

    if (filterParams.length > 0) {
      let finalParams;

      for (let i = 0; i < filterParams.length; i++) {
        if (i === 0) {
          finalParams = filterParams[i];
        } else {
          finalParams = { ...finalParams, ...filterParams[i] };
        }
      }
      setSearchParams(finalParams);
    }
  }
  console.log("#filterState", filterState);

  const filterCount =
    filterState.responseFilter.length + filterState.countryFilter.length + filterState.attributeFilter.length;

  function handleFilterClose() {
    setOpened(o => !o);
    dispatch({ type: "clearEmptyFilters" });
  }

  //const csrfToken = useAuthState().csrftoken;

  //const VerbatimTab = lazy(() => import('./SurveyResponseVerbatim'));

  useEffect(() => {
    getSurveyById(surveyId)
      .then(data => {
        if (data.error) {
          console.log(data.error.message);
          showErrorNotification();
        } else {
          setResponseData(data);
          setSurveyURL(surveyLinkURL(currentProduct.prodToken, data.code));
          setUserTag(
            data.survey_options.user_identifier_key
              ? userIdentifierTag(data.survey_options.user_identifier_key, data.survey_options.email?.platform)
              : ""
          );
          // if (timeframe === "all") {
          //   setDateRange([
          //     dayjs(data.started_at).subtract(1, "day").tz(userTimezone).unix(),
          //     dayjs(new Date()).tz(userTimezone).unix(),
          //   ]);
          // }
        }
      })
      .catch(error => {
        console.log(error);
        showErrorNotification();
      })
      .finally(setLoader(false));
  }, []);

  useEffect(() => {
    if (timeframe !== null) {
      if (timeframe === "all") {
        //this hack is to make sure that the date range is set to the survey start date and today's date
        // setDateRange([
        //   dayjs(responseData && responseData.started_at)
        //     .subtract(1, "day")
        //     .tz(userTimezone)
        //     .unix(),
        //   dayjs(new Date()).tz(userTimezone).unix(),
        // ]);
      } else setDateRange(getUnixDateRangeFromTimeframe(timeframe, userTimezone));
    }
  }, [timeframe]);

  function onCalendarRangeChange() {
    if (customDate.every(d => d !== null)) {
      setDateRange([dayjs(customDate[0]).tz(userTimezone).unix(), dayjs(customDate[1]).tz(userTimezone).unix()]);
      setTimeframe(null);
    }
  }

  function handleSegmentSelect(value) {
    setTimeframe(value);
    setCustomDate([null, null]);
  }
  console.log("surveyData before surveyURL", responseData);

  function handleCSVDownload(surveyId, dateRange, searchParams) {
    setDownloadLoader(true);

    exportDataAsCSV(surveyId, dateRange, searchParams)
      .then(() => {
        setDownloadLoader(false);
      })
      .catch(error => {
        console.error(error);
        setDownloadLoader(false);
      });
  }

  return (
    <>
      {loader ? (
        <FullPageSpinner />
      ) : (
        responseData && (
          <Box columns="three">
            {currentProduct.lastProdInitAt ? null : <InstallCodeBanner token={currentProduct.prodToken} />}
            <Flex className={style.responseReport} justify="flex-start" align="flex-start" direction="row" wrap="wrap">
              <Collapse in={opened} transitionDuration={100} transitionTimingFunction="linear">
                <div className={style.filterSection}>
                  <ResponseFilters
                    token={currentProduct.prodToken}
                    filterMatchesQueryParams={filterMatchesQueryParams}
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    csrftoken={csrftoken}
                    surveyId={surveyId}
                    questions={responseData.questions}
                    filterState={filterState}
                    filterCount={filterCount}
                    dispatch={dispatch}
                    handleFilterSubmit={handleFilterSubmit}
                    setCollapseOpen={setOpened}
                  />
                </div>
              </Collapse>
              <div className={`${style.reportSection} ${opened && style.openedFilter}`}>
                <Heading title={responseData.title} showDivider={false}>
                  {userHasPermission && (
                    <Group>
                      <Button
                        variant="default"
                        leftIcon={<IconFileTypeCsv color="gray" stroke={1} />}
                        rightIcon={
                          downloadLoader ? <Loader color="gray" size="xs" /> : <IconDownload size={16} stroke={1} />
                        }
                        size="xs"
                        onClick={() => {
                          handleCSVDownload(surveyId, dateRange, searchParams);
                        }}
                      >
                        <Text weight={400} size={14}>
                          {intl.formatMessage({ id: "reports.download_csv" })}
                        </Text>
                      </Button>
                      {responseData.platform == "link" && (
                        <Group position="right">
                          <Input
                            icon={<IconLink color="gray" />}
                            component="button"
                            rightSection={
                              <CopyValueIcon value={userTag.length > 0 ? `${surveyURL}/?${userTag}` : surveyURL} />
                            }
                          >
                            <Text weight={400} size={14}>
                              {intl.formatMessage({ id: "reports.survey_link" })}
                            </Text>
                          </Input>
                        </Group>
                      )}
                      {responseData.platform == "email" && (
                        <Group position="right">
                          <CopyButton value={embedEmailContent(responseData.questions[0], surveyURL, userTag)}>
                            {({ copied, copy }) => (
                              <Button
                                onClick={copy}
                                variant="default"
                                size="xs"
                                leftIcon={<IconMailOpened color="gray" stroke={1} />}
                                rightIcon={<IconCopy size={16} stroke={1} />}
                              >
                                <Text color={copied ? "teal" : "black"} weight={400} size={14}>
                                  {copied
                                    ? intl.formatMessage({ id: "reports.copied_html" })
                                    : intl.formatMessage({ id: "reports.copy_html" })}
                                </Text>
                              </Button>
                            )}
                          </CopyButton>
                        </Group>
                      )}

                      <Button
                        variant="default"
                        leftIcon={<IconPencil color="gray" stroke={1} />}
                        rightIcon={<IconClipboard stroke={1} size={16} />}
                        size="xs"
                        onClick={() => {
                          navigate(`/surveys/${surveyId}/edit/platform`);
                        }}
                      >
                        <Text weight={400} size={14}>
                          {intl.formatMessage({ id: "common.edit" })}
                        </Text>
                      </Button>

                      <SurveyStateControl currentState={responseData.state} surveyId={surveyId} />
                    </Group>
                  )}
                </Heading>

                <Space h="md" />
                <Group position="right">
                  {opened ? (
                    <Button
                      leftIcon={
                        filterCount > 0 ? (
                          <IconFilterCheck size={16} stroke={1} />
                        ) : (
                          <IconFilter color="gray" size={16} stroke={1} />
                        )
                      }
                      size="xs"
                      variant="default"
                      onClick={() => handleFilterClose()}
                    >
                      <Text size={14} weight={400}>
                        {intl.formatMessage({ id: "reports.hide_filter" })}
                      </Text>
                    </Button>
                  ) : (
                    <Button
                      leftIcon={
                        filterCount > 0 ? (
                          <IconFilterCheck size={16} stroke={1} />
                        ) : (
                          <IconFilter color="gray" size={16} stroke={1} />
                        )
                      }
                      size="xs"
                      variant="default"
                      onClick={() => setOpened(o => !o)}
                    >
                      <Text size={14} weight={400}>
                        {filterCount > 0
                          ? `${intl.formatMessage({ id: "filters.filter" })} (${filterCount})`
                          : intl.formatMessage({ id: "filters.filter" })}
                      </Text>
                    </Button>
                  )}

                  <Group sx={{ gap: 0 }}>
                    <CustomDateRangePicker
                      minDate={dayjs(responseData.started_at).toDate()}
                      maxDate={dayjs(new Date()).toDate()}
                      handleSubmit={onCalendarRangeChange}
                      customDate={customDate}
                      setCustomDate={setCustomDate}
                      timeframe={timeframe}
                    />

                    <SegmentedControlGen
                      //sx={{ float: 'right' }}
                      value={timeframe}
                      onChange={value => handleSegmentSelect(value)}
                      data={[
                        {
                          label: (
                            <Center>
                              <Tooltip
                                withArrow={true}
                                withinPortal={true}
                                position="top"
                                label={<Text size={12}>{getDateStringFromtimeframe("today", userTimezone)}</Text>}
                              >
                                <Box>{intl.formatMessage({ id: "reports.today" })}</Box>
                              </Tooltip>
                            </Center>
                          ),
                          value: "today",
                        },
                        {
                          label: (
                            <Center>
                              <Tooltip
                                withArrow={true}
                                withinPortal={true}
                                position="top"
                                label={<Text size={12}>{getDateStringFromtimeframe("yesterday", userTimezone)}</Text>}
                              >
                                <Box>{intl.formatMessage({ id: "reports.yesterday" })}</Box>
                              </Tooltip>
                            </Center>
                          ),
                          value: "yesterday",
                        },
                        {
                          label: (
                            <Center>
                              <Tooltip
                                withArrow={true}
                                withinPortal={true}
                                position="top"
                                label={<Text size={12}>{getDateStringFromtimeframe("last_7", userTimezone)}</Text>}
                              >
                                <Box>7D</Box>
                              </Tooltip>
                            </Center>
                          ),
                          value: "last_7",
                        },
                        {
                          label: (
                            <Center>
                              <Tooltip
                                withArrow={true}
                                withinPortal={true}
                                position="top"
                                label={<Text size={12}>{getDateStringFromtimeframe("last_30", userTimezone)}</Text>}
                              >
                                <Box>30D</Box>
                              </Tooltip>
                            </Center>
                          ),
                          value: "last_30",
                        },

                        {
                          label: (
                            <Center>
                              <Tooltip
                                withArrow={true}
                                withinPortal={true}
                                position="top"
                                label={<Text size={12}>{getDateStringFromtimeframe("last_3M", userTimezone)}</Text>}
                              >
                                <Box>3M</Box>
                              </Tooltip>
                            </Center>
                          ),
                          value: "last_3M",
                        },
                        {
                          label: (
                            <Center>
                              <Tooltip
                                withArrow={true}
                                withinPortal={true}
                                position="top"
                                label={<Text size={12}>{getDateStringFromtimeframe("last_6M", userTimezone)}</Text>}
                              >
                                <Box>6M</Box>
                              </Tooltip>
                            </Center>
                          ),
                          value: "last_6M",
                        },
                        {
                          label: (
                            <Center>
                              <Tooltip
                                withArrow={true}
                                withinPortal={true}
                                position="top"
                                label={<Text size={12}>{getDateStringFromtimeframe("last_12M", userTimezone)}</Text>}
                              >
                                <Box>12M</Box>
                              </Tooltip>
                            </Center>
                          ),
                          value: "last_12M",
                        },
                      ]}
                    />
                  </Group>
                </Group>

                <Tabs
                  //keepMounted={false}
                  value={tab}
                  onTabChange={value => {
                    const tabURL = `/surveys/${surveyId}/responses/${value}/`;
                    navigate({ pathname: tabURL, search: `?${searchParams.toString()}` });
                  }}
                >
                  <Tabs.List>
                    <Tabs.Tab value="report" icon={<IconClipboardData size={18} />}>
                      {intl.formatMessage({ id: "reports.report_tab" })}
                    </Tabs.Tab>
                    <Tabs.Tab value="all" icon={<IconFileDescription size={18} />}>
                      {intl.formatMessage({ id: "reports.responses_tab" })}
                    </Tabs.Tab>
                  </Tabs.List>
                  <Tabs.Panel value="report" sx={{ minHeight: "100vh" }}>
                    <Space h="md" />

                    <SurveyResponseReport
                      surveyId={responseData.id}
                      questions={responseData.questions}
                      platform={responseData.platform}
                      token={currentProduct.prodToken}
                      dateRange={dateRange}
                      csrftoken={csrftoken}
                      opened={opened}
                      searchParams={searchParams}
                      setSearchParams={setSearchParams}
                    />
                  </Tabs.Panel>
                  <Tabs.Panel value="all" sx={{ minHeight: "100vh" }}>
                    <Space h="md" />

                    <SurveyResponseVerbatim
                      surveyId={responseData.id}
                      questions={responseData.questions}
                      token={currentProduct.prodToken}
                      csrftoken={csrftoken}
                      dateRange={dateRange}
                      opened={opened}
                      setSearchParams={setSearchParams}
                      searchParams={searchParams}
                      platform={responseData.platform}
                      owner={responseData.owner}
                    />
                  </Tabs.Panel>
                </Tabs>
              </div>
            </Flex>
          </Box>
        )
      )}
    </>
  );
}

const SegmentedControlGen = styled(SegmentedControl)`
  input[type="radio"]:checked + label.mantine-SegmentedControl-labelActive {
    background-color: white;
    color: #000000;
    font-weight: 400;
    font-size: 14px;
  }
  &.mantine-SegmentedControl-root {
    margin-bottom: 0px;
  }
  .mantine-SegmentedControl-label {
    font-size: 14px;
    font-weight: 400;
  }
  .mantine-SegmentedControl-root + div {
    clear: both;
  }
`;
