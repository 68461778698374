import React, { useState, useEffect } from 'react';
import { useAuthState } from '../../../context/AuthContext';
import { Link, useParams, useNavigate, redirect, Navigate } from 'react-router-dom';

import { Paper, Text, Container, Center, Title, Button, Group, Box, Space, PasswordInput, Loader } from '@mantine/core';
import { IconArrowNarrowLeft, IconExclamationCircle } from '@tabler/icons-react';

import API from '../../../api/API';
import { API_URLS } from '../../../utils/constants';
import { validateResetPasswordToken } from '../../../api/Auth';
import { ErrorBox } from '../../../components/MantineOverride';

import { useForm } from '@mantine/form';

export default function ResetPassword() {
  const { csrftoken } = useAuthState();
  let { token } = useParams();
  let navigate = useNavigate();

  const [success, setSuccess] = useState(false);
  const [tokenLoader, setTokenLoader] = useState(true);
  const [tokenError, setTokenError] = useState(false);

  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);

  console.log('@tokenError', tokenError);

  console.log('@submissionError', error);

  useEffect(() => {
    validateResetPasswordToken(token, csrftoken)
      .then(data => {
        if (data.status_code === 404) {
          setTokenError(data);
        }

        console.log('resetpassword token data', data);
      })
      .catch(error => {
        console.log('****Token Error ****', error);

        setTokenError(error);
      })
      .finally(setTokenLoader(false));
  }, [token]);

  const form = useForm({
    initialValues: {
      password: 'secret',
      confirmPassword: 'secret',
    },

    validate: {
      confirmPassword: (value, values) => (value !== values.password ? 'Passwords did not match' : null),
    },

    transformValues: values => ({
      password: values.password,
      token: token,
    }),
  });

  async function handleSubmit(data) {
    setLoader(true);
    console.log('data in reset passsword', data);
    let response = await API.post(API_URLS.CONFIRM_RESET_PASSWORD_TOKEN, { ...data }, csrftoken);
    console.log(response);
    if (response.success) {
      setLoader(false);
      setSuccess(true);
      //navigate('success', { replace: false, state: { urlRedirect: true } });

      console.log('login success for', response.success);
      //dispatch({ type: 'AUTH_SUCCESS', payload: response.success.data.user });
    } else {
      setError(response.error);
      setLoader(false);
      //dispatch({ type: 'AUTH_ERROR', error: response.error });
      console.log('Login error', response.error);
    }
  }

  //console.log('$$$Token Error $$$ is', tokenError);

  if (tokenLoader) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  if (tokenError) {
    return (
      <Container size={420} my={40}>
        <Paper shadow="md" p={30} mt={30} radius="md" withBorder>
          <Box mx="auto">
            <Group position="center">
              <IconExclamationCircle align="center" color="red" size={50} stroke={1} />
            </Group>

            <Title align="center">Invalid Token</Title>
            <Text color="dimmed" size="sm" align="center" mt={10}>
              Please reset your password again.
            </Text>
            <Group position="center" mt={30}>
              <Link to="/forgot-password">
                <Button leftIcon={<IconArrowNarrowLeft />}>Back</Button>
              </Link>
            </Group>
          </Box>
        </Paper>
      </Container>
    );
  }

  if (success) {
    return <Navigate to="success" replace={false} state={{ urlRedirect: true }} />;
  } else {
    return (
      <Container size={520} my={40}>
        {loader ? (
          <Center>
            <Loader />
          </Center>
        ) : (
          <>
            <Title align="center" sx={{ fontWeight: 900 }}>
              Reset your Password
            </Title>
            <Paper shadow="md" p={30} mt={30} radius="md" withBorder>
              <Box mx="auto">
                {error ? (
                  <ErrorBox
                    title={error.message}
                    color="red"
                    withCloseButton
                    onClose={() => setError(null)}
                    closeButtonLabel="Close alert"
                  ></ErrorBox>
                ) : null}
                <form onSubmit={form.onSubmit(values => handleSubmit(values))}>
                  <PasswordInput label="Password" placeholder="Password" {...form.getInputProps('password')} />

                  <PasswordInput
                    mt="md"
                    label="Confirm password"
                    placeholder="Confirm password"
                    {...form.getInputProps('confirmPassword')}
                  />
                  <Group position="center">
                    <Button type="submit" fullWidth mt="xl">
                      Submit
                    </Button>
                  </Group>
                  <Group position="right" mt="md">
                    <Link to="/login" style={{ fontSize: '1.6rem', color: '#228be6' }}>
                      Back to login
                    </Link>
                  </Group>
                </form>
                <Space h="md" />
              </Box>
            </Paper>
          </>
        )}
      </Container>
    );
  }
}
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="a2f45390-d6c3-5641-b181-3fb7d7b44ee2")}catch(e){}}();
//# debugId=a2f45390-d6c3-5641-b181-3fb7d7b44ee2
