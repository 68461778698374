import React from "react";
import { Modal, Group, Button, Box, Divider, Text, Stack, Title } from "@mantine/core";
import { IconAlertTriangle } from "@tabler/icons-react";

export default function DeleteAlertModal({ opened, close, heading, message, onConfirm }) {
  return (
    <>
      <Modal opened={opened} size={380} onClose={close} withCloseButton={false}>
        <Box p={20}>
          <Stack align="center">
            <IconAlertTriangle size={40} color="red" />
            <Title order={3}>{heading}</Title>
          </Stack>

          <Divider my="sm" />
        </Box>
        <Text align="center">{message}</Text>

        <Group mt={20} p={20} position="center">
          <Button color="gray" onClick={close}>
            Cancel
          </Button>
          <Button onClick={onConfirm} color="red">
            Delete
          </Button>
        </Group>
      </Modal>
    </>
  );
}
