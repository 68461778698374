import React from 'react';

import { StyledTextInput } from '../../../../components/MantineOverride';

export function OpenText(props) {
  const { idx, question, handleChange } = props;

  return (
    <div className="shqQuestion">
      <StyledTextInput
        label={`Question ${idx + 1}`}
        id={`question${idx}`}
        name="question"
        maxLength={256}
        value={question.questionText}
        onChange={e => handleChange(idx, e)}
      />
    </div>
  );
}
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="642e4a09-4e15-5e6e-8f4d-0e1fb1cb22c4")}catch(e){}}();
//# debugId=642e4a09-4e15-5e6e-8f4d-0e1fb1cb22c4
