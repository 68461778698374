import React from "react";
import { SegmentedControlSHQ } from "../../../../components/MantineOverride";
import { useIntl } from "react-intl";

export default function TimeSegment(props) {
  const { interval, setInterval } = props;
  const intl = useIntl();
  return (
    <SegmentedControlSHQ
      value={interval}
      radius={20}
      size="xs"
      onChange={setInterval}
      data={[
        { value: "day", label: intl.formatMessage({ id: "reports.day", defaultMessage: "Day" }) },
        { value: "week", label: intl.formatMessage({ id: "reports.week", defaultMessage: "Week" }) },
        { value: "month", label: intl.formatMessage({ id: "reports.month", defaultMessage: "Month" }) },
      ]}
    />
  );
}
