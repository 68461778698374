export const el = {
  //-----------------Common Words--------------------
  "common.save": "Save",
  "common.cancel": "Cancel",
  "common.apply": "Apply",
  "common.delete": "Delete",
  "common.duplicate": "Duplicate",
  "common.edit": "Edit",
  "common.add": "Add",
  "common.remove": "Remove",
  "common.yes": "Yes",
  "common.no": "No",
  "common.next": "Next",
  "common.back": "Back",
  "common.done": "Done",
  "common.close": "Close",
  "common.upload": "Upload",
  "common.upload_logo": "Upload Logo",
  "common.email": "Email",
  "common.name": "Name",
  "common.full_name": "Full Name",
  "common.status": "Status",
  "common.loading_error": "Something went wrong. Please try again.",
  "common.loading": "Loading...",
  "common.success": "Success",
  "common.error": "Error",
  "common.copied": "Copied",
  "common.copy": "Copy",
  "common.questions": "Questions",
  "common.string": "String",
  "common.boolean": "Boolean",
  "common.number": "Number",
  "common.date": "Date",
  "common.load_more": "Load more",
  "common.view_all": "View all",
  "common.view_less": "View less",
  "common.user": "User",
  "common.responses": "Responses",
  "common.response": "Response",
  "common.go_back": "Go back",
  "common.survey": "Survey",

  //-----------------Nav--------------------
  "nav.new_survey": "New Survey",
  "nav.survey_list": "Survey List",
  "nav.user_attributes": "User Attributes",
  "nav.connect": "Connect",
  "nav.account": "Account",
  "nav.organization": "Organization",
  "nav.team": "Team",
  "nav.settings": "Settings",
  "nav.logout": "Logout",
  "nav.help": "Help",
  "nav.integrations": "Integrations",
  "nav.sites": "Sites",
  //-----------------Dashboard Page-----------------------
  "dashboard_page.heading": "Dashboard",
  "dashboard_page.subheading": "Welcome to Spark!",
  "dashboard_page.survey": "Survey",
  "dashboard_page.surveys": "Surveys",
  "dashboard_page.respondents": "Respondents",
  "dashboard_page.responses": "Responses",
  "dashboard_page.view_all": "View all",
  //-----------------Survey Page--------------------------
  "survey_page.heading": "Survey",
  "survey_page.subheading": "Create your survey by adding questions.",

  //-----------------New Survey Page--------------------
  "new_survey_page.heading": "New Survey",
  "new_survey_page.subheading": "Use a template from below or create from scratch.",
  "new_survey_page.create_button": "Create Survey from scratch",
  //-----------------Templates
  "templates.nps": "NPS",
  "templates.nps_desc": "How happy are your customers with the offering?",
  "templates.feature_launch": "Feature Launch",
  "templates.feature_launch_desc": "Ideal for assessing impact of newly launched features.",
  "templates.pmf": "Product Market Fit",
  "templates.pmf_desc": "Have you reached the fit?",
  "templates.feature_discovery": "Feature Discovery",
  "templates.feature_discovery_desc": "Understand what features are most important to your users.",
  "templates.csat": "CSAT",
  "templates.csat_desc": "Measure how satisfied are your customers with your product or service?",
  //-----------------Survey Listing Page--------------------
  "survey_list_page.heading": "Surveys",
  "survey_list_page.no_content_heading": "Understand <mark>why</mark> of your users' decisions",
  "survey_list_page.no_content_subheading": "Launch a in-product survey and start getting insights.",
  "survey_list_page.no_content_cta": "New Survey",
  "survey_list_page.no_content_hero_alt": "Survey question creation form",
  "survey_list_page.started_on": "Started on {date}",
  "survey_list_page.ended_on": "Ended on {date}",

  //-----------------User Attributes Page--------------------
  "user_attributes_page.heading": "User Attributes",
  "user_attributes_page.attribute": "Attribute",
  "user_attributes_page.type": "Type",
  "user_attributes_page.last_received": "Last Received",
  "user_attributes_page.month": "month",
  "user_attributes_page.ago": "ago",
  "user_attributes_page.months": "months",
  "user_attributes_page.string": "String",
  "user_attributes_page.boolean": "Boolean",
  "user_attributes_page.number": "Number",
  "user_attributes_page.date": "Date",
  "user_attributes_page.no_data_heading": "Reach out to relevant users",
  "user_attributes_page.no_data_subheading":
    "User attributes are additional data points that you already have about your users. You can send this information to FeedbackSpark to show your survey to a specific segment of users, for e.g. users who purchased in last 60 days.",
  //-----------------Connect Page--------------------
  "connect_page.heading": "Connect",
  "connect_page.html_help_text":
    "Copy the code below and paste it between the <mark>{html_tag}</mark> tags on all the pages where you want to show the survey.",
  "connect_page.npm": "NPM",
  "connect_page.Yarn": "Yarn",
  "connect_page.npm_initialize_spark": "Next in your application, as early as possible, initialize Spark.",
  "connect_page.recommeneded": "Recommended",
  //-----------------Organization Page--------------------
  "org_page.heading": "Organization",

  "org_page.website": "Website",
  "org_page.api_key": "API Key",
  "org_page.view_code": "View installation code",
  "org_page.logo": "Logo",
  "org_page.logo_desc": "This logo will be displayed in the survey page.",
  "org_page.upload_logo_btn": "Upload Logo",
  //-----------------Teams Page--------------------
  "team_page.heading": "Team",
  "team_page.access": "Access",
  "team_page.date_joined": "Date Joined",
  "team_page.members": "Members",
  "team_page.add_member_btn": "Add Member",
  "team_page.add_team_member": "Add Team Member",
  "team_page.access_level": "Access Level",
  "team_page.access_level_placeholder": "Select role",
  "team_page.access_viewer": "Viewer",
  "team_page.access_editor": "Editor",
  "team_page.you": "You",
  "team_page.role": "Role",
  "team_page.invited": "Invited",
  "team_page.accepted": "Accepted",
  "team_page.rejected": "Rejected",
  "team_page.resend_invite": "Resend Invite",
  "team_page.cancel_invite": "Cancel Invite",
  "team_page.remove_member": "Remove Member",
  //-----------------Settings Page--------------------

  "settings_page.heading": "Settings",
  "settings_page.product": "Product",
  "settings_page.product_desc": "Name is used to automatically fill in survey templates.",
  "settings_page.website": "Website",
  "settings_page.timezone": "Timezone",
  "settings_page.appearance": "Appearance",
  "settings_page.appearance_desc": "Customize the look and feel of the survey.",
  "settings_page.background": "Background",
  "settings_page.background_desc": "Background color for the survey.",
  "settings_page.font_color": "Font Color",
  "settings_page.font_color_desc": "Question & options font color.",
  "settings_page.button": "Button",
  "settings_page.button_desc": "Call to action button background color.",
  "settings_page.button_font_color": "Button Font Color",
  "settings_page.button_font_color_desc": "Call to action button font color.",
  "settings_page.border": "Border",
  "settings_page.border_desc": "Widget and input field border color",
  "settings_page.reconnect_period": "Reconnect Period",
  "settings_page.reconnect_period_desc":
    "Minimum number of days before same user is shown their next survey. Defaults to 7 days.",
  "settings_page.sample_question": "Sample Question Text",
  "settings_page.option1": "Option 1",
  "settings_page.option2": "Option 2",
  "settings_page.i_think": "I think...",
  "settings_page.done": "Done",
  "settings_page.powered_by": "Powered by",
  //-----------------Error Notifications--------------------
  "notif.sorry": "Sorry",
  "message.error": "Something went wrong",
  "message.no_delivery_platform": "Please add a delivery platform.",
  "message.no_question": "Please add a question to the survey.",
  "message.incomplete_questions": "Please complete all the questions.",
  "message.survey_not_saved": "An error occurred while saving the survey.",

  //-----------------Reports--------------------
  "reports.report_tab": "Αναφορά",
  "reports.responses_tab": "Απαντήσεις",
  "reports.filter": "Φίλτρο",
  "reports.custom": "Προσαρμοσμένο",
  "reports.today": "Σήμερα",
  "reports.yesterday": "Χθες",
  "reports.download_csv": "Λήψη CSV",
  "reports.survey_link": "Σύνδεσμος Έρευνας",
  "reports.copied_html": "Αντιγραμμένο HTML",
  "reports.copy_html": "Αντιγραφή HTML",
  "reports.unique_views": "Μοναδικές Προβολές",
  "reports.unique_views_desc": "Αριθμός μοναδικών χρηστών που είδαν την έρευνα τουλάχιστον μία φορά.",
  "reports.answered": "Απαντήθηκε",
  "reports.answered_desc": "Αριθμός μοναδικών χρηστών που απάντησαν τουλάχιστον σε μία ερώτηση.",
  "reports.completed": "Ολοκληρώθηκε",
  "reports.completed_desc": "Αριθμός μοναδικών χρηστών που απάντησαν σε όλες τις ερωτήσεις.",
  "reports.response_rate": "Ποσοστό Απαντήσεων",
  "reports.response_rate_desc": "Ποσοστό μοναδικών χρηστών που απάντησαν τουλάχιστον σε μία ερώτηση.",
  "reports.completion_rate": "Ποσοστό Ολοκλήρωσης",
  "reports.completion_rate_desc": "Ποσοστό μοναδικών χρηστών που ολοκλήρωσαν όλες τις ερωτήσεις.",
  "reports.most_responses_from": "Οι περισσότερες απαντήσεις από",
  "reports.view_more": "Προβολή περισσότερων",
  "reports.not_set": "Δεν έχει οριστεί",
  "reports.hide_filter": "Απόκρυψη Φίλτρου",
  "reports.custom_date": "Προσαρμοσμένο",
  "reports.filter_start_date": "Ημερομηνία Έναρξης",
  "reports.filter_end_date": "Ημερομηνία Λήξης",
  "reports.all_filters": "Όλα τα Φίλτρα",
  "reports.filter_by": "Φιλτράρισμα κατά",
  "reports.country_stat_heading": "Οι περισσότερες απαντήσεις από",
  "reports.country_stat_subheading": "Ερωτηθέντες ανά Χώρα (Κορυφαίες 5)",
  "reports.country_stat_help": "Προβολή Περισσότερων",
  "reports.day": "Ημέρα",
  "reports.week": "Εβδομάδα",
  "reports.month": "Μήνας",
  "reports.response_count": "Αριθμός απαντήσεων",
  "reports.rating": "Βαθμολογία",
  "reports.response_count_plural": "{count, plural, one {# Απάντηση} other {# Απαντήσεις}}",
  "reports.nps_promoters": "Προωθητές",
  "reports.nps_passives": "Παθητικοί",
  "reports.nps_detractors": "Αποτρεπτικοί",
  "reports.total": "σύνολο",
  "reports.rating_positive": "Θετικό",
  "reports.rating_negative": "Αρνητικό",
  "reports.rating_neutral": "Ουδέτερο",
  "reports.trend_heading": "Τάση",
  "reports.percent_share": "Μερίδιο",
  "reports.rating_score_tooltip": "Ποσοστό χρηστών που έδωσαν βαθμολογία 4 και 5.",
  "reports.no_data_msg": "Δεν υπάρχουν διαθέσιμα δεδομένα για αυτήν την περίοδο.",
  "reports.identified_themes": "Εντοπισμένα Θέματα",
  "reports.itdentified_tags": "Εντοπισμένες Ετικέτες",
  "reports.count": "Αριθμός",
  "reports.percent": "Ποσοστό",
  "reports.no_ai_analysis":
    "Χρειάζεστε τουλάχιστον δέκα απαντήσεις για να δημιουργήσετε ένα μοτίβο. Εν τω μεταξύ, μπορείτε να <link>ελέγξετε τις κυριολεκτικές απαντήσεις</link>.",
  "reports.sentiment": "Συναίσθημα",
  "reports.nhs": "Καθαρό Σκορ Ευτυχίας",
  "reports.nhs_desc": "Συνολικό % Θετικών – Συνολικό % Αρνητικών = Καθαρό Σκορ Ευτυχίας.",
  "reports.ai_allthemes": "Όλα τα Θέματα",
  "reports.ai_topthemes": "Κορυφαία Θέματα",
  "reports.ai_themecutoff_desc": "Εμφανίζονται τα θέματα με μερίδιο τουλάχιστον 3%.",

  //-----------------Filters--------------------
  "filters.filter": "Filter",

  "filters.response": "Response",
  "filters.country": "Country",
  "filters.clear": "Clear",
  "filters.response_to": "Response to",
  "filters.select_question": "Select Question",
  "filters.apply": "Apply Filters",
  "filters.equals": "Equals",
  "filters.select_country": "Select country",
  "filters.country_equals": "Country Equals",

  //-----------------Surveys General--------------------
  "survey.live": "Live",
  "survey.in_draft": "In Draft",
  "survey.pause": "Pause",
  "survey.paused": "Paused",
  "survey.completed": "Completed",
  "survey.archive": "Archive",
  "survey.archived": "Archived",
  "survey.mark_complete": "Mark Complete",
  "survey.launch": "Launch",
  "survey.draft": "Draft",
  "survey.platform": "Platform",
  "survey.audience": "Audience",
  "survey.notifications": "Notifications",
  "survey.review": "Review",
  "survey.continue": "Continue",
  "survey.name_placeholder": "Survey name",
  "survey.name_label": "Survey name",
  "survey.add_questions": "Add questions",
  "surveys.question_count_plural": "{count, plural, one {# Question} other {# Questions}}",
  "survey.limited_editing_title": "Limited Editing",
  "survey.limited_editing_desc":
    "This survey is live. You can only change the language. If you want to make other changes , we suggest that you mark this survey complete and start a new one.",
  "survey.platform_website": "Website",
  "survey.platform_website_desc": "Show survey inside your website.",
  "survey.platform_email": "Email",
  "survey.platform_email_desc": "Embed survey in an email.",
  "survey.platform_link": "Link",
  "survey.platform_link_desc": "Share unique survey link with your users.",
  "survey.select_delivery_platform": "Select survey delivery platform.",
  "survey.delivery_platform_label": "Email Delivery Platform",
  "survey.delivery_platform_placeholder": "Pick one",
  "survey.delivery_platform_tip": "{platform_tag} tag will be used to parse respondent email.",
  "survey.select_language": "Select language",
  "survey.lang_english": "English",
  "survey.lang_spanish": "Spanish",
  "survey.lang_french": "French",
  "survey.lang_german": "German",
  "survey.lang_italian": "Italian",
  "survey.lang_portuguese": "Portuguese",
  "survey.lang_dutch": "Dutch",
  "survey.lang_norwegian": "Norwegian",
  "survey.lang_greek": "Greek",
  "survey.lang_hindi": "Hindi",
  "survey.introduction_label": "Introduction",
  "survey.introduction_button_label": "Button text (leads to first question)",

  "survey.open_text": "Ανοιχτό Κείμενο",
  "survey.mcq": "Ερώτηση Πολλαπλής Επιλογής",
  "survey.mcq_single": "Ερώτηση Πολλαπλής Επιλογής - Μονή Επιλογή",
  "survey.mcq_multi": "Ερώτηση Πολλαπλής Επιλογής - Πολλαπλή Επιλογή",
  "survey.nps": "Βαθμολογία Καθαρού Προωθητή (NPS)",
  "survey.rating": "Βαθμολογία",
  "survey.q_email": "Email",
  "survey.q_redirect": "Ανακατεύθυνση",
  "survey.logic_next_thankyou": "Any other response continue to Thank you.",
  "survey.logic_next_question": "Any other response continue to next question.",
  "survey.btn_add_logic": "Add Logic",
  "survey.logic_alert": "Some of the questions have skip logic conditions. Moving may cause logical error.",
  "survey.q_required": "Required",
};
