import React, { useEffect, useState } from "react";
import style from "./../../style.scss";
import { ActionIcon, Box, Tabs, Tooltip, Group, Center, Text, Badge } from "@mantine/core";
import { PreviewSurveyEmail, PreviewSurveyWeb, SurveyFlowDiagram, FlowDiagram, PreviewSurveyLink } from "./components";
import { IconRefresh } from "@tabler/icons-react";
import { logicOptions } from "../../utils/helpers";
import { isArray } from "lodash";

export default function PreviewSurvey(props) {
  const { survey, prodToken, currentQuestion, setCurrentQuestion, currentProduct } = props;
  const [activeTab, setActiveTab] = useState("Survey");
  let options = survey.survey_options;

  console.log("FlowDiagram survey.questions", survey.questions);

  function skipLogicText(logic) {
    console.log("skiplogicText1", logic);
    let logicText = logicOptions.find(l => l.value == logic.logic)?.label;
    let predicateText;

    if (isArray(logic.predicate)) {
      predicateText = logic.predicate.join(",");
    } else {
      predicateText = String(logic.predicate);
    }
    console.log("skiplogicText2", predicateText);
    return `${"&nbsp;"}Response ${logicText}${predicateText.length > 0 ? ` ${predicateText}.` : "."} `;
  }

  const surveyData = () => {
    return survey.questions.map(q => {
      return {
        id: q.order,
        text: q.question_type == 30 ? q.options.primary_button_text : q.question_text,
        type: q.question_type,
        branches:
          q.options.next_conditions &&
          q.options.next_conditions?.length > 0 &&
          q.options.next_conditions?.map(c => {
            if (c.logic.length === 0 && c.next_q === "-1") {
              return {
                nextQuestionId: q.order + 1,
                condition: " Response _______ ",
              };
            } else if (c.logic.length > 0 && c.logic != "sub" && c.predicate.length == 0 && c.next_q === "-1") {
              return {
                nextQuestionId: q.order + 1,
                condition: skipLogicText(c),
              };
            } else if (c.logic.length > 0) {
              return {
                nextQuestionId: c.next_q ? parseInt(c.next_q) : -1,
                condition: skipLogicText(c),
              };
            } else {
              return {
                nextQuestionId: -1,
                condition: c.condition ? c.condition : " Response _______ ",
              };
            }
          }),
      };
    });
  };

  const finalData = [...surveyData(), { id: -1, text: "Thank you" }];

  useEffect(() => {
    window.addEventListener("message", ev => {
      const { message, params, channel } = ev.data;
      if (channel !== "_gradian") return;

      if (message == "openLink") {
        window.open(params, "_blank");
      }
    });
  }, []);

  return (
    <Box className={style.previewContainer}>
      <Tabs
        keepMounted={false}
        value={activeTab}
        onTabChange={setActiveTab}
        variant="default"
        styles={() => ({
          tab: {
            "&:hover": {
              backgroundColor: "transparent",
            },
          },

          leftIcon: {
            marginRight: 15,
          },
        })}
      >
        <Tabs.List>
          <Tabs.Tab value="Survey">Preview</Tabs.Tab>
          <Tabs.Tab value="FlowDiagram">Flow</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="Survey">
          {/* {survey.questions.length == 0 && (
            <Box mt="30%">
              <Center>
                <Text italic color="dimmed">
                  Please add questions.
                </Text>
              </Center>
            </Box>
          )} */}
          {survey.platform === "web" && (
            <PreviewSurveyWeb
              survey={survey}
              currentQuestion={currentQuestion}
              setCurrentQuestion={setCurrentQuestion}
              currentProduct={currentProduct}
            />
          )}
          {survey.platform === "link" && (
            <PreviewSurveyLink
              survey={survey}
              currentQuestion={currentQuestion}
              setCurrentQuestion={setCurrentQuestion}
              currentProduct={currentProduct}
            />
          )}

          {survey.platform === "email" && (
            <PreviewSurveyEmail survey={survey} token={prodToken} currentQuestion={currentQuestion} />
          )}
        </Tabs.Panel>
        <Tabs.Panel value="FlowDiagram">
          {survey.questions.length > 0 ? (
            <FlowDiagram surveyData={finalData} />
          ) : (
            <Center mt="xl">
              <Text italic color="grey" size="sm">
                No questions added yet
              </Text>
            </Center>
          )}
        </Tabs.Panel>
      </Tabs>
    </Box>
  );
}
