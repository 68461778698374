import React from "react";
import { ResponsiveContainer, PieChart, Pie, Legend, Cell, Tooltip } from "recharts";
import { graphColors } from "../../../../utils/colorScheme";
import { pluralize } from "../../../../utils/helpers";
import { useIntl, FormattedMessage } from "react-intl";

export function PieGraph(props) {
  const { chartData, graphOptions } = props;
  const intl = useIntl();

  // const chartData = responseSummary
  //   .sort((a, b) => b.answer - a.answer)
  //   .map(resp => {
  //     return {
  //       name: resp['answer'],
  //       value: resp['response_count'],
  //     };
  //   });

  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const renderDataInLegend = (value, entry) => {
    //console.log('entry in LEGEND', entry);
    const { color } = entry;
    return (
      <span style={{ color }}>
        {entry.payload.answer} | {entry.payload.percentage}% |{" "}
        <FormattedMessage
          id="reports.response_count_plural"
          values={{ count: entry.payload.value }}
          defaultMessage={pluralize(entry.payload.value, "Response", "s")}
        />
      </span>
    );
  };

  //console.log('chartdata: ', chartData);

  return (
    <ResponsiveContainer width="95%" height={400}>
      <PieChart width={700} height={400}>
        <Pie
          dataKey={graphOptions["dataKey"] || "count"}
          //isAnimationActive={false}
          data={chartData}
          cx="50%"
          cy="50%"
          outerRadius="80%"
          fill="#8884d8"
          labelLine={false}
          label={renderCustomizedLabel}
        >
          {chartData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={graphColors(chartData.length)[index]} />
          ))}
        </Pie>
        {/* <Tooltip /> */}

        <Legend
          layout="vertical"
          align="right"
          iconSize={20}
          verticalAlign="middle"
          height={400}
          formatter={renderDataInLegend}
          wrapperStyle={{ marginTop: "10%" }}
        />
      </PieChart>
    </ResponsiveContainer>
  );
}
