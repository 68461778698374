import React from "react";
import { BarChart, Bar, XAxis, YAxis, Label, CartesianGrid, Tooltip, ResponsiveContainer, Cell } from "recharts";
import { graphColors } from "../../../../utils/colorScheme";
import NPSRatingTooltip from "./NPSRatingTooltip";
import { useIntl } from "react-intl";

export function BarGraph2(props) {
  const { chartData, graphOptions = { xAxisProps: { dataKey: "answer" } }, questionType, dataType } = props;
  const intl = useIntl();

  console.log("BARgraphOptions", graphOptions);
  console.log("BAR chartData", chartData);

  //console.log('response summary in BarGraph', responseSummary);

  // const chartData = responseSummary
  //   .sort((a, b) => a.answer - b.answer)
  //   .map(resp => {
  //     return {
  //       name: resp['answer'],
  //       value: resp['response_count'],
  //     };
  //   });

  //console.log('chartdata: ', chartData);

  return (
    <ResponsiveContainer width="95%" height={400}>
      <BarChart
        //isAnimationActive={false}
        width={700}
        //height={400}
        data={chartData}
        //layout={questionType == 1 ? 'vertical' : 'horizontal'}
        margin={{
          top: 30,
          right: 30,
          left: 20,
          bottom: 60,
        }}
      >
        <CartesianGrid horizontal={false} strokeDasharray="1 1" />

        {/* <Legend verticalAlign="top" wrapperStyle={{ lineHeight: '40px' }} /> */}
        <YAxis interval="preserveStartEnd" {...graphOptions.yAxisProps} />

        {questionType === 4 || questionType === 5 ? (
          dataType == "npsRating" ? (
            <>
              <Tooltip content={<NPSRatingTooltip />} cursor={false} />
              <XAxis {...graphOptions.xAxisProps}>
                <Label
                  value={intl.formatMessage({ id: "reports.rating", defaultMessage: "Rating" })}
                  offset={-20}
                  position="insideBottom"
                  fontSize={14}
                />
              </XAxis>
              <Bar
                dataKey="count"
                barSize={40}
                radius={[6, 6, 0, 0]}
                label={{ position: "top", fontSize: 14, fill: "#000" }}
                animationDuration={100}
                isAnimationActive={false}
              >
                {chartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={graphOptions.cellFill(entry.answer)} />
                ))}
                {/* <LabelList dataKey="value" position="top" fontSize={14} fill="#000" /> */}
              </Bar>
            </>
          ) : (
            <>
              <XAxis {...graphOptions.xAxisProps} />
              <Bar
                dataKey="high"
                stackId="a"
                fill="#82ca9d"
                label={{ position: "center", fontSize: 14 }}
                radius={[6, 6, 0, 0]}
                animationDuration={100}
                isAnimationActive={false}
              />
              <Bar dataKey="low" stackId="a" fill="#ff5b43" />
              <Bar dataKey="neutral" stackId="a" fill="#CED4DA" stroke="#CED4DA" strokewidth={0.5} />
            </>
          )
        ) : questionType == 1 ? (
          <>
            <XAxis {...graphOptions.xAxisProps} />

            <Bar dataKey="count" label={{ position: "top", fontSize: 14 }} radius={[6, 6, 0, 0]} barSize={20}>
              {chartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  //fill="#CED4DA"
                  fill={graphColors(chartData.length)[index]}
                  stroke={graphColors(chartData.length)[index]}
                  strokeWidth={1}
                />
              ))}
            </Bar>
          </>
        ) : (
          <>
            <XAxis {...graphOptions.xAxisProps} />
            <Bar dataKey="count" label={{ position: "top", fontSize: 14 }} radius={[6, 6, 0, 0]}>
              {chartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  stackId="a"
                  fill={graphColors(chartData.length)[index]}
                  stroke={graphColors(chartData.length)[index]}
                  strokeWidth={1}
                />
              ))}
            </Bar>
          </>
        )}
      </BarChart>
    </ResponsiveContainer>
  );
}

// function NPSRatingLabel(props) {
//   const { x, y, stroke, value } = props;
//   return (
//     <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
//       {value}
//     </text>
//   );
// }
