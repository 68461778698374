export const embedEmailContent = (firstQuestion, surveyURL, userTag) => {
  console.log("!! firstQuestion", firstQuestion);
  switch (firstQuestion?.question_type) {
    case 4:
      return npsEmailContent(firstQuestion, surveyURL, userTag);
    case 2:
      return mcqSingleChoiceEmailContent(firstQuestion, surveyURL, userTag);
    case 5:
      return createRatingEmail(firstQuestion, surveyURL, userTag);
    default:
      break;
  }
};

const createRatingEmail = (firstQuestion, surveyEndpoint, userTag) => {
  const cellColor = "#ffffff";
  const bkgColor = "#ffffff";

  const scale = firstQuestion.options.rating_scale;
  const range = firstQuestion.options.rating_range;
  const question = firstQuestion.question_text;
  const minText = firstQuestion.options.min_text;
  const maxText = firstQuestion.options.max_text;

  // Common styles
  const baseStyles = {
    container: `max-width: 600px; width: 100%; margin: auto;border-radius: 4px;border: 1px solid #ccc;`,
    question: `font-size: 18px; margin: 0px 0;`,
    cell: `text-align: center; padding: 5px; width: ${100 / range}%;`,
    link: `text-decoration: none; display: block; margin: 0 auto; 
           background-color: ${cellColor}; color: #333; border-radius: 4px; 
           border: 1px solid #ccc; border-bottom: 3px solid #ccc; 
           text-align: center; font-weight: bold; padding: 8px 0;`,

    starlink: `text-decoration: none; display: block; margin: 0 auto;
             background-color: ${cellColor}; color: #333; 
             text-align: center; font-weight: bold; padding: 8px 0;`,
    emojilink: `text-decoration: none; font-size: 36px; display: block; margin: 0 auto;
             background-color: ${cellColor}; color: #333; 
             text-align: center; font-weight: bold; padding: 8px 0;`,
    label: `font-size: 12px;`,
    star: `font-size: 50px; display: block; margin-bottom: 4px; color: #FFD700;`,
    number: `font-size: 14px; color: #666;`,
  };

  // Rating display options
  const ratingDisplays = {
    numerical: i => i,
    star: i => `
    <a href="${surveyEndpoint}?resp=${i}${userTag ? `&${userTag}` : ""}"
       style="${baseStyles.starlink}"
       aria-label="Rating ${i} out of ${range}">
      <span style="${baseStyles.star}">⭐️</span>
      <span style="${baseStyles.number}">${i}</span>
    </a>
  `,
    emoji: i => {
      const emojis = range === 3 ? ["😟", "😐", "😊"] : ["😡", "😟", "😐", "😊", "😍"];
      return emojis[i - 1];
    },
  };

  // Generate cells for the rating options

  const generateCells = () => {
    return Array.from({ length: range }, (_, i) => i + 1)
      .map(num => {
        const display = ratingDisplays[scale](num);
        return `
          <td style="${baseStyles.cell}">
            ${
              scale === "star"
                ? display
                : `
              <a href="${surveyEndpoint}?resp=${num}${userTag ? `&${userTag}` : ""}"
                 style="${scale === "numerical" ? baseStyles.link : baseStyles.emojilink}"
                 aria-label="Rating ${num} out of ${range}">
                ${display}
              </a>
            `
            }
          </td>
        `;
      })
      .join("");
  };

  // Construct the complete HTML
  const html = `
    <table width="100%" cellpadding="0" cellspacing="0" bgcolor="${bkgColor}" style="${baseStyles.container}">
      <tr>
        <td align="center">
          <table width="100%" border="0" cellspacing="0" cellpadding="0">
            <tr>
              <td align="left" style="background-color: tranparent; padding: 20px;">
                <p style="${baseStyles.question}">${question}</p>
                
                <table cellspacing="0" cellpadding="0" border="0" style="margin: 0 auto; width: 100%;">
                  <tr>${generateCells()}</tr>
                </table>
                <table border="0" cellspacing="0" cellpadding="0" width="100%">
                  <tr>
                    <td align="left" style="${baseStyles.label}">${minText}</td>
                    <td align="right" style="${baseStyles.label}">${maxText}</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  `;

  return html.replace(/\s+/g, " ").trim();
};

const npsEmailContent = (firstQuestion, surveyEndpoint, userTag) => {
  const cellColor = "#ffffff";
  const bkgColor = "#ffffff";

  const linkStyle = `text-decoration: none; display: block; margin: 0 auto; background-color: ${cellColor}; color: #333; border-radius: 4px; border: 1px solid #ccc; border-bottom: 3px solid #ccc; text-align: center; font-weight: bold; padding: 6px 0; max-width: 60px;`;
  const cellStyle = `width: 9%; text-align: center; padding: 5px;`;

  const cellHTML = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].flatMap(num => {
    return `<td style="${cellStyle}"><a aria-label="${num}" href="${surveyEndpoint}?resp=${num}${
      userTag.length > 0 ? `&${userTag}` : ""
    }" style="${linkStyle}">${num}</a></td>`;
  });

  const actualHTML = `<table width="100%" cellpadding="0" cellspacing="0" bgcolor="#ffffff" style="max-width: 600px; width: 100%; margin: auto;"><tr><td align="center"><table width="100%" border="0" cellspacing="0" cellpadding="0">
<tr><td align="left" style="background-color: ${bkgColor}; padding: 20px;">
<p style="font-size: 18px; margin: 20px 0;">${firstQuestion ? firstQuestion.question_text : ""}</p>
<table cellspacing="0" cellpadding="0" border="0" style="margin: 0 auto; width: 100%;"><tr>
${cellHTML.join("")}
</tr></table><table border="0" cellspacing="0" cellpadding="0" width="100%"><tr>
<td align="left" style="font-size: 12px;">${firstQuestion ? firstQuestion.options.min_text : ""}</td>
<td align="right" style="font-size: 12px;">${firstQuestion ? firstQuestion.options.max_text : ""}</td>
</tr></table></td></tr></table></td></tr></table>`;

  return actualHTML;
};

const mcqSingleChoiceEmailContent = (firstQuestion, surveyEndpoint, userTag) => {
  if (firstQuestion.choices.length > 2) {
    const choiceHTML = firstQuestion.choices.flatMap(choice => {
      return `<p style="margin-bottom:10px;"><a href="${surveyEndpoint}?resp=${encodeURIComponent(choice.choice)}&cid=${
        choice.id
      }${
        userTag.length > 0 ? `&${userTag}` : ""
      }" style="text-decoration:none; background-color:#fff; padding:10px 15px; display:inline-block; width:100%; max-width:300px; text-align:center;color: #333; border-radius: 4px; border: 1px solid #ccc; border-bottom: 3px solid #ccc;">${
        choice.choice
      }</a></p>`;
    });

    return `<table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:600px; background-color:#ffffff; margin-top:30px;"><tr><td style="padding:20px; text-align:center;"><p style="font-size:18px; margin-bottom:30px;"><b>${
      firstQuestion.question_text
    }</b></p>${choiceHTML.join("")}</td></tr></table>`;
  } else {
    return `<table width="100%" cellpadding="0" cellspacing="0" border="0" style="font-family: Arial, sans-serif;"><tr>
    <td align="center"><table cellpadding="0" cellspacing="0" border="0" style="background-color: #ffffff; padding: 20px; text-align: center; ">
    <tr><td><p style="font-size: 18px; margin-bottom: 30px;"><b>${
      firstQuestion.question_text
    }</b></p><table cellpadding="0" cellspacing="0" border="0" style="margin: auto;margin-bottom:20px;">
    <tr><td style="padding: 0 10px;"><a href="${surveyEndpoint}?resp=${encodeURIComponent(
      firstQuestion.choices[0].choice
    )}&cid=${firstQuestion.choices[0].id}${
      userTag.length > 0 ? `&${userTag}` : ""
    }" style="background-color: #fff; color: #333; padding: 10px 20px; text-decoration: none; border-radius: 4px; font-size: 16px;border: 1px solid #ccc; border-bottom: 3px solid #ccc;">${
      firstQuestion.choices[0].choice
    }</a>
    </td><td style="padding: 0 10px;"><a href="${surveyEndpoint}?resp=${encodeURIComponent(
      firstQuestion.choices[1].choice
    )}&cid=${firstQuestion.choices[1].id}${
      userTag.length > 0 ? `&${userTag}` : ""
    }" style="background-color: #fff; color: #333;padding: 10px 20px; text-decoration: none; border-radius: 4px; font-size: 16px;border: 1px solid #ccc; border-bottom: 3px solid #ccc;">${
      firstQuestion.choices[1].choice
    }</a>
    </td></tr></table></td></tr></table></td></tr></table>`;
  }
};
