//import thin from './../fonts/OpenSans/OpenSans-Thin.ttf';

import light from './OpenSans/OpenSans-Light.ttf';
import regular from './OpenSans/OpenSans-Regular.ttf';
import medium from './OpenSans/OpenSans-Medium.ttf';
import semibold from './OpenSans/OpenSans-SemiBold.ttf';
import bold from './OpenSans/OpenSans-Bold.ttf';
import extrabold from './OpenSans/OpenSans-ExtraBold.ttf';
import italic from './OpenSans/OpenSans-Italic.ttf';

export const OpenSansFont = [
  {
    '@font-face': {
      fontFamily: 'Open Sans',
      src: `url('${light}') format('truetype')`,
      fontWeight: 300,
      fontStyle: 'normal',
    },
  },
  {
    '@font-face': {
      fontFamily: 'Open Sans',
      src: `url('${regular}') format('truetype')`,
      fontWeight: 400,
      fontStyle: 'normal',
    },
  },
  {
    '@font-face': {
      fontFamily: 'Open Sans',
      src: `url('${italic}') format('truetype')`,
      fontWeight: 500,
      fontStyle: 'italic',
    },
  },
  {
    '@font-face': {
      fontFamily: 'Open Sans',
      src: `url('${medium}') format('truetype')`,
      fontWeight: 500,
      fontStyle: 'normal',
    },
  },
  {
    '@font-face': {
      fontFamily: 'Open Sans',
      src: `url('${bold}') format('truetype')`,
      fontWeight: 700,
      fontStyle: 'normal',
    },
  },
  {
    '@font-face': {
      fontFamily: 'Open Sans',
      src: `url('${semibold}') format('truetype')`,
      fontWeight: 600,
      fontStyle: 'normal',
    },
  },
  {
    '@font-face': {
      fontFamily: 'Open Sans',
      src: `url('${extrabold}') format('truetype')`,
      fontWeight: 800,
      fontStyle: 'normal',
    },
  },
];
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="68818ade-d96b-5cad-b01e-3154c09dda0b")}catch(e){}}();
//# debugId=68818ade-d96b-5cad-b01e-3154c09dda0b
