export const es = {
  //-----------------Common Words--------------------
  "common.save": "Save",
  "common.cancel": "Cancel",
  "common.apply": "Apply",
  "common.delete": "Delete",
  "common.duplicate": "Duplicate",
  "common.edit": "Edit",
  "common.add": "Add",
  "common.remove": "Remove",
  "common.yes": "Yes",
  "common.no": "No",
  "common.next": "Next",
  "common.back": "Back",
  "common.done": "Done",
  "common.close": "Close",
  "common.upload": "Upload",
  "common.upload_logo": "Upload Logo",
  "common.email": "Email",
  "common.name": "Name",
  "common.full_name": "Full Name",
  "common.status": "Status",
  "common.loading_error": "Something went wrong. Please try again.",
  "common.loading": "Loading...",
  "common.success": "Success",
  "common.error": "Error",
  "common.copied": "Copied",
  "common.copy": "Copy",
  "common.questions": "Questions",

  //-----------------Nav--------------------
  "nav.new_survey": "New Survey",
  "nav.survey_list": "Survey List",
  "nav.user_attributes": "User Attributes",
  "nav.connect": "Connect",
  "nav.account": "Account",
  "nav.organization": "Organization",
  "nav.team": "Team",
  "nav.settings": "Settings",
  "nav.logout": "Logout",
  "nav.help": "Help",
  "nav.integrations": "Integrations",
  "nav.sites": "Sites",
  //-----------------Login Page----------------------------
  "login_page.heading": "Login",
  "login_page.subheading": "Welcome back! Please login to your account.",
  "login_page.email": "Email",
  "login_page.password": "Password",
  "login_page.forgot_password": "Forgot Password?",
  "login_page.login_button": "Login",
  "login_page.no_account": "Don't have an account?",
  "login_page.signup": "Sign up",
  //-----------------Signup Page---------------------------
  "signup_page.heading": "Sign Up",
  "signup_page.subheading": "Create an account to get started.",
  "signup_page.email": "Email",
  "signup_page.password": "Password",
  "signup_page.signup_button": "Sign Up",
  "signup_page.already_account": "Already have an account?",
  "signup_page.login": "Login",
  //-----------------Forgot Password Page-------------------
  "forgot_password_page.heading": "Forgot Password",
  "forgot_password_page.subheading": "Enter your email to reset your password.",
  "forgot_password_page.email": "Email",
  "forgot_password_page.reset_password_button": "Reset Password",
  "forgot_password_page.back_to_login": "Back to Login",
  //-----------------Reset Password Page--------------------
  "reset_password_page.heading": "Reset Password",
  "reset_password_page.subheading": "Enter your new password.",
  "reset_password_page.password": "Password",
  "reset_password_page.confirm_password": "Confirm Password",
  "reset_password_page.reset_password_button": "Reset Password",
  "reset_password_page.back_to_login": "Back to Login",
  //-----------------Email Verification Page----------------
  "email_verification_page.heading": "Email Verification",
  "email_verification_page.subheading": "Please verify your email.",
  "email_verification_page.verify_button": "Verify Email",
  //-----------------Dashboard Page-----------------------
  "dashboard_page.heading": "Dashboard",
  "dashboard_page.subheading": "Welcome to Spark!",
  "dashboard_page.survey": "Survey",
  "dashboard_page.surveys": "Surveys",
  "dashboard_page.respondents": "Respondents",
  "dashboard_page.responses": "Responses",
  "dashboard_page.view_all": "View all",
  //-----------------Survey Page--------------------------
  "survey_page.heading": "Survey",
  "survey_page.subheading": "Create your survey by adding questions.",

  //-----------------New Survey Page--------------------
  "new_survey_page.heading": "New Survey",
  "new_survey_page.subheading": "Use a template from below or create from scratch.",
  "new_survey_page.create_button": "Create Survey from scratch",
  //-----------------Templates
  "templates.nps": "NPS",
  "templates.nps_desc": "How happy are your customers with the offering?",
  "templates.feature_launch": "Feature Launch",
  "templates.feature_launch_desc": "Ideal for assessing impact of newly launched features.",
  "templates.pmf": "Product Market Fit",
  "templates.pmf_desc": "Have you reached the fit?",
  "templates.feature_discovery": "Feature Discovery",
  "templates.feature_discovery_desc": "Understand what features are most important to your users.",
  "templates.csat": "CSAT",
  "templates.csat_desc": "Measure how satisfied are your customers with your product or service?",
  //-----------------Survey Listing Page--------------------
  "survey_list_page.heading": "Surveys",

  "survey_list_page.no_content_heading": "Understand <mark>why</mark> of your users' decisions",
  "survey_list_page.no_content_subheading": "Launch a in-product survey and start getting insights.",
  "survey_list_page.no_content_cta": "New Survey",
  "survey_list_page.no_content_hero_alt": "Survey question creation form",
  "survey_list_page.started_on": "Started on {date}",
  "survey_list_page.ended_on": "Ended on {date}",

  //-----------------User Attributes Page--------------------
  "user_attributes_page.heading": "User Attributes",
  "user_attributes_page.attribute": "Attribute",
  "user_attributes_page.type": "Type",
  "user_attributes_page.last_received": "Last Received",
  "user_attributes_page.month": "month",
  "user_attributes_page.ago": "ago",
  "user_attributes_page.months": "months",
  "user_attributes_page.string": "String",
  "user_attributes_page.boolean": "Boolean",
  "user_attributes_page.number": "Number",
  "user_attributes_page.date": "Date",
  "user_attributes_page.no_data_heading": "Reach out to relevant users",
  "user_attributes_page.no_data_subheading":
    "User attributes are additional data points that you already have about your users. You can send this information to FeedbackSpark to show your survey to a specific segment of users, for e.g. users who purchased in last 60 days.",
  //-----------------Connect Page--------------------
  "connect_page.heading": "Connect",
  "connect_page.html_help_text":
    "Copy the code below and paste it between the <mark>{html_tag}</mark> tags on all the pages where you want to show the survey.",
  "connect_page.npm": "NPM",
  "connect_page.Yarn": "Yarn",
  "connect_page.npm_initialize_spark": "Next in your application, as early as possible, initialize Spark.",
  "connect_page.recommeneded": "Recommended",
  //-----------------Organization Page--------------------
  "org_page.heading": "Organization",

  "org_page.website": "Website",
  "org_page.api_key": "API Key",
  "org_page.view_code": "View installation code",
  "org_page.logo": "Logo",
  "org_page.logo_desc": "This logo will be displayed in the survey page.",
  "org_page.upload_logo_btn": "Upload Logo",
  //-----------------Teams Page--------------------
  "team_page.heading": "Team",
  "team_page.access": "Access",
  "team_page.date_joined": "Date Joined",
  "team_page.members": "Members",
  "team_page.add_member_btn": "Add Member",
  "team_page.add_team_member": "Add Team Member",
  "team_page.access_level": "Access Level",
  "team_page.access_level_placeholder": "Select role",
  "team_page.access_viewer": "Viewer",
  "team_page.access_editor": "Editor",
  "team_page.you": "You",
  "team_page.role": "Role",
  "team_page.invited": "Invited",
  "team_page.accepted": "Accepted",
  "team_page.rejected": "Rejected",
  "team_page.resend_invite": "Resend Invite",
  "team_page.cancel_invite": "Cancel Invite",
  "team_page.remove_member": "Remove Member",
  //-----------------Settings Page--------------------

  "settings_page.heading": "Settings",
  "settings_page.product": "Product",
  "settings_page.product_desc": "Name is used to automatically fill in survey templates.",
  "settings_page.website": "Website",
  "settings_page.timezone": "Timezone",
  "settings_page.appearance": "Appearance",
  "settings_page.appearance_desc": "Customize the look and feel of the survey.",
  "settings_page.background": "Background",
  "settings_page.background_desc": "Background color for the survey.",
  "settings_page.font_color": "Font Color",
  "settings_page.font_color_desc": "Question & options font color.",
  "settings_page.button": "Button",
  "settings_page.button_desc": "Call to action button background color.",
  "settings_page.button_font_color": "Button Font Color",
  "settings_page.button_font_color_desc": "Call to action button font color.",
  "settings_page.border": "Border",
  "settings_page.border_desc": "Widget and input field border color",
  "settings_page.reconnect_period": "Reconnect Period",
  "settings_page.reconnect_period_desc":
    "Minimum number of days before same user is shown their next survey. Defaults to 7 days.",
  "settings_page.sample_question": "Sample Question Text",
  "settings_page.option1": "Option 1",
  "settings_page.option2": "Option 2",
  "settings_page.i_think": "I think...",
  "settings_page.done": "Done",
  "settings_page.powered_by": "Powered by",
  //-----------------Notifications--------------------
  "notif.sorry": "Sorry",

  //-----------------Reports--------------------
  "reports.report_tab": "Report",
  "reports.responses_tab": "Responses",
  "reports.filter": "Filter",
  "reports.custom": "Custom",
  "reports.today": "Today",
  "reports.yesterday": "Yesterday",
  "reports.download_csv": "Download CSV",
  "reports.survey_link": "Survey Link",
  "reports.copied_html": "Copied HTML",
  "reports.copy_html": "Copy HTML",
  "reports.unique_views": "Unique Views",
  "reports.answered": "Answered",
  "reports.completed": "Completed",
  "reports.response_rate": "Response Rate",
  "reports.completion_rate": "Completion Rate",
  "reports.most_responses_from": "Most responses from",
  "reports.view_more": "View more",
  "reports.not_set": "Not set",
  "reports.hide_filter": "Hide Filter",
  "reports.custom_date": "Custom",
  "reports.filter_start_date": "Start Date",
  "reports.filter_end_date": "End Date",
  "reports.all_filters": "Todos los filtros",
  "reports.filter_by": "Filtrar por",
  "reports.day": "Día",
  "reports.week": "Semana",
  "reports.month": "Mes",
  "reports.response_count": "Número de respuestas",
  "reports.rating": "Calificación",
  "reports.response_count_plural": "{count, plural, one {# Respuesta} other {# Respuestas}}",
  "reports.nps_promoters": "Promotores",
  "reports.nps_passives": "Pasivos",
  "reports.nps_detractors": "Detractores",
  "reports.total": "Total",
  "reports.rating_positive": "Positivo",
  "reports.rating_negative": "Negativo",
  "reports.rating_neutral": "Neutral",

  //-----------------Filters--------------------
  "filters.filter": "Filter",

  "filters.response": "Response",
  "filters.country": "Country",
  "filters.clear": "Clear",
  "filters.response_to": "Response to",
  "filters.select_question": "Select Question",
  "filters.apply": "Apply Filters",
  "filters.equals": "Equals",
  "filters.select_country": "Select country",
  "filters.country_equals": "Country Equals",

  //-----------------Surveys General--------------------
  "survey.live": "Live",
  "survey.in_draft": "In Draft",
  "survey.pause": "Pause",
  "survey.paused": "Paused",
  "survey.completed": "Completed",
  "survey.archive": "Archive",
  "survey.archived": "Archived",
  "survey.mark_complete": "Mark Complete",
  "survey.launch": "Launch",
  "survey.draft": "Draft",
};
