import { QUESTION_TYPE_STRING_TO_ID } from "../utils/constants";
import { removeKeyFromObject } from "../utils/helpers";
import { nanoid } from "nanoid";
import { getFromLocalStorage } from "../utils/helpers";

export function getTemplateFromSlug(slug, productName, surveyData = null) {
  switch (slug) {
    case "nps":
      return nps(productName);
    case "pmf":
      return pmf(productName);
    case "featurelaunch":
      return featurelaunch;
    case "whatnext":
      return whatnext(productName);
    case "csat":
      return csat(productName);
    case "viaCopy":
      return viaCopy(surveyData);
    case "blank":
      return blank;
    default:
      return blank;
  }
}

export const templateList = [
  {
    slug: "nps",
    title: "NPS",
    description: "How happy are your customers with the offering?",
    badge: "Discovery",
    templateId: 1,
  },
  {
    slug: "featurelaunch",
    title: "Feature Launch",
    description: "Ideal for assessing impact of newly launched features.",
    badge: "Feature",
    templateId: 2,
  },
  {
    slug: "pmf",
    title: "Product Market Fit",
    description: "Have you reached the fit?",
    badge: "Launch",
    templateId: 3,
  },
  {
    slug: "whatnext",
    title: "Feature Discovery",
    description: "What should we build next?",
    badge: "Discovery",
    templateId: 4,
  },
  {
    slug: "csat",
    title: "CSAT",
    description: "Measure how happy your customers are with your product or service",
    badge: "Discovery",
    templateId: 5,
  },
];

const baseState = {
  contentVersion: 1, // were survey questions edited before
  platform: "web",
  environment: getFromLocalStorage("FSEnv") || "prod",
  languageCode: "en",
  surveyOptions: {
    //showIntroduction: false,
    // user_identifier_key: "email",
    //allow_multiple_responses: true,
    // introduction: {
    //   content:
    //     "<h2 style='text-align: center'>Can you help us?</h2><p style='text-align: center'>We are conducting a small study and need your inputs. </p><p style='text-align: center'>It won't take more than <mark>a minute</mark> of your time.</p>",
    //   primaryButtonText: "Let's, do it",
    //   //primaryButtonLink: '',
    //   //secondaryButtonText: 'May be later',
    //   //secondaryButtonLink: '',
    // },
    thankyou: {
      heading: "Thank you for your time.",
      subheading: "We appreciate your feedback.",
    },
    // days after which survey can be shown again after user declines. -1 means never.

    triggerCondition: {
      pages: "all", //'specific'
      eventType: "pageLoad", // abandon | halfScroll | textClick | cssClick,
      waitFor: 0,
      //innerText: '',
      //cssSelector: '',
      //urlToMatch: '', // value to match
      //matchCriteria: '', // regex to match
    },
    // branding: {
    //   borderColor: '#000000',
    //   background: '#ffffff',
    //   buttonColor: '#000000',
    //   buttonFontColor: '#ffffff',
    // },
    position: {
      widgetPosition: "bottom_right",
      widgetType: "popover", //OR Fullscreen
    },
  },
};

const nps = productName => {
  return {
    ...baseState,
    title: "My NPS Survey",
    templateId: 1,
    questions: [
      {
        questionType: QUESTION_TYPE_STRING_TO_ID["nps"],
        questionText: `How likely is it that you would recommend ${productName} to a friend or colleague?`,
        questionClientId: nanoid(),
        options: {
          ratingScale: "numerical",
          ratingRange: 10,
          minText: "Not at all Likely",
          maxText: "Extremely Likely",
          randomizeChoices: false,
          next_conditions: [
            { logic: "gte", predicate: 9, next_q: 1 },
            { logic: "lte", predicate: 6, next_q: 2 },
          ],
        },

        isRequired: true,
        order: 0,
      },
      {
        questionType: 1,
        questionClientId: nanoid(),
        questionText:
          "Thank you! Can you tell us what you love about our product and what we can do to continue providing an exceptional experience?",
        options: {
          randomize_choices: false,
          next_conditions: [{ logic: "sub", predicate: [], next_q: -1 }],
        },
        isRequired: true,
        order: 1,
      },
      {
        questionType: 1,
        questionClientId: nanoid(),
        questionText: "Is there anything specific that we can do to improve your experience?",
        options: { randomize_choices: false, next_conditions: [{ logic: "sub", predicate: [], next_q: -1 }] },
        isRequired: true,
        order: 2,
      },
    ],
  };
};

const featurelaunch = {
  ...baseState,
  title: "My new feature launch Survey",
  templateId: 2,
  questions: [
    {
      questionType: QUESTION_TYPE_STRING_TO_ID["rating"],
      questionClientId: nanoid(),
      questionText: `How will you rate this feature on a scale of 1 to 5? `,
      options: {
        minText: "Low",
        maxText: "High",
        ratingScale: "numerical",
        ratingRange: 5,
        randomizeChoices: false,
      },
      isRequired: true,
      order: 0,
    },
  ],
};

const pmf = productName => {
  return {
    ...baseState,
    title: "My PMF Survey",
    templateId: 3,
    questions: [
      {
        questionType: 2,
        questionClientId: nanoid(),
        questionText: `How would you feel if you could no longer use ${productName}?`,
        options: {
          randomizeChoices: false,
        },
        choices: [
          {
            choice: "Very disappointed",
          },
          {
            choice: "Somewhat disappointed",
          },
          {
            choice: "Not disappointed (it isn't really that useful)",
          },
        ],
        isRequired: true,
        order: 0,
      },
      {
        questionType: 1,
        questionClientId: nanoid(),
        questionText: `What is your primary use for ${productName}?`,
        options: { randomize_choices: false },
        isRequired: true,
        order: 1,
      },
      {
        questionType: 1,
        questionClientId: nanoid(),
        questionText: `What is the main benefit ${productName} provides to you?`,
        options: { randomize_choices: false },
        isRequired: true,
        order: 2,
      },
    ],
  };
};

const csat = productName => {
  return {
    ...baseState,
    title: "My CSAT Survey",
    templateId: 5,
    questions: [
      {
        questionType: QUESTION_TYPE_STRING_TO_ID["rating"],
        questionClientId: nanoid(),
        questionText: `How satisfied are you with your experience with ${productName}?`,
        options: {
          minText: "Very unsatisfied",
          ratingScale: "numerical",
          ratingRange: 5,
          maxText: "Very satisfied",
          randomizeChoices: false,
          next_conditions: [
            { logic: "gte", predicate: 4, next_q: 1 },
            { logic: "lte", predicate: 3, next_q: 2 },
          ],
        },
        isRequired: true,
        order: 0,
      },
      {
        questionType: 1,
        questionClientId: nanoid(),
        questionText: `Great! What is the main benefit ${productName} provides to you?`,
        options: { randomize_choices: false, next_conditions: [{ logic: "sub", predicate: [], next_q: -1 }] },
        isRequired: true,
        order: 1,
      },
      {
        questionType: 1,
        questionClientId: nanoid(),
        questionText: `Sorry to hear that! What can we do to improve your experience?`,
        options: { randomize_choices: false, next_conditions: [{ logic: "sub", predicate: [], next_q: -1 }] },
        isRequired: true,
        order: 2,
      },
    ],
  };
};

const whatnext = productName => {
  return {
    ...baseState,
    title: "My Product Discovery Survey",
    templateId: 4,
    questions: [
      {
        questionType: 2,
        questionClientId: nanoid(),
        questionText: `What's the one thing we could do to make ${productName} better?`,
        options: {
          randomizeChoices: false,
        },
        choices: [
          {
            choice: "Feature 1",
          },
          {
            choice: "Feature 2",
          },
          {
            choice: "Nothing, I like it as it is.",
          },
        ],
        isRequired: true,
        order: 0,
      },
    ],
  };
};

const blank = {
  ...baseState,
  title: "My blank survey",
  questions: [],
};

const viaCopy = data => {
  return {
    ...baseState,
    title: data.title,
    platform: data.platform,
    surveyOptions: data.survey_options,
    questions: removeKeyFromObject(data.questions, "id"),
  };
};
