import React from "react";

import { Anchor } from "@mantine/core";

export default function TextLink(props) {
  const { handleClick, text, fontSize, fontColor } = props;
  return (
    <Anchor
      variant="link"
      sx={{ cursor: "pointer" }}
      underline={false}
      size={fontSize || 14}
      color={fontColor}
      onClick={handleClick}
    >
      {text}
    </Anchor>
  );
}
