import React, { useEffect, useRef } from "react";
import mermaid from "mermaid";

export const FlowDiagram = ({ surveyData }) => {
  const chartRef = useRef(null);

  console.log("flowDiagram surveyData", surveyData);

  function generateChart() {
    let flowchart = "graph TD;\n";

    // Add Start section
    flowchart += "  Start(Start);\n";
    flowchart += `  Start --> Q${surveyData[0].id};\n`;
    const thankYouNode = surveyData.find(question => question.id === -1);

    for (const question of surveyData) {
      if (question.id === -1) {
        continue; // Skip the "Thank you" node in the loop
      }

      const questionId = `Q${question.id}`;
      const words = question.text.replace(/"/g, '\\"').split(" ");
      const chunkSize = 5;
      const chunks = [];

      for (let i = 0; i < words.length; i += chunkSize) {
        const chunk = words.slice(i, i + chunkSize).join(" ");
        chunks.push(chunk);
      }

      const wrappedText = chunks.join("<br>");
      flowchart += `  ${questionId}["${wrappedText}"];\n`;

      if (question.branches && question.branches.length > 0) {
        let nextQuestionId;
        for (const branch of question.branches) {
          nextQuestionId =
            branch.nextQuestionId === -1 || surveyData.length - 1 == branch.nextQuestionId
              ? "ThankYou"
              : `Q${branch.nextQuestionId}`;
          const condition = branch.condition;
          flowchart += `  ${questionId} -- ${condition} --> ${nextQuestionId};\n`;
        }
        const hasRemainingValues = [2, 3, 4, 5].includes(question.type); // Assuming a scale of 0-10

        if (hasRemainingValues) {
          const nextQuestion = surveyData.find(q => q.id === question.id + 1);
          if (nextQuestion) {
            flowchart += `  ${questionId} -- Any other response (if any) --> Q${nextQuestion.id};\n`;
          } else {
            flowchart += `  ${questionId} -- Any other response (if any) --> ThankYou;\n`;
          }
        }
      } else {
        // If the question has no branches, check if there is a next question
        const nextQuestion = surveyData.find(q => q.id === question.id + 1);
        if (nextQuestion) {
          flowchart += `  ${questionId} --> Q${nextQuestion.id};\n`;
        } else {
          // If there is no next question, direct the flow to the "Thank you" node
          flowchart += `  ${questionId} --> ThankYou;\n`;
        }
      }
    }

    if (thankYouNode) {
      const thankYouText = thankYouNode.text.replace(/"/g, '\\"');
      flowchart += `  ThankYou["${thankYouText}"];\n`;
      flowchart += "  End(End);\n";
      flowchart += "  ThankYou --> End;\n";
    }

    return flowchart;
  }

  useEffect(() => {
    mermaid.initialize({
      startOnLoad: true,
      htmlLabels: true,
    });
    mermaid.contentLoaded();
    const chart = generateChart();

    if (chartRef.current) {
      chartRef.current.innerHTML = `<div class="mermaid">${chart}</div>`;
      mermaid.contentLoaded();
    }
  }, [surveyData]);

  return <pre ref={chartRef} id="survey-flowchart" className="mermaid"></pre>;
};
