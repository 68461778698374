import React, { useState } from "react";
import { Box, MultiSelect, Group } from "@mantine/core";
import countryList from "../../data/countryList";
import { useIntl } from "react-intl";

export function FilterCountry(props) {
  const { index, filter, dispatch, searchParams } = props;
  const intl = useIntl();

  const [opened, setOpened] = useState(searchParams.get("filterby_country") ? false : true);

  //const { classes } = useStyles();

  function handleFilterChange(value) {
    dispatch({ type: "updateCountryFilter", value: value, index: index });
  }

  return (
    <Box>
      <Group sx={{ flexGrow: 1 }} position="apart">
        <MultiSelect
          value={filter.code}
          onChange={handleFilterChange}
          dropdownPosition="top"
          data={countryList}
          label={intl.formatMessage({ id: "filters.country_equals" })}
          placeholder={intl.formatMessage({ id: "filters.slect_country" })}
          searchable
          styles={theme => ({
            label: {
              fontSize: 14,
              marginBottom: 8,
              color: theme.colors.gray[6],
            },
          })}
        />
      </Group>
    </Box>
  );
}
