import update from "immutability-helper";

export const WebhookReducer = (WebhookState, action) => {
  switch (action.type) {
    case "ADD_WEBHOOK":
      return update(WebhookState, {
        $push: [
          {
            destinationUrl: "",
            securityToken: "",
          },
        ],
      });
    case "REMOVE_WEBHOOK":
      return update(WebhookState, {
        $splice: [[action.payload, 1]],
      });
    case "UPDATE_WEBHOOK":
      return update(WebhookState, {
        [action.payload.index]: {
          [action.payload.field]: { $set: action.payload.value },
        },
      });

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
