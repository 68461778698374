import React, { useState, useEffect } from "react";
import { fetchSurveyQuestionResponseData } from "../../../../../api/Survey";
import { pluralize, formatTimeTick, createArrayRange } from "../../../../../utils/helpers";
import { NPSColors } from "../../../../../utils/colorScheme";
import { BarGraph2, LineGraph2, PieGraph } from "../../charts";
import {
  Text,
  Center,
  Loader,
  Grid,
  RingProgress,
  Flex,
  Card,
  List,
  Space,
  ThemeIcon,
  Group,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import NoData from "../NoData";
import ChartSegmentControl from "../../ChartSegmentControl";
import TimeSegment from "../TimeSegment";
import { IconInfoCircle } from "@tabler/icons-react";
import { FormattedMessage, useIntl } from "react-intl";
import { ratingIconMap as iconMap } from "../../../../../utils/constants";

export function RatingReport(props) {
  const {
    questionId,
    surveyId,
    graphType,
    setGraphType,
    dateRange,
    ratingScale,
    ratingRange,
    questionType,
    searchParams,
  } = props;
  console.log("ratingScale", ratingScale);
  const [responseData, setResponseData] = useState();
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState();
  const [interval, setInterval] = useState("month");

  const intl = useIntl();

  const theme = useMantineTheme();

  useEffect(() => {
    console.log("questionType in useEffect", questionType);
    fetchSurveyQuestionResponseData(surveyId, questionId, dateRange, interval, questionType, searchParams)
      .then(data => {
        setResponseData(data);
      })
      .catch(error => {
        setError(error);
      })
      .finally(setLoader(false));
  }, [dateRange, interval, searchParams]);

  const timelineData = responseData && responseData.timeseries;
  const aggregateData = responseData && responseData.summary;

  const aggregateChartData =
    aggregateData &&
    aggregateData
      .sort((a, b) => a.answer - b.answer)
      .map(resp => {
        return {
          answer: parseInt(resp["answer"]),
          count: resp["response_count"],
          percentage: resp["response_pct"],
        };
      });

  const timelineChartData =
    timelineData &&
    timelineData
      .sort((a, b) => a["grouped_date"] - b["grouped_date"])
      .map(resp => {
        if (questionType == 4) {
          resp["score"] = Math.round(resp["high_pct"]) - Math.round(resp["low_pct"]);
        } else {
          resp["score"] = Math.round(resp["high_pct"]);
        }
        return resp;
      });

  const timelineTick = tickprops => {
    const { x, y, payload } = tickprops;
    console.log("ChartData in npsTick", timelineData[0]["interval"]);
    const { value } = payload;
    const tickvalue = formatTimeTick(value, interval, intl.locale);

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="middle" fill="#5C5F66" fontSize={12}>
          {tickvalue}
        </text>
      </g>
    );
  };

  const getTimelineYDomain = qType => {
    switch (qType) {
      case 4:
        return [-100, 100];

      default:
        return [0, 100];
    }
  };

  const timelineGraphOptions = () => {
    return {
      xAxisProps: {
        dataKey: "grouped_date",
        tick: timelineTick,
        //tickFormatter: timeTickFormatter,

        interval: timelineData[0]["interval"] == "day" ? 1 : 0,
      },
      yAxisProps: {
        label: { value: questionType == 4 ? "NPS" : "Score", fontSize: 14, angle: -90 },
        domain: getTimelineYDomain(questionType),
        type: "number",
        tick: { fill: "#5C5F66", strokeWidth: 0.5, fontSize: 12 },
      },
      margin: {
        top: 30,
        right: 30,
        left: 20,
        bottom: 30,
      },
    };
  };

  const scoreTooltip = () => {
    switch (parseInt(ratingRange)) {
      case 3:
        if (ratingScale === "numerical") {
          return "Percentage of users who gave 3 rating.";
        } else if (ratingScale === "emoji") {
          return "Percentage of users who gave 😊 rating.";
        } else if (ratingScale === "star") {
          return "Percentage of users who gave ★★★ rating.";
        } else {
          return "Percentage of users who gave 3 rating.";
        }

      case 5:
        if (ratingScale === "numerical") {
          return "Percentage of users who gave 4 and 5 ratings.";
        } else if (ratingScale === "emoji") {
          return "Percentage of users who gave 😊 and 😄 ratings.";
        } else if (ratingScale === "star") {
          return "Percentage of users who gave 4★ and 5★ ratings.";
        } else {
          return "Percentage of users who gave 4 and 5 ratings.";
        }

      default:
        break;
    }
  };

  const CustomXAxisTick = ({ x, y, payload, ratingType, ratingRange }) => {
    console.log("payload in CustomXAxisTick", payload);
    console.log("ratingType in CustomXAxisTick", ratingType);
    console.log("ratingRange in CustomXAxisTick", ratingRange);
    const icon = iconMap[ratingType][ratingRange][payload.value] || payload.value.toString();

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="middle"
          fill="#5C5F66"
          fontSize={payload.value > 0 ? 20 : 14}
          style={{ fontFamily: ratingType === "emoji" ? "Arial, sans-serif" : "monospace" }}
        >
          {icon}
        </text>
      </g>
    );
  };

  const renderTicks = () => {
    if (ratingRange) {
      if (ratingScale === "numerical") {
        return { fontSize: 14, fill: "#5C5F66", dy: 5 };
      } else {
        return null;
      }
    } else {
      return { fontSize: 14, fill: "#5C5F66", dy: 5 };
    }
  };

  const aggregateGraphOptions = ratingRange => {
    return {
      xAxisProps: {
        dataKey: "answer",
        ticks: createArrayRange(0, ratingRange),
        // axisline: false,
        // tickline: false,
        //height: 1,
        textAnchor: "middle",
        //width: 5,
        tick: renderTicks()
          ? renderTicks()
          : props => <CustomXAxisTick {...props} ratingType={ratingScale} ratingRange={ratingRange} />,
        //hide: true
        type: "number",
        domain: questionType == 4 ? [-1, ratingRange + 1] : [0, ratingRange + 1],
        interval: 0,
      },
      yAxisProps: {
        label: {
          value: intl.formatMessage({ id: "reports.response_count", defaultMessage: "Response count" }),
          fontSize: 14,
          angle: -90,
        },
        type: "number",
        tick: false,
      },
      margin: {
        top: 30,
        right: 30,
        left: 20,
        bottom: 30,
      },
      cellFill: val => {
        if (ratingRange == 10) {
          if (parseInt(val) <= 6) {
            return NPSColors["low"];
          } else if (parseInt(val) >= 9) {
            return NPSColors["high"];
          } else {
            return NPSColors["neutral"];
          }
        } else if (ratingRange == 5) {
          if (parseInt(val) <= 2) {
            return NPSColors["low"];
          } else if (parseInt(val) >= 4) {
            return NPSColors["high"];
          } else {
            return NPSColors["neutral"];
          }
        } else if (ratingRange == 3) {
          if (parseInt(val) == 1) {
            return NPSColors["low"];
          } else if (parseInt(val) == 3) {
            return NPSColors["high"];
          } else {
            return NPSColors["neutral"];
          }
        }
      },
    };
  };

  function renderChart(type, questionType = 4) {
    if (type === "bar") {
      return (
        <>
          {/* <Text align="center">Rating Distribution</Text> */}
          <BarGraph2
            chartData={aggregateChartData}
            graphOptions={aggregateGraphOptions(ratingRange)}
            questionType={questionType}
            dataType="npsRating"
          />
        </>
      );
    } else if (type === "line") {
      return (
        <LineGraph2 chartData={timelineChartData} graphOptions={timelineGraphOptions()} questionType={questionType} />
      );
    } else if (type === "pie") {
      return (
        <PieGraph
          chartData={aggregateChartData}
          graphOptions={aggregateGraphOptions(ratingRange)}
          questionType={questionType}
        />
      );
    }
  }

  const ratingScores = {
    totalResponses: function () {
      return timelineData && timelineData.reduce((sum, val) => (sum = sum + val["total_responses"]), 0);
    },
    highCount: function () {
      return timelineData && timelineData.reduce((sum, val) => (sum = sum + val["high"]), 0);
    },
    neutralCount: function () {
      return timelineData && timelineData.reduce((sum, val) => (sum = sum + val["neutral"]), 0);
    },
    lowCount: function () {
      return timelineData && timelineData.reduce((sum, val) => (sum = sum + val["low"]), 0);
    },
    highPct: function () {
      if (this.totalResponses() == 0) {
        return 0;
      }
      return Math.round((this.highCount() / this.totalResponses()) * 100);
    },
    neutralPct: function () {
      if (this.totalResponses() == 0) {
        return 0;
      }
      return Math.round((this.neutralCount() / this.totalResponses()) * 100);
    },
    lowPct: function () {
      if (this.totalResponses() == 0) {
        return 0;
      }
      return Math.round((this.lowCount() / this.totalResponses()) * 100);
    },
    nps: function () {
      return this.highPct() - this.lowPct();
    },
  };

  if (error) {
    return (
      <Text>
        {error.message || intl.formatMessage({ id: "message.error", defaultMessage: "Something went wrong." })}
      </Text>
    );
  }

  if (!timelineData || !aggregateData) {
    return <NoData />;
  }

  return loader ? (
    <Center>
      <Loader />
    </Center>
  ) : timelineData ? (
    <Grid>
      <Grid.Col span={4}>
        <div>
          <Card p="lg" radius="md">
            <Flex justify="center" align="center" direction="column" wrap="nowrap">
              <Card.Section>
                <RingProgress
                  size={170}
                  thickness={16}
                  label={
                    <Flex mih={50} justify="center" align="center" direction="column" wrap="nowrap">
                      <Group spacing={0}>
                        <Text size="xs" align="center">
                          {questionType == 4 ? "NPS" : "Score"}
                        </Text>
                        {questionType == 4 ? null : (
                          <Tooltip withArrow label={scoreTooltip()}>
                            <IconInfoCircle stroke={1} size={20} color={theme.colors.gray[4]} />
                          </Tooltip>
                        )}
                      </Group>
                      <Text size="40px" weight={400} align="center" px="xs" sx={{ pointerEvents: "none" }}>
                        {questionType == 4 ? ratingScores.nps() : ratingScores.highPct()}
                        {questionType == 5 && <span style={{ fontSize: "20px" }}>%</span>}
                      </Text>
                    </Flex>
                  }
                  sections={[
                    {
                      value: ratingScores.highPct(),
                      color: NPSColors["high"],
                      //tooltip: `Promoters: ${ratingScores.promotersCount()}`,
                    },
                    {
                      value: ratingScores.lowPct(),
                      color: NPSColors["low"],
                      //tooltip: `Detractors: ${ratingScores.detractorsCount()}`,
                    },
                    {
                      value: ratingScores.neutralPct(),
                      color: NPSColors["neutral"],
                      //tooltip: `Passives: ${ratingScores.passivesCount()}`,
                    },
                  ]}
                />
              </Card.Section>

              <Text color="dark.3" size={14}>
                <FormattedMessage
                  id="reports.response_count_plural"
                  values={{ count: ratingScores.totalResponses() }}
                  defaultMessage={pluralize(ratingScores.totalResponses(), "Response", "s")}
                />
              </Text>
              <Space h="md" />

              <List spacing="xs" size="sm" center>
                <List.Item icon={<ThemeIcon color={NPSColors["high"]} size={10} radius="xl"></ThemeIcon>}>
                  <Text color="dark.5" size={14}>
                    {ratingScores.highPct()}%{" "}
                    {questionType == 4
                      ? intl.formatMessage({ id: "reports.nps_promoters", defaultMessage: "Promoters" })
                      : intl.formatMessage({ id: "reports.rating_positive", defaultMessage: "Positive" })}{" "}
                    ({ratingScores.highCount()} {intl.formatMessage({ id: "reports.total", defaultMessage: "total" })})
                  </Text>
                </List.Item>
                <List.Item icon={<ThemeIcon color={NPSColors["neutral"]} size={10} radius="xl"></ThemeIcon>}>
                  <Text color="dark.5" size={14}>
                    {ratingScores.neutralPct()}%{" "}
                    {questionType == 4
                      ? intl.formatMessage({ id: "reports.nps_passives", defaultMessage: "Passives" })
                      : intl.formatMessage({ id: "reports.rating_neutral", defaultMessage: "Neutral" })}{" "}
                    ({ratingScores.neutralCount()}{" "}
                    {intl.formatMessage({ id: "reports.total", defaultMessage: "total" })})
                  </Text>
                </List.Item>
                <List.Item icon={<ThemeIcon color={NPSColors["low"]} size={10} radius="xl"></ThemeIcon>}>
                  <Text color="dark.5" size={14}>
                    {ratingScores.lowPct()}%{" "}
                    {questionType == 4
                      ? intl.formatMessage({ id: "reports.nps_detractors", defaultMessage: "Detractors" })
                      : intl.formatMessage({ id: "reports.rating_negative", defaultMessage: "Negative" })}{" "}
                    ({ratingScores.lowCount()} {intl.formatMessage({ id: "reports.total", defaultMessage: "total" })})
                  </Text>
                </List.Item>
              </List>
            </Flex>
          </Card>
        </div>
      </Grid.Col>
      <Grid.Col span={8}>
        <Group position={"right"}>
          {graphType.get(questionId) == "line" && (
            <TimeSegment interval={interval} setInterval={setInterval} qtype={questionType} />
          )}
          <ChartSegmentControl
            graphType={graphType}
            setGraphType={setGraphType}
            questionId={questionId}
            show={["bar", "line"]}
          />
        </Group>
        <Space h="md" />
        <div className="chartContainer" style={{ width: "100%" }}>
          {renderChart(graphType.get(questionId))}
        </div>
      </Grid.Col>
    </Grid>
  ) : (
    <Center>
      <Loader />
    </Center>
  );
}
