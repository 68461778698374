import React, { useState, useEffect } from 'react';
import { TextInput, Select, Space, Button, Modal } from '@mantine/core';
import { useForm } from '@mantine/form';
import API from '../../../../api/API';
import { API_URLS } from '../../../../utils/constants';
import { decamelizeKeys } from 'humps';
import { useAuthState } from '../../../../context/AuthContext';
import { showErrorNotification, showSuccessNotification } from '../../../../components/notifications';

export function TeamEditModal(props) {
  const { opened, setOpened, member, rolesData } = props;
  const { csrftoken } = useAuthState();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    form.setValues({
      fullName: member && member.fullName,
      email: member && member.email,
      accountRole: member && member.accountRole,
    });
  }, [member]);

  async function handleSubmit(data, event) {
    let response = await API.patch(API_URLS.UPDATE_TEAM_MEMBER(member.id), { ...decamelizeKeys(data) }, csrftoken);
    console.log(response);
    if (response.success) {
      setOpened(false);
      window.location.reload();

      console.log('Team Invite success', response.success);
      //dispatch({ type: 'AUTH_SUCCESS', payload: response.success.data.user });
    } else {
      showErrorNotification();
      console.log('Team Invite error', response.error);
    }
  }

  const form = useForm({
    initialValues: {
      fullName: '',
      email: '',
      accountRole: '',
    },
    validate: {
      fullName: value => (value.trim().length < 2 ? 'Name must have at least 2 letters' : null),
      email: value => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },

    //validateInputOnChange: true,
  });
  return (
    <Modal
      opened={opened}
      onClose={() => setOpened(false)}
      title="Edit Team Member"
      styles={() => ({
        title: {
          fontWeight: '700',
        },
        header: {},
      })}
    >
      <form onSubmit={form.onSubmit((values, event) => handleSubmit(values, event))}>
        <TextInput label="Full Name" {...form.getInputProps('fullName')} />
        <TextInput mt="sm" label="Email" placeholder="your@email.com" {...form.getInputProps('email')} />
        {member.isAccountOwner ? null : (
          <Select
            mt="sm"
            label="Access Level"
            placeholder="Select role"
            {...form.getInputProps('accountRole')}
            value={member.accountRole + ''}
            data={rolesData}
          />
        )}
        <Space h="md" />
        <Button type="submit" id="submitButton">
          Update
        </Button>
      </form>
    </Modal>
  );
}
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="7eb4061c-c967-52b9-8c04-94b5397fd323")}catch(e){}}();
//# debugId=7eb4061c-c967-52b9-8c04-94b5397fd323
