import React from 'react';
import { TextInput, Select, Space, Button, Modal } from '@mantine/core';
import { useForm } from '@mantine/form';

import API from '../../../../api/API';
import { API_URLS } from '../../../../utils/constants';
// import { camelizeKeys } from 'humps';
import { useAuthState } from '../../../../context/AuthContext';

import { showSuccessNotification, showErrorNotification } from '../../../../components/notifications';

export function TeamAddModal(props) {
  const { opened, setOpened, rolesData } = props;
  const { csrftoken } = useAuthState();

  async function handleSubmit(data, event) {
    let response = await API.post(API_URLS.SEND_TEAM_INVITE, { ...data }, csrftoken);
    console.log(response);
    if (response.success) {
      console.log('Team Invite success', response.success);

      setOpened(false);
      window.location.reload();
      showSuccessNotification('Invitation sent.');
    } else {
      showErrorNotification();
    }
  }

  const form = useForm({
    initialValues: {
      full_name: '',
      email: '',
      account_role: '',
    },
    validate: {
      full_name: value => (value.trim().length < 2 ? 'Name must have at least 2 letters' : null),
      email: value => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },

    //validateInputOnChange: true,
  });
  return (
    <Modal
      opened={opened}
      onClose={() => setOpened(false)}
      title="Add Team Member"
      styles={() => ({
        title: {
          fontWeight: '700',
        },
        header: {},
      })}
    >
      <form onSubmit={form.onSubmit((values, event) => handleSubmit(values, event))}>
        <TextInput label="Full Name" {...form.getInputProps('full_name')} />
        <TextInput mt="sm" label="Email" placeholder="your@email.com" {...form.getInputProps('email')} />
        <Select
          mt="sm"
          label="Access Level"
          placeholder="Select role"
          data={rolesData}
          {...form.getInputProps('account_role')}
        />
        <Space h="md" />
        <Button type="submit" id="submitButton">
          Add Member
        </Button>
      </form>
    </Modal>
  );
}
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="0bbd564d-1424-5195-8b3c-7089638ccd57")}catch(e){}}();
//# debugId=0bbd564d-1424-5195-8b3c-7089638ccd57
