import update, { extend } from "immutability-helper";
extend("$auto", function (value, object) {
  return object ? update(object, value) : update({}, value);
});

extend("$autoArray", function (value, object) {
  return object ? update(object, value) : update([], value);
});

extend("$delKey", function (keys, obj) {
  console.log("$133 key is", keys);
  console.log("$133 key is", obj);
  for (var i = 0; i < keys.length; i++) {
    delete obj[keys[i]];
  }
  return obj;
});

export const initialState = [];

export const NotificationReducer = (NotificationState, action) => {
  console.log("@urgent 1.0", action);
  switch (action.type) {
    case "addNotification":
      console.log("@slbug 1.0", action);
      return update(NotificationState, {
        $push: [action.value],
      });
    case "addCondition":
      return update(NotificationState, {
        [action.idx]: {
          conditions: { $push: [{ question_id: "", comparator: "", predicate: [], question_type: "" }] },
        },
      });
    case "updateCondition":
      console.log("@slbug 2.0", action);
      return update(NotificationState, {
        [action.nIndex]: {
          conditions: {
            [action.index]: {
              [action.section]: {
                $set: action.value,
              },
            },
          },
        },
      });
    case "updateNotificationSettings":
      return update(NotificationState, {
        [action.idx]: {
          $auto: {
            [action.section]: {
              $set: action.value,
            },
          },
          ...(action.section === "notify_for" &&
            action.value === "all" && {
              notify_for: {
                $set: action.value,
              },

              // $delKey: ["conditions"],
            }),
          // ...(action.section === "notify_for" &&
          //   action.value === "specific" && {
          //     notify_for: {
          //       $set: action.value,
          //     },
          //     // conditions: {
          //     //   $set: [],
          //     // },
          //   }),
        },
      });
    case "updateViaAPI":
      console.log("@slack update via api", action.value);
      return update(NotificationState, {
        $push: [action.value],
      });
    case "notificationsFromServer":
      console.log("@slack update via api", action.value);
      return update(NotificationState, {
        $set: action.value,
      });
    case "mergeNotificationSettings":
      console.log("#14 mergeNotificationSettings", action);
      return update(NotificationState, {
        [action.idx]: {
          $merge: action.value,
        },
      });
    case "updateNotificationSettingsMultiple":
      console.log("@slbug 7.0 notificationState", NotificationState);
      return {
        ...NotificationState,
        ...action.value,
      };
    case "deleteCondition":
      return update(NotificationState, {
        [action.nIndex]: {
          conditions: {
            $splice: [[action.index, 1]],
          },
        },
      });
    case "deleteNotification":
      return update(NotificationState, {
        $splice: [[action.idx, 1]],
      });
    case "resetNotificationState":
      return update(NotificationState, {
        [action.idx]: {
          $set: action.value,
        },
      });

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
