import { API_URLS } from "../utils/constants";
import API from "./API";
import { decamelizeKeys, camelizeKeys } from "humps";

export async function getUserAttributes(token) {
  console.log("Attribs Attribs token is", token);
  console.log("hail useEffect--------->");
  let response = await API.get(API_URLS.GET_ORGANIZATION(token));
  if (response.success) {
    return response.success.data.user_attributes;
  } else {
    console.log(response.error);
  }
}

export async function getTeamMembers() {
  let response = await API.get(API_URLS.GET_TEAM_MEMBERS);
  if (response.success) {
    console.log("TEAM LIST", response);
    return camelizeKeys(response.success.data);
  } else {
    console.log(response.error);
    return response;
  }
}

export async function deleteTeamMember(id, csrfToken) {
  let response = await API.delete(API_URLS.UPDATE_TEAM_MEMBER(id), csrfToken);
  if (response.success) {
    return camelizeKeys(response.success.data);
  } else {
    console.log("DELETE Error", response);
    return response;
  }
}

export async function validateTeamInviteToken(resetToken, csrfToken) {
  console.log("token validator called with csrfToke", csrfToken);
  let response = await API.post(API_URLS.VALIDATE_TEAM_INVITE_TOKEN, { token: resetToken }, csrfToken);
  if (response.success) {
    //console.log('$$$$$ Success Response $$$$', response.success);
    return response.success;
  } else if (response.error) {
    //console.log('##### Error Response #####', response.error);
    return response.error;
  }
}

export async function uploadProductLogo(logoImgFile, csrfToken, token) {
  const url = process.env.REACT_APP_SERVER_URL + "api/v1/" + API_URLS.UPLOAD_PRODUCT_LOGO(token);
  let response = await API.multipart_put(url, logoImgFile, csrfToken);

  if (response.success) {
    //console.log('$$$$$ Success Response $$$$', response.success);
    return response.success;
  } else if (response.error) {
    //console.log('##### Error Response #####', response.error);
    return response.error;
  }
}

export async function getAPIKey(token, env) {
  let response = await API.get(API_URLS.GET_API_KEY(token, env));
  if (response.success) {
    return response.success;
  } else if (response.error) {
    //console.log('##### Error Response #####', response.error);
    return response.error;
  }
}

export async function deleteProductLogo(token, csrfToken) {
  let response = await API.delete(API_URLS.DELETE_PRODUCT_LOGO(token), csrfToken);

  if (response.success) {
    //console.log('$$$$$ Success Response $$$$', response.success);
    return response.success;
  } else if (response.error) {
    //console.log('##### Error Response #####', response.error);
    return response.error;
  }
}
