import React, { useEffect, useRef } from "react";
import mermaid from "mermaid";

export const FlowDiagram = ({ surveyData }) => {
  const chartRef = useRef(null);

  console.log("FlowDiagram Data", surveyData);

  function areAllChoicesUsedInLogic(question) {
    // If question has no choices, return false
    if (!question.choices) return false;

    // If question has no next_conditions in options, return false
    if (!question.options?.next_conditions) return false;

    // Get all unique predicates from next_conditions
    const usedPredicates = question.options.next_conditions
      .filter(condition => condition.predicate) // Filter out empty predicates
      .map(condition => condition.predicate);

    // Get all choices
    const allChoices = question.choices.map(choice => choice.choice);

    // Check if every choice is used in predicates
    return (
      allChoices.every(choice => usedPredicates.some(predicate => predicate === choice)) &&
      allChoices.length === usedPredicates.length
    );
  }

  function generateChart() {
    if (!surveyData || surveyData.length === 0) {
      return "graph TD\n  Start(Start) --> End(End)";
    }

    let flowchart = "graph TD;\n";

    // Add Start section
    flowchart += "  Start(Start);\n";
    flowchart += `  Start --> Q${surveyData[0].id};\n`;

    for (const question of surveyData) {
      if (question.id === -1) continue; // Skip thank you node for now

      const questionId = `Q${question.id}`;
      const words = question.text.replace(/"/g, '\\"').split(" ");
      const chunkSize = 5;
      const chunks = [];

      for (let i = 0; i < words.length; i += chunkSize) {
        const chunk = words.slice(i, i + chunkSize).join(" ");
        chunks.push(chunk);
      }

      const wrappedText = chunks.join("<br>");
      flowchart += `  ${questionId}["Q${question.id + 1}:${wrappedText || " "}"];\n`;

      if (question.branches && question.branches.length > 0) {
        for (const branch of question.branches) {
          const nextQuestionId = branch.nextQuestionId === -1 ? "ThankYou" : `Q${branch.nextQuestionId}`;
          const condition = branch.condition.replace(/&nbsp;/g, "").trim();
          flowchart += `  ${questionId} -- ${condition} --> ${nextQuestionId};\n`;
        }

        const hasRemainingValues = [2, 3, 4, 5].includes(question.type); // Assuming a scale of 0-10

        // Only add "Any other response" for type 2 (multiple choice) questions
        // and only if not all branches are specified
        if (hasRemainingValues) {
          // Find the next question by ID
          const nextQuestion = surveyData.find(q => q.id === question.id + 1);
          if (nextQuestion) {
            flowchart += `  ${questionId} -- Any other response (if any) --> Q${nextQuestion.id};\n`;
          } else {
            flowchart += `  ${questionId} -- Any other response (if any) --> ThankYou;\n`;
          }
        }
      } else {
        // If no branches, go to next question or thank you
        const nextQuestion = surveyData.find(q => q.id === question.id + 1);
        if (nextQuestion) {
          flowchart += `  ${questionId} --> Q${nextQuestion.id};\n`;
        } else {
          flowchart += `  ${questionId} --> ThankYou;\n`;
        }
      }
    }

    // Add Thank you and End nodes
    const thankYouNode = surveyData.find(q => q.id === -1);
    flowchart += `  ThankYou["${thankYouNode?.text || "Thank you"}"];\n`;
    flowchart += "  End(End);\n";
    flowchart += "  ThankYou --> End;\n";

    return flowchart;
  }

  useEffect(() => {
    const renderChart = async () => {
      try {
        if (chartRef.current) {
          chartRef.current.innerHTML = "";
        }

        mermaid.initialize({
          startOnLoad: true,
          htmlLabels: true,
          securityLevel: "loose",
          flowchart: {
            useMaxWidth: true,
            htmlLabels: true,
            curve: "basis",
          },
        });

        const chart = generateChart();
        console.log("Generated chart:", chart); // For debugging

        const container = document.createElement("div");
        container.className = "mermaid";
        container.textContent = chart;

        if (chartRef.current) {
          chartRef.current.appendChild(container);
        }

        await mermaid.run();
      } catch (error) {
        console.error("Mermaid rendering error:", error);
        console.error("Chart definition:", generateChart()); // For debugging
      }
    };

    if (surveyData && surveyData.length > 0) {
      renderChart();
    }
  }, [surveyData]);

  return <div ref={chartRef} className="mermaid-container" style={{ width: "100%", minHeight: "200px" }} />;
};
