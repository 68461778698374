import React from "react";
import {
  ActionIcon,
  Paper,
  Menu,
  Space,
  Group,
  Badge,
  Title,
  Text,
  Tooltip,
  useMantineTheme,
  Card,
} from "@mantine/core";
import { IconDotsVertical, IconPencil, IconTrash, IconCopy } from "@tabler/icons-react";
import { Link } from "react-router-dom";
// import { showErrorNotification } from "../../../components/notifications";
// import { alertMessages } from "../../../utils/config";
// import { SURVEY_STATES } from "../../../utils/constants";
// import { pythonDateFormatter, datediff } from "../../../utils/helpers";
import { FormattedMessage, FormattedDate, useIntl } from "react-intl";
import { capitalize } from "../../../utils/helpers";

function SurveyListItem(props) {
  const { survey, handleDelete, currentUser } = props;
  const intl = useIntl();
  const theme = useMantineTheme();
  const userHasPermission = currentUser.isAccountOwner || currentUser.accessLevel == 1 || currentUser.accessLevel == 2;

  const badgeColor = state => {
    switch (state) {
      case 0:
        return "yellow";
      case 1:
        return "green";
      case 2:
        return "red";
      case 3:
        return "gray";
      case 4:
        return "gray";
      default:
        break;
    }
  };

  function translatedSurveyStates(state) {
    switch (state) {
      case 0:
        return intl.formatMessage({ id: "survey.in_draft" });
      case 1:
        return intl.formatMessage({ id: "survey.live" });
      case 2:
        return intl.formatMessage({ id: "survey.paused" });
      case 3:
        return intl.formatMessage({ id: "survey.completed" });
      case 4:
        return intl.formatMessage({ id: "survey.archived" });
      default:
        break;
    }
  }

  const cardLink = survey => {
    if (survey.state === 0) {
      return `${survey.id}/edit/platform`;
    } else {
      return `${survey.id}/responses/report`;
    }
  };

  // const surveyDurationText = (state, start, end) => {
  //   switch (state) {
  //     case 1:
  //       return `Started on ${pythonDateFormatter(start, "MMMM D, YYYY")}.`;
  //     case 2:
  //       return `Started on ${pythonDateFormatter(start, "MMMM D, YYYY")}.`;
  //     case 3:
  //     case 4:
  //       return `Ended on ${pythonDateFormatter(end, "MMMM D, YYYY")}.`;

  //     default:
  //       break;
  //   }
  // };

  const surveyDurationText = (state, start, end) => {
    switch (state) {
      case 1:
      case 2:
        return (
          <FormattedMessage
            id="survey_list_page.started_on"
            values={{
              date: <FormattedDate value={start} year="numeric" month="long" day="numeric" />,
            }}
          />
        );
      case 3:
      case 4:
        return (
          <FormattedMessage
            id="survey_list_page.ended_on"
            values={{
              date: <FormattedDate value={end} year="numeric" month="long" day="numeric" />,
            }}
          />
        );

      default:
        break;
    }
  };

  return (
    <>
      <Group position="right" sx={{ position: "absolute", right: 10, top: 15 }}>
        {userHasPermission && (
          <Menu withinPortal position="bottom-end" shadow="sm">
            <Menu.Target>
              <ActionIcon>
                <IconDotsVertical size={16} />
              </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item
                icon={<IconCopy size={14} />}
                component={Link}
                to={`new/viaCopy?from=${survey.id}`}
                sx={!userHasPermission && { pointerEvents: "none", color: "#adb5bd" }}
              >
                {intl.formatMessage({ id: "common.duplicate" })}
              </Menu.Item>
              <Menu.Item
                icon={<IconPencil size={14} />}
                component={Link}
                to={`${survey.id}/edit/platform`}
                sx={!userHasPermission && { pointerEvents: "none", color: "#adb5bd" }}
              >
                {intl.formatMessage({ id: "common.edit" })}
              </Menu.Item>
              <Menu.Item
                color="red"
                icon={<IconTrash size={14} />}
                onClick={e => handleDelete(e, survey.id)}
                disabled={!userHasPermission}
              >
                {intl.formatMessage({ id: "common.delete" })}
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        )}
      </Group>
      <Paper shadow="sm" p="lg" radius="md" withBorder sx={{ height: 140 }} component={Link} to={cardLink(survey)}>
        <Group>
          <Tooltip label={survey.title} disabled={survey.title.length < 1}>
            <Title truncate order={4} sx={{ maxWidth: "210px" }}>
              {survey.title}
            </Title>
          </Tooltip>
          <Badge color={badgeColor(survey.state)}>{translatedSurveyStates(survey.state)}</Badge>
        </Group>
        <Group>
          <Text size="xs" color="gray">
            {surveyDurationText(survey.state, survey.started_at, survey.ended_at)}
          </Text>
        </Group>
        <Group mt={10}>
          <Card bg="#f1f5f9" p={0} px={6} py={4}>
            <Text lh={1} size={12} color="dimmed">
              {capitalize(survey.platform)}
            </Text>
          </Card>
        </Group>
      </Paper>
      <Space h="md" />
    </>
  );
}

export default SurveyListItem;
