import React from "react";
import { Container, Space, Grid, Text, createStyles, Header, Group, Image, Flex } from "@mantine/core";
import style from "./../../style.scss";
import LogoImage from "../../images/logo-512.png";

import { Outlet } from "react-router-dom";

const useStyles = createStyles(() => ({
  mainWrapper: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  headerWrapper: {
    backgroundColor: "#f8f9fa",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
  },
  main: {
    "input[type=text]": {
      cursor: "text",
    },
  },
}));

const UnauthenticatedLayout = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.mainWrapper}>
      <Header height={60} className={classes.headerWrapper}>
        <Container className={classes.header} fluid>
          <Group>
            <Image
              placeholder={
                <Text fw={900} fz={32} className={style.logo}>
                  FeedbackSpark
                </Text>
              }
              src="https://cdn.feedbackspark.com/logos/fslogo.svg"
              width={150}
              alt="FeedbackSpark logo"
            />
          </Group>
        </Container>
      </Header>
      <main className={classes.main}>
        <Container>
          <Grid>
            <Grid.Col span={12}>
              <Outlet />
            </Grid.Col>
          </Grid>
          <Space h="lg" />
        </Container>
      </main>
    </div>
  );
};

export default UnauthenticatedLayout;
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="d5b4cb87-92c5-595a-bb89-27a8e0f0796b")}catch(e){}}();
//# debugId=d5b4cb87-92c5-595a-bb89-27a8e0f0796b
