import React from "react";
import { useDisclosure } from "@mantine/hooks";
import { Text, Box, Popover } from "@mantine/core";

export default function FSToolTip({ children, label }) {
  const [opened, { close, open }] = useDisclosure(false);

  return (
    <Popover width={200} position="bottom" withArrow shadow="md" opened={opened}>
      <Popover.Target>
        <Box onMouseEnter={open} onMouseLeave={close}>
          {children}
        </Box>
      </Popover.Target>
      <Popover.Dropdown sx={{ pointerEvents: "none" }}>
        <Text size="sm">{label}</Text>
      </Popover.Dropdown>
    </Popover>
  );
}
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="d2522396-c6d7-5986-af5c-d0e0628d64b4")}catch(e){}}();
//# debugId=d2522396-c6d7-5986-af5c-d0e0628d64b4
