import React, { createContext, useContext, useState, useMemo } from 'react';

const ThemeContext = createContext(null);

function useThemeState() {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useAuthState must be used within a AuthProvider');
  }

  return context;
}

const ThemeProvider = ({ children }) => {
  const [showAnnouncement, setShowAnnouncement] = useState(false);

  const contextValue = useMemo(
    () => ({
      showAnnouncement,
      setShowAnnouncement,
    }),
    [showAnnouncement, setShowAnnouncement]
  );
  return <ThemeContext.Provider value={contextValue}>{children}</ThemeContext.Provider>;
};

export { ThemeProvider, useThemeState };
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="790a3e32-7b9e-5083-8588-f0c5e93c2f8f")}catch(e){}}();
//# debugId=790a3e32-7b9e-5083-8588-f0c5e93c2f8f
