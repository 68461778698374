import React, { useEffect, useState } from "react";
import { Box, Text, Tooltip, Popover, Button, Table, Modal, Group, Alert, Divider } from "@mantine/core";
import API from "../../../../api/API";
import { pythonDateFormatter } from "../../../../utils/helpers";
import { TextLink } from "./../../../../components/UI";
import { IconPlayerRecord } from "@tabler/icons-react";

export default function WebhookLogs(props) {
  const { webhookLogs, setWebhookLogs } = props;
  const [opened, setOpened] = useState(false);

  async function getWebhookLogs() {
    let response = await API.get(`webhooks/logs`);
    if (response.success) {
      console.log("webhook logs", response.success.data);
      setWebhookLogs(response.success.data);
    } else {
      console.log("error", response.error);
    }
  }

  useEffect(() => {
    getWebhookLogs();
  }, []);

  const rows =
    webhookLogs &&
    webhookLogs.map((row, index) => (
      <tr key={index}>
        <td>
          <Tooltip label={row.webhook_name} position="top" withArrow>
            <Text sx={{ width: "120px" }} truncate>
              {row.webhook_name}
            </Text>
          </Tooltip>
        </td>
        <td>
          <Tooltip label={row.url} position="top" withArrow>
            <Text truncate>{row.url}</Text>
          </Tooltip>
        </td>
        <td>
          <Group>
            <IconPlayerRecord
              color={row.response_status.toString().charAt(0) == 2 ? "green" : "red"}
              fill={row.response_status.toString().charAt(0) == 2 ? "green" : "red"}
            />
            {row.response_status}
          </Group>
        </td>
        <td>
          <Text truncate>{pythonDateFormatter(row.timestamp, "MMMM D, YYYY hh:mm A Z")}</Text>
        </td>
        <td>
          <TextLink text="View details" handleClick={() => setOpened(true)} />
          <ResponseDetailModal response={row.response_body} opened={opened} setOpened={setOpened} />
        </td>
      </tr>
    ));

  return (
    <Box>
      <Alert mt={20} mb={20} color="yellow">
        Logs are periodically deleted, with only the last 50 logs shown.
      </Alert>
      {rows && rows.length > 0 ? (
        <Table fontSize={14}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Endpoint</th>
              <th>Status</th>
              <th>Triggered on</th>
              <th>Response</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      ) : (
        <Text mt={20} size={14} color="dimmed" italic>
          There are no logs.
        </Text>
      )}
    </Box>
  );
}

function ResponseDetailModal({ response, opened, setOpened }) {
  return (
    <Modal
      centered
      overlayColor="white"
      overlayOpacity={0.55}
      overlayBlur={3}
      opened={opened}
      onClose={() => setOpened(false)}
      title="Response Body"
    >
      <Divider my="sm" />
      {response}
    </Modal>
  );
}
