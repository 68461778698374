import React from "react";
import { Grid, Card, Text, Image, Stack, Box } from "@mantine/core";
import { Link } from "react-router-dom";
import { IconWebhook } from "@tabler/icons";
import ZapierLogo from "../../images/zapierLogo.png";
import WebhookLogo from "../../images/webhook.png";
// import SlackLogo from "../../images/Slack-mark.jpg";
// import ZendeskLogo from "../../images/zendeskLogo.png";
// import GSheetLogo from "./../../images/googleSheet.png";
import { Heading } from "../../components";
import style from "./../../style.scss";

export default function Integrations() {
  return (
    <Box>
      <Heading title="Integrations" showDivider={true} />
      <Grid align="flex-start">
        <Grid.Col span={2}>
          <Link to="/integrations/webhook" style={{ textDecoration: "none" }}>
            <Card
              sx={{ overflow: "visible", display: "flex", flexDirection: "column", justifyContent: "center" }}
              className={style.integration_card}
              shadow="sm"
              p="lg"
              radius="md"
              withBorder
            >
              <Stack align="center">
                <Image src={WebhookLogo} width="auto" height="80px" alt="webhook Logo" />
                <Text size={24} weight={700} lh={1}>
                  Webhooks
                </Text>
              </Stack>
            </Card>
          </Link>
        </Grid.Col>
      </Grid>
    </Box>
  );
}
