import React from "react";
import { Button, Stack, Space, Box, Text } from "@mantine/core";
import MCQChoice from "./mcq-choice";
import RandomizeChoices from "./RandomizeChoices";
import { StyledTextInput } from "../../../../components/MantineOverride";

export function MultipleChoiceQuestion(props) {
  const { idx, question, dispatch, handleChange, surveyState, platform } = props;
  let choiceCounter = 0;
  const nextChoiceIndex = () => choiceCounter++;

  function addChoice(idx, currentChoiceCount) {
    dispatch({
      type: "addChoice",
      index: idx,
      currentCount: currentChoiceCount,
    });
  }

  function removeChoice(idx, choiceIdx) {
    dispatch({
      type: "removeChoice",
      index: idx,
      choiceIndex: choiceIdx,
    });
  }

  function handleChoiceInput(idx, choiceIdx, event) {
    dispatch({
      type: "inputChange",
      index: idx,
      choiceIndex: choiceIdx,
      targetName: event.target.name,
      targetId: event.target.id,
      value: event.target.value,
    });
  }

  return (
    <div className="shqQuestion">
      <StyledTextInput
        label={`Question ${idx + 1}`}
        name="question"
        id={`question${idx}`}
        value={question.questionText}
        maxLength={256}
        onChange={e => handleChange(idx, e)}
      />
      <Space h="md" />

      <Box>
        <Text sx={{ fontSize: "14px", fontWeight: 400, color: "#5c5f66" }}>Choices</Text>
        <Stack>
          {question.choices &&
            question.choices.map((val, cidx) => (
              <MCQChoice
                key={`c${nextChoiceIndex()}`}
                questionIndex={idx}
                choiceIndex={cidx}
                choice={val}
                handleChange={handleChoiceInput}
                handleRemove={removeChoice}
                dispatch={dispatch}
                choiceCount={question.choices.length}
                surveyState={surveyState}
              />
            ))}
        </Stack>
      </Box>
      <Space h="md" />
      {/* <Divider my="sm" variant="dotted" /> */}
      {/* <Group position="apart" sx={{ alignItems: 'end' }}> */}
      <Button
        variant="outline"
        type="button"
        onClick={() => addChoice(idx, question.choices && question.choices.length)}
        className="space10"
      >
        Add Choice
      </Button>

      {question.order == 0 && platform === "email" ? null : (
        <>
          <Space h="md" />
          <RandomizeChoices dispatch={dispatch} qIndex={idx} qOptions={question.options} />
        </>
      )}
    </div>
  );
}

// #note Button inside forms need to have a type otherwise any button will submit the form.
// this is because by default button inside a form has type "submit".
// for details refer: https://redux-form.com/8.3.0/docs/faq/buttontype.md/
