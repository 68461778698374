export const surveyHQTheme = {
  colorScheme: "light",
  primaryColor: "Primary",
  //defaultRadius: 10,
  fontFamily: "OpenSans,Arial, sans-serif",
  headings: { fontWeight: 600, fontFamily: "OpenSans,Arial, sans-serif" },
  //activeStyles: { color: '#1812D6' },
  primaryShade: { light: 5, dark: 8 },
  colors: {
    Primary: [
      "#F8F9FA", // gray0
      "#EDF2FF", // indigo0
      "#fe5437", //sunset Orange
      "#ff5b43", //sunser Orange 2
      "#db446b", //red
      "#00b1d4", //primary blue
      "#0082a8", //little darker
      "#0076a1", // marginal dark
      "#77bb41", //primary2
      "#001f38", //too dark
    ],
    Primary1: [
      "#DAE3EA",
      "#BDD0DE",
      "#9FC0D7",
      "#7EB1D5",
      "#5AA6DB",
      "#309CE8",
      "#0096FF", //oceanblue
      "#1783CF",
      "#2573A9",
      "#2D658D",
      "#315A76",
      "#335064",
      "#324756",
    ],
    //red
    Secondary: [
      "#F8EFEF",
      "#EFCDCD",
      "#EBA8A8",
      "#F07E7E",
      "#D33B3B",
      "#E94343",
      "#FE4F4F", //red
      "#B73C3C",
      "#994141",
      "#824242",
    ],

    SHQgreen: ["#84B7AC", "#6BB4A4", "#4FB59F", "#3AB399", "#27B192", "#14B08E", "#00B28B", "#109074", "#1A7662"],
    SHQteal: [
      "#E6ECEB",
      "#CCDBD8",
      "#B3CEC9",
      "#99C5BC",
      "#7EC0B3",
      "#61C0AD",
      "#57AD9C",
      "#559588",
      "#528177",
      "#4E6F69",
      "#49615D",
      "#445552",
    ],
    faadoo: [
      "#E6E1D5",
      "#DBD0B8",
      "#D4C29A",
      "#D4B879",
      "#DAB155",
      "#E8AE2B",
      "#FAAD00",
      "#CA9317",
      "#A67E24",
      "#8A6D2C",
      "#745F30",
      "#625332",
      "#544931",
    ],
  },
  fontSizes: {
    xs: 14,
    sm: 16,
    md: 18,
    lg: 20,
    xl: 22,
  },
};

const colorPalette = {
  oceanBlue: [
    "#DAE3EA",
    "#BDD0DE",
    "#9FC0D7",
    "#7EB1D5",
    "#5AA6DB",
    "#309CE8",
    "#0096FF",
    "#1783CF",
    "#2573A9",
    "#2D658D",
    "#315A76",
    "#335064",
    "#324756",
  ],
  red: ["#F8EFEF", "#EFCDCD", "#EBA8A8", "#F07E7E", "#D33B3B", "#E94343", "#FE4F4F", "#B73C3C", "#994141", "#824242"],
  "bright-pink": [
    "#F0BBDD",
    "#ED9BCF",
    "#EC7CC3",
    "#ED5DB8",
    "#F13EAF",
    "#F71FA7",
    "#FF00A1",
    "#E00890",
    "#C50E82",
    "#AD1374",
  ],
  green: ["#84B7AC", "#6BB4A4", "#4FB59F", "#3AB399", "#27B192", "#14B08E", "#00B28B", "#109074", "#1A7662"],
  teal: [
    "#E6ECEB",
    "#CCDBD8",
    "#B3CEC9",
    "#99C5BC",
    "#7EC0B3",
    "#61C0AD",
    "#57AD9C",
    "#559588",
    "#528177",
    "#4E6F69",
    "#49615D",
    "#445552",
  ],
  faadoo: [
    "#E6E1D5",
    "#DBD0B8",
    "#D4C29A",
    "#D4B879",
    "#DAB155",
    "#E8AE2B",
    "#FAAD00",
    "#CA9317",
    "#A67E24",
    "#8A6D2C",
    "#745F30",
    "#625332",
    "#544931",
  ],
  sereneBlue: [
    "#F8F9FA", // gray0
    "#EDF2FF", // indigo0
    "#00b1d4", //primary blue
    "#007ec2", //primary2
    "#0082a8", //little darker
    "#0076a1", // marginal dark
    "#001f38", //too dark
    "#437b26", //green
    "#fe5437", //sunset Orange
    "#ff5b43", //sunser Orange 2
    "#db446b", //red
  ],
};
export const baseColors = {
  purple: "#7300ff",
  oceanBlue: "#0096ff",
  navyBlue: ["#3100ff", "#0032ff", "#006aff", "#009fff"],
  yellow: ["#ffbd00", "#ff9b00"],
  red: ["#ff0000", "#b30000"],
  green: "#00B28B",
};

export const SHQTheme = {
  colorScheme: "light",
  fontFamily: "Arial,Roboto,Inter,Open Sans, sans-serif",
  headings: {
    fontWeight: 700,
    fontFamily: "Arial,Roboto,Inter,Open Sans,sans-serif",
    sizes: {
      h1: { fontSize: 30 },
    },
  },
  colors: {
    raspberry: [
      "#f9b0ba", //0
      "#f69caa", //1
      "#f1889a", //2
      "#ec7389", //3
      "#e65c7a", //4
      "#df426a", //5
      "#D81E5B", //6
      "#bc194e", //7
      "#a01341", //8
      "#850e35", //9
    ],
  },
  black: "#171738",
  primaryColor: "indigo",
  //primaryShade: 4,
  fontSizes: {
    xs: 14,
    sm: 16,
    md: 18,
    lg: 20,
    xl: 22,
  },
  focusRing: "auto",
  globalStyles: () => ({
    html: {
      fontSize: "62.5%",
    },
    body: {
      fontWeight: 400,
    },
    "input[type=text]": {
      cursor: "text",
    },
  }),
};

export const themeColors = {
  purple: "#4C6EF5",
  orange: "#FCA311",
  black: "#14080E",
  richBlack: "#0E1116",
  raspberry: "#D81E5B",
  columbiaBlue: "#CDE6F5",
  spaceCadet: "#171738",
  emraldGreen: "#16DB93",
  sandyBrownOrange: "#F29E4C",
  lime: "#EFEA5A",
  magenta: "#D30C7B",
  jasmine: "#F4D06F",
  maize: "#FFE74C",
  //source: 'https://coolors.co/4c6ef5-ffffff-fca311-14080e-d81e5b'
};
!(function () {
  try {
    var e =
        "undefined" != typeof window
          ? window
          : "undefined" != typeof global
          ? global
          : "undefined" != typeof self
          ? self
          : {},
      n = new Error().stack;
    n &&
      ((e._sentryDebugIds = e._sentryDebugIds || {}), (e._sentryDebugIds[n] = "c0d5d2a4-8afd-5ab4-8ebd-5276d410f2c2"));
  } catch (e) {}
})();
//# debugId=c0d5d2a4-8afd-5ab4-8ebd-5276d410f2c2
