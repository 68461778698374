import React, { useEffect, useState, useReducer } from "react";
import { Space, Card, Tabs, Box, Button, Group } from "@mantine/core";
import { SlackIcon } from "../../../components/CustomIcons";
import { Slack } from "./notifications/Slack";
import { IconWebhook } from "@tabler/icons-react";
import API from "../../../api/API";
import { API_URLS } from "../../../utils/constants";
import Webhook from "../integrations/Webhook";
import { NotificationReducer } from "../../../reducers/NotificationReducer";
import { WebhookReducer } from "../../../reducers/WebhookReducer";
//import { comparatorByQuestionType } from '../../../utils/QuestionLogicHelper';
// import { wrap } from '@sentry/react';

export function Notifications(props) {
  const { options, questions, surveyId, csrfToken, surveyState } = props;
  const [notificationState, dispatch] = useReducer(NotificationReducer, []);
  const [webhookState, webhookDispatch] = useReducer(WebhookReducer, []);

  const [activeTab, setActiveTab] = useState("slack");
  const [notifications, setNotifications] = useState(notificationState);

  // const [notificationState, notificationDispatch] = useReducer(NotificationReducer, initialState);
  console.log("@bug 4.0 surveyState", surveyState);
  console.log("@bug 5.0 questions", questions);

  async function handleDelete(e, id, index) {
    e.preventDefault();
    let response = await API.delete(API_URLS.UPDATE_SURVEY_NOTIFICATION(id), csrfToken);
    if (response.success) {
      getNotification();
    }
  }

  function getNotification() {
    API.get(API_URLS.GET_SAVE_SURVEY_NOTIFICATIONS(), { type: "slack", sid: surveyId }).then(data => {
      if (data.success) {
        const savedNotification = data.success.data;
        console.log("@slbug notification from server", data);

        dispatch({
          type: "notificationsFromServer",
          value: savedNotification,
        });

        setNotifications(savedNotification);
      }
    });
  }

  useEffect(() => {
    getNotification();
  }, []);

  useEffect(() => {}, [notificationState.length]);

  // function handleAddNotification(notification_platform) {
  //   dispatch({
  //     type: "addNotification",
  //     notify_on: notification_platform,
  //   });
  // }

  useEffect(() => {
    //this is effect is to synchronise formState update
    //Ref: https://samuelsson.dev/update-state-with-usereducer-synchronously/
  }, [options]);

  return (
    <>
      <Space h="md" />
      <Card sx={{ overflow: "visible" }} shadow="sm" p="lg" radius="md" mb="xs" withBorder>
        <Tabs value={activeTab} onTabChange={setActiveTab}>
          <Tabs.List>
            <Tabs.Tab value="slack" icon={<SlackIcon size="16px" />}>
              Slack
            </Tabs.Tab>
            <Tabs.Tab value="webhook" icon={<IconWebhook size="16px" color="teal" />}>
              Webhook
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="slack">
            <Space h="md" />
            <Box>
              {console.log("@slbug 6.0 notificationState", notificationState)}
              <Slack
                notificationState={notificationState}
                dispatch={dispatch}
                options={options}
                questions={questions}
                surveyId={surveyId}
                handleDelete={handleDelete}
                dbNotifications={notifications}
                setDbNotifications={setNotifications}
                csrfToken={csrfToken}
              />
            </Box>

            <Space h="md" />
          </Tabs.Panel>
          <Tabs.Panel value="webhook">
            <Space h="md" />
            <Webhook webhookState={webhookState} dispatch={webhookDispatch} csrfToken={csrfToken} surveyId={surveyId} />
          </Tabs.Panel>
        </Tabs>
      </Card>

      <Space h="md" />

      <Space h="md" />
    </>
  );
}
