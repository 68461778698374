import React from 'react';
import { ActionIcon, Switch, Tooltip, Box, Group } from '@mantine/core';
import { IconTrash, IconMessage2, IconMessage2Off } from '@tabler/icons-react';
import { StyledTextInput } from '../../../../components/MantineOverride';

import style from './../../../../style.scss';

function MCQChoice(props) {
  const { questionIndex, choiceIndex, choice, handleChange, handleRemove, dispatch, choiceCount, surveyState } = props;

  function toggleComments(event) {
    console.log('ToggleComments checked value', event);
    dispatch({
      type: 'canComment',
      index: questionIndex,
      choiceIndex: choiceIndex,
      value: event.target.checked,
    });
  }

  return (
    <>
      <Box className={style.choiceInputContainer}>
        <StyledTextInput
          styles={theme => ({
            input: {
              border: '0px',
              width: '400px',
            },
            label: {
              color: theme.colors.dark[3],
              fontSize: '14px',
              fontWeight: 400,
            },
          })}
          //label={`Choice ${choiceIndex + 1}`}
          name="choice"
          maxLength={64}
          id={`question${questionIndex}_choice${choiceIndex}`}
          value={choice.choice}
          onChange={e => handleChange(questionIndex, choiceIndex, e)}

          //onFocus={handleBlur(`question${questionIndex}_choice${choiceIndex}`)}
        />
        <Group className={style.inputActionList}>
          <Tooltip label="Allow comments" withArrow openDelay={500}>
            <div>
              <Switch
                styles={theme => ({
                  track: {
                    //marginRight: '10px',
                    pointerEvents: 'all',
                    cursor: 'pointer',
                    backgroundColor: 'white',
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  },
                })}
                checked={choice.canComment || false}
                size="md"
                onChange={e => toggleComments(e)}
                color="indigo"
                //color={theme.colorScheme === 'dark' ? 'gray' : 'dark'}
                onLabel={<IconMessage2 size={16} stroke={2.5} />}
                offLabel={<IconMessage2Off size={16} stroke={2.5} />}
              />
            </div>
          </Tooltip>

          {choiceCount > 2 && surveyState != 1 ? (
            <Tooltip label="Delete" withArrow openDelay={500}>
              <ActionIcon
                variant="default"
                radius="xl"
                sx={{
                  backgroundColor: '#e9ecef',
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                <IconTrash onClick={() => handleRemove(questionIndex, choiceIndex)} size={16} color="#868e96" />
              </ActionIcon>
            </Tooltip>
          ) : null}
        </Group>
      </Box>

      {/* <Divider my="sm" /> */}
    </>
  );
}

export default MCQChoice;
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="ab25251b-68f7-5be0-80f9-306fbb4de61a")}catch(e){}}();
//# debugId=ab25251b-68f7-5be0-80f9-306fbb4de61a
