import { darken, transparentize } from "color2k";
import { objectIsEqual } from "../utils/helpers";
// export const userBranding = branding => {
//   if (branding)
//     return {
//       bodyFontColor: branding?.body_font_color,
//       backgroundColor: branding?.background_color,
//       borderColor: branding?.border_color,
//       buttonColor: branding?.button_color,
//       buttonFontColor: branding?.button_font_color,
//       progressBarColor: branding?.progress_bar_color,
//     };
// };

function isDefaultTheme(values) {
  const initialValues = {
    borderColor: "#000000",
    backgroundColor: "#ffffff",
    buttonColor: "#000000",
    buttonFontColor: "#ffffff",
    bodyFontColor: "#000000",
    progressBarColor: "#000000",
    accentColor: "#000000",
  };
  return objectIsEqual(values, initialValues);
}
const defaultTheme = {
  //Widget
  widget: {
    backgroundColor: "#ffffff",
    fontColor: "#000000", //#222222
    borderColor: "#000000",
  },
  crossButton: {
    color: "#000000",
  },
  thankyou: {
    iconColor: "#000000",
  },
  footer: {
    textColor: "gray",
  },
  ratingCell: {
    borderColor: "#d3d3d3",
    inputBackgroundColor: "#000000",
    inputFontColor: "#ffffff",
    accentColor: "#000000",
    ratingColor: "#000000",
  },
  progressBar: {
    wrapperBackgroundColor: "rgba(154, 152, 152, 0.51)", //hex: '#9a989882'
    barBackgroundColor: "#000000",
  },

  buttonActive: {
    borderColor: "transparent",
    backgroundColor: "rgb(37, 38, 43)", //hex: '#25262b'
    fontColor: "#ffffff",
  },
  buttonDisabled: {
    borderColor: "#ece9e9",
    backgroundColor: "#ece9e9",
    textColor: "#cccccc",
  },
  choiceInput: {
    borderColor: "#ccc",
    accentColor: "#000000",
  },
  inputWrapper: {
    backgroundColor: "#f7f7f7",
    borderColor: "#ced4da",
    fontColor: "#000",
    focusColor: "#33c3f0",
  },
  inputBox: {
    placeholderColor: "default",
  },
};

export const uiTheme = branding => {
  if (!isDefaultTheme(branding)) {
    return {
      widget: {
        "--shq-main-bg-color": branding.backgroundColor,
        "--shq-main-font-color": branding.bodyFontColor,
        "--shq-main-border-color": branding.borderColor,
      },
      shqLink: {
        "--shq-brand-link": transparentize(branding.bodyFontColor, 0.4),
      },
      thankyou: {
        "--shq-icon-color": branding.bodyFontColor,
      },
      inputWrapper: {
        "--shq-input-font-color": branding.bodyFontColor,
        "--shq-input-border-color": branding.borderColor,
        "--shq-input-bg-color": branding.backgroundColor,
        "--shq-input-focus-color": "",
      },
      inputBox: {
        "--shq-placeholder-color": transparentize(branding.bodyFontColor, 0.4),
      },
      choiceInput: {
        "--shq-accent-color": branding.accentColor,
      },
      submitButton: {
        "--shq-button-active-bg-color": branding.buttonColor,
        "--shq-button-active-font-color": branding.buttonFontColor,
      },
      progressBar: {
        "--shq-progressbar-foreground-color": branding.accentColor,
        "--shq-progressbar-background-color": defaultTheme.progressBar.wrapperBackgroundColor,
      },
      ratingCell: {
        // "--shq-rating-bg-color": darken(branding.backgroundColor, 0.3),
        "--shq-rating-bg-color": branding.ratingColor,
        "--shq-emoji-bg-color": branding.emojiColor,
        "--shq-rating-font-color": branding.backgroundColor,
        "--shq-rating-border-color": branding.borderColor,
        "--shq-rating-accent-color": branding.accentColor,
      },
    };
  } else {
    return {
      widget: {
        "--shq-main-bg-color": defaultTheme.widget.backgroundColor,
        "--shq-main-font-color": defaultTheme.widget.fontColor,
        "--shq-main-border-color": defaultTheme.widget.borderColor,
      },
      shqLink: {
        "--shq-brand-link": defaultTheme.footer.textColor,
      },
      thankyou: {
        "--shq-icon-color": defaultTheme.thankyou.iconColor,
      },
      inputWrapper: {
        "--shq-input-font-color": defaultTheme.inputWrapper.fontColor,
        "--shq-input-border-color": defaultTheme.inputWrapper.borderColor,
        "--shq-input-bg-color": defaultTheme.inputWrapper.backgroundColor,
        "--shq-input-focus-color": "",
      },
      choiceInput: {
        "--shq-accent-color": defaultTheme.choiceInput.accentColor,
      },
      inputBox: {
        "--shq-placeholder-color": "",
      },
      submitButton: {
        "--shq-button-active-bg-color": defaultTheme.buttonActive.backgroundColor,
        "--shq-button-active-font-color": defaultTheme.buttonActive.fontColor,
      },
      progressBar: {
        "--shq-progressbar-foreground-color": defaultTheme.progressBar.barBackgroundColor,
        "--shq-progressbar-background-color": defaultTheme.progressBar.wrapperBackgroundColor,
      },
      ratingCell: {
        "--shq-rating-bg-color": defaultTheme.ratingCell.inputBackgroundColor,
        "--shq-rating-font-color": defaultTheme.ratingCell.inputFontColor,
        "--shq-rating-border-color": defaultTheme.ratingCell.borderColor,
        "--shq-rating-accent-color": defaultTheme.ratingCell.accentColor,
      },
    };
  }
};
