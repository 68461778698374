import React, { useRef, useEffect, useCallback, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { graphColors, tagColors2 } from "../../../../utils/colorScheme";
import { formatTimeTick, onlyUnique } from "../../../../utils/helpers";
import { useIntl } from "react-intl";

import { Box, Card, List, ThemeIcon, Text } from "@mantine/core";
//import TextTooltip from './TextTooltip';

ChartJS.register(
  CategoryScale,
  TimeScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

export function TimeseriesGraph(props) {
  const { chartData, chartType, dataKey, qtype, interval } = props;
  const intl = useIntl();

  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipData, setTooltipData] = useState(null);
  const [tooltipPos, setTooltipPos] = useState(null);
  const chartRef = useRef(null);

  useEffect(() => {
    const chart = chartRef.current;

    if (chart) {
      console.log("ChartJS", chart);
    }
  }, []);

  const customTooltip = useCallback(context => {
    console.log("tooltip context", context);
    const tooltipModel = context.tooltip;
    if (tooltipModel.opacity == 0) {
      // hide tooltip visibilty
      setTooltipVisible(false);
      return;
    }

    const chart = chartRef.current;
    const canvas = chart.canvas;
    if (canvas) {
      // enable tooltip visibilty
      setTooltipVisible(true);
      const { offsetLeft: positionX, offsetTop: positionY } = canvas;
      //const position = context.chart.canvas.getBoundingClientRect();

      // set position of tooltip
      const left = positionX + tooltipModel.caretX + "px";
      const top = positionY + tooltipModel.caretY + "px";

      // handle tooltip multiple rerender
      if (tooltipPos?.top != top) {
        setTooltipPos({ top: top, left: left });
        setTooltipData(tooltipModel);
      }
    }
  });

  const lineOptions = {
    pointLabel: true,
    responsive: true,
    elements: {
      point: {
        hitradius: 6,
        hoverRadius: 6,
      },
    },
    layout: {
      padding: {
        top: 30,
        right: 30,
        left: 20,
        bottom: 60,
      },
    },

    plugins: {
      title: {
        display: true,
        text: intl.formatMessage({ id: "reports.trend_heading", defaultMessage: "Trend" }),
      },
      tooltip: {
        enabled: false,
        position: "nearest",
        external: customTooltip,
      },
      datalabels: {
        anchor: "end",
        color: "#000",
        align: "bottom",
        display: true,

        textStrokeColor: "#fff",
        //opacity: 0.5,
        //backgroundColor: '#fff',
        formatter: function (value) {
          return value.response_pct + "%";
        },
      },
      legend: {
        position: "top",
        labels: {
          usePointStyle: true,
          useBorderRadius: true,
          borderRadius: 4,
        },
      },
    },

    normalized: true,

    parsing: {
      xAxisKey: "grouped_date",
      yAxisKey: "response_pct",
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        offset: true,
        ticks: {
          callback: function (value, index, ticks) {
            console.log("value in callback tick", value);
            console.log("ticks in callback tick", ticks);
            console.log("index in callback tick", index);
            return formatTimeTick(this.getLabelForValue(value), interval, intl.locale);
          },
        },
      },
      y: {
        beginAtZero: true,
        min: 0,
        max: 100,
        ticks: {
          // Include a dollar sign in the tick
          callback: function (value, index, ticks) {
            return value + "%";
          },
        },
        title: {
          display: true,
          text: intl.formatMessage({ id: "reports.percent_share", defaultMessage: "Share" }),
        },
        grid: {
          display: true,
        },
      },
    },
  };

  const barOptions = {
    pointLabel: true,
    responsive: true,
    layout: {
      padding: {
        top: 5,
        left: 15,
        right: 15,
        bottom: 15,
      },
    },
    plugins: {
      title: {
        display: true,
        text: intl.formatMessage({ id: "reports.trend_heading", defaultMessage: "Trend" }),
      },
      tooltip: {
        enabled: false,
        position: "nearest",
        external: customTooltip,
      },
      datalabels: {
        color: "#fff",
        display: false,
        formatter: function (value) {
          return value.response_pct + "%";
        },
      },
      legend: {
        position: "top",
        labels: {
          usePointStyle: true,
          useBorderRadius: true,
          borderRadius: 4,
        },
      },
    },

    barThickness: 50,
    parsing: {
      xAxisKey: "grouped_date",
      yAxisKey: "response_pct",
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        stacked: true,

        ticks: {
          callback: function (value, index, ticks) {
            return formatTimeTick(this.getLabelForValue(value), interval, intl.locale);
          },
        },
      },
      y: {
        min: 0,
        max: 100,
        stacked: true,
        ticks: {
          // Include a dollar sign in the ticks
          callback: function (value, index, ticks) {
            return value + "%";
          },
        },
        title: {
          display: true,
          text: intl.formatMessage({ id: "reports.percent_share", defaultMessage: "Share" }),
        },
        grid: {
          display: true,
        },
        border: {
          display: true,
        },
      },
    },
  };

  const allDatelabels = chartData.map(o => o.data.map(d => d.grouped_date)).flat();
  const uniqDateLabels = allDatelabels.filter(onlyUnique).sort((a, b) => {
    let da = new Date(a),
      db = new Date(b);
    return da - db;
  });
  //console.log('alllabels', allDatelabels);

  console.log("uniqLabels", uniqDateLabels);
  //const uniqLabels = [...new Set(alllabels)];
  const data = {
    labels: uniqDateLabels,
    datasets: chartData.map((resp, index) => {
      return {
        label: resp[dataKey],
        data: resp["data"],
        borderRadius: "6",
        backgroundColor: qtype == 1 ? tagColors2(resp[dataKey]) : graphColors(chartData.length)[index],
        borderColor: qtype == 1 ? tagColors2(resp[dataKey]) : graphColors(chartData.length)[index],
      };
    }),
  };

  return (
    <Box>
      {chartType == "bar" && (
        <>
          <Bar ref={chartRef} options={barOptions} data={data} />
          {tooltipPos && (
            <TimeseriesTooltip
              data={tooltipData}
              position={tooltipPos}
              visibility={tooltipVisible}
              interval={interval}
            />
          )}
        </>
      )}
      {chartType == "line" && (
        <>
          <Line ref={chartRef} options={lineOptions} data={data} />
          {tooltipPos && (
            <TimeseriesTooltip
              data={tooltipData}
              position={tooltipPos}
              visibility={tooltipVisible}
              interval={interval}
            />
          )}
        </>
      )}
    </Box>
  );
}

function TimeseriesTooltip({ data, position, visibility, interval }) {
  const intl = useIntl();
  return (
    <div
      style={{
        maxWidth: "250px",
        maxHeight: "200px",
        position: "absolute",
        top: position?.top,
        left: position?.left,
        opacity: visibility ? 1 : 0,
        pointerEvents: "none",
      }}
    >
      <Card shadow="sm" p="lg" radius="md" withBorder>
        <Text weight={700}>{formatTimeTick(data.title[0], interval, intl.locale)}</Text>

        <List spacing="xs" size="sm" center>
          {data.dataPoints.map((val, index) => {
            return (
              <List.Item
                key={index}
                icon={<ThemeIcon color={val?.dataset.backgroundColor} size={10} radius="xl"></ThemeIcon>}
              >
                <Text color="dark.5" size={14}>
                  {val?.dataset.label}
                  {":"} {val?.raw.response_pct + "%"}
                </Text>
              </List.Item>
            );
          })}
        </List>
      </Card>
    </div>
  );
}
