import React, { useState, useEffect } from 'react';
//import Select from 'react-select';
import { Group, Text, Textarea, Space, Button, Divider, Accordion } from '@mantine/core';
import { IconCircleCheck } from '@tabler/icons-react';
import { pluralize } from '../../../../utils/helpers';

import { TextualTag, StyledSelect } from '../../../../components/MantineOverride';
// import { wrap } from '@sentry/react';

export function EmailContent(props) {
  const { dispatch, formState, prodToken } = props;
  let options = formState.surveyOptions;

  const [accordionItem, setAccordionItem] = useState('platform');

  function handleAccordionChange(e) {
    console.log('According Change', e);
    setAccordionItem(e);
  }

  useEffect(() => {
    //this is effect is to synchronise formState update
    //Ref: https://samuelsson.dev/update-state-with-usereducer-synchronously/
  }, [formState]);

  function handleInputChange(value, section, field) {
    console.log('$123 value in radio group input is', value);
    dispatch({
      type: 'settingChange',
      value: value,
      section: section,
      field: field,
    });
  }

  function addFilter(e) {
    e.preventDefault();
    dispatch({
      type: 'addFilter',
    });
  }

  return (
    <>
      <Space h="md" />
      <Accordion
        variant="separated"
        onChange={e => handleAccordionChange(e)}
        radius="md"
        defaultValue="platform"
        multiple
        styles={{
          item: {
            // styles added to all items
            backgroundColor: '#fff',
            border: '1px solid #ededed',

            // styles added to expanded item
            '&[data-active]': {
              backgroundColor: '#fff',
            },
          },

          chevron: {
            // styles added to chevron when it should rotate
            '&[data-rotate]': {
              transform: 'rotate(-90deg)',
            },
          },
        }}
      >
        <Space h="md" />
        <Accordion.Item value="email-delivery">
          <Accordion.Control>
            <Group position="apart">
              <div>
                <Text size="md" weight={400}>
                  Delivery Platform
                </Text>
                <Text size="xs" color="dimmed">
                  Select email delivery partner
                </Text>
              </div>
              <Group>
                {accordionItem === 'email-delivery' ? null : options.attributeFilters ? (
                  <Group>
                    <TextualTag>{pluralize(options.attributeFilters.length, 'Filter', 's')}</TextualTag>
                    <IconCircleCheck size={30} color="rgb(81, 207, 102)" />
                  </Group>
                ) : null}
              </Group>
            </Group>
            {accordionItem === 'email-delivery' ? <Divider></Divider> : null}
          </Accordion.Control>
          <Accordion.Panel>
            <StyledSelect
              value={options.email?.platform}
              onChange={val => handleInputChange(val, 'email', 'platform')}
              label="Email Delivery Platform"
              placeholder="Pick one"
              data={[
                { value: 'zendesk', label: 'Zendesk', icon: '' },
                { value: 'custom', label: 'Custom', icon: '' },
              ]}
            />
          </Accordion.Panel>
        </Accordion.Item>
        <Space h="md" />

        <Accordion.Item value="email-content">
          <Accordion.Control>
            <Group position="apart">
              <div>
                <Text size="md" weight={400}>
                  Email Content
                </Text>
                <Text size="xs" color="dimmed">
                  Customize email messaging.
                </Text>
              </div>
              <Group>
                {accordionItem === 'email-content' ? null : options.attributeFilters ? (
                  <Group>
                    <TextualTag>{pluralize(options.attributeFilters.length, 'Filter', 's')}</TextualTag>
                    <IconCircleCheck size={30} color="rgb(81, 207, 102)" />
                  </Group>
                ) : null}
              </Group>
            </Group>
            {accordionItem === 'email-content' ? <Divider></Divider> : null}
          </Accordion.Control>
          <Accordion.Panel>
            <Textarea
              value={options.email?.introduction}
              onChange={e => handleInputChange(e.target.value, 'email', 'introduction')}
              placeholder="Introduction"
              label="Introduction"
              withAsterisk
            />
            <Space h="md" />
            <Textarea
              value={options.email?.ending}
              onChange={e => handleInputChange(e.target.value, 'email', 'ending')}
              placeholder="Ending"
              label="Ending"
              withAsterisk
            />

            <Space h="md" />
            <Button type="button" onClick={e => addFilter(e)} className="space10">
              Add Filter
            </Button>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>

      <Space h="md" />
    </>
  );
}
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="584229b4-0b03-5e06-bdae-34b2375fa677")}catch(e){}}();
//# debugId=584229b4-0b03-5e06-bdae-34b2375fa677
