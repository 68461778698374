import React, { useCallback } from 'react';
import { RichTextEditor, Link, useRichTextEditorContext } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import { IconStar } from '@tabler/icons-react';

import { cleanContent } from '../utils/helpers';

export default function RTE({ handleChange, mainContent }) {
  const content = mainContent;

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link.configure({
        HTMLAttributes: {
          rel: 'noopener noreferrer',
          target: '_top',
        },
      }),
      Highlight,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
    ],
    content,
    onUpdate({ editor }) {
      console.log('editor', editor.getHTML());
      handleChange(cleanContent(editor.getHTML()), 'content');
    },
  });

  return (
    <RichTextEditor editor={editor}>
      <RichTextEditor.Toolbar sticky stickyOffset={60}>
        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Bold />
          <RichTextEditor.Italic />
          <RichTextEditor.Underline />
          <RichTextEditor.Strikethrough />
          <RichTextEditor.ClearFormatting />
          <RichTextEditor.Highlight />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.H1 />
          <RichTextEditor.H2 />
          <RichTextEditor.H3 />
          <RichTextEditor.H4 />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.BulletList />
          <RichTextEditor.OrderedList />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Link />
          <RichTextEditor.Unlink />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.AlignLeft />
          <RichTextEditor.AlignCenter />
          <RichTextEditor.AlignJustify />
          <RichTextEditor.AlignRight />
        </RichTextEditor.ControlsGroup>
        <InsertEmojiControl />
      </RichTextEditor.Toolbar>

      <RichTextEditor.Content />
    </RichTextEditor>
  );
}

function InsertEmojiControl() {
  const { editor } = useRichTextEditorContext();
  return (
    <RichTextEditor.ControlsGroup>
      <RichTextEditor.Control
        onClick={() => editor?.commands.insertContent('🥰')}
        aria-label="Insert smiling face with hearts emoji"
        title="Insert smiling face with hearts emoji"
      >
        🥰
      </RichTextEditor.Control>
      <RichTextEditor.Control
        onClick={() => editor?.commands.insertContent('🙂')}
        aria-label="Insert slightly smiling face emoji"
        title="Insert grinning face emoji"
      >
        🙂
      </RichTextEditor.Control>
      <RichTextEditor.Control
        onClick={() => editor?.commands.insertContent('🙏')}
        aria-label="Insert grinning face emoji"
        title="Insert grinning face emoji"
      >
        🙏
      </RichTextEditor.Control>
      <RichTextEditor.Control
        onClick={() => editor?.commands.insertContent('❤️')}
        aria-label="Insert orange heart emoji"
        title="Insert orange heart emoji"
      >
        🧡
      </RichTextEditor.Control>
      <RichTextEditor.Control
        onClick={() => editor?.commands.insertContent('✨')}
        aria-label="Insert sparkle emoji"
        title="Insert sparkle emoji"
      >
        ✨
      </RichTextEditor.Control>
      <RichTextEditor.Control
        onClick={() => editor?.commands.insertContent('🔥')}
        aria-label="Insert fire emoji"
        title="Insert fire emoji"
      >
        🔥
      </RichTextEditor.Control>
      <RichTextEditor.Control
        onClick={() => editor?.commands.insertContent('⭐')}
        aria-label="Insert star emoji"
        title="Insert star emoji"
      >
        ⭐
      </RichTextEditor.Control>
      <RichTextEditor.Control
        onClick={() => editor?.commands.insertContent('🎁')}
        aria-label="Insert wrapped gift emoji"
        title="Insert wrapped gift emoji"
      >
        🎁
      </RichTextEditor.Control>
    </RichTextEditor.ControlsGroup>
  );
}
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="b90ad040-793d-5697-82b7-c6b5b6fd01a2")}catch(e){}}();
//# debugId=b90ad040-793d-5697-82b7-c6b5b6fd01a2
