import React from 'react';
import { Link } from 'react-router-dom';
import { Button, createStyles, Container, Overlay, Title, Text, Group, Anchor, Header } from '@mantine/core';

const useStyles = createStyles(theme => ({
  root: {
    // paddingTop: 80,
    // paddingBottom: 80,
    maxWidth: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
  },

  footer: {
    //marginTop: 120,
    borderTop: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]}`,
  },

  footer_inner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,

    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column',
    },
  },

  links: {
    [theme.fn.smallerThan('xs')]: {
      marginTop: theme.spacing.md,
    },
  },
  hero: {
    position: 'relative',
    backgroundImage:
      'url(https://images.unsplash.com/photo-1510133768164-a8f7e4d4e3dc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2342&q=80)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },

  container: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    //paddingBottom: `calc(${theme.spacing.xl} * 6)`,
    zIndex: 1,
    position: 'relative',

    [theme.fn.smallerThan('sm')]: {
      height: 500,
      paddingBottom: `calc(${theme.spacing.xl} * 3)`,
    },
  },

  title: {
    color: theme.white,
    fontSize: 60,
    fontWeight: 900,
    lineHeight: 1.1,

    [theme.fn.smallerThan('sm')]: {
      fontSize: 40,
      lineHeight: 1.2,
    },

    [theme.fn.smallerThan('xs')]: {
      fontSize: 28,
      lineHeight: 1.3,
    },
  },

  description: {
    color: theme.white,
    maxWidth: 600,
    textAlign: 'center',

    [theme.fn.smallerThan('sm')]: {
      maxWidth: '100%',
      fontSize: theme.fontSizes.sm,
    },
  },

  control: {
    marginTop: `calc(${theme.spacing.xl} * 1.5)`,

    [theme.fn.smallerThan('sm')]: {
      width: '100%',
    },
  },
}));

export default function Page404() {
  const { classes } = useStyles();

  return (
    <div id="404-page">
      <div className={classes.hero}>
        <Overlay
          gradient="linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, .65) 40%)"
          opacity={1}
          zIndex={0}
        />
        <Header height={60} mb={120}>
          <Container className={classes.header} fluid>
            <Group>
              <Text></Text>
            </Group>
          </Container>
        </Header>
        <Container className={classes.container}>
          <Title className={classes.title}>404</Title>
          <Text className={classes.description} size="xl" mt="xl">
            Sorry, the page you are looking for does not exit.
          </Text>
          <Link to="/">
            <Button size="md" radius="xl" className={classes.control} mt="lg">
              {' '}
              {"Let's get you home"}
            </Button>
          </Link>
        </Container>

        <footer className={classes.footer}>
          <Container className={classes.footer_inner}>
            <Group className={classes.links}>
              <Text className={classes.description}>
                Photo by{' '}
                <Anchor
                  className={classes.description}
                  sx={{ textDecoration: 'underline' }}
                  href="https://unsplash.com/@dallehj?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
                  target="_blank"
                >
                  Daniel Jensen
                </Anchor>{' '}
                on{' '}
                <Anchor
                  className={classes.description}
                  sx={{ textDecoration: 'underline' }}
                  target="_blank"
                  href="https://unsplash.com/photos/UDleHDOhBZ8?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
                >
                  Unsplash
                </Anchor>
              </Text>
            </Group>
          </Container>
        </footer>
      </div>
      {/* <Container className={classes.root}>
        <div className={classes.inner}>
          <Image src={Image404} className={classes.image} />

          <div className={classes.content}>
            <Title className={classes.title}>Lost, are we?</Title>
            <Text color="dimmed" size="lg" align="center" className={classes.description}>
              The page you are looking for does not exit.
            </Text>
            <Group position="center" mt="lg">
              <Link to="/">
                <Button size="md"> Back to home</Button>
              </Link>
            </Group>
            <footer className={classes.footer}>
              <Container className={classes.footer_inner}>
                <Group className={classes.links}>
                  Photo by{' '}
                  <Anchor
                    href="https://unsplash.com/@dallehj?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
                    target="_blank"
                  >
                    Daniel Jensen
                  </Anchor>{' '}
                  on{' '}
                  <Anchor href="https://unsplash.com/photos/UDleHDOhBZ8?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
                    Unsplash
                  </Anchor>
                </Group>
              </Container>
            </footer>
          </div>
        </div>
      </Container> */}
    </div>
  );
}
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="dd185092-2618-50ae-9be3-68eebec6c4f2")}catch(e){}}();
//# debugId=dd185092-2618-50ae-9be3-68eebec6c4f2
