import React from 'react';
import { Button, Group, TextInput, PasswordInput } from '@mantine/core';
import FormFieldError from '../../../../components/FormFieldError';

export function SignupForm(props) {
  const { form, error, loading, next } = props;

  return (
    <>
      <TextInput label="Full Name" placeholder="John Snow" {...form.getInputProps('fullName')} />
      <TextInput mt="md" label="Email" placeholder="your@email.com" {...form.getInputProps('email')} />
      {console.log('Signup form error', error)}
      {error && error.email ? <FormFieldError errors={error.email} /> : null}
      <PasswordInput mt="md" label="Password" placeholder="Password" {...form.getInputProps('password')} />
      <Group position="center" mt="md">
        <Button fullWidth mt="xl" type="button" id="continueButton" onClick={next} disabled={loading}>
          Continue
        </Button>
      </Group>
    </>
  );
}
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="b8749933-0e4f-53d7-b7a0-e3babb428d91")}catch(e){}}();
//# debugId=b8749933-0e4f-53d7-b7a0-e3babb428d91
