import React from "react";
import { Modal, useMantineTheme, Input, Text, Box } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { IconCircleCheck, IconX } from "@tabler/icons-react";
import { surveyLinkURLNew } from "../../../utils/config";
import CopyValueIcon from "../../../components/CopyValueIcon";

export function SurveyLinkModal(props) {
  const { opened, setOpened, survey, token, emailPlatform, userIdentifierKey } = props;
  const navigate = useNavigate();
  const theme = useMantineTheme();

  const surveyURL = surveyLinkURLNew(token, survey.code, userIdentifierKey, emailPlatform);

  return (
    <Modal
      size={550}
      withCloseButton={false}
      opened={opened}
      radius="md"
      onClose={() => {
        setOpened(false);
        navigate(`/surveys/${survey.id}/responses/report`);
      }}
      overlayColor={theme.colorScheme === "dark" ? theme.colors.dark[9] : theme.colors.gray[2]}
      overlayOpacity={0.55}
      overlayBlur={3}
    >
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", margin: "20px" }}>
        <IconX
          style={{ position: "absolute", top: "10px", right: "10px", cursor: "pointer" }}
          color="#a9a6a6"
          onClick={() => {
            setOpened(false);
            navigate(`/surveys/${survey.id}/responses/report`);
          }}
        />
        <div style={{ textAlign: "center", marginBottom: "30px" }}>
          <IconCircleCheck stroke={1} size={150} color="#16DB93" />
          <Text weight={700}>Your survey is live!</Text>
          <Text>Share this link with your users.</Text>
        </div>
        <Input
          variant="filled"
          sx={{ width: "80%" }}
          size="md"
          radius="xs"
          component="button"
          rightSection={<CopyValueIcon value={surveyURL} />}
        >
          <Text sx={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }} size={16}>
            {surveyURL}
          </Text>
        </Input>
      </Box>
    </Modal>
  );
}
