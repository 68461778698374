import React, { memo } from 'react';
import { Card, List, Text, ThemeIcon } from '@mantine/core';
import { formatTimeTick } from '../../../../../utils/helpers';

function TextTooltip({ data, position, visibility, timeframe }) {
  return (
    <div
      style={{
        maxWidth: '250px',
        maxHeight: '200px',
        position: 'absolute',
        top: position?.top,
        left: position?.left,
        opacity: visibility ? 1 : 0,
        pointerEvents: 'none',
      }}
    >
      <Card shadow="sm" p="lg" radius="md" withBorder>
        <Text weight={700}>{formatTimeTick(data.title[0], timeframe)}</Text>

        <List spacing="xs" size="sm" center>
          {data.dataPoints.map((val, index) => {
            return (
              <List.Item
                key={index}
                icon={<ThemeIcon color={val?.dataset.backgroundColor} size={10} radius="xl"></ThemeIcon>}
              >
                <Text color="dark.5" size={14}>
                  {val?.dataset.label}
                  {':'} {val?.raw.response_pct + '%'}
                </Text>
              </List.Item>
            );
          })}
        </List>
      </Card>
    </div>
  );
}
export default memo(TextTooltip);
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="5cdde313-5ed6-571e-a79f-88bcc0576162")}catch(e){}}();
//# debugId=5cdde313-5ed6-571e-a79f-88bcc0576162
