import React, { useState, useEffect } from "react";
import { Card, Group, Space, Switch, Collapse, Text } from "@mantine/core";
import { StyledTextInput } from "../../../components/MantineOverride";
import { IconChevronDown, IconTrash } from "@tabler/icons";
import { useIntl } from "react-intl";
import { DeleteIcon } from "../../../components/UI";

import RTE from "../../../components/RTE";

export function Introduction({
  details,
  dispatch,
  setCurrentQuestion,
  handleDelete,
  setAccordionItem,
  accordianItem,
  opened,
  setOpened,
}) {
  const intl = useIntl();

  // const [opened, setOpened] = useState(true);

  function handleOpen(e) {
    e.preventDefault();
    setOpened(o => !o);
  }

  useEffect(() => {
    console.log("#768 accordianItem in Introduction", accordianItem);
    if (accordianItem) {
      setOpened(false);
    }
  }, [accordianItem]);

  useEffect(() => {
    if (opened) {
      setCurrentQuestion(null);
      setAccordionItem(null);
    }
  }, [opened]);

  function handleChange(val, section) {
    dispatch({
      type: "updateIntroduction",
      section: section,
      value: val,
    });
  }

  useEffect(() => {
    console.log("details in Introduction", details);
  }, [details]);

  return (
    <>
      <Card sx={{ overflow: "visible" }} shadow="sm" p="lg" radius="md" mt="md" mb="xs" withBorder>
        <Group position="apart" onClick={e => handleOpen(e)} sx={{ cursor: "pointer" }}>
          <Text size={16}>
            {intl.formatMessage({
              id: "survey.introduction",
              defaultMessage: "Introduction",
            })}
          </Text>

          <Group onClick={e => e.stopPropagation()}>
            {opened && <DeleteIcon handleClick={handleDelete} />}

            <IconChevronDown
              size={18}
              stroke={1.5}
              color="rgb(23, 23, 56)"
              onClick={e => handleOpen(e)}
              style={{
                transition: "transform 400ms ease-in-out",
                transform: `rotate(${opened ? 180 : 0}deg)`,
              }}
            />
          </Group>
        </Group>

        <Collapse in={opened} animateOpacity transitionDuration={300} transitionTimingFunction="ease">
          <Space h="md" />
          <div className="shqQuestion">
            <RTE handleChange={handleChange} mainContent={details && details.content} />

            <Space h="md" />

            <Group grow>
              <StyledTextInput
                label={intl.formatMessage({
                  id: "survey.introduction_button_label",
                  defaultMessage: "Button text (leads to first question)",
                })}
                id="primary_button_text"
                name="primary_button_tex"
                value={details && details.primaryButtonText}
                onChange={e => handleChange(e.target.value, "primaryButtonText")}
              />
            </Group>
            <Space h="md" />
          </div>
        </Collapse>
      </Card>
    </>
  );
}
