import React, { useState } from 'react';
import { useAuthState } from '../../../context/AuthContext';
import { Link } from 'react-router-dom';
import { Card, Paper, Container, Text, Title, TextInput, Button, Group, Box, Space } from '@mantine/core';
import { ErrorBox } from '../../../components/MantineOverride';
import API from '../../../api/API';
import { API_URLS } from '../../../utils/constants';
import { IconArrowNarrowLeft, IconCircleCheck } from '@tabler/icons-react';

import { useForm } from '@mantine/form';

export default function ForgotPassword() {
  const [loader, setLoader] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState();
  const { csrftoken } = useAuthState();

  const form = useForm({
    initialValues: {
      email: '',
    },

    validate: {
      email: value => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });

  async function handleSubmit(data, event) {
    let response = await API.post(API_URLS.SEND_RESET_TOKEN, { ...data }, csrftoken);
    console.log(response);
    if (response.success) {
      setSuccess(true);
      console.log('forgot password success', response.success);
      //dispatch({ type: 'AUTH_SUCCESS', payload: response.success.data.user });
    } else {
      setError(response.error);
      //dispatch({ type: 'AUTH_ERROR', error: response.error });
      console.log('forgot password error', response.error);
    }
  }

  if (success) {
    return (
      <Container size={420} my={40}>
        <Paper shadow="md" p={30} mt={30} radius="md" withBorder>
          <Box mx="auto">
            <Group position="center">
              <IconCircleCheck align="center" color="#51cf66" size={50} stroke={1} />
            </Group>

            <Title align="center" color="green">
              Email sent
            </Title>
            <Text color="dimmed" size="sm" align="center" mt={10}>
              If an account exists with this email address, you will recieve an email with the instructions to reset
              your password.
            </Text>
            <Group position="center" mt={30}>
              <Link to="/login">
                <Button leftIcon={<IconArrowNarrowLeft />}>Back to login</Button>
              </Link>
            </Group>
          </Box>
        </Paper>
      </Container>
    );
  } else {
    return (
      <Container size={520} my={40}>
        <Title align="center" sx={{ fontWeight: 900 }}>
          Forgot Password?
        </Title>
        <Text color="dimmed" size="sm" align="center" mt={5}>
          Enter your email and we will send you instructions on how to reset your password.
        </Text>
        <Card shadow="md" p={30} mt={30} radius="md" withBorder>
          {error && error.message ? (
            <>
              <ErrorBox
                title={error.message}
                color="red"
                withCloseButton
                onClose={() => setError(null)}
                closeButtonLabel="Close alert"
              ></ErrorBox>
            </>
          ) : null}
          <Box mx="auto">
            <form onSubmit={form.onSubmit((values, event) => handleSubmit(values, event))}>
              <TextInput label="Email" placeholder="your@email.com" {...form.getInputProps('email')} />

              <Group position="center">
                <Button type="submit" fullWidth mt="xl">
                  Send password reset email
                </Button>
              </Group>
              <Group position="right" mt="md">
                <Link to="/login" style={{ fontSize: '1.6rem', color: '#228be6' }}>
                  Back to login
                </Link>
              </Group>
            </form>

            <Space h="md" />
          </Box>
        </Card>
      </Container>
    );
  }
}
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="f618dfc1-78e2-524c-8210-c81bdeb72990")}catch(e){}}();
//# debugId=f618dfc1-78e2-524c-8210-c81bdeb72990
