import APIBase from "./APIBase";
import axios from "axios";
//import { useAuthDispatch } from '../context/AuthContext';
import { decamelizeKeys } from "humps";

const API = {
  get,
  post,
  multipart_put,
  put,
  patch,
  delete: _delete,
};

function get(url, params) {
  const requestOptions = {
    params: params,
  };
  return APIBase.get(url, requestOptions)
    .then(response => handleResponse(response))
    .catch(error => handleError(error));
}

function post(url, requestData, csrfToken, params) {
  let body = JSON.stringify(decamelizeKeys(requestData));
  const requestOptions = {
    headers: { "X-CSRFTOKEN": csrfToken },
    params: params,
  };
  console.log("requestOptions in post ", requestOptions);

  return APIBase.post(url, body, requestOptions)
    .then(response => handleResponse(response))
    .catch(error => handleError(error));
}
function multipart_put(url, requestData, csrfToken) {
  return axios
    .put(url, requestData, {
      withCredentials: true,
      headers: { "X-CSRFTOKEN": csrfToken, "Content-Type": "multipart/form-data" },
    })
    .then(response => handleResponse(response))
    .catch(error => handleError(error));
}

function put(url, requestData, csrfToken, params) {
  let body = JSON.stringify(requestData);
  const requestOptions = {
    headers: { "X-CSRFTOKEN": csrfToken },
    params: params,
  };
  return APIBase.put(url, body, requestOptions)
    .then(response => handleResponse(response))
    .catch(error => handleError(error));
}

function patch(url, requestData, csrfToken, params) {
  let body = JSON.stringify(requestData);
  const requestOptions = {
    headers: { "X-CSRFTOKEN": csrfToken },
    params: params,
  };
  return APIBase.patch(url, body, requestOptions)
    .then(response => handleResponse(response))
    .catch(error => handleError(error));
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(url, csrfToken) {
  const requestOptions = {
    headers: { "X-CSRFTOKEN": csrfToken },
  };
  return APIBase.delete(url, requestOptions).then(handleResponse).catch(handleError);
}

// helper functions

function handleResponse(response) {
  console.log("Response in handleResponse", response);

  if (parseInt(response.status) >= 400) {
    const error = (response.data && response.data.message) || response.status;
    console.log("error in 403", error);
    return Promise.reject(error);
  }

  return { success: response };
}

function handleError(error) {
  console.log("Bingo Bango", error);
  if (error.response) {
    console.log("error response", error.response);
    const errorData = error.response.data;
    console.log(errorData);
    console.log(error.response.status);
    return { error: errorData };
    //console.log(error.response.headers);
  } else if (error.request) {
    console.log(error.request);
    return { error: error.request };
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log("Error", error);
    return { error: error };
  }
  //console.log(error.config);
}

export default API;
