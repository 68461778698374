import React from 'react';
import { Menu } from '@mantine/core';
import { IconDotsVertical, IconTrash } from '@tabler/icons-react';

export function EllipsisMenu(props) {
  const { idx, clickHandler } = props;
  return (
    <Menu position="bottom">
      <Menu.Target>
        <div>
          <IconDotsVertical size={14} />
        </div>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item onClick={e => clickHandler(e, idx)}>
          <IconTrash size={14} />
          Delete
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="9132d2bf-25fe-5e03-a4cd-b0d9bc3e5240")}catch(e){}}();
//# debugId=9132d2bf-25fe-5e03-a4cd-b0d9bc3e5240
