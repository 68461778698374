export const alertMessages = {
  cannot_edit_survey: "Only the account owner or the editors can edit a survey.",
  cannot_create_survey: "Only the account owner or the editors can create a survey.",
  cannot_add_team_member: "Only the account owner can add a team member.",
};

export const successMessages = {
  user_deleted: "User deleted",
  invitation_sent: member => `Invitation sent to ${member.fullName}`,
};

export const userIdentifiers = {
  email: "respondent_email",
  uid: "respondent_id",
};

export const userIdentifierTag = (uniqueIdentifier, platform) => {
  switch (uniqueIdentifier) {
    case userIdentifiers["email"]:
      return `${userIdentifiers["email"]}=${platformEmailTag(platform)}`;
    case userIdentifiers["uid"]:
      return `${userIdentifiers["uid"]}=<any_string_identifier>`;
  }
};

export function surveyLinkURLNew(accId, surveycode, userIdentifier, emailPlatform) {
  const surveylinkcode = window.btoa(`${accId}|${surveycode}`);
  const userTag = userIdentifier ? userIdentifierTag(userIdentifier, emailPlatform) : "";
  return `${process.env.REACT_APP_SURVEY_LINK}${surveylinkcode}${userTag.length > 0 ? `?${userTag}` : ""}`;
}

export const platformEmailTag = platform => {
  switch (platform) {
    case "zendesk":
      return "{{ticket.requester.email}}";
    case "sendgrid":
      return "{{email}}";
    case "mailchimp":
      return "*|EMAIL|*";
    case "mailgun":
      return "%recipient_email%";
    case "other":
      return "<set_email_here>";
    default:
      return "<set_email_here>";
  }
};
