export const comparatorByQuestionType = [
  { value: "gt", label: "is greater than", qtype: [4, 5], operator: ">" },
  { value: "gte", label: "is greater than or equal to", qtype: [4, 5], operator: ">=" },
  { value: "lt", label: "is less than", qtype: [4, 5], operator: "<" },
  { value: "lte", label: "is less than or equal to", qtype: [4, 5], operator: "<=" },
  { value: "eq", label: "is equal to", qtype: [2, 3, 4, 5], operator: "=" },
  { value: "neq", label: "is not equal to", qtype: [2, 4, 5], operator: "!=" },
  { value: "con", label: "contains", qtype: [1], operator: "contains" },
  { value: "dcon", label: "does not contain", qtype: [1], operator: "does not contain" },
];

function ratingOptions(n) {
  let ratings = [];
  for (let i = 1; i <= n; i++) {
    ratings.push({ value: i, label: i });
  }
  return ratings;
}

export function ratingOptionsByQtype(qtype) {
  if (qtype === 4) {
    return ratingOptions(10);
  } else if (qtype === 5) {
    return ratingOptions(5);
  }
}

export function filteredLogicOptions(options, questionType) {
  return options.filter(x => x.qtype.find(y => y === questionType));
}

export function choiceOptions(choices) {
  let choiceList = [];
  for (let i = 0; i < choices.length; i++) {
    choiceList.push({ value: choices[i].choice, label: choices[i].choice });
  }
  return choiceList;
}

export function filteredPredicateOptions(question, predicate) {
  if (Array.isArray(predicate)) {
    return choiceOptions(question.choices).find(x => predicate.find(l => l === x.value));
  } else {
    return choiceOptions(question.choices).find(x => predicate.value === x.value);
  }
}
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="d2e25aa5-1f63-5283-910f-6c95d2facc69")}catch(e){}}();
//# debugId=d2e25aa5-1f63-5283-910f-6c95d2facc69
