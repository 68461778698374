import React from "react";
import { Modal, useMantineTheme, Input, Text, Box, CopyButton, Button, Space } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { IconCircleCheck, IconX } from "@tabler/icons-react";
import { embedEmailContent } from "../../../utils/embedEmailContent";
import { Prism } from "@mantine/prism";

import vsLight from "prism-react-renderer/themes/vsLight";
import vsDark from "prism-react-renderer/themes/vsDark";

import { surveyLinkURL } from "../../../utils/helpers";
import { userIdentifierTag } from "../../../utils/config";

export function SurveyEmailModal(props) {
  const { opened, setOpened, survey, token } = props;
  console.log("!!survey", survey);
  const navigate = useNavigate();
  const theme = useMantineTheme();

  const surveyURL = surveyLinkURL(token, survey.code);
  const userTag =
    survey.survey_options && survey.survey_options.user_identifier_key
      ? userIdentifierTag(survey.survey_options.user_identifier_key, survey.survey_options.email?.platform)
      : survey.surveyOptions && survey.surveyOptions.user_identifier_key
      ? userIdentifierTag(survey.surveyOptions.user_identifier_key, survey.surveyOptions.email?.platform)
      : "";

  return (
    <Modal
      size="auto"
      withCloseButton={false}
      opened={opened}
      radius="md"
      onClose={() => {
        setOpened(false);
        navigate(`/surveys/${survey.id}/responses/report`);
      }}
      overlayColor={theme.colorScheme === "dark" ? theme.colors.dark[9] : theme.colors.gray[2]}
      overlayOpacity={0.55}
      overlayBlur={3}
    >
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", margin: "20px" }}>
        <IconX
          style={{ position: "absolute", top: "10px", right: "10px", cursor: "pointer" }}
          color="#a9a6a6"
          onClick={() => {
            setOpened(false);
            navigate(`/surveys/${survey.id}/responses/report`);
          }}
        />
        <div style={{ textAlign: "center", marginBottom: "30px" }}>
          <IconCircleCheck stroke={1} size={150} color="#16DB93" />
          <Text weight={700}>Your survey is now live!</Text>
          <Text>Copy and embed this html in your email.</Text>
        </div>
        <div style={{ maxWidth: "800px" }}>
          <Prism
            scrollAreaComponent="div"
            language="html"
            sx={{ border: "1px dotted #ddd" }}
            getPrismTheme={(_theme, colorScheme) => (colorScheme === "light" ? vsLight : vsDark)}
          >
            {embedEmailContent(survey.questions[0], surveyURL, userTag)}
          </Prism>
        </div>
        <Space h="md" />
        <CopyButton value={embedEmailContent(survey.questions[0], surveyURL, userTag)}>
          {({ copied, copy }) => (
            <Button color={copied ? "teal" : "blue"} onClick={copy}>
              {copied ? "Copied HTML" : "Copy HTML"}
            </Button>
          )}
        </CopyButton>
      </Box>
    </Modal>
  );
}
