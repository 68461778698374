import React from "react";
import { Card, Group, Space, Checkbox } from "@mantine/core";
import { StyledTextInput } from "components/MantineOverride";
import RTE from "components/RTE";

export function Redirect({ idx, question, dispatch }) {
  function handleChange(val, section) {
    dispatch({
      type: "updateStatement",
      section: section,
      value: val,
      index: idx,
    });
  }

  return (
    <>
      <div className="shqQuestion">
        <RTE handleChange={handleChange} mainContent={question.options.content} />

        <Space h="md" />

        <Group grow>
          <StyledTextInput
            label="Button Text"
            id="st_prim_btn_text"
            name="st_prim_btn_text"
            value={question.options.primaryButtonText}
            onChange={e => handleChange(e.target.value, "primaryButtonText")}
          />
          <StyledTextInput
            label="Button Link"
            id="st_prim_btn_link"
            name="st_prim_btn_link"
            value={question.options.primaryButtonLink}
            onChange={e => handleChange(e.target.value, "primaryButtonLink")}
          />
        </Group>
        {/* <Group grow sx={{ alignItems: "flex-end" }}>
          <StyledTextInput
            label="Secondary text link"
            id="st_sec_btn_text"
            name="st_sec_btn_text"
            value={question.options.secondaryButtonText}
            onChange={e => handleChange(e.target.value, "primaryButtonText")}
          />
          <Checkbox
            checked={question.options.hideSecondaryButton}
            onChange={e => handleChange(e.currentTarget.checked, "hideSecondaryButton")}
            label="Hide secondary button."
          />
        </Group> */}
        <Space h="md" />
      </div>
    </>
  );
}
