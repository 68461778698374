import React from 'react';
//import { Select } from '@mantine/core';
import { StyledSelect } from '../../../../components/MantineOverride';
import { IconChevronDown } from '@tabler/icons-react';

const randomizationOptions = [
  { value: 'kco', label: 'Keep current Order' },
  { value: 'rc', label: 'Randomize choices' },
  { value: 'klrr', label: 'Keep last choice in place and randomize others ' },
];

export default function RandomizeChoices(props) {
  const { dispatch, qIndex, qOptions } = props;
  console.log('randomize option', qOptions.randomizeChoices);
  console.log('default randomizaton', randomizationOptions[0]);

  function handleSelectChange(val, section) {
    dispatch({
      type: section,
      index: qIndex,
      value: val,
    });
    console.log('qOptions in RandomizeChoices', qOptions);
  }

  return (
    <>
      <StyledSelect
        label="Randomize choice ordering"
        defaultValue={randomizationOptions[0].value}
        onChange={val => handleSelectChange(val, 'choiceRandomization')}
        data={randomizationOptions}
        rightSection={<IconChevronDown size={14} />}
        styles={() => ({
          wrapper: { width: '360px' },
          root: { width: '360px' },
        })}
        //value={randomizationOptions.find(x => x.value === qOptions.randomizeChoices)}
      />
    </>
  );
}
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="0854fddb-9ab3-5eaf-9461-e266f057f444")}catch(e){}}();
//# debugId=0854fddb-9ab3-5eaf-9461-e266f057f444
