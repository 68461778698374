import dayjs from 'dayjs';

function isNull(input) {
  return input === null;
}

// function isArray(input) {
//   return input instanceof Array;
// }

function isNaN(input) {
  return input !== input;
}

function isNANorNUll(input) {
  return isNull(input) || isNaN(input);
}

export default function checkDataType(input) {
  if (isNANorNUll(input)) {
    return undefined;
  }
  switch (typeof input) {
    case 'string':
      return dayjs(input).isValid() ? 'date' : 'string';
    case 'number':
      return 'number';
    case 'boolean':
      return 'boolean';
    default:
      return undefined;
  }
}
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="138d85a4-17e4-58e6-82de-d235cc1c166f")}catch(e){}}();
//# debugId=138d85a4-17e4-58e6-82de-d235cc1c166f
