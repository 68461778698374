//Norwegian
export const no = {
  left_nav: {
    new_survey: "New Survey",
    survey_list: "Survey List",
    user_attributes: "User Attributes",
    connect: "Connect",
    account: "Account",
    organization: "Organization",
    team: "Team",
    settings: "Settings",
    logout: "Logout",
    help: "Help",
  },
  new_survey_page: {
    heading: "New Survey",
    subheading: "Use a template from below or create from scratch.",
    create_button: "Create Survey from scratch",
  },
  templates: {
    nps: "NPS",
    nps_desc: "How happy are your customers with the offering?",
    feature_launch: "Feature Launch",
    feature_launch_desc: "Ideal for assessing impact of newly launched features.",
    pmf: "Product Market Fit",
    pmf_desc: "Have you reached the fit?",
    feature_discovery: "Feature Discovery",
    feature_discovery_desc: "Understand what features are most important to your users.",
    csat: "CSAT",
    csat_desc: "Measure how satisfied are your customers with your product or service?",
  },
  survey_list_page: {
    surveys: "Surveys",
    duplicate: "Duplicate",
    delete: "Delete",
    edit: "Edit",
    live: "Live",
    in_draft: "In Draft",
    paused: "Paused",
  },
  user_attributes_page: {
    heading: "User Attributes",
    attribute: "Attribute",
    type: "Type",
    last_received: "Last Received",
    month: "month",
    ago: "ago",
    months: "months",
    string: "String",
    boolean: "Boolean",
    number: "Number",
    date: "Date",
  },
  connect_page: {
    heading: "Connect",
    html_help_text:
      "Paste this code between the <head> and </head> on all the pages where you want to show the survey.",
    npm: "NPM",
    Yarn: "Yarn",
    npm_initialize_spark: "Next in your application, as early as possible, initialize Spark.",
    recommeneded: "Recommended",
  },
  org_page: {
    heading: "Organization",
    name: "Name",
    website: "Website",
    api_key: "API Key",
    view_code: "View installation code",
    logo: "Logo",
    logo_desc: "This logo will be displayed in the survey page.",
    upload_logo_btn: "Upload Logo",
  },
  team_page: {
    heading: "Team",
    name: "Name",
    email: "Email",
    access: "Access",
    date_joined: "Date Joined",
    members: "Members",
    add_member_btn: "Add Member",
    add_team_member: "Add Team Member",
    full_name: "Full Name",
    access_level: "Access Level",
    access_level_placeholder: "Select role",
    access_viewer: "Viewer",
    access_editor: "Editor",
    you: "You",
    role: "Role",
    status: "Status",
    invited: "Invited",
    accepted: "Accepted",
    rejected: "Rejected",
    resend_invite: "Resend Invite",
    cancel_invite: "Cancel Invite",
    remove_member: "Remove Member",
    edit: "Edit",
  },
  settings_page: {
    heading: "Settings",
    product: "Product",
    product_desc: "Name is used to automatically fill in survey templates.",
    name: "Name",
    website: "Website",
    timezone: "Timezone",
    save: "Save",
    appearance: "Appearance",
    appearance_desc: "Customize the look and feel of the survey.",
    background: "Background",
    background_desc: "Background color for the survey.",
    font_color: "Font Color",
    font_color_desc: "Question & options font color.",
    button: "Button",
    button_desc: "Call to action button background color.",
    button_font_color: "Button Font Color",
    button_font_color_desc: "Call to action button font color.",
    border: "Border",
    border_desc: "Widget and input field border color",
    reconnect_period: "Reconnect Period",
    reconnect_period_desc: "Minimum number of days before same user is shown their next survey. Defaults to 7 days.",
    sample_question: "Sample Question Text",
    option1: "Option 1",
    option2: "Option 2",
    i_think: "I think...",
    done: "Done",
    powered_by: "Powered by",
  },
};
