import React from 'react';
import { Stack, Space, Box, Text } from '@mantine/core';
import MCQChoice from './mcq-choice';
import RandomizeChoices from './RandomizeChoices';
import { StyledSelect, StyledTextInput } from '../../../../components/MantineOverride';

export function BinaryQuestion(props) {
  const { idx, question, dispatch, handleChange, surveyState } = props;
  let choiceCounter = 0;
  const nextChoiceIndex = () => choiceCounter++;

  //   function addChoice(idx, currentChoiceCount) {
  //     dispatch({
  //       type: 'addChoice',
  //       index: idx,
  //       currentCount: currentChoiceCount,
  //     });
  //   }

  //   function removeChoice(idx, choiceIdx) {
  //     dispatch({
  //       type: 'removeChoice',
  //       index: idx,
  //       choiceIndex: choiceIdx,
  //     });
  //   }

  function handleChoiceInput(idx, choiceIdx, event) {
    dispatch({
      type: 'inputChange',
      index: idx,
      choiceIndex: choiceIdx,
      targetName: event.target.name,
      targetId: event.target.id,
      value: event.target.value,
    });
  }

  return (
    <div className="shqQuestion">
      <StyledTextInput
        label={`Question ${idx + 1}`}
        name="question"
        id={`question${idx}`}
        value={question.questionText}
        maxLength={256}
        onChange={e => handleChange(idx, e)}
      />
      <Space h="md" />

      <Box>
        <Text sx={{ fontSize: '14px', fontWeight: 400, color: '#5c5f66' }}>Choices</Text>
        <Stack>
          {question.choices &&
            question.choices.map((val, cidx) => (
              <MCQChoice
                key={`c${nextChoiceIndex()}`}
                questionIndex={idx}
                choiceIndex={cidx}
                choice={val}
                handleChange={handleChoiceInput}
                dispatch={dispatch}
                choiceCount={question.choices.length}
                surveyState={surveyState}
              />
            ))}
        </Stack>
      </Box>
      <Space h="md" />
      {/* <Divider my="sm" variant="dotted" /> */}
      {/* <Group position="apart" sx={{ alignItems: 'end' }}> */}

      <Space h="md" />

      <RandomizeChoices dispatch={dispatch} qIndex={idx} qOptions={question.options} />
      {/* </Group> */}
    </div>
  );
}

// #note Button inside forms need to have a type otherwise any button will submit the form.
// this is because by default button inside a form has type "submit".
// for details refer: https://redux-form.com/8.3.0/docs/faq/buttontype.md/
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="d992ca71-a2e5-50a1-bca0-fc81cfc4899d")}catch(e){}}();
//# debugId=d992ca71-a2e5-50a1-bca0-fc81cfc4899d
