import React, { useEffect, useState } from "react";
import { SurveyForm } from "./components/form";
import { useAuthState } from "../../context/AuthContext";
import { getSurveyById } from "../../api/Survey";
import { Box } from "@mantine/core";

import { getTemplateFromSlug } from "./../../data/TemplateList";
import { useParams } from "react-router-dom";

export default function NewSurvey() {
  const { user, currentProduct, organization } = useAuthState();
  const [surveyData, setSurveyData] = useState();
  const productName = currentProduct ? currentProduct.name : organization.products[0].name;
  console.log("currentPRoduct in NewSurvey", currentProduct);
  console.log("productName in NewSurvey", productName);
  let { template } = useParams();
  const queryParams = new URLSearchParams(location.search);
  console.log("copyFrom", queryParams.get("from"));
  const copyFrom = queryParams.get("from");

  useEffect(() => {
    if (copyFrom) {
      getSurveyById(copyFrom).then(data => {
        console.log("survey data in edit form is ", data);
        setSurveyData(getTemplateFromSlug(template, productName, data));
      });
    } else {
      setSurveyData(getTemplateFromSlug(template, productName));
    }
  }, []);

  console.log("surveyData in NewSurvey", surveyData);

  return (
    <Box mt={30}>{surveyData && <SurveyForm survey={surveyData} user={user} currentProduct={currentProduct} />}</Box>
  );
}
