import React from 'react';
import { Link, Navigate, useLocation } from 'react-router-dom';

import { Paper, Text, Container, Title, Button, Group, Box } from '@mantine/core';
import { IconArrowNarrowLeft, IconCircleCheck } from '@tabler/icons-react';

export default function AccountActivated() {
  const { state } = useLocation();

  if (state && state.urlRedirect) {
    return (
      <Container size={420} my={40}>
        <Paper shadow="md" p={30} mt={30} radius="md" withBorder>
          <Box mx="auto">
            <Group position="center">
              <IconCircleCheck align="center" color="green" size={50} stroke={1} />
            </Group>

            <Title align="center"> Account Activated</Title>
            <Text color="dimmed" size="sm" align="center" mt={10}>
              You can now login with your new password.
            </Text>
            <Group position="center" mt={30}>
              <Link to="/login">
                <Button leftIcon={<IconArrowNarrowLeft />}>Back to login</Button>
              </Link>
            </Group>
          </Box>
        </Paper>
      </Container>
    );
  } else {
    return <Navigate to="/login" replace={false} />;
  }
}
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="fc3daea5-ccaf-51b2-975a-f52cbf3f0d13")}catch(e){}}();
//# debugId=fc3daea5-ccaf-51b2-975a-f52cbf3f0d13
