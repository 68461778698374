import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  Container,
  Title,
  Text,
  Center,
  TextInput,
  Button,
  Group,
  Box,
  PasswordInput,
  Space,
} from "@mantine/core";
import { ErrorBox } from "../../../components/MantineOverride";

import { useForm } from "@mantine/form";

export default function LoginForm(props) {
  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },

    validate: {
      email: value => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      password: value => (value.length > 5 ? null : "Password should be atleast 6 character long"),
    },
  });

  return (
    <Container size={520} my={40}>
      <Title align="center" sx={{ fontWeight: 900 }}>
        Welcome back!
      </Title>

      <Card shadow="md" p={30} mt={30} radius="md" withBorder>
        {props.error && props.error.error ? (
          <>
            <ErrorBox
              title={props.error.error}
              color="red"
              withCloseButton
              onClose={() => props.setError()}
              closeButtonLabel="Close alert"
            ></ErrorBox>
            {/* <Text ta="center" c="red" fz="xs">
              {props.error.error}
            </Text> */}
          </>
        ) : null}
        <Box mx="auto">
          <Center>
            <Text size="xl" weight={400}>
              Log in
            </Text>
          </Center>

          <form onSubmit={form.onSubmit((values, event) => props.handleSubmit(values, event))}>
            <TextInput label="Email" placeholder="your@email.com" {...form.getInputProps("email")} />
            <PasswordInput label="Password" mt="md" placeholder="Your Password" {...form.getInputProps("password")} />
            <Group position="right" mt="md">
              <Link to="/forgot-password" style={{ fontSize: "1.6rem", color: "#228be6" }}>
                Forgot password?
              </Link>
            </Group>

            <Group position="center">
              <Button type="submit" fullWidth mt="xl">
                Log in
              </Button>
            </Group>
            <Text color="dimmed" size="sm" align="center" mt={20}>
              Don&apos;t have an account yet?{" "}
              <Link to="/signup" style={{ fontSize: "1.6rem", color: "#228be6" }}>
                Sign up
              </Link>
            </Text>
          </form>
          <Space h="md" />
        </Box>
      </Card>
    </Container>
  );
}
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="c16ce0aa-2066-5b92-97e8-24bce77f2239")}catch(e){}}();
//# debugId=c16ce0aa-2066-5b92-97e8-24bce77f2239
