import chroma from "chroma-js";

export const graphColors = count => {
  const startColor = "#00b1d4";
  const endColor = "#ff5b43";
  return chroma.scale([startColor, endColor]).mode("lch").colors(count);
};

export const tagColors = tag => {
  switch (tag) {
    case "Bug":
      return "#f16885";
    case "Payment Issue":
      return "#ff9d8f";
    case "Usability Issue":
      return "#ffd0b6";
    case "Pricing":
      return "#ffffe0";
    case "Request":
      return "#9acee9";
    case "Feature Request":
      return "#4799dd";
    case "Appreciation":
      return "#0061c0";
    default:
      break;
  }
};
export const tagColors2 = tag => {
  switch (tag) {
    case "Bug":
      return "#F64740";
    case "General Complain":
      return "#fca5a5";
    case "Payment & Subscription":
      return "#f97316";
    case "Pricing":
      return "#fdba74";
    case "Usability Issue":
      return "#FFE74C";
    case "Feature Request":
      return "#38bdf8";
    case "Appreciation":
      return "#16DB93";
    case "General Request":
      return "#facc15";
    case "Good Customer Support":
      return "#bef264";
    case "Bad Customer Support":
      return "#7f1d1d";
    case "Customer Service":
      return "#7f1d1d";
    case "Customer Support":
      return "#16DB93";

    default:
      break;
  }
};

export const NPSColors = {
  low: "#F64740", //'rgb(255, 107, 107)',
  neutral: "#FFE74C", //'#c6c6c6',
  high: "#16DB93", //'rgb(81, 207, 102)',
};

export const SentimentColors = {
  very_negative: "#F64740",
  negative: "#F64740",
  neutral: "#FFE74C",
  mixed: "#FFE74C",
  positive: "#16DB93",
  very_positive: "#16DB93",
  unknown: "#c6c6c6",
  "": "#c6c6c6",
};

export const AVATAR_COLORS = [
  "#00876c",
  "#419c74",
  "#68b07c",
  "#8dc485",
  "#b2d890",
  "#d8ec9d",
  "#ffffad",
  "#fce28c",
  "#f8c372",
  "#f4a45f",
  "#ed8453",
  "#e2624f",
  "#d43d51",
];
//source: https://learnui.design/tools/data-color-picker.html#divergent
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="c607f38b-f59c-507d-b97a-10cc908aac6a")}catch(e){}}();
//# debugId=c607f38b-f59c-507d-b97a-10cc908aac6a
