import React, { useState } from "react";
import { Text, Card, Group } from "@mantine/core";
import CopyValueIcon from "./CopyValueIcon";
import style from "./../style.scss";

export default function APIKeyBox({ apiKey, boxWidth }) {
  const [showKey, setShowKey] = useState(false);
  const maskedKey = apiKey ? apiKey.replace(/./g, "•") : "";
  const toggleShowKey = () => setShowKey(!showKey);

  return (
    <Card p={6} withBorder onMouseEnter={() => setShowKey(true)} onMouseLeave={() => setShowKey(false)}>
      <Group>
        <Text className={style.api_key_display} sx={{ whiteSpace: "nowrap", width: boxWidth }} size={14}>
          {showKey ? apiKey : maskedKey}
        </Text>
        <CopyValueIcon value={apiKey} />
      </Group>
    </Card>
  );
}
