import React from "react";
import { Box } from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";

export default function DeleteIcon(props) {
  const { iconColor, handleClick } = props;
  return (
    <Box
      aria-label="Delete"
      sx={{
        borderRadius: "32px",
        minWidth: "28px",
        minHeight: "28px",
        height: "28px",
        width: "28px",
        padding: "0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        backgroundColor: "transparent",
        "&:hover": {
          backgroundColor: "#e9ecef",
        },
      }}
    >
      <IconTrash size={16} color={iconColor || "#868e96"} onClick={handleClick} />
    </Box>
  );
}
