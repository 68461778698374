import React, { useState, useEffect } from "react";
import { Card, Group, Collapse, Text, Badge } from "@mantine/core";
import { IconCheck, IconChevronDown } from "@tabler/icons-react";
import { StyledTextInput } from "../../../components/MantineOverride";

export function ThankYou({ dispatch, details }) {
  const [opened, setOpened] = useState(false);

  function handleOpen(e) {
    e.preventDefault();
    setOpened(o => !o);
  }

  function handleChange(e, section) {
    console.log("e.target.value", e.target.value);
    dispatch({
      type: "updateThankyou",
      section: section,
      value: e.target.value,
    });
  }

  return (
    <Card sx={{ overflow: "visible" }} shadow="sm" p="lg" radius="md" mt="md" mb="xs" withBorder cursor="pointer">
      <Group position="apart" onClick={e => handleOpen(e)}>
        <Group>
          <IconCheck size={20} stroke={2} />
          <Text size={16}>Thank you note</Text>
        </Group>
        <IconChevronDown
          size={18}
          stroke={1.5}
          color="rgb(23, 23, 56)"
          style={{
            transition: "transform 400ms ease-in-out",
            transform: `rotate(${opened ? 180 : 0}deg)`,
          }}
        />
      </Group>

      <Collapse in={opened}>
        <StyledTextInput
          label="Heading"
          id="thankyou_heading"
          name="thankyou_heading"
          value={details.heading}
          onChange={e => handleChange(e, "heading")}
        />
        <StyledTextInput
          label="Sub Heading"
          id="thankyou_subheading"
          name="thankyou_subheading"
          value={details.subheading}
          onChange={e => handleChange(e, "subheading")}
        />
      </Collapse>
    </Card>
  );
}
