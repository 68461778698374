import React from "react";
import dayjs from "dayjs";
import { IconDeviceDesktop, IconDeviceMobile, IconLink, IconMail } from "@tabler/icons-react";
import DOMPurify from "dompurify";

import relativeTime from "dayjs/plugin/relativeTime";
import dayOfYear from "dayjs/plugin/dayOfYear";
import weekOfYear from "dayjs/plugin/weekOfYear";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { ratingIconMap as iconMap } from "./constants";
import { camelizeKeys } from "humps";
import { userIdentifierTag } from "./config";

dayjs.extend(relativeTime);
dayjs.extend(weekOfYear);
dayjs.extend(dayOfYear);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
require("dayjs/locale/es");
import _ from "lodash";

export const checkIfValueExists = value => {
  console.log("@slack value is", typeof value);

  if (value === null || value === undefined) return false;
  if (Array.isArray(value) && value.length === 0) return false;
  switch (typeof value) {
    case "string":
      return value.length > 0;
    case "object":
      return _.isEmpty(value) ? false : true;
    case "number":
      return true;
    case "boolean":
      return true;
    default:
      return false;
  }
};

export function getUnixDateRangeFromTimeframe(timeframe, timezone) {
  switch (timeframe) {
    case "today":
      return [dayjs(new Date()).tz(timezone).startOf("day").unix(), dayjs(new Date()).tz(timezone).endOf("day").unix()];
    case "yesterday":
      return [
        dayjs(new Date()).subtract(1, "day").tz(timezone).startOf("day").unix(),
        dayjs(new Date()).subtract(1, "day").tz(timezone).endOf("day").unix(),
      ];
    case "last_7":
      return [dayjs(new Date()).subtract(7, "days").tz(timezone).unix(), dayjs(new Date()).tz(timezone).unix()];
    case "last_30":
      return [dayjs(new Date()).subtract(30, "days").tz(timezone).unix(), dayjs(new Date()).tz(timezone).unix()];
    case "last_60":
      return [dayjs(new Date()).subtract(60, "days").tz(timezone).unix(), dayjs(new Date()).tz(timezone).unix()];
    case "last_3M":
      return [
        dayjs(new Date()).add(1, "day").subtract(3, "month").tz(timezone).unix(),
        dayjs(new Date()).tz(timezone).unix(),
      ];
    case "last_6M":
      return [
        dayjs(new Date()).add(1, "day").subtract(6, "month").tz(timezone).unix(),
        dayjs(new Date()).tz(timezone).unix(),
      ];
    case "last_12M":
      return [
        dayjs(new Date()).add(1, "day").subtract(12, "month").tz(timezone).unix(),
        dayjs(new Date()).tz(timezone).unix(),
      ];
    default:
      return [dayjs(new Date()).subtract(7, "days").tz(timezone).unix(), dayjs(new Date()).tz(timezone).unix()];
  }
}

export function getDateStringFromtimeframe(timeframe, timezone, locale = "en") {
  switch (timeframe) {
    case "today":
      return dayjs(new Date()).tz(timezone).locale(locale).format("MMMM DD,YYYY (z)");
    case "yesterday":
      return dayjs(new Date()).subtract(1, "day").tz(timezone).locale(locale).format("MMMM DD,YYYY (z)");
    case "last_7":
      return `${dayjs(new Date()).subtract(7, "day").tz(timezone).locale(locale).format("MMMM DD,YYYY")} - ${dayjs(
        new Date()
      )
        .tz(timezone)
        .format("MMM DD,YYYY (z)")}`;
    case "last_30":
      return `${dayjs(new Date()).subtract(30, "day").tz(timezone).locale(locale).format("MMMM DD,YYYY")} - ${dayjs(
        new Date()
      )
        .tz(timezone)
        .locale(locale)
        .format("MMMM DD,YYYY (z)")}`;
    case "last_3M":
      return `${dayjs(new Date())
        .add(1, "day")
        .subtract(3, "month")
        .tz(timezone)
        .locale(locale)
        .format("MMMM DD,YYYY")} - ${dayjs(new Date()).tz(timezone).locale(locale).format("MMMM DD,YYYY (z)")}`;
    case "last_6M":
      return `${dayjs(new Date())
        .add(1, "day")
        .subtract(6, "month")
        .tz(timezone)
        .locale(locale)
        .format("MMMM DD,YYYY")} - ${dayjs(new Date()).tz(timezone).locale(locale).format("MMMM DD,YYYY (z)")}`;
    case "last_12M":
      return `${dayjs(new Date())
        .add(1, "day")
        .subtract(1, "year")
        .tz(timezone)
        .locale(locale)
        .format("MMM DD,YYYY")} - ${dayjs(new Date()).tz(timezone).format("MMMM DD,YYYY (z)")}`;
    default:
      return `${dayjs(new Date()).subtract(7, "days").tz(timezone).locale(locale).format("MMMM DD,YYYY")} - ${dayjs(
        new Date()
      )
        .tz(timezone)
        .locale(locale)
        .format("MMMM DD,YYYY (z)")}`;
  }
}

export function getTodayDateWithTz(timezone) {
  return dayjs(new Date()).tz(timezone).format("MMM DD,YYYY (z)");
}

export function ratingArray(n) {
  let ratings = [];
  for (let i = 1; i <= n; i++) {
    ratings.push(i);
  }
  return ratings;
}
export function createArrayRange(startingPoint, endPoint) {
  let initialArray = [];
  for (let i = startingPoint; i <= endPoint; i++) {
    initialArray.push(i);
  }
  return initialArray;
}

export function surveyLinkURL(accId, surveycode) {
  const surveylinkcode = window.btoa(`${accId}|${surveycode}`);
  return `${process.env.REACT_APP_SURVEY_LINK}${surveylinkcode}`;
}

export function isInViewport(element) {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export function generateAvatar(text, foregroundColor, backgroundColor, size) {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  canvas.width = size;
  canvas.height = size;

  // Draw background
  context.fillStyle = backgroundColor;
  context.fillRect(0, 0, canvas.width, canvas.height);

  // Draw text
  context.font = "bold 100px Assistant";
  context.fillStyle = foregroundColor;
  context.textAlign = "center";
  context.textBaseline = "middle";
  context.fillText(text, canvas.width / 2, canvas.height / 2);

  return canvas.toDataURL("image/png");
}

export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}
export const reorderArray = (event, originalArray) => {
  const movedItem = originalArray.filter((item, index) => index === event.oldIndex);
  const remainingItems = originalArray.filter((item, index) => index !== event.oldIndex);

  const reorderedItems = [
    ...remainingItems.slice(0, event.newIndex),
    movedItem[0],
    ...remainingItems.slice(event.newIndex),
  ];

  return reorderedItems;
};

export function moveArrayElement(from, to) {
  this.splice(to, 0, this.splice(from, 1)[0]);
}

export function cleanContent(dirty) {
  return DOMPurify.sanitize(dirty, { USE_PROFILES: { html: true }, ADD_ATTR: ["target"] });
}

export function getFromLocalStorage(key, defaultValue) {
  if (typeof window === "undefined") {
    return defaultValue;
  }

  try {
    // Get from local storage by key
    let item;
    item = window.localStorage.getItem(key);

    // Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : null;
  } catch (error) {
    // If error also return initialValue
    console.log(error);
  }
}

export function setToLocalStorage(key, value, storageType) {
  try {
    if (typeof window !== "undefined") {
      if (storageType.toLowerCase() == "session") {
        window.sessionStorage.setItem(key, JSON.stringify(value));
      } else {
        window.localStorage.setItem(key, JSON.stringify(value));
      }
    }
  } catch (error) {
    // If error also return initialValue
    console.log(error);
  }
}

export function groupBy(key) {
  return function group(array) {
    return array.reduce((acc, obj) => {
      const property = obj[key];
      acc[property] = acc[property] || [];
      acc[property].push(obj);
      return acc;
    }, {});
  };
}

export function datediff(start, end, hideSuffix = true) {
  if (start !== null && end !== null) {
    return dayjs(start).from(end, hideSuffix);
    // const startDate = dayjs(start); //.format('YYYY-MM-DD');
    // //console.log('startDate in diff', startDate);
    // const endDate = dayjs(end); //.format('YYYY-MM-DD');
    // //console.log('endDate in diff', endDate);
    // return endDate.diff(startDate, 'day');
  }
}

export function dateDiffFromToday(date, userLocale, hideSuffix = false) {
  return dayjs(date).locale(userLocale).fromNow(hideSuffix);
}

export const getPlatformIcon = platform => {
  switch (platform) {
    case "web":
      return <IconDeviceDesktop size={30} stroke={1} />;
    case "link":
      return <IconLink size={30} stroke={1} />;
    case "mobile":
      return <IconDeviceMobile size={30} stroke={1} />;
    case "email":
      return <IconMail size={30} stroke={1} />;
    default:
      break;
  }
};

export function objectIsEqual(obj1, obj2) {
  return _.isEqual(obj1, obj2);
}

export function arrayElementJoin(array) {
  if (!Array.isArray(array)) {
    return "";
  }
  if (array.length == 0) {
    return "";
  }
  if (array.length == 1) {
    return String(array[0]);
  } else if (array.length == 2) {
    return array.join(" and ");
  } else if (array.length > 2) {
    const lastElement = array.slice(-1)[0];
    const restElements = array.slice(0, array.length - 1);
    return restElements.join(", ") + " and " + lastElement;
  }
}

export function getQueryStringFromArray(array) {
  if (!Array.isArray(array)) {
    return "";
  }
  if (array.length == 0) {
    return "";
  }
  if (array.length == 1) {
    return array[0];
  } else if (array.length > 1) {
    return array.join("|");
  }
}

export function arraySummariseWith(array, conjunction = " and ") {
  if (!Array.isArray(array)) {
    return array;
  }
  if (array.length == 0) {
    return "";
  }
  if (array.length == 1) {
    return String(array[0]);
  } else if (array.length == 2) {
    return array.join(conjunction);
  } else if (array.length > 2) {
    const firstTwoElements = array.slice(0, 2);

    const restCount = array.length - 2;

    return firstTwoElements.join(", ") + " or " + `${restCount} more.`;
  }
}

export function humanizedString(str) {
  return capitalize(str.split("_").join(" "));
}
export function pluralize(count, noun, suffix = "s", onlyNoun = false) {
  if (onlyNoun) {
    return `${noun}${count !== 1 ? suffix : ""}`;
  } else {
    return `${count} ${noun}${count !== 1 ? suffix : ""}`;
  }
}
export function pythonDateFormatter(pythonDate, format) {
  if (pythonDate !== null) {
    var date = new Date(pythonDate);
    //console.log('date before dayjs is', date);
    var d = dayjs(date);
    //console.log('dayjs d is', d);
    return d.format(format);
    //return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  }
}

export function cleanSurvey(survey) {
  const clonedForm = _.cloneDeep(survey);
  const cleanedQuestions = survey.questions.filter(q => q.questionType !== null);
  console.log("in clean survey", cleanedQuestions);
  // const orderedQuestions = cleanedQuestions.map((q, idx) => {
  //   console.log('clean before', q);
  //   return (q.order = idx);
  // });

  //console.log('in clean survey ordered', orderedQuestions);

  const cleanedSurvey = _.set(clonedForm, "questions", cleanedQuestions);

  return cleanedSurvey;
}

export function keycounter() {
  let counter = 1;
  return function incrementCounter() {
    return counter++;
  };
}

export function sortByValue(a, b) {
  return b.value - a.value;
}
export function sortByCount(a, b) {
  return b.count - a.count;
}

export function capitalize(text) {
  return text.trim().replace(/^\w/, c => c.toUpperCase());
}
export function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}
export function getLocale() {
  if (navigator.languages != undefined) return navigator.languages[0];
  return "en-US";
}

export function formatTimeTick(date, timelength, userLocale = "en-US") {
  console.log("#21 userLocale", userLocale);

  const dt = new Date(date);
  let tickvalue;

  if (timelength === "month") {
    const month = dt.getMonth();
    if (month == 0) {
      tickvalue = dt.toLocaleDateString(userLocale, { month: "short", year: "2-digit" });
    } else {
      tickvalue = dt.toLocaleDateString(userLocale, { month: "short" });
    }
  } else if (timelength === "week") {
    if (dayjs(dt).week() === 1) {
      tickvalue = dt.toLocaleDateString(userLocale, { month: "short", day: "numeric", year: "2-digit" });
    } else {
      tickvalue = dt.toLocaleDateString(userLocale, { month: "short", day: "numeric" });
    }
  } else if (timelength === "year") {
    tickvalue = dt.toLocaleDateString(userLocale, { year: "numeric" });
  } else if (timelength === "day") {
    if (dayjs(dt).dayOfYear() === 1) {
      tickvalue = dt.toLocaleDateString(userLocale, { month: "short", day: "numeric", year: "2-digit" });
    } else {
      tickvalue = dt.toLocaleDateString(userLocale, { month: "short", day: "numeric" });
    }
  }
  return tickvalue;
}

export function eventHumanString(event) {
  switch (event) {
    case "SURVEY_ANSWERED":
      return "On every response";
    case "SURVEY_COMPLETED":
      return "On survey completion";
    default:
      return event;
  }
}

export function isValidHttpsUrl(string) {
  try {
    const url = new URL(string);
    return url.protocol === "https:";
  } catch (err) {
    return false;
  }
}

export function dateObjectToString(date, timeformat) {
  return dayjs(date).format(timeformat);

  //return `${Intl.DateTimeFormat(userLocale, timeformat).format(date)}`;
}

export function getDurationString(date, timelength, userLocale = "en-US") {
  const dt = new Date(date);
  let timeformat;
  let endDate;

  const startDate = dayjs(dt);

  if (timelength == "day") {
    timeformat = {
      month: "short",
      day: "numeric",
      year: "numeric",
    };
    return `${Intl.DateTimeFormat(userLocale, timeformat).format(startDate)}`;
  } else {
    if (timelength === "week") {
      timeformat = {
        month: "short",
        day: "numeric",
      };
      endDate = startDate.add(6, "day");
    } else if (timelength === "year") {
      timeformat = {
        month: "short",
        year: "2-digit",
        day: "numeric",
      };
      endDate = startDate.endOf("year");
    } else {
      timeformat = {
        month: "short",
        year: "2-digit",
        day: "numeric",
      };
      endDate = startDate.endOf("month");
    }

    return `${Intl.DateTimeFormat(userLocale, timeformat).format(startDate)} - ${Intl.DateTimeFormat(
      userLocale,
      timeformat
    ).format(endDate)}`;
  }
}

export const logicOptions = [
  { value: "gt", label: "is greater than" },
  { value: "gte", label: "is greater than or equal to" },
  { value: "lt", label: "is less than" },
  { value: "lte", label: "is less than or equal to" },
  { value: "eq", label: "is equal to" },
  { value: "neq", label: "is not equal to" },
  { value: "con", label: "contains" },
  { value: "dcon", label: "does not contain" },
  { value: "sub", label: "is submitted" },
  { value: "incao", label: "includes at least one" },
  { value: "incal", label: "includes all" },
  { value: "dinc", label: "does not include" },
  { value: "skip", label: "is skipped" },
];

export function parseLogicFlow(originQuestionOrder, questionId, logicFlow) {
  console.log("parseLogicFlow", logicFlow);
  if (logicFlow.length === 0) {
    return;
  }

  const final = [];

  function parsedString(logic, logicLabel, originOrder, predicateString) {
    if (logic === "incao" || logic === "incal") {
      return `Asked when answer to question ${originOrder + 1} ${logicLabel} of ${predicateString}`;
    } else if (logic === "sub" || logic === "skip") {
      return `Asked when answer to question ${originOrder + 1} ${logicLabel}`;
    } else {
      return `Asked when answer to question ${originOrder + 1} ${logicLabel} ${predicateString}`;
    }
  }

  logicFlow.map(l => {
    if (l.logic.length === 0) return;
    const predicateString = Array.isArray(l.predicate)
      ? l.predicate.length > 1
        ? l.predicate.slice(0, -1).join(",") + " and " + l.predicate.slice(-1)
        : l.predicate[0]
      : l.predicate || "";
    const logicLabel = logicOptions.filter(lo => lo.value === l.logic)[0].label;
    const obj = {};
    obj[questionId] = parsedString(l.logic, logicLabel, originQuestionOrder, predicateString);

    final.push({
      nextQ: l.next_q,
      message: parsedString(l.logic, logicLabel, originQuestionOrder, predicateString),
      qid: questionId,
    });
  });
  return final;
}

export function getCurrentTimestampInSecs() {
  const currentTime = new Date();
  const currentTimeStamp = currentTime.getTime() / 1000;
  return currentTimeStamp;
}

export function isValidURL(stri) {
  console.log("yooosdsdsdsd", stri);
  let rUrl =
    // eslint-disable-next-line
    /^((https?):)?\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;
  const pattern = new RegExp(rUrl);
  console.log("pattern test", pattern.test(stri));
  return pattern.test(stri);
}

export const exportData = (data, fileName, type) => {
  // Create a link and download the file
  const blob = new Blob([data], { type });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
};

export const removeKeyFromObject = (obj, obj_key) => {
  Object.keys(obj).forEach(
    key =>
      (key === obj_key && delete obj[key]) ||
      (obj[key] && typeof obj[key] === "object" && removeKeyFromObject(obj[key], obj_key))
  );
  return obj;
};
export function getLastNDiffDates(n = 7, timeUnit = "day") {
  return [dayjs(new Date()).subtract(n, timeUnit).toDate(), dayjs(new Date()).toDate()];
}

export function parseRatingResponse(question, answer) {
  if (question.question_type === 5) {
    if (question.options.rating_range) {
      if (question.options.rating_scale === "numerical") {
        return answer;
      } else {
        return iconMap[question.options.rating_scale][question.options.rating_range][answer];
      }
    } else {
      return answer;
    }
  } else {
    return answer;
  }
}

export function showIntroduction(form) {
  const formState = camelizeKeys(form);
  const showIntroduction =
    "showIntroduction" in formState.surveyOptions
      ? formState.surveyOptions.showIntroduction
      : formState.surveyOptions.introduction
      ? true
      : false;
  return showIntroduction;
}
