export function isAccountOwnerOrSelf(requestingUser, member) {
  if (requestingUser.isAccountOwner) {
    return true;
  }
  if (requestingUser.id !== member.id) {
    return false;
  } else {
    return true;
  }
}
export function userAccessLevel(currentUser) {
  if (currentUser.isAccountOwner) {
    return 'isAccountOwner';
  }
  switch (currentUser.accessLevel) {
    case 1:
      return 'Editor';
    case 2:
      return 'Developer';
    default:
      return 'Viewer';
  }
}
export function isEditor(requestingUser) {
  return requestingUser.isAccountOwner || requestingUser.accountRole == 1;
}

export function isViewer(requestingUser) {
  return requestingUser.accountRole == 3;
}

// export function editorCan() {
//   return {};
// }

const allPermissions = [
  'can_add_team_member',
  'can_edit_team_member',
  'can_edit_invited_member',
  'can_reinvite_member',
  'can_edit_product',
  'can_add_product',
  'can_edit_appearance',
  'can_edit_reconnect_period',
  'can_add_survey',
  'can_edit_survey',
  'can_delete_survey',
  'can_archive_survey',
  'can_export_report',
  'can_add_repondents',
  'can_delete_respondents',
  'can_edit_respondents',
  'can_add_user_attributes',
  'can_delete_user_attributes',
  'can_edit_user_attributes',
  'can_manage_billing',
  'can_edit_self_account',
  'can_delete_self_account',
];

const editorPermissions = [
  'can_edit_product',
  'can_add_product',
  'can_edit_appearance',
  'can_edit_reconnect_period',
  'can_add_survey',
  'can_edit_survey',
  'can_delete_survey',
  'can_archive_survey',
  'can_export_report',
  'can_add_repondents',
  'can_delete_respondents',
  'can_edit_respondents',
  'can_add_user_attributes',
  'can_delete_user_attributes',
  'can_edit_user_attributes',
  'can_edit_self_account',
  'can_delete_self_account',
];

const developerPermissions = [
  'can_add_survey',
  'can_edit_survey',
  'can_delete_survey',
  'can_archive_survey',
  'can_export_report',
  'can_add_repondents',
  'can_delete_respondents',
  'can_edit_respondents',
  'can_add_user_attributes',
  'can_delete_user_attributes',
  'can_edit_user_attributes',
  'can_edit_self_account',
  'can_delete_self_account',
];

const viewerPermissions = ['can_edit_self_account', 'can_delete_self_account'];

export function checkUserCan(user, permission) {
  if (user.accessLevel == 1) {
    return editorPermissions.includes(permission);
  } else if (user.accessLevel == 2) {
    return developerPermissions.includes(permission);
  } else {
    return viewerPermissions.includes(permission);
  }
}
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="f5f45e1d-c813-5ce9-8267-8d50b8fecfb6")}catch(e){}}();
//# debugId=f5f45e1d-c813-5ce9-8267-8d50b8fecfb6
