import axios from "axios";

import { decamelizeKeys, camelizeKeys } from "humps";

const APIBase = axios.create({
  //baseURL: 'http://staging.api.surveyhq.io/api/v1/',
  baseURL: process.env.REACT_APP_SERVER_URL + "api/v1/",
  headers: { "Content-Type": "application/json", Accept: "application/json" },
  withCredentials: true,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken",
  transformRequest: [
    function (data, headers) {
      //console.log('headers in axios create is', headers);
      // Do whatever you want to transform the data

      return decamelizeKeys(data);
    },
  ],
});

// // Add a request interceptor
// APIBase.interceptors.request.use(
//   function (config) {
//     console.log('config is', config);
//     if (config.method === 'post') {
//       const _csrfToken = useCSRF();
//       console.log('csrf token is', _csrfToken);
//       if (_csrfToken) {
//         config.headers['X-CSRFToken'] = _csrfToken;
//       }
//     }
//     return config;
//   },
//   function (error) {
//     console.log(error);
//     // Do something with request error
//     return Promise.reject(error);
//   }
// );

// // Add a response interceptor
// APIBase.interceptors.response.use(
//   function (response) {
//     // Any status code that lie within the range of 2xx cause this function to trigger
//     // Do something with response data
//     return camelizeKeys(response);
//   },
//   function (error) {
//     // console.log('error in APIBase is', error.response);
//     // if (error.response) {
//     //   if (error.response.status === 403 || error.response.status === 401) {
//     //     console.log(error.response.data.message);
//     //   }
//     //   if (error.response.status === 500) {
//     //     console.log('API error:', error.response.data.message);
//     //   }
//     // }

//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     // Do something with response error
//     return Promise.reject(error);
//   }
// );
export default APIBase;
