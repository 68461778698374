import React, { memo, useState, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "../../../../../components/ErrorFallback";
import * as Sentry from "@sentry/react";
import {
  Card,
  Table,
  Group,
  RingProgress,
  Text,
  Flex,
  Box,
  Stack,
  Tooltip,
  useMantineTheme,
  Button,
} from "@mantine/core";

import {
  IconChevronRight,
  IconInfoCircle,
  IconMoodHappy,
  IconMoodSmile,
  IconMoodNeutral,
  IconMoodAnnoyed2,
  IconMoodAngry,
  IconMoodConfuzed,
  IconQuestionMark,
} from "@tabler/icons-react";
import { SentimentColors } from "../../../../../utils/colorScheme";
import { sortByCount } from "../../../../../utils/helpers";
import VerbatimModal from "./VerbatimModal";
import { API_URLS } from "../../../../../utils/constants";
import { useIntl } from "react-intl";

export const SummaryReport = memo(function SummaryReport({
  sentimentData,
  tagsData,
  themesData,
  opened,
  setOpened,
  questionId,
  surveyId,
  interval,
  dateRange,
  setSearchParams,
  searchParams,
}) {
  console.log("SummaryReport", sentimentData);

  const theme = useMantineTheme();
  const intl = useIntl();

  const [clickCount, setClickCount] = useState(0);
  const [currentSentiment, setCurrentSentiment] = useState();

  const sentimentList = ["Positive", "Neutral", "Negative", "Unknown"];

  const sentimentIconList = {
    "Very Positive": (
      <IconMoodHappy size={40} color={SentimentColors["very_positive".toLowerCase().replace(" ", "_")]} />
    ),

    Positive: <IconMoodSmile size={40} color={SentimentColors["positive".toLowerCase().replace(" ", "_")]} />,
    Neutral: <IconMoodNeutral size={40} color={SentimentColors["neutral".toLowerCase().replace(" ", "_")]} />,
    Mixed: <IconMoodConfuzed size={40} color={SentimentColors["mixed".toLowerCase().replace(" ", "_")]} />,
    Negative: <IconMoodAnnoyed2 size={40} color={SentimentColors["negative".toLowerCase().replace(" ", "_")]} />,
    "Very Negative": (
      <IconMoodAngry size={40} color={SentimentColors["very_negative".toLowerCase().replace(" ", "_")]} />
    ),

    Unknown: <IconQuestionMark size={40} color={SentimentColors["unknown".toLowerCase().replace(" ", "_")]} />,

    "": <IconQuestionMark size={40} color={SentimentColors["unknown".toLowerCase().replace(" ", "_")]} />,
  };

  const sentimentDataList = sentimentList.map(sentiment => {
    if (sentimentData.find(s => s.sentiment === sentiment)) {
      //   console.log("sentiment", sentiment);
      return {
        emotion: sentiment,
        label: `${sentiment}: ${Math.round(sentimentData.find(s => s.sentiment === sentiment)?.count_pct)}% `,
        value: parseFloat(sentimentData.find(s => s.sentiment === sentiment)?.count_pct),
        color: SentimentColors[sentiment.toLowerCase().replace(" ", "_")],
        tooltip: `${sentiment}: ${sentimentData.find(s => s.sentiment === sentiment)?.count_pct}%`,
        icon:
          sentiment === "" ? (
            <IconQuestionMark size={20} color={SentimentColors["unknown".toLowerCase().replace(" ", "_")]} />
          ) : (
            sentimentIconList[sentiment]
          ),
      };
    }
  });

  const NHS =
    parseFloat(sentimentData.find(s => s.sentiment === "Positive")?.count_pct) -
    parseFloat(sentimentData.find(s => s.sentiment === "Negative")?.count_pct);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
      onError={error => {
        // log the error to your error reporting service
        Sentry.captureException(error);
      }}
    >
      <Card shadow="sm" p="lg" radius="md" withBorder>
        <Text weight={700} align="center">
          {intl.formatMessage({ id: "reports.sentiment", defaultMessage: "Sentiment" })}
        </Text>

        {/* <Progress radius="sm" size={20} sections={sentimentDataList} /> */}

        <Group position="center" spacing={100} align="flex-end">
          <Stack spacing={0}>
            <RingProgress
              size={140}
              thickness={16}
              label={
                <Flex mih={50} justify="center" align="center" direction="column" wrap="nowrap">
                  <Text size="30px" weight={400} align="center" px="xs" sx={{ pointerEvents: "none" }}>
                    {Math.ceil(NHS)}
                  </Text>
                </Flex>
              }
              sections={sentimentDataList.filter(s => s !== undefined)}
            />
            <Group align="flex-end" spacing={6}>
              <Text size="xs" align="center">
                {intl.formatMessage({ id: "reports.nhs", defaultMessage: "Net Happiness Score" })}
              </Text>
              <Tooltip
                withArrow
                label={intl.formatMessage({
                  id: "reports.nhs_desc",
                  defaultMessage:
                    "Net Happiness Score is the difference between the percentage of Positive and Negative sentiment.",
                })}
              >
                <IconInfoCircle stroke={1} size={20} color={theme.colors.gray[5]} />
              </Tooltip>
            </Group>
          </Stack>

          <Flex gap={30}>
            {console.log("@sentimentList", sentimentDataList)}
            {sentimentDataList.map((sentiment, index) => {
              if (sentiment) {
                console.log("@sentiment", sentiment);
                return (
                  <Stack key={index}>
                    {sentiment.icon}

                    <Text align="center" weight={"bold"} color="dark.5" size={18}>
                      {Math.round(parseFloat(sentiment.value)) + "%"}
                    </Text>
                    <Text size={12}>{sentiment.emotion}</Text>
                  </Stack>
                );
              }
            })}
          </Flex>
        </Group>
      </Card>
      <Card sx={{ flexGrow: 1 }} shadow="sm" p="lg" radius="md" withBorder>
        <TagTable
          tagsData={tagsData}
          setOpened={setOpened}
          opened={opened}
          questionId={questionId}
          surveyId={surveyId}
          interval={interval}
          dateRange={dateRange}
        />
      </Card>

      <Group position="apart" grow>
        <Card shadow="sm" p="lg" radius="md" withBorder mt={20}>
          <ThemeTable
            themesData={themesData}
            setOpened={setOpened}
            opened={opened}
            questionId={questionId}
            surveyId={surveyId}
            interval={interval}
            dateRange={dateRange}
            theme={theme}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
        </Card>
      </Group>
      {currentSentiment && (
        <VerbatimModal
          clickCount={clickCount}
          opened={opened}
          surveyId={surveyId}
          tagType="sentiment"
          tagValue={currentSentiment}
          setOpened={setOpened}
          page1_url={API_URLS.GET_TAGGED_RESPONSES(surveyId, dateRange, questionId)}
          duration={interval}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
      )}
    </ErrorBoundary>
  );
});

function ThemeTable(props) {
  const {
    themesData,
    opened,
    setOpened,
    surveyId,
    questionId,
    dateRange,
    interval,
    theme,
    searchParams,
    setSearchParams,
  } = props;
  const [themeClickCount, setThemeClickCount] = useState(0);
  const [currentTheme, setCurrentTheme] = useState();
  const [showAll, setShowAll] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    let themeInUrl = searchParams.get("theme");
    setCurrentTheme(themeInUrl);
    setOpened(true);
  }, [searchParams.get("theme")]);

  const rows = themesData
    .filter(theme => (showAll ? Math.round(theme.count_pct) > -1 : Math.round(theme.count_pct) > 2))
    .sort(sortByCount)
    .map(element => (
      <tr
        className="theme-row"
        style={{ cursor: "pointer" }}
        key={element.theme}
        onClick={() => {
          setCurrentTheme(element.theme);
          setThemeClickCount(themeClickCount + 1);
          searchParams.append("theme", element.theme);

          setSearchParams(searchParams, { replace: true, state: null });
          setOpened(true);
        }}
      >
        <td>{element.theme}</td>
        <td>{element.count}</td>
        <td>{Math.round(element.count_pct)}%</td>

        <td style={{ textAlign: "right" }}>
          <IconChevronRight color="gray" size={14} />
        </td>
      </tr>
    ));

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
      onError={error => {
        // log the error to your error reporting service
        Sentry.captureException(error);
      }}
    >
      <Table striped highlightOnHover withBorder>
        <thead>
          <tr>
            <th>
              <Group spacing={6}>
                {showAll
                  ? intl.formatMessage({ id: "reports.ai_allthemes", defaultMessage: "All Themes" })
                  : intl.formatMessage({ id: "reports.ai_topthemes", defaultMessage: "Top Themes" })}

                <Tooltip
                  label={intl.formatMessage({
                    id: "reports.ai_themecutoff_desc",
                    defaultMessage: "Themes with at least 3% share are shown.",
                  })}
                >
                  <IconInfoCircle stroke={1} size={20} color={theme.colors.gray[5]} />
                </Tooltip>
              </Group>
            </th>
            <th>{intl.formatMessage({ id: "reports.count", defaultMessage: "Count" })}</th>
            <th>{intl.formatMessage({ id: "reports.percent", defaultMessage: "Percent" })}</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      <Box sx={{ textAlign: "center" }} mt={10}>
        <Button
          variant="subtle"
          onClick={() => {
            setShowAll(!showAll);
          }}
        >
          <Text weight={400}>
            {showAll
              ? intl.formatMessage({ id: "reports.view_less", defaultMessage: "View less" })
              : intl.formatMessage({ id: "reports.view_all", defaultMessage: "View all" })}
          </Text>
        </Button>
      </Box>
      {currentTheme && (
        <VerbatimModal
          clickCount={themeClickCount}
          opened={opened}
          tagType="theme"
          tagValue={currentTheme}
          setOpened={setOpened}
          page1_url={API_URLS.GET_TAGGED_RESPONSES(surveyId, dateRange, questionId)}
          duration={interval}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        />
      )}
    </ErrorBoundary>
  );
}

function TagTable(props) {
  const { tagsData, opened, setOpened, surveyId, questionId, dateRange, interval } = props;
  const [tagClickCount, setTagClickCount] = useState(0);
  const [currentTag, setCurrentTag] = useState();
  const intl = useIntl();

  const rows = tagsData.sort(sortByCount).map(element => (
    <tr
      key={element.tag}
      onClick={() => {
        setCurrentTag(element.tag);
        setTagClickCount(tagClickCount + 1);
        setOpened(true);
      }}
      style={{ cursor: "pointer" }}
    >
      <td>{element.tag}</td>
      <td>{element.count}</td>
      <td>{Math.round(element.count_pct)}%</td>
      <td style={{ textAlign: "right" }}>
        <IconChevronRight color="gray" size={14} />
      </td>
    </tr>
  ));

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
      onError={error => {
        // log the error to your error reporting service
        Sentry.captureException(error);
      }}
    >
      <Table striped highlightOnHover withBorder>
        <thead>
          <tr>
            <th>{intl.formatMessage({ id: "reports.itdentified_tags", defaultMessage: "Identified Tags" })}</th>
            <th>{intl.formatMessage({ id: "reports.count", defaultMessage: "Count" })}</th>
            <th>{intl.formatMessage({ id: "reports.percent", defaultMessage: "Percent" })}</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      {currentTag && (
        <VerbatimModal
          clickCount={tagClickCount}
          opened={opened}
          tagType="tag"
          tagValue={currentTag}
          setOpened={setOpened}
          page1_url={API_URLS.GET_TAGGED_RESPONSES(surveyId, dateRange, questionId)}
          duration={interval}
        />
      )}
    </ErrorBoundary>
  );
}
