import React, { useState, useEffect } from "react";
import { fetchSurveyQuestionResponseData } from "../../../../../api/Survey";
import { PieGraph, BarGraph2, LineGraph2, TimeseriesGraph } from "../../charts";

import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "../../../../../components/ErrorFallback";
import * as Sentry from "@sentry/react";
import { Text, Center, Loader, Space, Group } from "@mantine/core";

import NoData from "../NoData";
import TimeSegment from "../TimeSegment";
import { useIntl } from "react-intl";

import ChartSegmentControl from "../../ChartSegmentControl";

export function MCQReport(props) {
  const { questionId, surveyId, graphType, setGraphType, dateRange, questionType, searchParams } = props;
  const [responseData, setResponseData] = useState();
  const [loader, setLoader] = useState(true);
  const [interval, setInterval] = useState("week");
  const [error, setError] = useState();
  const intl = useIntl();

  console.log("MCQ dateRange", dateRange);

  useEffect(() => {
    fetchSurveyQuestionResponseData(surveyId, questionId, dateRange, interval, questionType, searchParams)
      .then(data => {
        console.log("MCQ DATA in fetchSurveyQuestionResponseData", data);
        setResponseData(data);
      })
      .catch(error => {
        console.log("error in fetchSurveyQuestionResponseData", error);
        setError(error);
      })
      .finally(setLoader(false));
  }, [dateRange, interval, searchParams]);

  const timelineData = responseData && responseData.timeseries;
  console.log("MCQ Timeline data", timelineData);
  const aggregateData = responseData && responseData.summary;

  const aggregateChartData =
    aggregateData &&
    aggregateData
      .sort((a, b) => a.answer - b.answer)
      .map(resp => {
        return {
          answer: resp["answer"],
          count: resp["response_count"],
          percentage: resp["response_pct"],
        };
      });

  // const timelineChartData =
  //   timelineData &&
  //   timelineData
  //     .sort((a, b) => a['grouped_date'] - b['grouped_date'])
  //     .map(resp => {
  //       return {
  //         grouped_date: resp['grouped_date'],
  //         answer: resp['answer'],
  //         count: resp['response_count'],
  //         percentage: resp['response_pct'],
  //       };
  //     });

  console.log("chart data in MCQ is ", aggregateChartData);
  const graphOptions = {
    xAxisProps: {
      dataKey: "answer",
      //tick: npsTick,
      //tickFormatter: timeTickFormatter,

      interval: 0,
    },
    yAxisProps: {
      label: {
        value: intl.formatMessage({ id: "reports.response_count", defaultMessage: "Response count" }),
        fontSize: 14,
        angle: -90,
      },

      type: "number",
      tick: { fill: "#5C5F66", strokeWidth: 0.5, fontSize: 12 },
    },
    margin: {
      top: 30,
      right: 30,
      left: 20,
      bottom: 30,
    },
  };

  function renderChart(type) {
    if (type === "bar") {
      return (
        <>
          {/* <Text align="center">Rating Distribution</Text> */}
          <BarGraph2 chartData={aggregateChartData} graphOptions={graphOptions} />
        </>
      );
    } else if (type === "line") {
      return (
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => {
            // reset the state of your app so the error doesn't happen again
          }}
          onError={error => {
            // log the error to your error reporting service
            Sentry.captureException(error);
          }}
        >
          <TimeseriesGraph
            chartData={timelineData}
            dataKey="answer"
            chartType="line"
            interval={interval}
            setInterval={setInterval}
            qtype={questionType}
          />
        </ErrorBoundary>
      );
    } else if (type === "pie") {
      return <PieGraph chartData={aggregateChartData} graphOptions={graphOptions} />;
    }
  }

  if (error) {
    return (
      <Text>
        {error.message || intl.formatMessage({ id: "message.error", defaultMessage: "Something went wrong." })}
      </Text>
    );
  }

  if (!aggregateChartData) {
    return <NoData />;
  }

  return loader ? (
    <Center>
      <Loader />
    </Center>
  ) : aggregateChartData ? (
    <>
      <Group position={"right"}>
        {graphType.get(questionId) == "line" && <TimeSegment interval={interval} setInterval={setInterval} />}

        <ChartSegmentControl
          graphType={graphType}
          setGraphType={setGraphType}
          questionId={questionId}
          show={["bar", "pie", "line"]}
        />
      </Group>
      <Space h="md" />

      <div className="chartContainer">{renderChart(graphType.get(questionId))}</div>
    </>
  ) : (
    <Center>
      <Loader />
    </Center>
  );
}
