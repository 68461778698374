import React, { useEffect, useRef } from "react";
import { MultiDirectedGraph } from "graphology";

import Sigma from "sigma";

export const SurveyFlowDiagram = ({ surveyData }) => {
  const containerRef = useRef(null);

  const parsedQId = id => {
    //console.log('id', id, 'if -1', parseInt(surveyData.length), 'else', parseInt(id));
    return id == -1 ? parseInt(surveyData.length) : parseInt(id);
  };

  const calculateNodePositions = surveyData => {
    let levelMap = new Map();
    let columnMap = new Map();

    const assignLevelAndColumn = (questionId, level, column) => {
      console.log("questionId", questionId, "level", level, "column", level);
      console.log("levelMap has", levelMap.has(questionId));
      if (!levelMap.has(questionId)) {
        levelMap.set(questionId, level);
        columnMap.set(questionId, column);

        const question = surveyData.find(q => q.id === questionId);
        console.log("found question", question);
        if (question && question.branches) {
          let nextColumn = column;
          question.branches.forEach((branch, index) => {
            console.log("level", level, ":questionId in ~~", branch.nextQuestionId);
            assignLevelAndColumn(branch.nextQuestionId, level + 1, nextColumn);
            nextColumn += 1; // Increment column for each branch
          });
        } else {
          return;
        }
      }
    };

    // Start by assigning the first question level 0 and column 0
    assignLevelAndColumn(parsedQId(surveyData[0].id), 0, 0);

    return surveyData.map(question => {
      console.log("##", levelMap);
      const level = levelMap.get(parsedQId(question.id));
      const column = columnMap.get(parsedQId(question.id));
      console.log("qid", question.id, "parsedqId", parsedQId(question.id), "level", level, "column", column);

      let xPosition = column === undefined ? 999 : column * 100; // Adjust horizontal spacing as needed
      let yPosition = column === undefined ? 999 : level * 100; // Adjust vertical spacing as needed

      return {
        ...question,
        x: 1 * xPosition,
        y: -1 * yPosition,
      };
    });
  };

  useEffect(() => {
    console.log("Survey Data:", surveyData); // Log the survey data for inspection

    const graph = new MultiDirectedGraph();

    const positionedData = calculateNodePositions(surveyData);

    // Add nodes with calculated positions
    positionedData.forEach(question => {
      console.log("Adding node with ID:", question.id, "Type of ID:", typeof question.id);
      console.log("x:", question.x, "| y:", question.y);
      if (typeof question.id === "string" || typeof question.id === "number") {
        graph.addNode(question.id, {
          label: question.text,
          x: question.x,
          y: question.y,
          size: 10,
        });
      } else {
        console.error("Invalid ID for node:", question.id); // Log if ID is not valid
      }
    });

    // Add edges based on survey logic
    surveyData.forEach(question => {
      if (question.branches) {
        question.branches.forEach(branch => {
          if (graph.hasNode(branch.nextQuestionId)) {
            console.log("Adding edge from", question.id, "to", branch.nextQuestionId); // Log edge addition
            graph.addEdge(question.id, branch.nextQuestionId, {
              id: `edge-${question.id}-${branch.nextQuestionId}`,
              type: "arrow",
              label: `${branch.condition}`,
              size: 3,
            });
          } else {
            //console.error('Target node not found:', branch.nextQuestionId); // Log error if target node is missing
          }
        });
      }
    });

    // graph.nodes().forEach((node, i) => {
    //   const angle = (i * -1 * Math.PI) / graph.order;
    //   graph.setNodeAttribute(node, 'x', 100 * Math.sin(angle));
    //   graph.setNodeAttribute(node, 'y', 100 * Math.cos(angle));
    // });

    const sigmaInstance = new Sigma(graph, containerRef.current, {
      allowInvalidContainer: true,
      renderEdgeLabels: true,
    });

    return () => {
      sigmaInstance.kill();
    };
  }, [surveyData]);

  return <div ref={containerRef} style={{ width: "100%", height: "100vh" }} />;
};
